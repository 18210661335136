import {AppUpdateBanner} from "@components";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  flourishApi,
  setWorkflowMappingId,
  setWorkflowStaffId,
  useAppDispatch,
  useGetUsersQuery,
  useGetWorkflowMappingsQuery,
  usePostWorkflowMappingsMutation,
  User,
  userName,
  useSelectWorkflowStaffId,
} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Box, IconButton, SelectField, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

export interface WorkflowListFilter {
  name?: string;
}
interface WorkflowListHeaderProps {
  onListFilterChange: (filter: WorkflowListFilter) => void;
}

// This component goes at the top of the Workflow Mapping list.
// It allows selecting the current workflow and manually adding Users to the workflow as workflow
// mappings. In the future it'll support filtering and bulk actions.
export const WorkflowListHeader = ({
  onListFilterChange,
}: WorkflowListHeaderProps): ReactElement | null => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const dispatch = useAppDispatch();
  const profile = useReadProfile();
  const toast = useToast();

  const [createWorkflowMapping] = usePostWorkflowMappingsMutation();
  // TODO: Figure out a longer term fix for showing all staff panels. We'll grow past 100 staff
  // soon.
  const {data: staffData} = useGetUsersQuery({type: UserTypes.Staff, limit: 100, disabled: false});

  const staffId = useSelectWorkflowStaffId();
  const selectedStaff = staffData?.data?.find((s) => s._id === staffId);
  const workflowMapping = useGetWorkflowMappingsQuery(staffId ? {staffId} : skipToken);
  const [nameFilter, setNameFilter] = useState("");

  const otherWorkflowOptions = staffData?.data
    ?.filter((u) => u?._id !== profile?._id && !u?.testUser)
    .map((w) => ({
      label: `${userName(w)} ${w?.testUser ? " (Test User)" : ""}`,
      value: w._id,
    }))
    // Sort alphabetically by label
    .sort((w1, w2) => (w1.label < w2.label ? -1 : 1));

  const testStaffWorkflowOptions = staffData?.data
    ?.filter((u) => u?._id !== profile?._id && u?.testUser)
    .map((w) => ({
      label: `${userName(w)} (Test User)`,
      value: w._id,
    }))
    // Sort alphabetically by label
    .sort((w1, w2) => (w1.label < w2.label ? -1 : 1));

  // If nothing is already selected, select the first item in the list.
  useEffect(() => {
    if (!selectedStaff && !staffId && profile) {
      dispatch(setWorkflowStaffId(profile._id));
    }
  }, [profile, staffId, selectedStaff, dispatch]);

  if (!profile) {
    return null;
  }

  // Make sure the user's workflows are at the top.
  const workflowOptions = [
    {
      label: `Me - ${userName(profile)}`,
      value: profile._id,
    },
    ...(otherWorkflowOptions ?? []),
    ...(testStaffWorkflowOptions ?? []),
  ];

  async function addWorkflowMapping(user: User | null): Promise<void> {
    if (!user) {
      return;
    }

    let wf;
    try {
      wf = await createWorkflowMapping({
        userId: user._id,
        staffId,
      }).unwrap();
    } catch (error) {
      toast.catch(error);
      return;
    }

    dispatch(setWorkflowMappingId(wf._id));
  }

  const handleOnListFilterChange = ({name}: WorkflowListFilter): void => onListFilterChange({name});

  return (
    <>
      <AppUpdateBanner />
      <Box
        alignItems="center"
        color="base"
        direction="column"
        marginBottom={2}
        maxWidth="100%"
        padding={2}
        rounding="md"
      >
        <Box direction="row" maxWidth="100%" width="100%">
          <Box flex="grow" marginRight={2}>
            <SelectField
              options={workflowOptions}
              requireValue={false}
              value={staffId}
              onChange={async (value): Promise<void> => {
                dispatch(setWorkflowStaffId(value));
                dispatch(flourishApi.util.invalidateTags(["usersettings"]));
              }}
            />
          </Box>
          <Box alignItems="center" direction="row" justifyContent="center">
            {/* TODO: figure out how to handle disabled icon button */}
            <IconButton
              accessibilityLabel="add"
              iconName="user-plus"
              tooltipText="Add user to panel"
              variant="secondary"
              onClick={(): void => {
                navigation.navigate("UserPicker", {
                  title: "Add user to panel",
                  patient: true,
                  familyMember: true,
                  // Remove this when we have UserExplorer
                  staff: true,
                  onSelect: addWorkflowMapping,
                  userFilter: (u: User | null): boolean => {
                    return Boolean(
                      !workflowMapping?.data?.data?.find((wm) => u?._id === wm?.userId)
                    );
                  },
                });
              }}
            />
          </Box>
        </Box>
        <Box flex="grow" marginTop={2} width="100%">
          <TextField
            placeholder="Search by name..."
            type="text"
            value={nameFilter}
            onChange={(value: string): void => {
              setNameFilter(value);
              handleOnListFilterChange({name: value});
            }}
          />
        </Box>
      </Box>
    </>
  );
};
