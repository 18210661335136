import {
  Conversation,
  usePatchConversationsByIdMutation,
  useUpdateConversationMutedMutation,
} from "@store";
import {Modal, Text, useTheme, useToast} from "ferns-ui";
import React, {ReactElement, useCallback, useState} from "react";
import {Pressable, View} from "react-native";

import {useSelectCurrentUserId} from "../ferns-rtk";

interface InternalChatConversationSettingsMenuProps {
  conversation: Conversation | undefined;
  showSettingsMenu: boolean;
  setShowSettingsMenu: (show: boolean) => void;
  onLeaveConversation: () => void;
}

export const InternalChatConversationSettingsMenu = ({
  conversation,
  showSettingsMenu,
  setShowSettingsMenu,
  onLeaveConversation,
}: InternalChatConversationSettingsMenuProps): ReactElement | null => {
  const {theme} = useTheme();
  const toast = useToast();
  const currentUserId = useSelectCurrentUserId();

  const [updateConversationMuted, {isLoading: updateConversationMutedLoading}] =
    useUpdateConversationMutedMutation();
  const [updateConversation, {isLoading: updateConversationLoading}] =
    usePatchConversationsByIdMutation();
  const [showLeaveConversationConfirmation, setShowLeaveConversationConfirmation] = useState(false);

  const handleLeaveConversation = useCallback(async (): Promise<void> => {
    if (!conversation || !currentUserId) {
      return;
    }

    const remainingUsers = conversation.users
      ?.map((u) => ({
        userId: u.userId?._id,
      }))
      .filter((u) => u.userId !== currentUserId);

    if (!remainingUsers || remainingUsers.length < 2) {
      toast.error("Cannot leave a conversation unless there are at least 2 other participants.");
      return;
    }
    try {
      await updateConversation({
        id: conversation._id,
        body: {
          users: remainingUsers as any[],
        },
      }).unwrap();
      setShowLeaveConversationConfirmation(false);
      onLeaveConversation();
    } catch (error) {
      toast.catch(error, "Error attempting to leave conversation");
    }
  }, [conversation, currentUserId, onLeaveConversation, toast, updateConversation]);

  const muted = conversation?.muted ?? false;

  const handleMuteConversation = useCallback(async (): Promise<void> => {
    if (!conversation || !currentUserId) {
      return;
    }

    try {
      await updateConversationMuted({
        conversationId: conversation._id,
        ownerId: currentUserId,
        muted: !muted,
      }).unwrap();
      setShowSettingsMenu(false);
    } catch (error) {
      toast.catch(error, "Error attempting to mute conversation");
    }
  }, [conversation, currentUserId, muted, setShowSettingsMenu, toast, updateConversationMuted]);

  if (!conversation || !currentUserId || !showSettingsMenu) {
    return null;
  }

  return (
    <View
      style={{
        position: "absolute",
        top: 50,
        backgroundColor: theme.surface.neutralLight,
        borderWidth: 1,
        borderColor: theme.surface.secondaryDark,
        zIndex: 1000000,
        width: 120,
        borderRadius: theme.primitives.radiusMd,
      }}
    >
      <Pressable
        disabled={updateConversationMutedLoading}
        style={{
          padding: theme.spacing.sm,
          alignItems: "center",
        }}
        onPress={handleMuteConversation}
      >
        <Text>{muted ? "Unmute" : "Mute"}</Text>
      </Pressable>
      {conversation?.type === "Multi" && conversation?.users?.length > 2 && (
        <Pressable
          disabled={updateConversationLoading}
          style={{
            borderColor: theme.surface.secondaryDark,
            padding: theme.spacing.sm,
            alignItems: "center",
            borderTopWidth: 1,
          }}
          onPress={async () => {
            setShowLeaveConversationConfirmation(true);
          }}
        >
          <Text>Leave</Text>
        </Pressable>
      )}
      <Modal
        primaryButtonOnClick={handleLeaveConversation}
        primaryButtonText="Accept & Leave"
        secondaryButtonOnClick={() => setShowLeaveConversationConfirmation(false)}
        secondaryButtonText="Cancel"
        text="Are you sure you want to leave this conversation? You will not be able to join again unless added by an existing participant."
        title="Leave Conversation"
        visible={showLeaveConversationConfirmation}
        onDismiss={() => setShowLeaveConversationConfirmation(false)}
      />
    </View>
  );
};
