/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {LinkingOptions} from "@react-navigation/native";
import * as Linking from "expo-linking";

export const LinkingConfiguration: LinkingOptions<any> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      // Tabs
      Staff: {
        screens: {
          Workflows: "Panel",
          Scheduling: "Scheduling",
          InternalChatScreen: "Chat",
          Tools: "Tools",
          Profile: "Profile",
          PreClinicUpdates: "PsychiatristPreClinicUpdates",
        },
      },
      // Staff Screens
      AlertsExplorer: "AlertsExplorer",
      BillingInfo: "BillingInfo",
      CarePodAdmin: "CarePodAdmin",
      CarePodsOverview: "CarePodsOverview",
      ColorPalette: "ColorPalette",
      CompanyOrganizationAdmin: "CompanyOrganizationAdmin",
      Contacts: "Contacts",
      CreateAppointment: "CreateAppointment",
      CreateFamilyUnit: "CreateFamilyUnit",
      CreateForm: "CreateForm",
      CreateScheduleItem: "CreateScheduleItem",
      CreateIntake: "CreateIntake",
      CreateStaff: "CreateStaff",
      CreateUser: "CreateUser",
      Dialer: "Dialer",
      DoseSpotUI: "DoseSpotUI",
      DotPhraseAdmin: "DotPhraseAdmin",
      ExternalClinicianAdmin: "ExternalClinicianAdmin",
      FormAdmin: "FormAdmin",
      GPT: "GPT",
      GuidingHours: "GuidingHours",
      HealthItemAdmin: "HealthItemAdmin",
      InsurancePlanAdmin: "InsurancePlanAdmin",
      IntakeScheduling: "IntakeScheduling",
      ManageConversationsScreen: "ManageConversationsScreen",
      ManageFormScreen: "ManageFormScreen",
      MessageExplorer: "MessageExplorer",
      MyTeam: "MyTeam",
      PreClinicUpdates: "PreClinicUpdates",
      ReferralMethodAdmin: "ReferralMethodAdmin",
      ReferralSourceAdmin: "ReferralSourceAdmin",
      ResetPassword: "ResetPassword",
      ScheduleAppointment: "ScheduleAppointment",
      Stats: "Stats",
      Team: "Team",
      ToDoListExplorer: "ToDoListExplorer",
      User: "User",
      UserBio: "UserBio",
      UserExplorer: "UserExplorer",
      UserStatusAdmin: "UserStatusAdmin",
      ViewForm: "ViewForm",
    },
  },
};
