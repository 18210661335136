import {printAddress, ScheduleItemType, User} from "@store";
import {isFamilyMember, isPatient} from "@utils";
import {FieldOption} from "ferns-ui";

export const SCHEDULE_ITEM_DEFAULT_USER_NOTE =
  "Message your guide in app if you know 2 days in advance that you cannot make your appointment.";

export type NotificationsState = {minutesBefore: number; sendAsPush: boolean; sendAsSms: boolean}[];

export enum RecurringType {
  NoRepeat = "NoRepeat",
  Weekly = "Weekly",
}

export const RecurringTypeOptions: FieldOption[] = [
  {label: "Does not repeat", value: RecurringType.NoRepeat},
  {label: "Repeats Weekly", value: RecurringType.Weekly},
];

export type StaffRole =
  | "Therapist"
  | "Psychiatrist"
  | "PatientGuide"
  | "FamilyGuide"
  | "CareAdvocate";

type AppointmentConfig = {
  title: string; // displayed in in Google Calendar, schedule screen, etc.

  reminders: {minutesBefore: number; sendAsPush: boolean; sendAsSms: boolean}[];
  duration: number;
  userNotes?: (user?: User) => string;
  staffRoles?: StaffRole[];
  // If true, will include a video link in the meeting details and sms reminders.
  video?: boolean;
  // If true and the patient is a minor, the patient's guardian will be invited to the
  // meeting.
  psychiatry?: boolean;
  // If true, will show travel time in the appointment scheduler
  travelTime?: boolean;
  documentationTime?: number;
  group?: "Therapy" | "Psychiatry" | "Guide" | "CareAdvocate" | "Enrollment";
  // Whether to create a feedback survey for the meeting
  feedbackSurvey?: boolean;
  engagementType?: "Clinical" | "NonClinical";
  deprecated?: boolean; // used to filter appt type options in create schedule item dropdown
};

export const APPOINTMENT_CONFIG: {
  [K in ScheduleItemType]?: AppointmentConfig; // replace 'any' with the type of your values
} = {
  "Eligibility Interview": {
    title: "Pre-Enrollment Consultation",
    reminders: [
      {minutesBefore: 5, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 15, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 60, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: [],
    video: true,
    group: "Therapy",
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  // Deprecated with Appointment Scheduling. Use "Guide/Therapy Clinical Intake" instead.
  "Clinical Intake": {
    title: "Clinical Intake",
    reminders: [
      {minutesBefore: 15, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 120,
    staffRoles: ["Therapist", "PatientGuide"],
    video: true,
    group: "Enrollment",
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Guide Clinical Intake": {
    title: "Guide Clinical Intake",
    reminders: [
      {minutesBefore: 15, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: ["PatientGuide", "FamilyGuide"],
    video: true,
    group: "Enrollment",
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Therapy Clinical Intake": {
    title: "Therapy Clinical Intake",
    reminders: [
      {minutesBefore: 15, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 70,
    staffRoles: ["Therapist"],
    video: true,
    group: "Enrollment",
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Therapy Intake": {
    title: "Therapy Intake",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 120,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Psychiatry Intake": {
    title: "Psychiatry Intake",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 80,
    staffRoles: ["Psychiatrist"],
    psychiatry: true,
    video: true,
    group: "Psychiatry",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "In Home Onboarding Visit": {
    title: "Guide In-Person Visit",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: ["CareAdvocate"],
    group: "CareAdvocate",
    travelTime: true,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  // Follow ups
  Therapy: {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
    deprecated: true,
  },
  "Therapy - Patient Session": {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Therapy - Caregiver Session": {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  Psychiatry: {
    title: "Psychiatry Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 25,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Psychiatrist"],
    psychiatry: true,
    video: true,
    group: "Psychiatry",
    documentationTime: 5,
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  "Member Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  "Patient Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  "Family Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["FamilyGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  "In Home Guide Visit": {
    title: "In Home Care Advocate Visit",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 60,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    travelTime: true,
    group: "Guide",
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  Travel: {
    title: "Travel",
    reminders: [],
    duration: 50,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    documentationTime: 10,
  },
  "Individualized Service Plan": {
    title: "Individualized Service Plan Meeting",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 30,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    group: "Therapy",
    video: true,
    engagementType: "Clinical",
  },
  "Meet and Greet": {
    title: "Meet and Greet",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 30,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    engagementType: "NonClinical",
  },
  Other: {
    title: "",
    reminders: [{minutesBefore: 30, sendAsPush: true, sendAsSms: true}],
    duration: 50,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    documentationTime: 10,
    engagementType: "NonClinical",
  },
};

// Filter out deprecated appt types from create schedule item dropdown
export const DEFAULT_APPT_OPTIONS = Object.keys(APPOINTMENT_CONFIG)
  .filter((key) => !APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG]?.deprecated === true)
  .map((key) => ({
    label: key === "Eligibility Interview" ? "Pre-Enrollment Consultation" : key,
    value: key,
  }));

export const VIDEO_MEETINGS = Object.keys(APPOINTMENT_CONFIG).filter(
  (key): key is keyof typeof APPOINTMENT_CONFIG => {
    const {video} = APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG] ?? {};
    return Boolean(video);
  }
);

export const DEFAULT_TITLES = Object.values(APPOINTMENT_CONFIG).map((v) => v.title);

export const NotificationOptions: Map<string, number> = new Map([
  ["5 minutes before", 5],
  ["15 minutes before", 15],
  ["30 minutes before", 30],
  ["1 hour before", 60],
  ["1 day before", 1440],
]);

// Automatically set the title based on the type and users/staff to match how the care team
// was naming these events before.
// TODO: Add tests for appointment helpers.
export const generateScheduleItemTitle = (
  newType: ScheduleItemType | "",
  newUsers: User[],
  newStaff: User[],
  recurring: keyof typeof RecurringType
): string => {
  if (!newType || !APPOINTMENT_CONFIG[newType]?.title) {
    return "";
  }
  let newTitle = APPOINTMENT_CONFIG[newType]?.title ?? "";
  if (recurring === RecurringType.Weekly) {
    newTitle = `Weekly ${newTitle}`;
  }
  if (newUsers.length && newStaff.length) {
    const patients = newUsers.filter((u) => isPatient(u.type));
    const familyMembers = newUsers.filter((u) => isFamilyMember(u.type));

    const usersName = patients.length ? patients[0].name : familyMembers[0].name;

    const staffName = newStaff[0].name;

    if (usersName && staffName) {
      return `${newTitle}: ${staffName} <> ${usersName}`;
    } else {
      return newTitle;
    }
  } else {
    return newTitle;
  }
};

export const generateScheduleItemLocation = ({
  type,
  users,
  staff,
}: {
  type: ScheduleItemType;
  users: User[];
  staff: User[];
}): {location: string; helperText: string} => {
  const appointmentConfig = APPOINTMENT_CONFIG[type];
  if (!appointmentConfig) {
    console.warn(`No appointment config found for type: ${type}`);
    return {location: "", helperText: ""};
  }

  let location = "";
  let helperText = "";

  if (appointmentConfig.video) {
    // If this is a clinical intake, we always want to use the guide's link.
    if (type === "Clinical Intake" || type === "Guide Clinical Intake") {
      const guide = staff?.find((s) => s.staffRoles.PatientGuide && Boolean(s.videoChatLink));
      if (guide?.videoChatLink) {
        helperText = `Automatically set to ${guide.name}'s video chat link.`;
        location = guide.videoChatLink;
      }
    } else {
      // In all other cases, grab the first staff with a video chat link.
      const videoStaff = staff?.find((s) => Boolean(s.videoChatLink));
      if (videoStaff?.videoChatLink) {
        helperText = `Automatically set to ${videoStaff.name}'s video chat link.`;
        location = videoStaff.videoChatLink;
      }
    }
  } else if (type === "In Home Onboarding Visit" || type === "In Home Guide Visit") {
    // Grab the first user with an address.
    const selectedAddressUser = users.find((m) => m.address?.address1);
    // For in person, automatically use the address of the first user.
    if (selectedAddressUser) {
      helperText = `Autofilled address of ${selectedAddressUser.name}`;
      location = printAddress(selectedAddressUser.address);
    }
  }

  return {location, helperText};
};

export const getUserFriendlyNotificationTimeString = (minutesBefore: number): string => {
  if (minutesBefore === 60) {
    return "1 hour before";
  } else if (minutesBefore === 1440) {
    return "1 day before";
  } else {
    return `${minutesBefore} minutes before`;
  }
};

export const getColorForAppointmentType = (type?: ScheduleItemType): string => {
  if (!type) {
    return "red";
  }
  const config = APPOINTMENT_CONFIG[type];
  if (config?.group === "Therapy") {
    return "green";
  } else if (config?.group === "Psychiatry") {
    return "blue";
  } else if (config?.group === "Guide") {
    return "purple";
  } else if (config?.group === "Enrollment") {
    return "orange";
  } else {
    return "red";
  }
};
