import {MarkdownView} from "@components";
import {IsWeb} from "@utils";
import {Box, Button, Heading, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {LayoutChangeEvent, View} from "react-native";

import {AlertDocumentationContents} from "./AlertDocumentationContents";

export const AlertsDocumentation = ({
  scrollToPosition,
  selectedScrollRef,
}: {
  scrollToPosition: (yOffset: number) => void;
  selectedScrollRef: string | undefined;
}): ReactElement | null => {
  const recipientsRef = useRef<View>(null);
  const triggeringEventsRef = useRef<View>(null);
  const centerVsBannerRef = useRef<View>(null);
  const textMergeFieldsRef = useRef<View>(null);
  const refs = {
    recipientsRef,
    triggeringEventsRef,
    centerVsBannerRef,
    textMergeFieldsRef,
  };
  type OffsetsType = {
    [key in RefKeys]: number;
  };
  const initialOffsets: OffsetsType = Object.keys(refs).reduce((acc, key) => {
    acc[key as RefKeys] = 0;
    return acc;
  }, {} as OffsetsType);
  const [offsets, setOffsets] = useState<OffsetsType>(initialOffsets);

  const onLayout = (event: LayoutChangeEvent, refName: string): void => {
    const layout = event.nativeEvent.layout;
    setOffsets((prevOffsets) => ({
      ...prevOffsets,
      [refName]: layout.y,
    }));
  };

  type RefKeys = keyof typeof refs;
  const scrollToRef = (refName: RefKeys): void => {
    if (refs[refName] && refs[refName].current) {
      const yOffset = IsWeb
        ? ((refs[refName] as any)?.current?.offsetTop ?? 1)
        : (offsets[refName] ?? 1);
      scrollToPosition(yOffset);
    }
  };
  // scroll to the selected scroll ref when the component mounts
  useEffect(() => {
    if (selectedScrollRef) {
      scrollToRef(selectedScrollRef as RefKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScrollRef]);

  return (
    <Box width={IsWeb ? "80%" : "100%"}>
      <Box padding={2}>
        <Heading size="lg">Alerts Documentation</Heading>
      </Box>
      <Box paddingX={2}>
        <Text>
          Alerts are a way to notify staff of important events that occur within the system, and are
          triggered by events that occur within the system.
        </Text>
        <Text>
          They are displayed in the Alert Center when an alert is prevalent to the staff user, or in
          an Alert Banner above conversation when the selected patient has an open unresolved and
          not archived alert.
        </Text>
        <Text>
          Alerts can be resolved by staff, and are configured to be automatically resolved if the
          triggering event is no longer applicable.
        </Text>
      </Box>
      <Box padding={2}>
        <Heading size="md">Alerts Context</Heading>
        <Box paddingY={1}>
          <Button
            text="Alert Recipients"
            variant="muted"
            onClick={() => scrollToRef("recipientsRef")}
          />
          <Button
            text="Triggering Events"
            variant="muted"
            onClick={() => scrollToRef("triggeringEventsRef")}
          />
          <Button
            text="Center vs Banner"
            variant="muted"
            onClick={() => scrollToRef("centerVsBannerRef")}
          />
          <Button text="Text Merge Fields" onClick={() => scrollToRef("textMergeFieldsRef")} />
        </Box>
      </Box>
      <View ref={recipientsRef} onLayout={(event) => onLayout(event, "recipientsRef")}>
        <Box padding={2}>
          <Box paddingY={1}>
            <Heading size="md">{AlertDocumentationContents.alertRecipients.title}</Heading>
          </Box>
          <MarkdownView>{AlertDocumentationContents.alertRecipients.text}</MarkdownView>
        </Box>
      </View>
      <View ref={triggeringEventsRef} onLayout={(event) => onLayout(event, "triggeringEventsRef")}>
        <Box padding={2}>
          <Box paddingY={1}>
            <Heading size="md">{AlertDocumentationContents.triggeringEvents.title}</Heading>
          </Box>
          <MarkdownView>{AlertDocumentationContents.triggeringEvents.text}</MarkdownView>
        </Box>
      </View>
      <View ref={centerVsBannerRef} onLayout={(event) => onLayout(event, "centerVsBannerRef")}>
        <Box padding={2}>
          <Box>
            <Heading size="md">{AlertDocumentationContents.centerVsBanner.title}</Heading>
          </Box>
          <MarkdownView>{AlertDocumentationContents.centerVsBanner.text}</MarkdownView>
        </Box>
      </View>
      <View ref={textMergeFieldsRef} onLayout={(event) => onLayout(event, "textMergeFieldsRef")}>
        <Box padding={2}>
          <Box paddingY={1}>
            <Heading size="md">{AlertDocumentationContents.textMergeFields.title}</Heading>
          </Box>
          <MarkdownView>{AlertDocumentationContents.textMergeFields.text}</MarkdownView>
        </Box>
      </View>
    </Box>
  );
};
