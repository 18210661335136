import {AlertCard} from "@components";
import {useReadProfile, useReadProfileUsersWorkFlow} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  AlertInstance,
  useGetAlertInstanceQuery,
  usePatchAlertInstancesByIdMutation,
  useSelectShowSideDrawer,
  useSetShowSideDrawer,
} from "@store";
import {IsWeb} from "@utils";
import {
  Box,
  Button,
  Heading,
  IconButton,
  MultiselectField,
  Pagination,
  SegmentedControl,
  Spinner,
  Text,
} from "ferns-ui";
import React, {useEffect, useState} from "react";

export const AlertsView = ({}: {}): React.ReactElement | null => {
  const [tab, setTab] = useState<"Inbox" | "Archived">("Inbox");
  const [isAlertFilterCollapsed, setIsAlertFilterCollapsed] = useState(true);
  const [isResolved, setAlertStatusFilter] = useState<boolean[]>([]);
  const [page, setPage] = useState(1);
  const setShowSideDrawer = useSetShowSideDrawer();
  const showSideDrawer = useSelectShowSideDrawer();
  const [updateAlertInstance] = usePatchAlertInstancesByIdMutation();
  const profile = useReadProfile();
  const profileUsersWorkFlow = useReadProfileUsersWorkFlow();

  const {data: alertInstances, isLoading} = useGetAlertInstanceQuery(
    profile
      ? {
          page,
          archived: tab === "Archived",
          resolved: {$in: isResolved},
        }
      : skipToken
  );

  const organizeAlerts = (alerts: AlertInstance[]): AlertInstance[] => {
    if (!alerts.length) {
      return [];
    }
    const sortAlertsByDate = (a: AlertInstance, b: AlertInstance): number =>
      new Date(b.created).getTime() - new Date(a.created).getTime();
    if (tab === "Archived") {
      return [...alerts].sort(sortAlertsByDate);
    }
    const unresolvedAlerts = alerts.filter((alert) => !alert.resolved);
    const resolvedAlerts = alerts.filter((alert) => alert.resolved);

    unresolvedAlerts.sort(sortAlertsByDate);
    resolvedAlerts.sort(sortAlertsByDate);

    return [...unresolvedAlerts, ...resolvedAlerts];
  };

  const organizedAlerts = organizeAlerts(alertInstances?.data ?? []);

  const resetPage = function (): void {
    setPage(1);
  };

  const resetFilters = function (): void {
    setAlertStatusFilter([]);
    resetPage();
  };

  // The dependency array ensures this effect only runs when `showSideDrawer` changes
  useEffect(() => {
    const resetState = function (): void {
      resetFilters();
      setTab("Inbox");
      setIsAlertFilterCollapsed(true);
    };
    if (!showSideDrawer) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideDrawer]);

  const unarchiveAll = (): void => {
    if (profile) {
      alertInstances?.data?.forEach(async (a) => {
        const updatedArchived = a?.archivedByUsers.filter((u) => u !== profile._id);
        await updateAlertInstance({id: a._id, body: {archivedByUsers: updatedArchived}});
      });
    }
    resetPage();
  };

  const archiveAll = (): void => {
    if (profile) {
      alertInstances?.data?.forEach(async (a) => {
        if (a.resolved) {
          const updatedArchived = [...a?.archivedByUsers, profile._id];
          await updateAlertInstance({id: a._id, body: {archivedByUsers: updatedArchived}});
        }
      });
    }
    resetPage();
  };

  const renderFilterDropdown = (): React.ReactElement | null => {
    if (isAlertFilterCollapsed) {
      return null;
    }
    return (
      <Box
        color="base"
        dangerouslySetInlineStyle={{
          __style: {borderTopRightRadius: 0},
        }}
        direction="column"
        paddingX={3}
        paddingY={1}
        rounding="md"
      >
        <Box alignItems="center" direction="row" justifyContent="between">
          <Heading size="sm">Filters</Heading>
          <Button text="Reset" variant="muted" onClick={resetFilters} />
        </Box>
        <MultiselectField
          options={[
            {label: "Resolved", value: "true"},
            {label: "Unresolved", value: "false"},
          ]}
          title="By status"
          value={isResolved.map(String)}
          onChange={(filters: string[]): void => {
            resetPage();
            setAlertStatusFilter(filters.map((f) => f === "true"));
          }}
        />
      </Box>
    );
  };

  return (
    <Box color="neutralLight" margin={1} maxHeight="100%" padding={2}>
      <Box alignItems="center" justifyContent="center" paddingY={1}>
        <Heading size="md">Alert Center</Heading>
      </Box>
      <Box direction="row" gap={4} justifyContent="between" width="100%">
        <Box flex="grow" maxWidth={250}>
          <SegmentedControl
            items={["Inbox", "Archive"]}
            selectedIndex={tab === "Inbox" ? 0 : 1}
            onChange={(index) => setTab(index === 0 ? "Inbox" : "Archived")}
          />
        </Box>
        <IconButton
          accessibilityLabel="alert center filter toggle"
          iconName="filter"
          onClick={(): void => setIsAlertFilterCollapsed(!isAlertFilterCollapsed)}
        />
      </Box>
      {renderFilterDropdown()}
      {!alertInstances?.data?.length ? (
        <Box alignItems="center" justifyContent="center" padding={4}>
          <Text bold>No alerts to show</Text>
        </Box>
      ) : (
        <>
          <Box maxHeight="100%" scroll>
            <Box direction="row" paddingY={1} wrap>
              {IsWeb ? (
                <Text>
                  <Text italic>*Name </Text> notates the patient/caregiver is not in your panel
                </Text>
              ) : (
                <Text>*Name notates the patient/caregiver is not in your panel</Text>
              )}
            </Box>
            <Box>
              {isLoading ? (
                <Box alignItems="center" justifyContent="center" padding={4}>
                  <Spinner size="md" />
                </Box>
              ) : (
                organizedAlerts.map((instance) => (
                  <AlertCard
                    key={instance._id}
                    alert={instance}
                    profileUserId={profile?._id ?? ""}
                    profileUsersWorkFlow={profileUsersWorkFlow}
                    setShowSideDrawer={setShowSideDrawer}
                  />
                ))
              )}
            </Box>
          </Box>

          <Box direction="row" justifyContent="between" paddingY={2} width="100%">
            <Box direction="row">
              <Box>
                <Button
                  confirmationText={
                    tab === "Archived"
                      ? "Move all the current alerts on page inbox?"
                      : "Are you sure you want archive the Resolved alerts on page?"
                  }
                  disabled={
                    tab !== "Archived" ? !alertInstances?.data?.some((a) => a.resolved) : false
                  }
                  modalTitle={
                    tab === "Archived"
                      ? "Move All Showing to Inbox"
                      : "Archive Resolved Alerts Shown"
                  }
                  text={tab === "Archived" ? "Move all to inbox" : "Archive All Resolved"}
                  variant="secondary"
                  withConfirmation
                  onClick={tab === "Archived" ? unarchiveAll : archiveAll}
                />
              </Box>
            </Box>
            <Box direction="row">
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={Math.ceil((alertInstances?.total ?? 0) / (alertInstances?.limit ?? 1))}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
