/* Columns used in various views of User Explorer */

import {
  CheckedCell,
  DateCell,
  ExplorerNav,
  LinkButtonCell,
  NameCell,
  TextCell,
  UserExplorerColumn,
  UserStatusBadge,
} from "@components";
import {hasPreferredPharmacy, useGetUserStatusesQuery, User} from "@store";
import {isPatient} from "@utils";
import {Box, IconColor, IconName, Text} from "ferns-ui";
import parsePhoneNumber from "libphonenumber-js";
import get from "lodash/get";
import React from "react";

import {IntakeCell} from "./IntakeCell";

// Cell widths
export const DATE = 100;
export const CHECKBOX = 100;
export const LINK_BUTTON = 140;
// export const DROPDOWN = 120;

interface ColumnProps {
  user: User;
  navigation?: ExplorerNav;
}

// A cell that allows checking and unchecking tasks on the user.
export const TaskCell = ({
  user,
  taskField,
}: {
  user: User;
  taskField:
    | "roiSent"
    | "signedROIReceived"
    | "signedROISentToProviders"
    | "medicalRecordsRequested"
    | "dischargeMedsReceived"
    | "documentationRequestSent"
    | "authorizationInformationConfirmed";
}): React.ReactElement => {
  return <CheckedCell checked={Boolean(user.tasks?.[taskField])} />;
};

export const UserStatusComponent = ({user}: ColumnProps): React.ReactElement | null => {
  if (isPatient(user.type)) {
    return (
      <Box flex="grow" justifyContent="center">
        <UserStatusBadge statusId={user.statusId} />
      </Box>
    );
  } else {
    return null;
  }
};

export const userStatusColumn: UserExplorerColumn = {
  title: "Status",
  sort: "status",
  width: 160,
  Component: (user) => <UserStatusComponent user={user} />,
};

export const userInitialsColumn: UserExplorerColumn = {
  title: "Initials",
  sort: "initials",
  width: 60,
  Component: (user: User): React.ReactElement => <TextCell text={user.initials || ""} />,
};

export const NameComponent = ({user}: ColumnProps): React.ReactElement => {
  const {data: userStatusData} = useGetUserStatusesQuery({});
  const userStatus = userStatusData?.data?.find((u) => u._id === user.statusId)?.name;

  if (!isPatient(user.type)) {
    return <NameCell text={user.name} userId={user._id} />;
  }

  let variant: IconColor | undefined;
  let icon: IconName | undefined;
  let tooltip: string | undefined;
  if (
    ["Enrolled", "Therapy Intake", "Psychiatry Intake"].includes(userStatus || "") &&
    !user.growth?.serviceStartDate
  ) {
    tooltip = "Missing Service Start Date";
    icon = "triangle-exclamation";
    variant = "warning";
  } else if (userStatus === "Enrolled" && !user.growth?.enrolledDate) {
    tooltip = "Missing Enrolled Date";
    icon = "triangle-exclamation";
    variant = "warning";
  } else if (userStatus === "Referred" && !user.growth?.referralDate) {
    tooltip = "Missing Referral Date";
    icon = "triangle-exclamation";
    variant = "warning";
  } else if (userStatus === "Discharged" && !user.growth?.dischargeDate) {
    tooltip = "Missing Discharge Date";
    icon = "triangle-exclamation";
    variant = "error";
  }

  return (
    <NameCell
      iconColor={variant}
      iconName={icon}
      text={user.name}
      tooltip={tooltip}
      userId={user._id}
    />
  );
};

export const nameColumn: UserExplorerColumn = {
  title: "Name",
  sort: "name",
  width: 200,
  Component: (user: User): React.ReactElement => <NameComponent user={user} />,
};

export const userTypeColumn: UserExplorerColumn = {
  title: "User Type",
  sort: "type",
  width: 200,
  Component: (user: User): React.ReactElement => <TextCell text={user.type} />,
};

export const idColumn: UserExplorerColumn = {
  title: "ID",
  sort: "_id",
  width: 205,
  Component: (user: User): React.ReactElement => <TextCell text={user._id} />,
};

export const dobColumn: UserExplorerColumn = {
  title: "DOB",
  width: DATE + 40, // Extra 40px for the "since" text
  sort: "birthday",
  Component: (user): React.ReactElement => (
    <DateCell displaySince={false} field="birthday" model={user} />
  ),
};

export const memberIdColumn: UserExplorerColumn = {
  title: "Member ID",
  width: 200,
  sort: "billingInfo.memberId",
  Component: (user: User): React.ReactElement => (
    <TextCell text={user.billingInfo?.memberId ?? ""} />
  ),
};

export const authorizationDateColumn: UserExplorerColumn = {
  title: "Authorization Date",
  width: DATE,
  sort: "growth.authorizationDate",
  Component: (user): React.ReactElement => (
    <DateCell field="growth.authorizationDate" model={user} />
  ),
};

export const referralDateColumn: UserExplorerColumn = {
  title: "Referral Date",
  width: DATE,
  sort: "growth.referralDate",
  Component: (user): React.ReactElement => <DateCell field="growth.referralDate" model={user} />,
};

export const referralAcceptedDateColumn: UserExplorerColumn = {
  title: "Referral Accepted Date",
  width: DATE,
  sort: "growth.referralAcceptedDate",
  Component: (user): React.ReactElement => (
    <DateCell field="growth.referralAcceptedDate" model={user} />
  ),
};

export const enrolledDateColumn: UserExplorerColumn = {
  title: "Enrolled Date",
  sort: "growth.enrolledDate",
  width: DATE + 60, // Extra 40px for the "since" text
  Component: (user): React.ReactElement => (
    <DateCell displaySince field="growth.enrolledDate" model={user} />
  ),
};

export const serviceStartDateColumn: UserExplorerColumn = {
  title: "Service Start Date",
  sort: "growth.serviceStartDate",
  width: DATE + 40, // Extra 40px for the "since" text
  Component: (user): React.ReactElement => (
    <DateCell field="growth.serviceStartDate" model={user} />
  ),
};

export const estimatedDischargeDateColumn: UserExplorerColumn = {
  title: "Estimated Discharge Date",
  sort: "growth.estimatedDischargeDate",
  // extra 20px for the title to fit
  width: DATE + 10,
  Component: (user): React.ReactElement => (
    <DateCell field="growth.estimatedDischargeDate" model={user} />
  ),
};

export const dischargeDateColumn: UserExplorerColumn = {
  title: "Actual Discharge Date",
  sort: "growth.dischargeDate",
  width: DATE,
  Component: (user): React.ReactElement => <DateCell field="growth.dischargeDate" model={user} />,
};

export const hospitalDischargeDateColumn: UserExplorerColumn = {
  title: "Hospital Discharge Date",
  sort: "growth.hospitalDischargeDate",
  width: DATE,
  Component: (user): React.ReactElement => (
    <DateCell field="growth.hospitalDischargeDate" model={user} />
  ),
};

export const eligibilityInterviewDateColumn: UserExplorerColumn = {
  title: "Pre-Enrollment Consultation",
  width: DATE,
  Component: (user): React.ReactElement => (
    <IntakeCell field="growth.eligibilityInterview" user={user} />
  ),
};

export const clinicalIntakeDateColumn: UserExplorerColumn = {
  title: "Clinical Intake",
  width: DATE,
  Component: (user): React.ReactElement => <IntakeCell field="growth.clinicalIntake" user={user} />,
};

export const therapyIntakeDateColumn: UserExplorerColumn = {
  title: "Therapy Intake",
  width: DATE,
  Component: (user): React.ReactElement => <IntakeCell field="growth.therapyIntake" user={user} />,
};

export const psychiatryIntakeDateColumn: UserExplorerColumn = {
  title: "Psychiatry Intake",
  width: DATE,
  Component: (user): React.ReactElement => (
    <IntakeCell field="growth.psychiatryIntake" user={user} />
  ),
};

export const guideVisitDateColumn: UserExplorerColumn = {
  title: "Meet & Greet",
  width: DATE,
  Component: (user): React.ReactElement => <IntakeCell field="growth.meetAndGreet" user={user} />,
};

export const careAdvocateColumn: UserExplorerColumn = {
  title: "In Home Onboarding Visit",
  width: DATE,
  Component: (user): React.ReactElement => (
    <IntakeCell field="growth.inHomeOnboarding" user={user} />
  ),
};

export const carePodColumn: UserExplorerColumn = {
  title: "Care Pod",
  // TODO: Figure out how to sort on populated fields in UserExplorer.
  // Leaving sort here will at least group them.
  sort: "carePod",
  width: 100,
  Component: (user) => <TextCell text={(user as any).carePodName} />,
};

export const stateColumn: UserExplorerColumn = {
  title: "State",
  sort: "address.state",
  width: 100,
  Component: (user) => <TextCell text={(user as any).address?.state} />,
};

export const referralSourceColumn: UserExplorerColumn = {
  title: "Referral Source",
  width: 80,
  Component: (user) => <TextCell text={(user as any).referralSource} />,
};

export const referralMethodColumn: UserExplorerColumn = {
  title: "Referral Method",
  width: 120,
  Component: (user) => <TextCell text={(user as any).referralMethod} />,
};

export const referringClinicianColumn: UserExplorerColumn = {
  title: "Referring Clinician",
  width: 120,
  Component: (user) => <TextCell text={(user as any).referringClinician} />,
};

export const referringPsychiatristColumn: UserExplorerColumn = {
  title: "Referring Psychiatrist",
  width: 120,
  Component: (user) => <TextCell text={(user as any).referringPsychiatrist} />,
};

export const healthPlanColumn: UserExplorerColumn = {
  title: "Health Plan",
  width: 80,
  Component: (user) => <TextCell text={(user as any).insurancePlan} />,
};

export const healthInsuranceColumn: UserExplorerColumn = {
  title: "Health Insurance",
  width: CHECKBOX,
  Component: (user) => <CheckedCell checked={Boolean((user as any).healthInsurance)} />,
};

export const billableColumn: UserExplorerColumn = {
  title: "Billable",
  width: CHECKBOX,
  Component: (user: User): React.ReactElement => <CheckedCell checked={(user as any).isBillable} />,
};

export const acceptingReferralsColumn: UserExplorerColumn = {
  title: "Accepting Referrals",
  width: CHECKBOX,
  Component: (user: User): React.ReactElement => (
    <CheckedCell checked={(user as any).acceptingReferrals} />
  ),
};

export const smsNotificationConsentColumn: UserExplorerColumn = {
  title: "SMS Notifications",
  width: CHECKBOX,
  Component: (user): React.ReactElement => <CheckedCell checked={Boolean(user.smsNotifications)} />,
};

export const documentationRequestSentColumn: UserExplorerColumn = {
  title: "Documentation Request Sent",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <TaskCell taskField="documentationRequestSent" user={user} />
  ),
};

export const dischargeMedsReceivedColumn: UserExplorerColumn = {
  title: "Discharge Meds Received",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <TaskCell taskField="dischargeMedsReceived" user={user} />
  ),
};

export const authorizationConfirmedColumn: UserExplorerColumn = {
  title: "Authorization Information Confirmed",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <TaskCell taskField="authorizationInformationConfirmed" user={user} />
  ),
};

export const roiSentColumn: UserExplorerColumn = {
  title: "ROI Sent",
  width: CHECKBOX,
  Component: (user): React.ReactElement => <TaskCell taskField="roiSent" user={user} />,
};

export const signedROIColumn: UserExplorerColumn = {
  title: "Signed ROI Received",
  width: CHECKBOX,
  Component: (user): React.ReactElement => <TaskCell taskField="signedROIReceived" user={user} />,
};

export const signedROISentToProvidersColumn: UserExplorerColumn = {
  title: "Signed ROI Sent To Providers",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <TaskCell taskField="signedROISentToProviders" user={user} />
  ),
};

export const medicalRecordsRequestedColumn: UserExplorerColumn = {
  title: "Medical records received from providers",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <TaskCell taskField="medicalRecordsRequested" user={user} />
  ),
};

export const googleDriveColumn: UserExplorerColumn = {
  title: "Google Drive",
  width: LINK_BUTTON,
  Component: (user): React.ReactElement => (
    <LinkButtonCell field="documentFolder" text="Google Drive" user={user} />
  ),
};

export const additionalNotesColumn: UserExplorerColumn = {
  title: "Additional Notes + Next Steps",
  width: 800,
  Component: (user): React.ReactElement => <TextCell text={get(user, "growth.notes") ?? ""} />,
};

export const providerInfoReceivedColumn: UserExplorerColumn = {
  title: "Provider Information Received",
  width: CHECKBOX,
  Component: (user): React.ReactElement => (
    <CheckedCell checked={Boolean((user as any).providerInfoReceived)} />
  ),
};

export const providerInfoColumn: UserExplorerColumn = {
  title: "Provider Contact",
  width: 300,
  Component: (user): React.ReactElement => <TextCell text={(user as any).providerInfo} />,
};

export const guardianColumn: UserExplorerColumn = {
  title: "Contact Info",
  width: 300,
  Component: (user): React.ReactElement => {
    const guardians = user.contacts?.filter((c) => c.types.includes("Guardian"));

    const guardianStrings = guardians?.map((g) =>
      [
        g.name,
        g.phoneNumber ? parsePhoneNumber(g.phoneNumber)?.formatNational() : undefined,
        g.email,
      ]
        .filter((gs) => gs)
        .join(", ")
    );
    return (
      <Box flex="grow" justifyContent="center" width="100%">
        {guardianStrings.map((guardianString) => (
          <Text key={guardianString[0]}>{guardianString}</Text>
        ))}
      </Box>
    );
  },
};

export const preferredPharmacyColumn: UserExplorerColumn = {
  title: "Preferred Pharmacy",
  width: CHECKBOX,
  Component: (user): React.ReactElement => {
    return <CheckedCell checked={hasPreferredPharmacy(user)} />;
  },
};

export const lastClaimDateColumn: UserExplorerColumn = {
  title: "Last Claim Date",
  width: DATE,
  sort: "lastClaimDate",
  Component: (user): React.ReactElement => <DateCell field="lastClaimDate" model={user} />,
};

export const DateColumn: Partial<UserExplorerColumn> = {
  width: DATE,
  sort: "lastClaimDate",
  Component: (user): React.ReactElement => <DateCell field="lastClaimDate" model={user} />,
};

export const fitbitColumn: UserExplorerColumn = {
  title: "Fitbit",
  width: CHECKBOX,
  Component: (user: User): React.ReactElement => <CheckedCell checked={Boolean(user.fitbitId)} />,
};
