/* eslint-disable react/prop-types */
import {FormCreationQuestionComponent} from "@components";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Form,
  FormQuestion,
  splitOnUpperCase,
  StaffRoles,
  useGetFormsByIdQuery,
  useGetFormsQuery,
  usePatchFormsByIdMutation,
  usePostFormsMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {IsMobileDevice, pageOnError} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  Heading,
  Icon,
  MultiselectField,
  Page,
  SelectField,
  Text,
  TextField,
} from "ferns-ui";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import DraggableFlatList, {ScaleDecorator} from "react-native-draggable-flatlist";

interface CreateFormProps extends StaffStackScreenProps<"CreateForm"> {}

type UserUpdateRoles =
  | "Therapist"
  | "Psychiatrist"
  | "PatientGuide"
  | "FamilyGuide"
  | "TherapistSupervisor"
  | "PatientGuideSupervisor"
  | "FamilyGuideSupervisor"
  | "EnrollmentCoordinator";

const FORM_ERRORS = {
  existingInternalKey: "An existing form has this Internal Key. Please choose a different one.",
  missingName: "Name is required.",
  missingQuestions: "At least one question is required",
  missingPrompt: "This form has a question without a prompt. Please update or remove the question.",
};

export const CreateFormScreen = ({route, navigation}: CreateFormProps): ReactElement => {
  let form: Form | null = null;
  const [createForm, {error: createError, isSuccess: createSuccess, isLoading: createLoading}] =
    usePostFormsMutation();
  const [updateForm, {error: updateError, isSuccess: updateSuccess, isLoading: updateLoading}] =
    usePatchFormsByIdMutation();

  const {data: formData} = useGetFormsByIdQuery(
    route.params?.formId ? route.params.formId : skipToken
  );
  if (formData) {
    form = formData;
  }
  // request all forms to be able to check for duplicate internal keys
  const {data: forms} = useGetFormsQuery({deleted: {$in: [true, false]}});

  const [name, setName] = useState(form?.name ?? "");
  const [description, setDescription] = useState(form?.description ?? "");
  const [type, setType] = useState<"Assessment" | "Note" | "Survey">(form?.type ?? "Note");
  const [questions, setQuestions] = useState<FormQuestion[]>([]);
  const [patientVisibility, setPatientVisibility] = useState<boolean>(
    form?.patientVisibility ?? false
  );
  const [isDraft, setIsDraft] = useState<boolean>(form?.isDraft ?? false);
  const [allowScheduleItemLinking, setAllowScheduleItemLinking] = useState<boolean>(
    form?.allowScheduleItemLinking ?? false
  );
  const [hideAttendanceStatus, setHideAttendanceStatus] = useState<boolean>(
    form?.hideAttendanceStatus ?? false
  );

  // Convert object of booleans to flat array of strings
  const [userUpdateRoles, setUserUpdateRoles] = useState<string[]>([]);
  const [sendUserUpdates, setSendUserUpdates] = useState<boolean>(false);
  const [allowUserCompletion, setAllowUserCompletion] = useState<boolean>(false);
  const [requireUserSignature, setRequireUserSignature] = useState<boolean>(false);
  const [requireSupervisorSignature, setRequireSupervisorSignature] = useState<boolean>(false);
  const [internalKey, setInternalKey] = useState<string>(form?.internalKey ?? "");
  const [excludeFormRiskLanguageAlerts, setExcludeFormRiskLanguageAlerts] = useState(
    form?.excludeFormRiskLanguageAlerts ?? false
  );

  const hasSupervisorOnlyQuestions = questions.some((q) => q.isSupervisorOnly);
  // On load, set the form values
  useEffect(() => {
    if (form) {
      setName(form.name);
      setType(form.type);
      setQuestions(form.questions);
      setPatientVisibility(form.patientVisibility ?? false);
      setIsDraft(form.isDraft ?? false);
      setAllowScheduleItemLinking(form.allowScheduleItemLinking ?? false);
      setHideAttendanceStatus(form.hideAttendanceStatus ?? false);
      setAllowUserCompletion(form.allowUserCompletion ?? false);
      setRequireUserSignature(form.requireUserSignature ?? false);
      setRequireSupervisorSignature(form.requireSupervisorSignature ?? false);
      setInternalKey(form.internalKey ?? "");
      setExcludeFormRiskLanguageAlerts(form.excludeFormRiskLanguageAlerts ?? false);

      const staffRoles = {...form.userUpdateRoles};
      delete staffRoles._id;
      setUserUpdateRoles(
        Object.keys(staffRoles || {}).filter((r) => {
          return r !== "_id" && staffRoles?.[r as UserUpdateRoles];
        })
      );
      setSendUserUpdates(Object.values(staffRoles).some((r) => r));
      if (form.questions.length && form.questions.some((q) => q.isSupervisorOnly)) {
        setRequireSupervisorSignature(true);
      }
    }
  }, [form]);

  // On success, navigate back to the previous screen
  useEffect(() => {
    if (updateSuccess || createSuccess) {
      navigation.pop();
    }
  }, [updateSuccess, createSuccess, navigation]);

  // set errors disables save button and displays on form
  const formErrors = useMemo(() => {
    const errors = new Set<string>();
    let internalKeys: string[] = [];

    const checkError = (condition: boolean, error: string): void => {
      if (condition) {
        errors.add(error);
      } else {
        errors.delete(error);
      }
    };

    if (forms?.data && forms?.data.length) {
      internalKeys = forms.data
        .filter((f) => f?.internalKey !== undefined)
        .map((f) => f.internalKey!);
    }

    checkError(!name, FORM_ERRORS.missingName);
    checkError(
      internalKeys.includes(internalKey) && !form?.internalKey,
      FORM_ERRORS.existingInternalKey
    );
    checkError(!questions.length, FORM_ERRORS.missingQuestions);
    checkError(
      questions.some((q) => !q.prompt),
      FORM_ERRORS.missingPrompt
    );

    return Array.from(errors);
  }, [name, questions, internalKey, forms, form]);

  const questionDelete = (i: number): void => {
    const formQuestions = [...questions];
    formQuestions.splice(i, 1);
    setQuestions(formQuestions);
  };

  const questionSave = (i: number, formQuestion: FormQuestion): boolean => {
    const formQuestions = [...(questions ?? [])];
    formQuestions[i] = formQuestion;

    setQuestions(formQuestions);
    if (formQuestions.length && formQuestions.some((q) => q.isSupervisorOnly)) {
      setRequireSupervisorSignature(true);
    }
    return true;
  };

  const unimplementedStaffRoles = [StaffRoles.SuperUser, StaffRoles.TherapistSupervisor];
  const staffRoles = Object.values(StaffRoles)
    .filter((r) => !unimplementedStaffRoles.includes(r))
    .map((role) => ({
      label: splitOnUpperCase(role),
      value: role,
    }));

  const convertRolesArrayToObject = (roles: string[]): any => {
    const rolesObject: any = {};
    roles.forEach((role) => {
      rolesObject[role] = true;
    });
    return rolesObject;
  };

  // The assessments should have static names (e.g. PHQ-9 should always be "PHQ-9". If it's changed,
  // the form sync scripts will fail.
  const cannotEditFormNames = Boolean(name === "Open Notes" || form?.assessmentType);

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box gap={4}>
        <Heading>{form?.name ?? "New Form"}</Heading>

        <TextField
          disabled={cannotEditFormNames}
          errorText={formErrors.includes(FORM_ERRORS.missingName) ? FORM_ERRORS.missingName : ""}
          title="Name"
          value={name}
          onChange={(value): void => {
            setName(value);
          }}
        />
        <TextField
          title="Description"
          value={description}
          onChange={(value): void => {
            setDescription(value);
          }}
        />
        <SelectField
          helperText="Forms of type Note will be synced to Charm Health. Assessments will have a score associated with them."
          options={[
            {label: "Note", value: "Note"},
            {label: "Assessment", value: "Assessment"},
            {label: "Survey", value: "Survey"},
          ]}
          requireValue
          title="Type"
          value={type}
          onChange={(value: string): void => {
            setType(value as "Assessment" | "Note" | "Survey");
            if (value !== "Note") {
              setExcludeFormRiskLanguageAlerts(false);
            }
          }}
        />

        <BooleanField
          helperText="Drafts will be hidden from the form assignment list."
          title="Is Draft?"
          value={isDraft}
          onChange={(value: boolean): void => {
            setIsDraft(value);
          }}
        />
        <BooleanField
          helperText="Track attendance for patient engagement using this form."
          title="Allow Schedule Item Linking"
          value={allowScheduleItemLinking}
          onChange={(value: boolean): void => {
            setAllowScheduleItemLinking(value);
            // If we allow schedule item linking, we should also show the attendance status dropdown
            if (value && hideAttendanceStatus) {
              setHideAttendanceStatus(false);
            }
          }}
        />
        <BooleanField
          disabled={Boolean(allowScheduleItemLinking)}
          helperText={
            "Do not ask the attendance status or show drop down." +
            `${Boolean(allowScheduleItemLinking) ? " Showing is mandatory for schedule item linking." : ""}`
          }
          title="Hide Attendance Status"
          value={hideAttendanceStatus}
          onChange={setHideAttendanceStatus}
        />
        <BooleanField
          disabled={Boolean(hasSupervisorOnlyQuestions)}
          helperText={
            "Require a Staff Supervisor's signature to finalize this form." +
            `${hasSupervisorOnlyQuestions ? " Supervisor-only questions are included, a signature is mandatory." : ""}`
          }
          title="Require Supervisor Signature"
          value={requireSupervisorSignature}
          onChange={setRequireSupervisorSignature}
        />
        <BooleanField
          helperText="Request non-staff user (Patient or Family Member) to sign the form. It will not be required to finalize, and the form will still need to be sent to user by staff member."
          title="Request User Signature"
          value={requireUserSignature}
          onChange={(value: boolean): void => {
            setRequireUserSignature(value);
            if (value) {
              // If we require a user signature, set user completion and patient visibility;
              setAllowUserCompletion(true);
              setPatientVisibility(true);
            }
          }}
        />
        <BooleanField
          disabled={Boolean(requireUserSignature)}
          helperText={
            "Allow staff to send this form to users (Patients or Family Members) for self-completion." +
            `${Boolean(requireUserSignature) ? " Mandatory for requesting user signature." : ""}`
          }
          title="Allow User Completion"
          value={allowUserCompletion}
          onChange={(value: boolean): void => {
            setAllowUserCompletion(value);
            if (value) {
              // If we enable user completion, we should also enable patient visibility
              setPatientVisibility(true);
            }
          }}
        />
        <BooleanField
          disabled={Boolean(allowUserCompletion)}
          helperText={
            "Upon completion this form will be visible in the App for Patient or Family Members." +
            `${Boolean(allowUserCompletion) ? " Mandatory for allowing user completion." : ""}` +
            `${Boolean(requireUserSignature) ? " Mandatory for requesting user signature." : ""}`
          }
          title="Patient Visibility"
          value={patientVisibility}
          onChange={setPatientVisibility}
        />
        <BooleanField
          disabled={Boolean(type !== "Note")}
          helperText={`${Boolean(type !== "Note") ? "Only Notes are reviewed for risk language." : "This note will not be reviewed for risk language or generate risk language alerts."}`}
          title="Exclude Risk Language Detection for Note"
          value={excludeFormRiskLanguageAlerts}
          onChange={
            type === "Note"
              ? (value: boolean): void => setExcludeFormRiskLanguageAlerts(value)
              : (): any => {} // Provide a default empty function
          }
        />
        <BooleanField
          helperText="Staff will receive updates regarding changes to this form."
          title="Send Staff Updates"
          value={sendUserUpdates}
          onChange={setSendUserUpdates}
        />
        {Boolean(sendUserUpdates) && (
          <MultiselectField
            options={staffRoles}
            title="Staff Roles to Send Updates"
            value={userUpdateRoles}
            variant="rightText"
            onChange={setUserUpdateRoles}
          />
        )}
        <TextField
          disabled={Boolean(form?.internalKey)}
          errorText={
            formErrors.includes(FORM_ERRORS.existingInternalKey)
              ? FORM_ERRORS.existingInternalKey
              : ""
          }
          helperText="This key is used to identify the form in the database. It should be kabob-case and unique."
          placeholder="e.g. individualized-service-plan"
          title="Internal Key - Engineering Use Only"
          type="text"
          value={internalKey}
          onChange={(value: string): void => {
            // trim and replace all capital letters with lowercase
            setInternalKey(value.trim().toLowerCase());
          }}
        />
        <Heading size="sm">Questions</Heading>
        {Boolean(IsMobileDevice) ? (
          questions.map((q, i) => {
            const item = {
              formQuestion: q,
              index: i,
              formType: type,
              excludeFormRiskLanguageAlerts,
            };
            return (
              <Box key={`question-${i}`}>
                <FormCreationQuestionComponent
                  {...item}
                  questions={questions}
                  onDelete={(): void => questionDelete(item.index)}
                  onDuplicate={(): void => {
                    const formQuestions = [...questions];
                    const newQuestion: any = {...item.formQuestion};
                    delete newQuestion._id;
                    delete newQuestion.internalKey;
                    formQuestions.splice(item.index + 1, 0, newQuestion);
                    setQuestions(formQuestions);
                  }}
                  onSave={(question): boolean => questionSave(item.index, question)}
                />
              </Box>
            );
          })
        ) : (
          <DraggableFlatList
            data={questions.map((q, i) => ({
              formQuestion: q,
              index: i,
              formType: type,
              excludeFormRiskLanguageAlerts,
            }))}
            keyExtractor={(q: any, i): string => q.formQuestion._id ?? i}
            renderItem={({item, drag}: {item: any; drag?: () => void}): ReactElement => {
              return (
                <ScaleDecorator>
                  <FormCreationQuestionComponent
                    {...item}
                    drag={drag}
                    questions={questions}
                    onDelete={(): void => questionDelete(item.index)}
                    onDuplicate={(): void => {
                      const formQuestions = [...questions];
                      const newQuestion: any = {...item.formQuestion};
                      delete newQuestion._id;
                      delete newQuestion.internalKey;
                      formQuestions.splice(item.index + 1, 0, newQuestion);
                      setQuestions(formQuestions);
                    }}
                    onSave={(question): boolean => questionSave(item.index, question)}
                  />
                </ScaleDecorator>
              );
            }}
            onDragEnd={({data}: {data: {formQuestion: FormQuestion}[]}): void => {
              setQuestions(data.map((d) => d.formQuestion));
            }}
          />
        )}

        <Box width={200}>
          <Button
            text="Add Question"
            variant="secondary"
            onClick={(): void => {
              setQuestions([
                ...questions,
                {
                  prompt: "",
                  type: "Freeform",
                  options: [],
                  grouping: "",
                },
              ] as FormQuestion[]);
            }}
          />
        </Box>
        <Box paddingY={4} width={200}>
          <Button
            disabled={Boolean(formErrors.length) || createLoading || updateLoading}
            loading={createLoading || updateLoading}
            text={form?._id ? "Save Form" : "Create Form"}
            onClick={async (): Promise<void> => {
              if (form?._id) {
                await updateForm({
                  id: form?._id,
                  body: {
                    name,
                    description,
                    type,
                    questions: questions as FormQuestion[],
                    patientVisibility,
                    userUpdateRoles: sendUserUpdates
                      ? convertRolesArrayToObject(userUpdateRoles)
                      : {},
                    isDraft,
                    allowScheduleItemLinking,
                    hideAttendanceStatus,
                    allowUserCompletion,
                    requireUserSignature,
                    requireSupervisorSignature,
                    // Only allow notes to exclude risk language alerts
                    ...(type === "Note" && {excludeFormRiskLanguageAlerts}),
                    // Conditional inclusion of internalKey
                    ...(internalKey.trim() !== "" && {internalKey}),
                  },
                });
              } else {
                await createForm({
                  name,
                  description,
                  type,
                  questions: questions as FormQuestion[],
                  patientVisibility,
                  userUpdateRoles: sendUserUpdates
                    ? convertRolesArrayToObject(userUpdateRoles)
                    : {},
                  isDraft,
                  allowScheduleItemLinking,
                  hideAttendanceStatus,
                  allowUserCompletion,
                  requireUserSignature,
                  requireSupervisorSignature,
                  // only allow notes to exclude risk language alerts
                  ...(type === "Note" && {excludeFormRiskLanguageAlerts}),
                  // Conditional inclusion of internalKey
                  ...(internalKey.trim() !== "" && {internalKey}),
                });
              }
            }}
          />
        </Box>
        {Boolean(formErrors.length > 0) && (
          <>
            {formErrors.map((error, i) => (
              <Box key={`questionError-${i}`} alignContent="center" direction="row" gap={2}>
                <Box alignItems="center" justifyContent="center">
                  <Icon color="error" iconName="triangle-exclamation" />
                </Box>
                <Text key={`questionError-${i}`} color="error">
                  {error}
                </Text>
              </Box>
            ))}
          </>
        )}
        {Boolean(createError || updateError) && (
          <Text bold color="error">
            {(createError as any)?.data?.title || (updateError as any)?.data?.title}
          </Text>
        )}
      </Box>
    </Page>
  );
};
