import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Address,
  passwordHasErrors,
  splitOnUpperCase,
  StaffRoles,
  useCreateEmailUserMutation,
  useGetUsersQuery,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {isSuperUser, pageOnError, UserTypes} from "@utils";
import {
  AddressField,
  AddressInterface,
  BooleanField,
  Box,
  Button,
  DateTimeField,
  Page,
  SelectField,
  Text,
  TextField,
  useToast,
} from "ferns-ui";
import React, {ReactElement, useCallback, useState} from "react";

interface CreateUserProps extends StaffStackScreenProps<"CreateStaff"> {}
interface StaffRolesQueryCondition {
  "staffRoles.PatientGuideSupervisor"?: boolean;
  "staffRoles.FamilyGuideSupervisor"?: boolean;
  "staffRoles.TherapistSupervisor"?: boolean;
}

export const CreateStaffScreen = ({navigation}: CreateUserProps): ReactElement => {
  const [createEmailUser, {isLoading}] = useCreateEmailUserMutation();
  const user = useReadProfile();
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState(false);
  const [password, setPassword] = useState("");
  const [staffRoles, setStaffRoles] = useState({
    SuperUser: false,
    Therapist: false,
    Psychiatrist: false,
    PatientGuide: false,
    FamilyGuide: false,
    TherapistSupervisor: false,
    PatientGuideSupervisor: false,
    FamilyGuideSupervisor: false,
    EnrollmentCoordinator: false,
    ClinicalLeader: false,
    ClinicalDirector: false,
    RiskManager: false,
    MedicalDirector: false,
    EnrollmentSupervisor: false,
    SoftwareEngineer: false,
    ClinicalQualityAssurance: false,
  });
  const [supervisor, setSupervisor] = useState("");

  const [address, setAddress] = useState<Partial<Address>>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [testUser, setTestUser] = useState<boolean>(false);
  const [birthday, setBirthday] = useState<string>("");
  const [gender, setGender] = useState("");

  const queryConditions: StaffRolesQueryCondition[] = [];
  if (staffRoles.PatientGuide) queryConditions.push({"staffRoles.PatientGuideSupervisor": true});
  if (staffRoles.FamilyGuide) queryConditions.push({"staffRoles.FamilyGuideSupervisor": true});
  if (staffRoles.Therapist) queryConditions.push({"staffRoles.TherapistSupervisor": true});

  const supervisorQuery = queryConditions.length ? {$or: queryConditions} : skipToken;
  const {data: res} = useGetUsersQuery(supervisorQuery);
  const supervisors = res?.data;

  const supervisorOptions = supervisors?.map((s) => ({label: s.name, value: s._id})) || [];

  const isSupervisorRequired =
    staffRoles.PatientGuide || staffRoles.FamilyGuide || staffRoles.Therapist;

  const isMissingSupervisor =
    (staffRoles.PatientGuide || staffRoles.FamilyGuide || staffRoles.Therapist) &&
    supervisorOptions.length &&
    !supervisor;

  const canSubmit = Boolean(name && birthday && email && password && !isMissingSupervisor);

  const submit = async (): Promise<void> => {
    const validationError = passwordHasErrors(UserTypes.Staff, password);

    if (validationError) {
      toast.error(validationError);
      return;
    }
    let err;
    await createEmailUser({
      email,
      password,
      type: UserTypes.Staff,
      name,
      birthday,
      staffRoles,
      admin,
      gender,
      address,
      testUser,
      ...(Boolean(supervisor) && {supervisor}),
    } as any)
      .unwrap()
      .catch((error: any) => {
        toast.catch(error, error?.data?.title ?? "Failed to create staff user");
        return;
      });
    if (!err) {
      navigation.pop();
    }
  };

  const checkAndClearSupervisor = useCallback((updatedRoles: typeof staffRoles) => {
    // Check if roles require a supervisor is no longer selected
    if (!updatedRoles.PatientGuide && !updatedRoles.FamilyGuide && !updatedRoles.Therapist) {
      setSupervisor(""); // Clear the supervisor if none of the critical roles are selected
    }
  }, []);

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={2}>
        <TextField
          title="Name"
          value={name}
          onChange={(value): void => {
            setName(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <TextField
          title="Email"
          type="email"
          value={email}
          onChange={(value): void => {
            setEmail(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <TextField
          helperText="They will change this when they first log in"
          title="Password"
          value={password}
          onChange={(value): void => {
            setPassword(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <DateTimeField title="Birthday" type="date" value={birthday} onChange={setBirthday} />
      </Box>
      <Box paddingY={2}>
        <SelectField
          options={[
            {label: "Please Select", value: ""},
            {label: "Male", value: "Male"},
            {label: "Female", value: "Female"},
            {label: "Non-binary", value: "Non-binary"},
            {label: "Prefer to self-describe", value: "Prefer to self-describe"},
            {label: "Prefer not to say", value: "Prefer not to say"},
          ]}
          requireValue
          title="Gender"
          value={gender}
          onChange={setGender}
        />
      </Box>
      <Box paddingY={2}>
        <AddressField value={(address ?? {}) as AddressInterface} onChange={setAddress} />
      </Box>
      <Box maxWidth={200}>
        <BooleanField
          title="Test User"
          value={testUser}
          onChange={(value): void => setTestUser(value)}
        />
      </Box>

      {user?.admin && (
        <Box maxWidth={200} paddingY={4}>
          <BooleanField
            title="Admin"
            value={admin}
            onChange={(value: boolean): void => setAdmin(value)}
          />
        </Box>
      )}
      <Box marginTop={4} maxWidth={200}>
        <Box paddingY={2}>
          <Text bold size="lg">
            Staff Roles:
          </Text>
        </Box>
        {["Therapist", "Psychiatrist", "PatientGuide", "FamilyGuide"].map((role) => (
          <Box key={role} paddingY={2}>
            <BooleanField
              title={splitOnUpperCase(role)}
              value={staffRoles[role as StaffRoles] as boolean}
              onChange={(value: boolean): void => {
                const updatedRoles = {...staffRoles, [role]: value};
                checkAndClearSupervisor(updatedRoles);
                setStaffRoles(updatedRoles);
              }}
            />
          </Box>
        ))}
        {/* TODO: prevent creation of super user by non-superuser staff on the backend */}
        {Boolean(user && isSuperUser(user)) &&
          [
            "ClinicalQualityAssurance",
            "SoftwareEngineer",
            "EnrollmentSupervisor",
            "TherapistSupervisor",
            "PatientGuideSupervisor",
            "FamilyGuideSupervisor",
            "EnrollmentCoordinator",
            "ClinicalLeader",
            "RiskManager",
            "SuperUser",
          ].map((role) => (
            <Box key={`${role}superUser`} paddingY={2}>
              <BooleanField
                title={splitOnUpperCase(role)}
                value={staffRoles[role as StaffRoles] as boolean}
                onChange={(value: boolean): void => {
                  const updatedRoles = {...staffRoles, [role]: value};
                  checkAndClearSupervisor(updatedRoles);
                  setStaffRoles(updatedRoles);
                }}
              />
            </Box>
          ))}
      </Box>
      {isSupervisorRequired && supervisorOptions.length ? (
        <Box paddingY={2}>
          <SelectField
            options={[...[{label: "Please Select", value: ""}], ...supervisorOptions]}
            requireValue
            title="Supervisor"
            value={supervisor}
            onChange={setSupervisor}
          />
        </Box>
      ) : null}
      <Button
        disabled={!canSubmit || isLoading}
        loading={isLoading}
        text="Create Staff"
        onClick={submit}
      />
    </Page>
  );
};
