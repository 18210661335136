import {emptySplitApi as api} from "../ferns-rtk/emptyApi";
export const addTagTypes = [
  "alertinstances",
  "alerts",
  "auditlogevents",
  "avatars",
  "carepods",
  "charmtokens",
  "companyorganizations",
  "conversations",
  "messages",
  "conversationstatuses",
  "criticalevents",
  "medicaldiagnoses",
  "dotphrases",
  "externalclinicians",
  "familyunits",
  "fitbitsleeps",
  "fitbitintradaysteps",
  "fitbithrvs",
  "fitbitheartrates",
  "fitbitstatuses",
  "forminstances",
  "forms",
  "gpthistories",
  "guidinghours",
  "healtheventinstances",
  "healthevents",
  "insuranceplans",
  "notifications",
  "patientweeklyupdates",
  "preclinicupdates",
  "prescriptions",
  "referralmethods",
  "referralsources",
  "scheduleitems",
  "recurringscheduleitems",
  "todoitems",
  "userbios",
  "users",
  "usersessions",
  "usersettings",
  "userstatuses",
  "userupdates",
  "voicemails",
  "workflowmappings",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAlertInstance: build.query<GetAlertInstanceRes, GetAlertInstanceArgs>({
        query: (queryArg) => ({
          url: `/alertInstance/`,
          params: {
            _id: queryArg._id,
            archived: queryArg.archived,
            associatedUserId: queryArg.associatedUserId,
            resolved: queryArg.resolved,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["alertinstances"],
      }),
      getAlertInstanceById: build.query<GetAlertInstanceByIdRes, GetAlertInstanceByIdArgs>({
        query: (queryArg) => ({url: `/alertInstance/${queryArg}`}),
        providesTags: ["alertinstances"],
      }),
      patchAlertInstanceById: build.mutation<PatchAlertInstanceByIdRes, PatchAlertInstanceByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/alertInstance/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.body,
          }),
          invalidatesTags: ["alertinstances"],
        }
      ),
      getAlertInstances: build.query<GetAlertInstancesRes, GetAlertInstancesArgs>({
        query: (queryArg) => ({
          url: `/alertInstances/`,
          params: {
            _id: queryArg._id,
            archived: queryArg.archived,
            associatedUserId: queryArg.associatedUserId,
            resolved: queryArg.resolved,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["alertinstances"],
      }),
      getAlertInstancesById: build.query<GetAlertInstancesByIdRes, GetAlertInstancesByIdArgs>({
        query: (queryArg) => ({url: `/alertInstances/${queryArg}`}),
        providesTags: ["alertinstances"],
      }),
      patchAlertInstancesById: build.mutation<
        PatchAlertInstancesByIdRes,
        PatchAlertInstancesByIdArgs
      >({
        query: (queryArg) => ({
          url: `/alertInstances/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["alertinstances"],
      }),
      getAlerts: build.query<GetAlertsRes, GetAlertsArgs>({
        query: (queryArg) => ({
          url: `/alerts/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["alerts"],
      }),
      getAlertsById: build.query<GetAlertsByIdRes, GetAlertsByIdArgs>({
        query: (queryArg) => ({url: `/alerts/${queryArg}`}),
        providesTags: ["alerts"],
      }),
      patchAlertsById: build.mutation<PatchAlertsByIdRes, PatchAlertsByIdArgs>({
        query: (queryArg) => ({
          url: `/alerts/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["alerts"],
      }),
      getAppointmentSlots: build.query<GetAppointmentSlotsRes, GetAppointmentSlotsArgs>({
        query: (queryArg) => ({
          url: `/appointmentSlots`,
          params: {
            staffId: queryArg.staffId,
            carePodId: queryArg.carePodId,
            type: queryArg.type,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            duration: queryArg.duration,
            documentationTime: queryArg.documentationTime,
            travelTime: queryArg.travelTime,
            timezone: queryArg.timezone,
          },
        }),
      }),
      postAuditLog: build.mutation<PostAuditLogRes, PostAuditLogArgs>({
        query: (queryArg) => ({url: `/auditLog/`, method: "POST", body: queryArg}),
        invalidatesTags: ["auditlogevents"],
      }),
      getAuditLog: build.query<GetAuditLogRes, GetAuditLogArgs>({
        query: (queryArg) => ({
          url: `/auditLog/`,
          params: {
            _id: queryArg._id,
            isActivityLogEvent: queryArg.isActivityLogEvent,
            appliedUserId: queryArg.appliedUserId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["auditlogevents"],
      }),
      getAuditLogById: build.query<GetAuditLogByIdRes, GetAuditLogByIdArgs>({
        query: (queryArg) => ({url: `/auditLog/${queryArg}`}),
        providesTags: ["auditlogevents"],
      }),
      postAvatars: build.mutation<PostAvatarsRes, PostAvatarsArgs>({
        query: (queryArg) => ({url: `/avatars/`, method: "POST", body: queryArg}),
        invalidatesTags: ["avatars"],
      }),
      getAvatars: build.query<GetAvatarsRes, GetAvatarsArgs>({
        query: (queryArg) => ({
          url: `/avatars/`,
          params: {
            _id: queryArg._id,
            ownerId: queryArg.ownerId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["avatars"],
      }),
      getAvatarsById: build.query<GetAvatarsByIdRes, GetAvatarsByIdArgs>({
        query: (queryArg) => ({url: `/avatars/${queryArg}`}),
        providesTags: ["avatars"],
      }),
      patchAvatarsById: build.mutation<PatchAvatarsByIdRes, PatchAvatarsByIdArgs>({
        query: (queryArg) => ({
          url: `/avatars/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["avatars"],
      }),
      postCarePods: build.mutation<PostCarePodsRes, PostCarePodsArgs>({
        query: (queryArg) => ({url: `/carePods/`, method: "POST", body: queryArg}),
        invalidatesTags: ["carepods"],
      }),
      getCarePods: build.query<GetCarePodsRes, GetCarePodsArgs>({
        query: (queryArg) => ({
          url: `/carePods/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["carepods"],
      }),
      getCarePodsById: build.query<GetCarePodsByIdRes, GetCarePodsByIdArgs>({
        query: (queryArg) => ({url: `/carePods/${queryArg}`}),
        providesTags: ["carepods"],
      }),
      patchCarePodsById: build.mutation<PatchCarePodsByIdRes, PatchCarePodsByIdArgs>({
        query: (queryArg) => ({
          url: `/carePods/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["carepods"],
      }),
      deleteCarePodsById: build.mutation<DeleteCarePodsByIdRes, DeleteCarePodsByIdArgs>({
        query: (queryArg) => ({url: `/carePods/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["carepods"],
      }),
      getCharmTokens: build.query<GetCharmTokensRes, GetCharmTokensArgs>({
        query: (queryArg) => ({
          url: `/charmTokens/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["charmtokens"],
      }),
      deleteCharmTokensById: build.mutation<DeleteCharmTokensByIdRes, DeleteCharmTokensByIdArgs>({
        query: (queryArg) => ({url: `/charmTokens/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["charmtokens"],
      }),
      postCompanyOrganizations: build.mutation<
        PostCompanyOrganizationsRes,
        PostCompanyOrganizationsArgs
      >({
        query: (queryArg) => ({url: `/companyOrganizations/`, method: "POST", body: queryArg}),
        invalidatesTags: ["companyorganizations"],
      }),
      getCompanyOrganizations: build.query<GetCompanyOrganizationsRes, GetCompanyOrganizationsArgs>(
        {
          query: (queryArg) => ({
            url: `/companyOrganizations/`,
            params: {
              _id: queryArg._id,
              page: queryArg.page,
              sort: queryArg.sort,
              limit: queryArg.limit,
            },
          }),
          providesTags: ["companyorganizations"],
        }
      ),
      getCompanyOrganizationsById: build.query<
        GetCompanyOrganizationsByIdRes,
        GetCompanyOrganizationsByIdArgs
      >({
        query: (queryArg) => ({url: `/companyOrganizations/${queryArg}`}),
        providesTags: ["companyorganizations"],
      }),
      patchCompanyOrganizationsById: build.mutation<
        PatchCompanyOrganizationsByIdRes,
        PatchCompanyOrganizationsByIdArgs
      >({
        query: (queryArg) => ({
          url: `/companyOrganizations/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["companyorganizations"],
      }),
      deleteCompanyOrganizationsById: build.mutation<
        DeleteCompanyOrganizationsByIdRes,
        DeleteCompanyOrganizationsByIdArgs
      >({
        query: (queryArg) => ({url: `/companyOrganizations/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["companyorganizations"],
      }),
      postConversations: build.mutation<PostConversationsRes, PostConversationsArgs>({
        query: (queryArg) => ({url: `/conversations/`, method: "POST", body: queryArg}),
        invalidatesTags: ["conversations"],
      }),
      getConversations: build.query<GetConversationsRes, GetConversationsArgs>({
        query: (queryArg) => ({
          url: `/conversations/`,
          params: {
            _id: queryArg._id,
            "users.userId": queryArg["users.userId"],
            type: queryArg.type,
            $and: queryArg.$and,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["conversations"],
      }),
      getConversationsById: build.query<GetConversationsByIdRes, GetConversationsByIdArgs>({
        query: (queryArg) => ({url: `/conversations/${queryArg}`}),
        providesTags: ["conversations"],
      }),
      patchConversationsById: build.mutation<PatchConversationsByIdRes, PatchConversationsByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/conversations/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.body,
          }),
          invalidatesTags: ["conversations"],
        }
      ),
      deleteConversationsById: build.mutation<
        DeleteConversationsByIdRes,
        DeleteConversationsByIdArgs
      >({
        query: (queryArg) => ({url: `/conversations/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["conversations"],
      }),
      getConversationsSummary: build.query<GetConversationsSummaryRes, GetConversationsSummaryArgs>(
        {
          query: () => ({url: `/conversationsSummary`}),
          providesTags: ["conversations", "messages", "conversationstatuses"],
        }
      ),
      postConversationStatuses: build.mutation<
        PostConversationStatusesRes,
        PostConversationStatusesArgs
      >({
        query: (queryArg) => ({url: `/conversationStatuses/`, method: "POST", body: queryArg}),
        invalidatesTags: ["conversationstatuses"],
      }),
      getConversationStatuses: build.query<GetConversationStatusesRes, GetConversationStatusesArgs>(
        {
          query: (queryArg) => ({
            url: `/conversationStatuses/`,
            params: {
              _id: queryArg._id,
              ownerId: queryArg.ownerId,
              page: queryArg.page,
              sort: queryArg.sort,
              limit: queryArg.limit,
            },
          }),
          providesTags: ["conversationstatuses"],
        }
      ),
      getConversationStatusesById: build.query<
        GetConversationStatusesByIdRes,
        GetConversationStatusesByIdArgs
      >({
        query: (queryArg) => ({url: `/conversationStatuses/${queryArg}`}),
        providesTags: ["conversationstatuses"],
      }),
      deleteConversationStatusesById: build.mutation<
        DeleteConversationStatusesByIdRes,
        DeleteConversationStatusesByIdArgs
      >({
        query: (queryArg) => ({url: `/conversationStatuses/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["conversationstatuses"],
      }),
      postCriticalEvents: build.mutation<PostCriticalEventsRes, PostCriticalEventsArgs>({
        query: (queryArg) => ({url: `/criticalEvents/`, method: "POST", body: queryArg}),
        invalidatesTags: ["criticalevents"],
      }),
      getCriticalEvents: build.query<GetCriticalEventsRes, GetCriticalEventsArgs>({
        query: (queryArg) => ({
          url: `/criticalEvents/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["criticalevents"],
      }),
      getCriticalEventsById: build.query<GetCriticalEventsByIdRes, GetCriticalEventsByIdArgs>({
        query: (queryArg) => ({url: `/criticalEvents/${queryArg}`}),
        providesTags: ["criticalevents"],
      }),
      postCsv: build.mutation<PostCsvRes, PostCsvArgs>({
        query: (queryArg) => ({url: `/csv`, method: "POST", body: queryArg}),
      }),
      postMessageCsv: build.mutation<PostMessageCsvRes, PostMessageCsvArgs>({
        query: (queryArg) => ({url: `/messageCSV`, method: "POST", body: queryArg}),
      }),
      getDiagnoses: build.query<GetDiagnosesRes, GetDiagnosesArgs>({
        query: (queryArg) => ({
          url: `/diagnoses/`,
          params: {
            _id: queryArg._id,
            label: queryArg.label,
            $and: queryArg.$and,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["medicaldiagnoses"],
      }),
      getDiagnosesById: build.query<GetDiagnosesByIdRes, GetDiagnosesByIdArgs>({
        query: (queryArg) => ({url: `/diagnoses/${queryArg}`}),
        providesTags: ["medicaldiagnoses"],
      }),
      postDosespotUiUrl: build.mutation<PostDosespotUiUrlRes, PostDosespotUiUrlArgs>({
        query: (queryArg) => ({url: `/dosespotUiUrl`, method: "POST", body: queryArg}),
      }),
      postDosespotSyncPatient: build.mutation<
        PostDosespotSyncPatientRes,
        PostDosespotSyncPatientArgs
      >({
        query: (queryArg) => ({url: `/dosespotSyncPatient`, method: "POST", body: queryArg}),
      }),
      postDotPhrases: build.mutation<PostDotPhrasesRes, PostDotPhrasesArgs>({
        query: (queryArg) => ({url: `/dotPhrases/`, method: "POST", body: queryArg}),
        invalidatesTags: ["dotphrases"],
      }),
      getDotPhrases: build.query<GetDotPhrasesRes, GetDotPhrasesArgs>({
        query: (queryArg) => ({
          url: `/dotPhrases/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["dotphrases"],
      }),
      getDotPhrasesById: build.query<GetDotPhrasesByIdRes, GetDotPhrasesByIdArgs>({
        query: (queryArg) => ({url: `/dotPhrases/${queryArg}`}),
        providesTags: ["dotphrases"],
      }),
      patchDotPhrasesById: build.mutation<PatchDotPhrasesByIdRes, PatchDotPhrasesByIdArgs>({
        query: (queryArg) => ({
          url: `/dotPhrases/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["dotphrases"],
      }),
      deleteDotPhrasesById: build.mutation<DeleteDotPhrasesByIdRes, DeleteDotPhrasesByIdArgs>({
        query: (queryArg) => ({url: `/dotPhrases/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["dotphrases"],
      }),
      postExternalClinicians: build.mutation<PostExternalCliniciansRes, PostExternalCliniciansArgs>(
        {
          query: (queryArg) => ({url: `/externalClinicians/`, method: "POST", body: queryArg}),
          invalidatesTags: ["externalclinicians"],
        }
      ),
      getExternalClinicians: build.query<GetExternalCliniciansRes, GetExternalCliniciansArgs>({
        query: (queryArg) => ({
          url: `/externalClinicians/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["externalclinicians"],
      }),
      getExternalCliniciansById: build.query<
        GetExternalCliniciansByIdRes,
        GetExternalCliniciansByIdArgs
      >({
        query: (queryArg) => ({url: `/externalClinicians/${queryArg}`}),
        providesTags: ["externalclinicians"],
      }),
      patchExternalCliniciansById: build.mutation<
        PatchExternalCliniciansByIdRes,
        PatchExternalCliniciansByIdArgs
      >({
        query: (queryArg) => ({
          url: `/externalClinicians/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["externalclinicians"],
      }),
      deleteExternalCliniciansById: build.mutation<
        DeleteExternalCliniciansByIdRes,
        DeleteExternalCliniciansByIdArgs
      >({
        query: (queryArg) => ({url: `/externalClinicians/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["externalclinicians"],
      }),
      postFamilyUnits: build.mutation<PostFamilyUnitsRes, PostFamilyUnitsArgs>({
        query: (queryArg) => ({url: `/familyUnits/`, method: "POST", body: queryArg}),
        invalidatesTags: ["familyunits"],
      }),
      getFamilyUnits: build.query<GetFamilyUnitsRes, GetFamilyUnitsArgs>({
        query: (queryArg) => ({
          url: `/familyUnits/`,
          params: {
            _id: queryArg._id,
            familyUsers: queryArg.familyUsers,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["familyunits"],
      }),
      getFamilyUnitsById: build.query<GetFamilyUnitsByIdRes, GetFamilyUnitsByIdArgs>({
        query: (queryArg) => ({url: `/familyUnits/${queryArg}`}),
        providesTags: ["familyunits"],
      }),
      patchFamilyUnitsById: build.mutation<PatchFamilyUnitsByIdRes, PatchFamilyUnitsByIdArgs>({
        query: (queryArg) => ({
          url: `/familyUnits/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["familyunits"],
      }),
      deleteFamilyUnitsById: build.mutation<DeleteFamilyUnitsByIdRes, DeleteFamilyUnitsByIdArgs>({
        query: (queryArg) => ({url: `/familyUnits/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["familyunits"],
      }),
      getFitbitSleep: build.query<GetFitbitSleepRes, GetFitbitSleepArgs>({
        query: (queryArg) => ({
          url: `/fitbitSleep/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            period: queryArg.period,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["fitbitsleeps"],
      }),
      getFitbitSleepById: build.query<GetFitbitSleepByIdRes, GetFitbitSleepByIdArgs>({
        query: (queryArg) => ({url: `/fitbitSleep/${queryArg}`}),
        providesTags: ["fitbitsleeps"],
      }),
      getFitbitSteps: build.query<GetFitbitStepsRes, GetFitbitStepsArgs>({
        query: (queryArg) => ({
          url: `/fitbitSteps/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            period: queryArg.period,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["fitbitintradaysteps"],
      }),
      getFitbitStepsById: build.query<GetFitbitStepsByIdRes, GetFitbitStepsByIdArgs>({
        query: (queryArg) => ({url: `/fitbitSteps/${queryArg}`}),
        providesTags: ["fitbitintradaysteps"],
      }),
      getFitbitHrv: build.query<GetFitbitHrvRes, GetFitbitHrvArgs>({
        query: (queryArg) => ({
          url: `/fitbitHrv/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            period: queryArg.period,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["fitbithrvs"],
      }),
      getFitbitHrvById: build.query<GetFitbitHrvByIdRes, GetFitbitHrvByIdArgs>({
        query: (queryArg) => ({url: `/fitbitHrv/${queryArg}`}),
        providesTags: ["fitbithrvs"],
      }),
      getFitbitHeartRate: build.query<GetFitbitHeartRateRes, GetFitbitHeartRateArgs>({
        query: (queryArg) => ({
          url: `/fitbitHeartRate/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            period: queryArg.period,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["fitbitheartrates"],
      }),
      getFitbitHeartRateById: build.query<GetFitbitHeartRateByIdRes, GetFitbitHeartRateByIdArgs>({
        query: (queryArg) => ({url: `/fitbitHeartRate/${queryArg}`}),
        providesTags: ["fitbitheartrates"],
      }),
      getFitbitStatuses: build.query<GetFitbitStatusesRes, GetFitbitStatusesArgs>({
        query: (queryArg) => ({
          url: `/fitbitStatuses/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["fitbitstatuses"],
      }),
      getFitbitStatusesById: build.query<GetFitbitStatusesByIdRes, GetFitbitStatusesByIdArgs>({
        query: (queryArg) => ({url: `/fitbitStatuses/${queryArg}`}),
        providesTags: ["fitbitstatuses"],
      }),
      postFormInstances: build.mutation<PostFormInstancesRes, PostFormInstancesArgs>({
        query: (queryArg) => ({url: `/formInstances/`, method: "POST", body: queryArg}),
        invalidatesTags: ["forminstances"],
      }),
      getFormInstances: build.query<GetFormInstancesRes, GetFormInstancesArgs>({
        query: (queryArg) => ({
          url: `/formInstances/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            type: queryArg.type,
            formId: queryArg.formId,
            "answers.1.answers.0": queryArg["answers.1.answers.0"],
            authorType: queryArg.authorType,
            "form.assessmentType": queryArg["form.assessmentType"],
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["forminstances"],
      }),
      getFormInstancesById: build.query<GetFormInstancesByIdRes, GetFormInstancesByIdArgs>({
        query: (queryArg) => ({url: `/formInstances/${queryArg}`}),
        providesTags: ["forminstances"],
      }),
      patchFormInstancesById: build.mutation<PatchFormInstancesByIdRes, PatchFormInstancesByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/formInstances/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.body,
          }),
          invalidatesTags: ["forminstances"],
        }
      ),
      deleteFormInstancesById: build.mutation<
        DeleteFormInstancesByIdRes,
        DeleteFormInstancesByIdArgs
      >({
        query: (queryArg) => ({url: `/formInstances/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["forminstances"],
      }),
      postFormInstancesPdfByFormInstanceId: build.mutation<
        PostFormInstancesPdfByFormInstanceIdRes,
        PostFormInstancesPdfByFormInstanceIdArgs
      >({
        query: (queryArg) => ({
          url: `/formInstancesPDF/${queryArg.formInstanceId}`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postForms: build.mutation<PostFormsRes, PostFormsArgs>({
        query: (queryArg) => ({url: `/forms/`, method: "POST", body: queryArg}),
        invalidatesTags: ["forms"],
      }),
      getForms: build.query<GetFormsRes, GetFormsArgs>({
        query: (queryArg) => ({
          url: `/forms/`,
          params: {
            _id: queryArg._id,
            type: queryArg.type,
            name: queryArg.name,
            deleted: queryArg.deleted,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["forms"],
      }),
      getFormsById: build.query<GetFormsByIdRes, GetFormsByIdArgs>({
        query: (queryArg) => ({url: `/forms/${queryArg}`}),
        providesTags: ["forms"],
      }),
      patchFormsById: build.mutation<PatchFormsByIdRes, PatchFormsByIdArgs>({
        query: (queryArg) => ({url: `/forms/${queryArg.id}`, method: "PATCH", body: queryArg.body}),
        invalidatesTags: ["forms"],
      }),
      deleteFormsById: build.mutation<DeleteFormsByIdRes, DeleteFormsByIdArgs>({
        query: (queryArg) => ({url: `/forms/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["forms"],
      }),
      postGptHistories: build.mutation<PostGptHistoriesRes, PostGptHistoriesArgs>({
        query: (queryArg) => ({url: `/gptHistories/`, method: "POST", body: queryArg}),
        invalidatesTags: ["gpthistories"],
      }),
      getGptHistories: build.query<GetGptHistoriesRes, GetGptHistoriesArgs>({
        query: (queryArg) => ({
          url: `/gptHistories/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["gpthistories"],
      }),
      getGptHistoriesById: build.query<GetGptHistoriesByIdRes, GetGptHistoriesByIdArgs>({
        query: (queryArg) => ({url: `/gptHistories/${queryArg}`}),
        providesTags: ["gpthistories"],
      }),
      patchGptHistoriesById: build.mutation<PatchGptHistoriesByIdRes, PatchGptHistoriesByIdArgs>({
        query: (queryArg) => ({
          url: `/gptHistories/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["gpthistories"],
      }),
      deleteGptHistoriesById: build.mutation<DeleteGptHistoriesByIdRes, DeleteGptHistoriesByIdArgs>(
        {
          query: (queryArg) => ({url: `/gptHistories/${queryArg}`, method: "DELETE"}),
          invalidatesTags: ["gpthistories"],
        }
      ),
      postGuidingHours: build.mutation<PostGuidingHoursRes, PostGuidingHoursArgs>({
        query: (queryArg) => ({url: `/guidingHours/`, method: "POST", body: queryArg}),
        invalidatesTags: ["guidinghours"],
      }),
      getGuidingHours: build.query<GetGuidingHoursRes, GetGuidingHoursArgs>({
        query: (queryArg) => ({
          url: `/guidingHours/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["guidinghours"],
      }),
      getGuidingHoursById: build.query<GetGuidingHoursByIdRes, GetGuidingHoursByIdArgs>({
        query: (queryArg) => ({url: `/guidingHours/${queryArg}`}),
        providesTags: ["guidinghours"],
      }),
      patchGuidingHoursById: build.mutation<PatchGuidingHoursByIdRes, PatchGuidingHoursByIdArgs>({
        query: (queryArg) => ({
          url: `/guidingHours/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["guidinghours"],
      }),
      deleteGuidingHoursById: build.mutation<DeleteGuidingHoursByIdRes, DeleteGuidingHoursByIdArgs>(
        {
          query: (queryArg) => ({url: `/guidingHours/${queryArg}`, method: "DELETE"}),
          invalidatesTags: ["guidinghours"],
        }
      ),
      postHealthEventInstances: build.mutation<
        PostHealthEventInstancesRes,
        PostHealthEventInstancesArgs
      >({
        query: (queryArg) => ({url: `/healthEventInstances/`, method: "POST", body: queryArg}),
        invalidatesTags: ["healtheventinstances"],
      }),
      getHealthEventInstances: build.query<GetHealthEventInstancesRes, GetHealthEventInstancesArgs>(
        {
          query: (queryArg) => ({
            url: `/healthEventInstances/`,
            params: {
              _id: queryArg._id,
              userId: queryArg.userId,
              page: queryArg.page,
              sort: queryArg.sort,
              limit: queryArg.limit,
            },
          }),
          providesTags: ["healtheventinstances"],
        }
      ),
      getHealthEventInstancesById: build.query<
        GetHealthEventInstancesByIdRes,
        GetHealthEventInstancesByIdArgs
      >({
        query: (queryArg) => ({url: `/healthEventInstances/${queryArg}`}),
        providesTags: ["healtheventinstances"],
      }),
      patchHealthEventInstancesById: build.mutation<
        PatchHealthEventInstancesByIdRes,
        PatchHealthEventInstancesByIdArgs
      >({
        query: (queryArg) => ({
          url: `/healthEventInstances/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["healtheventinstances"],
      }),
      deleteHealthEventInstancesById: build.mutation<
        DeleteHealthEventInstancesByIdRes,
        DeleteHealthEventInstancesByIdArgs
      >({
        query: (queryArg) => ({url: `/healthEventInstances/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["healtheventinstances"],
      }),
      postHealthEvents: build.mutation<PostHealthEventsRes, PostHealthEventsArgs>({
        query: (queryArg) => ({url: `/healthEvents/`, method: "POST", body: queryArg}),
        invalidatesTags: ["healthevents"],
      }),
      getHealthEvents: build.query<GetHealthEventsRes, GetHealthEventsArgs>({
        query: (queryArg) => ({
          url: `/healthEvents/`,
          params: {
            _id: queryArg._id,
            type: queryArg.type,
            name: queryArg.name,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["healthevents"],
      }),
      getHealthEventsById: build.query<GetHealthEventsByIdRes, GetHealthEventsByIdArgs>({
        query: (queryArg) => ({url: `/healthEvents/${queryArg}`}),
        providesTags: ["healthevents"],
      }),
      patchHealthEventsById: build.mutation<PatchHealthEventsByIdRes, PatchHealthEventsByIdArgs>({
        query: (queryArg) => ({
          url: `/healthEvents/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["healthevents"],
      }),
      deleteHealthEventsById: build.mutation<DeleteHealthEventsByIdRes, DeleteHealthEventsByIdArgs>(
        {
          query: (queryArg) => ({url: `/healthEvents/${queryArg}`, method: "DELETE"}),
          invalidatesTags: ["healthevents"],
        }
      ),
      postInsurancePlans: build.mutation<PostInsurancePlansRes, PostInsurancePlansArgs>({
        query: (queryArg) => ({url: `/insurancePlans/`, method: "POST", body: queryArg}),
        invalidatesTags: ["insuranceplans"],
      }),
      getInsurancePlans: build.query<GetInsurancePlansRes, GetInsurancePlansArgs>({
        query: (queryArg) => ({
          url: `/insurancePlans/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["insuranceplans"],
      }),
      getInsurancePlansById: build.query<GetInsurancePlansByIdRes, GetInsurancePlansByIdArgs>({
        query: (queryArg) => ({url: `/insurancePlans/${queryArg}`}),
        providesTags: ["insuranceplans"],
      }),
      patchInsurancePlansById: build.mutation<
        PatchInsurancePlansByIdRes,
        PatchInsurancePlansByIdArgs
      >({
        query: (queryArg) => ({
          url: `/insurancePlans/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["insuranceplans"],
      }),
      deleteInsurancePlansById: build.mutation<
        DeleteInsurancePlansByIdRes,
        DeleteInsurancePlansByIdArgs
      >({
        query: (queryArg) => ({url: `/insurancePlans/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["insuranceplans"],
      }),
      postMessages: build.mutation<PostMessagesRes, PostMessagesArgs>({
        query: (queryArg) => ({url: `/messages/`, method: "POST", body: queryArg}),
        invalidatesTags: ["messages"],
      }),
      getMessages: build.query<GetMessagesRes, GetMessagesArgs>({
        query: (queryArg) => ({
          url: `/messages/`,
          params: {
            _id: queryArg._id,
            conversationId: queryArg.conversationId,
            from: queryArg.from,
            created: queryArg.created,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["messages"],
      }),
      getMessagesById: build.query<GetMessagesByIdRes, GetMessagesByIdArgs>({
        query: (queryArg) => ({url: `/messages/${queryArg}`}),
        providesTags: ["messages"],
      }),
      patchMessagesById: build.mutation<PatchMessagesByIdRes, PatchMessagesByIdArgs>({
        query: (queryArg) => ({
          url: `/messages/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["messages"],
      }),
      deleteMessagesById: build.mutation<DeleteMessagesByIdRes, DeleteMessagesByIdArgs>({
        query: (queryArg) => ({url: `/messages/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["messages"],
      }),
      postNotifications: build.mutation<PostNotificationsRes, PostNotificationsArgs>({
        query: (queryArg) => ({url: `/notifications/`, method: "POST", body: queryArg}),
        invalidatesTags: ["notifications"],
      }),
      getNotifications: build.query<GetNotificationsRes, GetNotificationsArgs>({
        query: (queryArg) => ({
          url: `/notifications/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["notifications"],
      }),
      getNotificationsById: build.query<GetNotificationsByIdRes, GetNotificationsByIdArgs>({
        query: (queryArg) => ({url: `/notifications/${queryArg}`}),
        providesTags: ["notifications"],
      }),
      patchNotificationsById: build.mutation<PatchNotificationsByIdRes, PatchNotificationsByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/notifications/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.body,
          }),
          invalidatesTags: ["notifications"],
        }
      ),
      deleteNotificationsById: build.mutation<
        DeleteNotificationsByIdRes,
        DeleteNotificationsByIdArgs
      >({
        query: (queryArg) => ({url: `/notifications/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["notifications"],
      }),
      postPatientWeeklyUpdates: build.mutation<
        PostPatientWeeklyUpdatesRes,
        PostPatientWeeklyUpdatesArgs
      >({
        query: (queryArg) => ({url: `/patientWeeklyUpdates/`, method: "POST", body: queryArg}),
        invalidatesTags: ["patientweeklyupdates"],
      }),
      getPatientWeeklyUpdates: build.query<GetPatientWeeklyUpdatesRes, GetPatientWeeklyUpdatesArgs>(
        {
          query: (queryArg) => ({
            url: `/patientWeeklyUpdates/`,
            params: {
              _id: queryArg._id,
              patientId: queryArg.patientId,
              page: queryArg.page,
              sort: queryArg.sort,
              limit: queryArg.limit,
            },
          }),
          providesTags: ["patientweeklyupdates"],
        }
      ),
      getPatientWeeklyUpdatesById: build.query<
        GetPatientWeeklyUpdatesByIdRes,
        GetPatientWeeklyUpdatesByIdArgs
      >({
        query: (queryArg) => ({url: `/patientWeeklyUpdates/${queryArg}`}),
        providesTags: ["patientweeklyupdates"],
      }),
      patchPatientWeeklyUpdatesById: build.mutation<
        PatchPatientWeeklyUpdatesByIdRes,
        PatchPatientWeeklyUpdatesByIdArgs
      >({
        query: (queryArg) => ({
          url: `/patientWeeklyUpdates/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["patientweeklyupdates"],
      }),
      postPreClinicUpdates: build.mutation<PostPreClinicUpdatesRes, PostPreClinicUpdatesArgs>({
        query: (queryArg) => ({url: `/preClinicUpdates/`, method: "POST", body: queryArg}),
        invalidatesTags: ["preclinicupdates"],
      }),
      getPreClinicUpdates: build.query<GetPreClinicUpdatesRes, GetPreClinicUpdatesArgs>({
        query: (queryArg) => ({
          url: `/preClinicUpdates/`,
          params: {
            _id: queryArg._id,
            ownerId: queryArg.ownerId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["preclinicupdates"],
      }),
      getPreClinicUpdatesById: build.query<GetPreClinicUpdatesByIdRes, GetPreClinicUpdatesByIdArgs>(
        {
          query: (queryArg) => ({url: `/preClinicUpdates/${queryArg}`}),
          providesTags: ["preclinicupdates"],
        }
      ),
      patchPreClinicUpdatesById: build.mutation<
        PatchPreClinicUpdatesByIdRes,
        PatchPreClinicUpdatesByIdArgs
      >({
        query: (queryArg) => ({
          url: `/preClinicUpdates/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["preclinicupdates"],
      }),
      deletePreClinicUpdatesById: build.mutation<
        DeletePreClinicUpdatesByIdRes,
        DeletePreClinicUpdatesByIdArgs
      >({
        query: (queryArg) => ({url: `/preClinicUpdates/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["preclinicupdates"],
      }),
      getPrescriptions: build.query<GetPrescriptionsRes, GetPrescriptionsArgs>({
        query: (queryArg) => ({
          url: `/prescriptions/`,
          params: {
            _id: queryArg._id,
            patientId: queryArg.patientId,
            prescriberId: queryArg.prescriberId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["prescriptions"],
      }),
      getPrescriptionsById: build.query<GetPrescriptionsByIdRes, GetPrescriptionsByIdArgs>({
        query: (queryArg) => ({url: `/prescriptions/${queryArg}`}),
        providesTags: ["prescriptions"],
      }),
      postRefreshPrescriptions: build.mutation<
        PostRefreshPrescriptionsRes,
        PostRefreshPrescriptionsArgs
      >({
        query: (queryArg) => ({url: `/refreshPrescriptions`, method: "POST", body: queryArg}),
      }),
      postReferralMethods: build.mutation<PostReferralMethodsRes, PostReferralMethodsArgs>({
        query: (queryArg) => ({url: `/referralMethods/`, method: "POST", body: queryArg}),
        invalidatesTags: ["referralmethods"],
      }),
      getReferralMethods: build.query<GetReferralMethodsRes, GetReferralMethodsArgs>({
        query: (queryArg) => ({
          url: `/referralMethods/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["referralmethods"],
      }),
      getReferralMethodsById: build.query<GetReferralMethodsByIdRes, GetReferralMethodsByIdArgs>({
        query: (queryArg) => ({url: `/referralMethods/${queryArg}`}),
        providesTags: ["referralmethods"],
      }),
      patchReferralMethodsById: build.mutation<
        PatchReferralMethodsByIdRes,
        PatchReferralMethodsByIdArgs
      >({
        query: (queryArg) => ({
          url: `/referralMethods/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["referralmethods"],
      }),
      deleteReferralMethodsById: build.mutation<
        DeleteReferralMethodsByIdRes,
        DeleteReferralMethodsByIdArgs
      >({
        query: (queryArg) => ({url: `/referralMethods/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["referralmethods"],
      }),
      postReferralSources: build.mutation<PostReferralSourcesRes, PostReferralSourcesArgs>({
        query: (queryArg) => ({url: `/referralSources/`, method: "POST", body: queryArg}),
        invalidatesTags: ["referralsources"],
      }),
      getReferralSources: build.query<GetReferralSourcesRes, GetReferralSourcesArgs>({
        query: (queryArg) => ({
          url: `/referralSources/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["referralsources"],
      }),
      getReferralSourcesById: build.query<GetReferralSourcesByIdRes, GetReferralSourcesByIdArgs>({
        query: (queryArg) => ({url: `/referralSources/${queryArg}`}),
        providesTags: ["referralsources"],
      }),
      patchReferralSourcesById: build.mutation<
        PatchReferralSourcesByIdRes,
        PatchReferralSourcesByIdArgs
      >({
        query: (queryArg) => ({
          url: `/referralSources/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["referralsources"],
      }),
      deleteReferralSourcesById: build.mutation<
        DeleteReferralSourcesByIdRes,
        DeleteReferralSourcesByIdArgs
      >({
        query: (queryArg) => ({url: `/referralSources/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["referralsources"],
      }),
      postScheduleItems: build.mutation<PostScheduleItemsRes, PostScheduleItemsArgs>({
        query: (queryArg) => ({url: `/scheduleItems/`, method: "POST", body: queryArg}),
        invalidatesTags: ["scheduleitems"],
      }),
      getScheduleItems: build.query<GetScheduleItemsRes, GetScheduleItemsArgs>({
        query: (queryArg) => ({
          url: `/scheduleItems/`,
          params: {
            _id: queryArg._id,
            "users.userId": queryArg["users.userId"],
            "staff.userId": queryArg["staff.userId"],
            startDatetime: queryArg.startDatetime,
            endDatetime: queryArg.endDatetime,
            type: queryArg.type,
            recurringId: queryArg.recurringId,
            attendanceStatus: queryArg.attendanceStatus,
            itemType: queryArg.itemType,
            carePodIds: queryArg.carePodIds,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["scheduleitems"],
      }),
      getScheduleItemsById: build.query<GetScheduleItemsByIdRes, GetScheduleItemsByIdArgs>({
        query: (queryArg) => ({url: `/scheduleItems/${queryArg}`}),
        providesTags: ["scheduleitems"],
      }),
      patchScheduleItemsById: build.mutation<PatchScheduleItemsByIdRes, PatchScheduleItemsByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/scheduleItems/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.body,
          }),
          invalidatesTags: ["scheduleitems"],
        }
      ),
      deleteScheduleItemsById: build.mutation<
        DeleteScheduleItemsByIdRes,
        DeleteScheduleItemsByIdArgs
      >({
        query: (queryArg) => ({url: `/scheduleItems/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["scheduleitems"],
      }),
      postRecurringScheduleItems: build.mutation<
        PostRecurringScheduleItemsRes,
        PostRecurringScheduleItemsArgs
      >({
        query: (queryArg) => ({url: `/recurringScheduleItems/`, method: "POST", body: queryArg}),
        invalidatesTags: ["recurringscheduleitems"],
      }),
      getRecurringScheduleItems: build.query<
        GetRecurringScheduleItemsRes,
        GetRecurringScheduleItemsArgs
      >({
        query: (queryArg) => ({
          url: `/recurringScheduleItems/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["recurringscheduleitems"],
      }),
      getRecurringScheduleItemsById: build.query<
        GetRecurringScheduleItemsByIdRes,
        GetRecurringScheduleItemsByIdArgs
      >({
        query: (queryArg) => ({url: `/recurringScheduleItems/${queryArg}`}),
        providesTags: ["recurringscheduleitems"],
      }),
      patchRecurringScheduleItemsById: build.mutation<
        PatchRecurringScheduleItemsByIdRes,
        PatchRecurringScheduleItemsByIdArgs
      >({
        query: (queryArg) => ({
          url: `/recurringScheduleItems/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["recurringscheduleitems"],
      }),
      deleteRecurringScheduleItemsById: build.mutation<
        DeleteRecurringScheduleItemsByIdRes,
        DeleteRecurringScheduleItemsByIdArgs
      >({
        query: (queryArg) => ({url: `/recurringScheduleItems/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["recurringscheduleitems"],
      }),
      postSyncScheduleGoogleCalendar: build.mutation<
        PostSyncScheduleGoogleCalendarRes,
        PostSyncScheduleGoogleCalendarArgs
      >({
        query: (queryArg) => ({url: `/syncScheduleGoogleCalendar`, method: "POST", body: queryArg}),
        invalidatesTags: ["scheduleitems", "recurringscheduleitems"],
      }),
      postScriptsLoadTestData: build.mutation<
        PostScriptsLoadTestDataRes,
        PostScriptsLoadTestDataArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/loadTestData`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsFtp: build.mutation<PostScriptsFtpRes, PostScriptsFtpArgs>({
        query: (queryArg) => ({
          url: `/scripts/ftp`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCharmSyncStaff: build.mutation<
        PostScriptsCharmSyncStaffRes,
        PostScriptsCharmSyncStaffArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/charm/syncStaff`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCharmSyncUsers: build.mutation<
        PostScriptsCharmSyncUsersRes,
        PostScriptsCharmSyncUsersArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/charm/syncUsers`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCharmSyncForms: build.mutation<
        PostScriptsCharmSyncFormsRes,
        PostScriptsCharmSyncFormsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/charm/syncForms`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsInternalChatSetup: build.mutation<
        PostScriptsInternalChatSetupRes,
        PostScriptsInternalChatSetupArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/internalChatSetup`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCreateConvos: build.mutation<
        PostScriptsCreateConvosRes,
        PostScriptsCreateConvosArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/createConvos`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsDeleteOrphanWorkflowMappings: build.mutation<
        PostScriptsDeleteOrphanWorkflowMappingsRes,
        PostScriptsDeleteOrphanWorkflowMappingsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/deleteOrphanWorkflowMappings`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsBackfillUserSettings: build.mutation<
        PostScriptsBackfillUserSettingsRes,
        PostScriptsBackfillUserSettingsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/backfillUserSettings`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsSyncAllScheduleItemsGoogleCalendar: build.mutation<
        PostScriptsSyncAllScheduleItemsGoogleCalendarRes,
        PostScriptsSyncAllScheduleItemsGoogleCalendarArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/syncAllScheduleItemsGoogleCalendar`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCreateGoogleCalendar: build.mutation<
        PostScriptsCreateGoogleCalendarRes,
        PostScriptsCreateGoogleCalendarArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/createGoogleCalendar`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsSyncAssessments: build.mutation<
        PostScriptsSyncAssessmentsRes,
        PostScriptsSyncAssessmentsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/syncAssessments`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsLoadMedicalDiagnosisData: build.mutation<
        PostScriptsLoadMedicalDiagnosisDataRes,
        PostScriptsLoadMedicalDiagnosisDataArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/loadMedicalDiagnosisData`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsLoadAlertsData: build.mutation<
        PostScriptsLoadAlertsDataRes,
        PostScriptsLoadAlertsDataArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/loadAlertsData`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsCleanupNotificationTasks: build.mutation<
        PostScriptsCleanupNotificationTasksRes,
        PostScriptsCleanupNotificationTasksArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/cleanupNotificationTasks`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsBackfillPatientIds: build.mutation<
        PostScriptsBackfillPatientIdsRes,
        PostScriptsBackfillPatientIdsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/backfillPatientIds`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsSyncIntakeDates: build.mutation<
        PostScriptsSyncIntakeDatesRes,
        PostScriptsSyncIntakeDatesArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/syncIntakeDates`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsDeleteNotificationsForDeletedScheduledItems: build.mutation<
        PostScriptsDeleteNotificationsForDeletedScheduledItemsRes,
        PostScriptsDeleteNotificationsForDeletedScheduledItemsArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/deleteNotificationsForDeletedScheduledItems`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsUpdateFormsIsRequired: build.mutation<
        PostScriptsUpdateFormsIsRequiredRes,
        PostScriptsUpdateFormsIsRequiredArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/updateFormsIsRequired`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsLoadCompanyHolidays: build.mutation<
        PostScriptsLoadCompanyHolidaysRes,
        PostScriptsLoadCompanyHolidaysArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/loadCompanyHolidays`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postScriptsSaveAllUsers: build.mutation<
        PostScriptsSaveAllUsersRes,
        PostScriptsSaveAllUsersArgs
      >({
        query: (queryArg) => ({
          url: `/scripts/saveAllUsers`,
          method: "POST",
          body: queryArg.body,
          params: {wetRun: queryArg.wetRun},
        }),
      }),
      postTodo: build.mutation<PostTodoRes, PostTodoArgs>({
        query: (queryArg) => ({url: `/todo/`, method: "POST", body: queryArg}),
        invalidatesTags: ["todoitems"],
      }),
      getTodo: build.query<GetTodoRes, GetTodoArgs>({
        query: (queryArg) => ({
          url: `/todo/`,
          params: {
            _id: queryArg._id,
            assignedUser: queryArg.assignedUser,
            referencedUser: queryArg.referencedUser,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["todoitems"],
      }),
      getTodoById: build.query<GetTodoByIdRes, GetTodoByIdArgs>({
        query: (queryArg) => ({url: `/todo/${queryArg}`}),
        providesTags: ["todoitems"],
      }),
      patchTodoById: build.mutation<PatchTodoByIdRes, PatchTodoByIdArgs>({
        query: (queryArg) => ({url: `/todo/${queryArg.id}`, method: "PATCH", body: queryArg.body}),
        invalidatesTags: ["todoitems"],
      }),
      deleteTodoById: build.mutation<DeleteTodoByIdRes, DeleteTodoByIdArgs>({
        query: (queryArg) => ({url: `/todo/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["todoitems"],
      }),
      postUserBio: build.mutation<PostUserBioRes, PostUserBioArgs>({
        query: (queryArg) => ({url: `/userBio/`, method: "POST", body: queryArg}),
        invalidatesTags: ["userbios"],
      }),
      getUserBio: build.query<GetUserBioRes, GetUserBioArgs>({
        query: (queryArg) => ({
          url: `/userBio/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["userbios"],
      }),
      getUserBioById: build.query<GetUserBioByIdRes, GetUserBioByIdArgs>({
        query: (queryArg) => ({url: `/userBio/${queryArg}`}),
        providesTags: ["userbios"],
      }),
      patchUserBioById: build.mutation<PatchUserBioByIdRes, PatchUserBioByIdArgs>({
        query: (queryArg) => ({
          url: `/userBio/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["userbios"],
      }),
      deleteUserBioById: build.mutation<DeleteUserBioByIdRes, DeleteUserBioByIdArgs>({
        query: (queryArg) => ({url: `/userBio/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["userbios"],
      }),
      postGetStaffPhoneNumber: build.mutation<
        PostGetStaffPhoneNumberRes,
        PostGetStaffPhoneNumberArgs
      >({
        query: (queryArg) => ({url: `/getStaffPhoneNumber`, method: "POST", body: queryArg}),
      }),
      postUsers: build.mutation<PostUsersRes, PostUsersArgs>({
        query: (queryArg) => ({url: `/users/`, method: "POST", body: queryArg}),
        invalidatesTags: ["users"],
      }),
      getUsers: build.query<GetUsersRes, GetUsersArgs>({
        query: (queryArg) => ({
          url: `/users/`,
          params: {
            _id: queryArg._id,
            type: queryArg.type,
            name: queryArg.name,
            initials: queryArg.initials,
            testUser: queryArg.testUser,
            disabled: queryArg.disabled,
            patientId: queryArg.patientId,
            "careTeam.Therapist": queryArg["careTeam.Therapist"],
            "careTeam.Psychiatrist": queryArg["careTeam.Psychiatrist"],
            "careTeam.Guide": queryArg["careTeam.Guide"],
            "careTeam.PatientGuide": queryArg["careTeam.PatientGuide"],
            "careTeam.FamilyGuide": queryArg["careTeam.FamilyGuide"],
            "staffRoles.Psychiatrist": queryArg["staffRoles.Psychiatrist"],
            "staffRoles.PatientGuideSupervisor": queryArg["staffRoles.PatientGuideSupervisor"],
            "staffRoles.FamilyGuideSupervisor": queryArg["staffRoles.FamilyGuideSupervisor"],
            "staffRoles.TherapistSupervisor": queryArg["staffRoles.TherapistSupervisor"],
            carePod: queryArg.carePod,
            supervisor: queryArg.supervisor,
            $or: queryArg.$or,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["users"],
      }),
      getUsersById: build.query<GetUsersByIdRes, GetUsersByIdArgs>({
        query: (queryArg) => ({url: `/users/${queryArg}`}),
        providesTags: ["users"],
      }),
      patchUsersById: build.mutation<PatchUsersByIdRes, PatchUsersByIdArgs>({
        query: (queryArg) => ({url: `/users/${queryArg.id}`, method: "PATCH", body: queryArg.body}),
        invalidatesTags: ["users"],
      }),
      deleteUsersById: build.mutation<DeleteUsersByIdRes, DeleteUsersByIdArgs>({
        query: (queryArg) => ({url: `/users/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["users"],
      }),
      postUserSessions: build.mutation<PostUserSessionsRes, PostUserSessionsArgs>({
        query: (queryArg) => ({url: `/userSessions/`, method: "POST", body: queryArg}),
        invalidatesTags: ["usersessions"],
      }),
      getUserSessions: build.query<GetUserSessionsRes, GetUserSessionsArgs>({
        query: (queryArg) => ({
          url: `/userSessions/`,
          params: {
            _id: queryArg._id,
            ownerId: queryArg.ownerId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["usersessions"],
      }),
      getUserSessionsById: build.query<GetUserSessionsByIdRes, GetUserSessionsByIdArgs>({
        query: (queryArg) => ({url: `/userSessions/${queryArg}`}),
        providesTags: ["usersessions"],
      }),
      patchUserSessionsById: build.mutation<PatchUserSessionsByIdRes, PatchUserSessionsByIdArgs>({
        query: (queryArg) => ({
          url: `/userSessions/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["usersessions"],
      }),
      deleteUserSessionsById: build.mutation<DeleteUserSessionsByIdRes, DeleteUserSessionsByIdArgs>(
        {
          query: (queryArg) => ({url: `/userSessions/${queryArg}`, method: "DELETE"}),
          invalidatesTags: ["usersessions"],
        }
      ),
      postUserSettings: build.mutation<PostUserSettingsRes, PostUserSettingsArgs>({
        query: (queryArg) => ({url: `/userSettings/`, method: "POST", body: queryArg}),
        invalidatesTags: ["usersettings"],
      }),
      getUserSettingsById: build.query<GetUserSettingsByIdRes, GetUserSettingsByIdArgs>({
        query: (queryArg) => ({url: `/userSettings/${queryArg}`}),
        providesTags: ["usersettings"],
      }),
      patchUserSettingsById: build.mutation<PatchUserSettingsByIdRes, PatchUserSettingsByIdArgs>({
        query: (queryArg) => ({
          url: `/userSettings/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["usersettings"],
      }),
      postUserStatuses: build.mutation<PostUserStatusesRes, PostUserStatusesArgs>({
        query: (queryArg) => ({url: `/userStatuses/`, method: "POST", body: queryArg}),
        invalidatesTags: ["userstatuses"],
      }),
      getUserStatuses: build.query<GetUserStatusesRes, GetUserStatusesArgs>({
        query: (queryArg) => ({
          url: `/userStatuses/`,
          params: {
            _id: queryArg._id,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["userstatuses"],
      }),
      getUserStatusesById: build.query<GetUserStatusesByIdRes, GetUserStatusesByIdArgs>({
        query: (queryArg) => ({url: `/userStatuses/${queryArg}`}),
        providesTags: ["userstatuses"],
      }),
      patchUserStatusesById: build.mutation<PatchUserStatusesByIdRes, PatchUserStatusesByIdArgs>({
        query: (queryArg) => ({
          url: `/userStatuses/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["userstatuses"],
      }),
      deleteUserStatusesById: build.mutation<DeleteUserStatusesByIdRes, DeleteUserStatusesByIdArgs>(
        {
          query: (queryArg) => ({url: `/userStatuses/${queryArg}`, method: "DELETE"}),
          invalidatesTags: ["userstatuses"],
        }
      ),
      getUserUpdates: build.query<GetUserUpdatesRes, GetUserUpdatesArgs>({
        query: (queryArg) => ({
          url: `/userUpdates/`,
          params: {
            _id: queryArg._id,
            ownerId: queryArg.ownerId,
            appliedUserId: queryArg.appliedUserId,
            eventName: queryArg.eventName,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["userupdates"],
      }),
      getUserUpdatesById: build.query<GetUserUpdatesByIdRes, GetUserUpdatesByIdArgs>({
        query: (queryArg) => ({url: `/userUpdates/${queryArg}`}),
        providesTags: ["userupdates"],
      }),
      patchUserUpdatesById: build.mutation<PatchUserUpdatesByIdRes, PatchUserUpdatesByIdArgs>({
        query: (queryArg) => ({
          url: `/userUpdates/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["userupdates"],
      }),
      getVoicemails: build.query<GetVoicemailsRes, GetVoicemailsArgs>({
        query: (queryArg) => ({
          url: `/voicemails/`,
          params: {
            _id: queryArg._id,
            ownerId: queryArg.ownerId,
            read: queryArg.read,
            archived: queryArg.archived,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["voicemails"],
      }),
      getVoicemailsById: build.query<GetVoicemailsByIdRes, GetVoicemailsByIdArgs>({
        query: (queryArg) => ({url: `/voicemails/${queryArg}`}),
        providesTags: ["voicemails"],
      }),
      patchVoicemailsById: build.mutation<PatchVoicemailsByIdRes, PatchVoicemailsByIdArgs>({
        query: (queryArg) => ({
          url: `/voicemails/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["voicemails"],
      }),
      postWorkflowMappings: build.mutation<PostWorkflowMappingsRes, PostWorkflowMappingsArgs>({
        query: (queryArg) => ({url: `/workflowMappings/`, method: "POST", body: queryArg}),
        invalidatesTags: ["workflowmappings"],
      }),
      getWorkflowMappings: build.query<GetWorkflowMappingsRes, GetWorkflowMappingsArgs>({
        query: (queryArg) => ({
          url: `/workflowMappings/`,
          params: {
            _id: queryArg._id,
            userId: queryArg.userId,
            staffId: queryArg.staffId,
            page: queryArg.page,
            sort: queryArg.sort,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["workflowmappings"],
      }),
      getWorkflowMappingsById: build.query<GetWorkflowMappingsByIdRes, GetWorkflowMappingsByIdArgs>(
        {
          query: (queryArg) => ({url: `/workflowMappings/${queryArg}`}),
          providesTags: ["workflowmappings"],
        }
      ),
      patchWorkflowMappingsById: build.mutation<
        PatchWorkflowMappingsByIdRes,
        PatchWorkflowMappingsByIdArgs
      >({
        query: (queryArg) => ({
          url: `/workflowMappings/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.body,
        }),
        invalidatesTags: ["workflowmappings"],
      }),
      deleteWorkflowMappingsById: build.mutation<
        DeleteWorkflowMappingsByIdRes,
        DeleteWorkflowMappingsByIdArgs
      >({
        query: (queryArg) => ({url: `/workflowMappings/${queryArg}`, method: "DELETE"}),
        invalidatesTags: ["workflowmappings"],
      }),
    }),
    overrideExisting: false,
  });
export {injectedRtkApi as openapi};
export type GetAlertInstanceRes = /** status 200 Successful list */ {
  data?: {
    alertId: {
      /**
       * Designates the type of user who the alert is aboutused to filter creation of
       * alertInstance.associatedUserId
       */
      associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /** Identifies the user who created the alert. */
      createdBy?: any;
      driver?: "cron" | "event";
      /**
       * If the alert was loaded from alertsData.ts via loadAlertData,
       * this will be set.To prevent duplicate alerts from being loaded or to update existing
       */
      internalKey?: string;
      isActive?: boolean;
      /**
       * If sendNotificationsToRecipients is true,
       * this message will be sent to all alert recipients when an alertInstance is created.Do NOT
       * Include information that is HIPAA protected
       */
      notificationMessage?: string;
      occurs?: {
        /**
         * Specifies the time frame in which the alertInstance is created relative to the
         * triggering event.
         */
        chronology?: "after" | "before" | "every";
        /**
         * The number of days, weeks, or months before, after,
         * or every the triggering event occurs.If occurs.isImmediately is true, increment must be
         * 0. See validation hook
         */
        increment?: number;
        /**
         * If true, the alert will be triggered immediately before or after the triggering event
         * occurs.If true, increment must be 0 and chronology must be either before or after.
         * See validation hook
         */
        isImmediately?: boolean;
        /**
         * Details the specific type of scheduled item related to when the alertInstance creation
         * occursNot all triggeringEvents require a scheduledItem.See validation hook and
         * TriggeringEventsRequiresScheduleItemForCron.
         */
        scheduleItemType?:
          | "Eligibility Interview"
          | "Clinical Intake"
          | "Guide Clinical Intake"
          | "Therapy Clinical Intake"
          | "Therapy Intake"
          | "Psychiatry Intake"
          | "In Home Onboarding Visit"
          | "Therapy"
          | "Therapy - Patient Session"
          | "Therapy - Caregiver Session"
          | "Psychiatry"
          | "Member Guide"
          | "Patient Guide"
          | "Family Guide"
          | "In Home Guide Visit"
          | "Travel"
          | "Individualized Service Plan"
          | "Meet and Greet"
          | "Other";
        /** The unit of time for the increment field. */
        unit?: "hours" | "days" | "weeks" | "months";
      };
      /**
       * If recipientUserType is Staff Specifies the roles of staff members who should view the
       * alertInstance.'Self' is used to assign an alert to specific users.
       * alertInstance.assignedToUsers will be set to the staff members ids
       */
      recipientStaffRole?: (
        | "ClinicalDirector"
        | "ClinicalLeader"
        | "ClinicalQualityAssurance"
        | "EnrollmentCoordinator"
        | "EnrollmentSupervisor"
        | "FamilyGuide"
        | "FamilyGuideSupervisor"
        | "MedicalDirector"
        | "PatientGuide"
        | "PatientGuideSupervisor"
        | "Psychiatrist"
        | "RiskManager"
        | "SoftwareEngineer"
        | "SuperUser"
        | "Therapist"
        | "TherapistSupervisor"
        | "MemberGuideSupervisor"
        | "Guide"
        | "Self"
      )[];
      /** Designates the type of user who will view the alertInstance. */
      recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /**
       * If true, notifications will be sent to all alert recipients when an alertInstance is
       * created.
       */
      sendNotificationsToRecipients?: boolean;
      /** Contains the user facing message of the alertInstance displayed to the recipient. */
      text?: string;
      /** Contains the user facing name of the alert displayed in admin. */
      title?: string;
      /** Specifies the event that triggers alertInstance creation. */
      triggeringEvent?: {
        /**
         * event driven:Specifies if a todo has been assigned to a user and trigger an
         * AlertInstance creation
         */
        assignTodo?: boolean;
        /**
         * event driven:Specifies the status of an individual's attendance that triggers an
         * AlertInstance creation
         */
        attendanceStatus?: string[];
        createdFormInstance?: {
          /**
           * event driven:Specifies the form that was created and triggers an AlertInstance
           * creation
           */
          formInternalKey?: string;
        };
        /**
         * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
         * creation
         */
        missingFamilyUnit?: boolean;
        /**
         * cron driven:Specifies which missing patient information should trigger an AlertInstance
         * creation
         */
        missingPatientInfo?: (
          | "address"
          | "address.address1"
          | "address.city"
          | "address.state"
          | "address.zipcode"
          | "phoneNumber"
          | "email"
          | "billingInfo"
          | "billingInfo.firstName"
          | "billingInfo.lastName"
          | "billingInfo.gender"
          | "billingInfo.insurancePlan"
          | "billingInfo.memberId"
          | "billingInfo.groupId"
          | "billingInfo.healthPlanId"
          | "billingInfo.authorizationCode"
          | "carePod"
          | "careTeam"
          | "careTeam.Therapist"
          | "careTeam.Psychiatrist"
          | "careTeam.PatientGuide"
          | "careTeam.FamilyGuide"
          | "preferredPharmacies"
        )[];
        /**
         * event driven:Specifies if a form, type note,
         * has risk language and triggers an AlertInstance creation
         */
        noteHasRiskLanguage?: boolean;
        /**
         * typically cron driven: Specifies the time for an AlertInstance creation.
         * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance
         * should be created do not create alertInstance
         */
        reminder?: {
          /** Used to process bulk alert creations during cron */
          type?: "user";
          /**
           * Used to find the user's status and status has the correct flag to create an
           * AlertInstance
           */
          userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
          queryConstructor?: {
            /**
             * The field, type Date, within the mode evaluate against alert.occurs for
             * AlertInstance creation.i.e.'growth.enrolledDate',
             * 'created'
             */
            dateField?: "growth.enrolledDate";
            /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
            model?: "User";
          };
          _id?: string;
        };
        /**
         * cron driven:Specifies if a critical event form instance is not status 'Signed' and
         * trigger an AlertInstance creation
         */
        unsignedCriticalEventFormInstance?: boolean;
        _id?: string;
      };
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    /**
     * Users manage their alertInstance notification center.When they archive an alertInstance,
     * their userId is added to this list to prevent it from showing up in their inbox.
     */
    archivedByUsers: any[];
    /**
     * If the alertInstance is assigned to specific user(s),
     * their userId is added to this list.The alertInstance will show up in their notification
     * center.This is currently only being used if the recipientStaffRole is 'Self'.
     */
    assignedToUsers: any[];
    associatedUserId: LimitedUser;
    /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
    flaggedRiskLanguage: string[];
    /**
     * If recipientUserType is Staff, Specifies the roles of staff members who should view the
     * alertInstance.Copied value of alert.recipientStaffRoles.
     */
    recipientStaffRole: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /**
     * Designates the type of user who will view the alertInstance.Copied value of
     * alert.recipientUserTypes.
     */
    recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
    resolved: boolean;
    resolvedBy?: {
      /**
       * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
       * instead of Id because many instances will be resolved by automation and will not have a
       * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
       * details on the alertInstances activity use auditLogEvents
       */
      name?: string;
      /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
      userId?: any;
    };
    /** DEPRECATED: Use source.id and source.model instead. */
    scheduleItemId?: any;
    /**
     * If the alertInstance was created relative to a models data,
     * reference to the source.To prevent duplicate alertInstances from being created for the same
     * user and alert.
     */
    source?: {
      model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
      _id?: string;
    };
    /**
     * The event that triggered the alertInstance creation.Reference to the key of
     * alert.triggeringEvents.
     */
    triggeringEvent:
      | "assignTodo"
      | "attendanceStatus"
      | "createdFormInstance"
      | "missingFamilyUnit"
      | "missingPatientInfo"
      | "noteHasRiskLanguage"
      | "reminder"
      | "unsignedCriticalEventFormInstance";
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    alertText?: string;
    dueDateText?: string;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetAlertInstanceArgs = {
  _id?: {
    $in?: string[];
  };
  archived?:
    | any
    | {
        $in?: any[];
      };
  associatedUserId?:
    | any
    | {
        $in?: any[];
      };
  resolved?:
    | boolean
    | {
        $in?: boolean[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetAlertInstanceByIdRes = /** status 200 Successful read */ {
  alertId: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver?: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive?: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients?: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text?: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title?: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent?: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  /**
   * Users manage their alertInstance notification center.When they archive an alertInstance,
   * their userId is added to this list to prevent it from showing up in their inbox.
   */
  archivedByUsers: any[];
  /**
   * If the alertInstance is assigned to specific user(s),
   * their userId is added to this list.The alertInstance will show up in their notification
   * center.This is currently only being used if the recipientStaffRole is 'Self'.
   */
  assignedToUsers: any[];
  associatedUserId: LimitedUser;
  /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
  flaggedRiskLanguage: string[];
  /**
   * If recipientUserType is Staff, Specifies the roles of staff members who should view the
   * alertInstance.Copied value of alert.recipientStaffRoles.
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /**
   * Designates the type of user who will view the alertInstance.Copied value of
   * alert.recipientUserTypes.
   */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  resolved: boolean;
  resolvedBy?: {
    /**
     * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
     * instead of Id because many instances will be resolved by automation and will not have a
     * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
     * details on the alertInstances activity use auditLogEvents
     */
    name?: string;
    /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
    userId?: any;
  };
  /** DEPRECATED: Use source.id and source.model instead. */
  scheduleItemId?: any;
  /**
   * If the alertInstance was created relative to a models data,
   * reference to the source.To prevent duplicate alertInstances from being created for the same
   * user and alert.
   */
  source?: {
    model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
    _id?: string;
  };
  /**
   * The event that triggered the alertInstance creation.Reference to the key of
   * alert.triggeringEvents.
   */
  triggeringEvent:
    | "assignTodo"
    | "attendanceStatus"
    | "createdFormInstance"
    | "missingFamilyUnit"
    | "missingPatientInfo"
    | "noteHasRiskLanguage"
    | "reminder"
    | "unsignedCriticalEventFormInstance";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  alertText?: string;
  dueDateText?: string;
};
export type GetAlertInstanceByIdArgs = string;
export type PatchAlertInstanceByIdRes = /** status 200 Successful update */ {
  alertId: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver?: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive?: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients?: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text?: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title?: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent?: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  /**
   * Users manage their alertInstance notification center.When they archive an alertInstance,
   * their userId is added to this list to prevent it from showing up in their inbox.
   */
  archivedByUsers: any[];
  /**
   * If the alertInstance is assigned to specific user(s),
   * their userId is added to this list.The alertInstance will show up in their notification
   * center.This is currently only being used if the recipientStaffRole is 'Self'.
   */
  assignedToUsers: any[];
  associatedUserId: LimitedUser;
  /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
  flaggedRiskLanguage: string[];
  /**
   * If recipientUserType is Staff, Specifies the roles of staff members who should view the
   * alertInstance.Copied value of alert.recipientStaffRoles.
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /**
   * Designates the type of user who will view the alertInstance.Copied value of
   * alert.recipientUserTypes.
   */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  resolved: boolean;
  resolvedBy?: {
    /**
     * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
     * instead of Id because many instances will be resolved by automation and will not have a
     * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
     * details on the alertInstances activity use auditLogEvents
     */
    name?: string;
    /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
    userId?: any;
  };
  /** DEPRECATED: Use source.id and source.model instead. */
  scheduleItemId?: any;
  /**
   * If the alertInstance was created relative to a models data,
   * reference to the source.To prevent duplicate alertInstances from being created for the same
   * user and alert.
   */
  source?: {
    model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
    _id?: string;
  };
  /**
   * The event that triggered the alertInstance creation.Reference to the key of
   * alert.triggeringEvents.
   */
  triggeringEvent:
    | "assignTodo"
    | "attendanceStatus"
    | "createdFormInstance"
    | "missingFamilyUnit"
    | "missingPatientInfo"
    | "noteHasRiskLanguage"
    | "reminder"
    | "unsignedCriticalEventFormInstance";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  alertText?: string;
  dueDateText?: string;
};
export type PatchAlertInstanceByIdArgs = {
  id: string;
  body: {
    alertId?: {
      /**
       * Designates the type of user who the alert is aboutused to filter creation of
       * alertInstance.associatedUserId
       */
      associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /** Identifies the user who created the alert. */
      createdBy?: any;
      driver?: "cron" | "event";
      /**
       * If the alert was loaded from alertsData.ts via loadAlertData,
       * this will be set.To prevent duplicate alerts from being loaded or to update existing
       */
      internalKey?: string;
      isActive?: boolean;
      /**
       * If sendNotificationsToRecipients is true,
       * this message will be sent to all alert recipients when an alertInstance is created.Do NOT
       * Include information that is HIPAA protected
       */
      notificationMessage?: string;
      occurs?: {
        /**
         * Specifies the time frame in which the alertInstance is created relative to the
         * triggering event.
         */
        chronology?: "after" | "before" | "every";
        /**
         * The number of days, weeks, or months before, after,
         * or every the triggering event occurs.If occurs.isImmediately is true, increment must be
         * 0. See validation hook
         */
        increment?: number;
        /**
         * If true, the alert will be triggered immediately before or after the triggering event
         * occurs.If true, increment must be 0 and chronology must be either before or after.
         * See validation hook
         */
        isImmediately?: boolean;
        /**
         * Details the specific type of scheduled item related to when the alertInstance creation
         * occursNot all triggeringEvents require a scheduledItem.See validation hook and
         * TriggeringEventsRequiresScheduleItemForCron.
         */
        scheduleItemType?:
          | "Eligibility Interview"
          | "Clinical Intake"
          | "Guide Clinical Intake"
          | "Therapy Clinical Intake"
          | "Therapy Intake"
          | "Psychiatry Intake"
          | "In Home Onboarding Visit"
          | "Therapy"
          | "Therapy - Patient Session"
          | "Therapy - Caregiver Session"
          | "Psychiatry"
          | "Member Guide"
          | "Patient Guide"
          | "Family Guide"
          | "In Home Guide Visit"
          | "Travel"
          | "Individualized Service Plan"
          | "Meet and Greet"
          | "Other";
        /** The unit of time for the increment field. */
        unit?: "hours" | "days" | "weeks" | "months";
      };
      /**
       * If recipientUserType is Staff Specifies the roles of staff members who should view the
       * alertInstance.'Self' is used to assign an alert to specific users.
       * alertInstance.assignedToUsers will be set to the staff members ids
       */
      recipientStaffRole?: (
        | "ClinicalDirector"
        | "ClinicalLeader"
        | "ClinicalQualityAssurance"
        | "EnrollmentCoordinator"
        | "EnrollmentSupervisor"
        | "FamilyGuide"
        | "FamilyGuideSupervisor"
        | "MedicalDirector"
        | "PatientGuide"
        | "PatientGuideSupervisor"
        | "Psychiatrist"
        | "RiskManager"
        | "SoftwareEngineer"
        | "SuperUser"
        | "Therapist"
        | "TherapistSupervisor"
        | "MemberGuideSupervisor"
        | "Guide"
        | "Self"
      )[];
      /** Designates the type of user who will view the alertInstance. */
      recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /**
       * If true, notifications will be sent to all alert recipients when an alertInstance is
       * created.
       */
      sendNotificationsToRecipients?: boolean;
      /** Contains the user facing message of the alertInstance displayed to the recipient. */
      text?: string;
      /** Contains the user facing name of the alert displayed in admin. */
      title?: string;
      /** Specifies the event that triggers alertInstance creation. */
      triggeringEvent?: {
        /**
         * event driven:Specifies if a todo has been assigned to a user and trigger an
         * AlertInstance creation
         */
        assignTodo?: boolean;
        /**
         * event driven:Specifies the status of an individual's attendance that triggers an
         * AlertInstance creation
         */
        attendanceStatus?: string[];
        createdFormInstance?: {
          /**
           * event driven:Specifies the form that was created and triggers an AlertInstance
           * creation
           */
          formInternalKey?: string;
        };
        /**
         * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
         * creation
         */
        missingFamilyUnit?: boolean;
        /**
         * cron driven:Specifies which missing patient information should trigger an AlertInstance
         * creation
         */
        missingPatientInfo?: (
          | "address"
          | "address.address1"
          | "address.city"
          | "address.state"
          | "address.zipcode"
          | "phoneNumber"
          | "email"
          | "billingInfo"
          | "billingInfo.firstName"
          | "billingInfo.lastName"
          | "billingInfo.gender"
          | "billingInfo.insurancePlan"
          | "billingInfo.memberId"
          | "billingInfo.groupId"
          | "billingInfo.healthPlanId"
          | "billingInfo.authorizationCode"
          | "carePod"
          | "careTeam"
          | "careTeam.Therapist"
          | "careTeam.Psychiatrist"
          | "careTeam.PatientGuide"
          | "careTeam.FamilyGuide"
          | "preferredPharmacies"
        )[];
        /**
         * event driven:Specifies if a form, type note,
         * has risk language and triggers an AlertInstance creation
         */
        noteHasRiskLanguage?: boolean;
        /**
         * typically cron driven: Specifies the time for an AlertInstance creation.
         * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance
         * should be created do not create alertInstance
         */
        reminder?: {
          /** Used to process bulk alert creations during cron */
          type?: "user";
          /**
           * Used to find the user's status and status has the correct flag to create an
           * AlertInstance
           */
          userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
          queryConstructor?: {
            /**
             * The field, type Date, within the mode evaluate against alert.occurs for
             * AlertInstance creation.i.e.'growth.enrolledDate',
             * 'created'
             */
            dateField?: "growth.enrolledDate";
            /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
            model?: "User";
          };
          _id?: string;
        };
        /**
         * cron driven:Specifies if a critical event form instance is not status 'Signed' and
         * trigger an AlertInstance creation
         */
        unsignedCriticalEventFormInstance?: boolean;
        _id?: string;
      };
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    /**
     * Users manage their alertInstance notification center.When they archive an alertInstance,
     * their userId is added to this list to prevent it from showing up in their inbox.
     */
    archivedByUsers?: any[];
    /**
     * If the alertInstance is assigned to specific user(s),
     * their userId is added to this list.The alertInstance will show up in their notification
     * center.This is currently only being used if the recipientStaffRole is 'Self'.
     */
    assignedToUsers?: any[];
    associatedUserId?: LimitedUser;
    /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
    flaggedRiskLanguage?: string[];
    /**
     * If recipientUserType is Staff, Specifies the roles of staff members who should view the
     * alertInstance.Copied value of alert.recipientStaffRoles.
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /**
     * Designates the type of user who will view the alertInstance.Copied value of
     * alert.recipientUserTypes.
     */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    resolved?: boolean;
    resolvedBy?: {
      /**
       * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
       * instead of Id because many instances will be resolved by automation and will not have a
       * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
       * details on the alertInstances activity use auditLogEvents
       */
      name?: string;
      /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
      userId?: any;
    };
    /** DEPRECATED: Use source.id and source.model instead. */
    scheduleItemId?: any;
    /**
     * If the alertInstance was created relative to a models data,
     * reference to the source.To prevent duplicate alertInstances from being created for the same
     * user and alert.
     */
    source?: {
      model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
      _id?: string;
    };
    /**
     * The event that triggered the alertInstance creation.Reference to the key of
     * alert.triggeringEvents.
     */
    triggeringEvent?:
      | "assignTodo"
      | "attendanceStatus"
      | "createdFormInstance"
      | "missingFamilyUnit"
      | "missingPatientInfo"
      | "noteHasRiskLanguage"
      | "reminder"
      | "unsignedCriticalEventFormInstance";
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    alertText?: string;
    dueDateText?: string;
  };
};
export type GetAlertInstancesRes = /** status 200 Successful list */ {
  data?: {
    alertId: {
      /**
       * Designates the type of user who the alert is aboutused to filter creation of
       * alertInstance.associatedUserId
       */
      associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /** Identifies the user who created the alert. */
      createdBy?: any;
      driver?: "cron" | "event";
      /**
       * If the alert was loaded from alertsData.ts via loadAlertData,
       * this will be set.To prevent duplicate alerts from being loaded or to update existing
       */
      internalKey?: string;
      isActive?: boolean;
      /**
       * If sendNotificationsToRecipients is true,
       * this message will be sent to all alert recipients when an alertInstance is created.Do NOT
       * Include information that is HIPAA protected
       */
      notificationMessage?: string;
      occurs?: {
        /**
         * Specifies the time frame in which the alertInstance is created relative to the
         * triggering event.
         */
        chronology?: "after" | "before" | "every";
        /**
         * The number of days, weeks, or months before, after,
         * or every the triggering event occurs.If occurs.isImmediately is true, increment must be
         * 0. See validation hook
         */
        increment?: number;
        /**
         * If true, the alert will be triggered immediately before or after the triggering event
         * occurs.If true, increment must be 0 and chronology must be either before or after.
         * See validation hook
         */
        isImmediately?: boolean;
        /**
         * Details the specific type of scheduled item related to when the alertInstance creation
         * occursNot all triggeringEvents require a scheduledItem.See validation hook and
         * TriggeringEventsRequiresScheduleItemForCron.
         */
        scheduleItemType?:
          | "Eligibility Interview"
          | "Clinical Intake"
          | "Guide Clinical Intake"
          | "Therapy Clinical Intake"
          | "Therapy Intake"
          | "Psychiatry Intake"
          | "In Home Onboarding Visit"
          | "Therapy"
          | "Therapy - Patient Session"
          | "Therapy - Caregiver Session"
          | "Psychiatry"
          | "Member Guide"
          | "Patient Guide"
          | "Family Guide"
          | "In Home Guide Visit"
          | "Travel"
          | "Individualized Service Plan"
          | "Meet and Greet"
          | "Other";
        /** The unit of time for the increment field. */
        unit?: "hours" | "days" | "weeks" | "months";
      };
      /**
       * If recipientUserType is Staff Specifies the roles of staff members who should view the
       * alertInstance.'Self' is used to assign an alert to specific users.
       * alertInstance.assignedToUsers will be set to the staff members ids
       */
      recipientStaffRole?: (
        | "ClinicalDirector"
        | "ClinicalLeader"
        | "ClinicalQualityAssurance"
        | "EnrollmentCoordinator"
        | "EnrollmentSupervisor"
        | "FamilyGuide"
        | "FamilyGuideSupervisor"
        | "MedicalDirector"
        | "PatientGuide"
        | "PatientGuideSupervisor"
        | "Psychiatrist"
        | "RiskManager"
        | "SoftwareEngineer"
        | "SuperUser"
        | "Therapist"
        | "TherapistSupervisor"
        | "MemberGuideSupervisor"
        | "Guide"
        | "Self"
      )[];
      /** Designates the type of user who will view the alertInstance. */
      recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /**
       * If true, notifications will be sent to all alert recipients when an alertInstance is
       * created.
       */
      sendNotificationsToRecipients?: boolean;
      /** Contains the user facing message of the alertInstance displayed to the recipient. */
      text?: string;
      /** Contains the user facing name of the alert displayed in admin. */
      title?: string;
      /** Specifies the event that triggers alertInstance creation. */
      triggeringEvent?: {
        /**
         * event driven:Specifies if a todo has been assigned to a user and trigger an
         * AlertInstance creation
         */
        assignTodo?: boolean;
        /**
         * event driven:Specifies the status of an individual's attendance that triggers an
         * AlertInstance creation
         */
        attendanceStatus?: string[];
        createdFormInstance?: {
          /**
           * event driven:Specifies the form that was created and triggers an AlertInstance
           * creation
           */
          formInternalKey?: string;
        };
        /**
         * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
         * creation
         */
        missingFamilyUnit?: boolean;
        /**
         * cron driven:Specifies which missing patient information should trigger an AlertInstance
         * creation
         */
        missingPatientInfo?: (
          | "address"
          | "address.address1"
          | "address.city"
          | "address.state"
          | "address.zipcode"
          | "phoneNumber"
          | "email"
          | "billingInfo"
          | "billingInfo.firstName"
          | "billingInfo.lastName"
          | "billingInfo.gender"
          | "billingInfo.insurancePlan"
          | "billingInfo.memberId"
          | "billingInfo.groupId"
          | "billingInfo.healthPlanId"
          | "billingInfo.authorizationCode"
          | "carePod"
          | "careTeam"
          | "careTeam.Therapist"
          | "careTeam.Psychiatrist"
          | "careTeam.PatientGuide"
          | "careTeam.FamilyGuide"
          | "preferredPharmacies"
        )[];
        /**
         * event driven:Specifies if a form, type note,
         * has risk language and triggers an AlertInstance creation
         */
        noteHasRiskLanguage?: boolean;
        /**
         * typically cron driven: Specifies the time for an AlertInstance creation.
         * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance
         * should be created do not create alertInstance
         */
        reminder?: {
          /** Used to process bulk alert creations during cron */
          type?: "user";
          /**
           * Used to find the user's status and status has the correct flag to create an
           * AlertInstance
           */
          userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
          queryConstructor?: {
            /**
             * The field, type Date, within the mode evaluate against alert.occurs for
             * AlertInstance creation.i.e.'growth.enrolledDate',
             * 'created'
             */
            dateField?: "growth.enrolledDate";
            /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
            model?: "User";
          };
          _id?: string;
        };
        /**
         * cron driven:Specifies if a critical event form instance is not status 'Signed' and
         * trigger an AlertInstance creation
         */
        unsignedCriticalEventFormInstance?: boolean;
        _id?: string;
      };
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    /**
     * Users manage their alertInstance notification center.When they archive an alertInstance,
     * their userId is added to this list to prevent it from showing up in their inbox.
     */
    archivedByUsers: any[];
    /**
     * If the alertInstance is assigned to specific user(s),
     * their userId is added to this list.The alertInstance will show up in their notification
     * center.This is currently only being used if the recipientStaffRole is 'Self'.
     */
    assignedToUsers: any[];
    associatedUserId: LimitedUser;
    /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
    flaggedRiskLanguage: string[];
    /**
     * If recipientUserType is Staff, Specifies the roles of staff members who should view the
     * alertInstance.Copied value of alert.recipientStaffRoles.
     */
    recipientStaffRole: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /**
     * Designates the type of user who will view the alertInstance.Copied value of
     * alert.recipientUserTypes.
     */
    recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
    resolved: boolean;
    resolvedBy?: {
      /**
       * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
       * instead of Id because many instances will be resolved by automation and will not have a
       * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
       * details on the alertInstances activity use auditLogEvents
       */
      name?: string;
      /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
      userId?: any;
    };
    /** DEPRECATED: Use source.id and source.model instead. */
    scheduleItemId?: any;
    /**
     * If the alertInstance was created relative to a models data,
     * reference to the source.To prevent duplicate alertInstances from being created for the same
     * user and alert.
     */
    source?: {
      model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
      _id?: string;
    };
    /**
     * The event that triggered the alertInstance creation.Reference to the key of
     * alert.triggeringEvents.
     */
    triggeringEvent:
      | "assignTodo"
      | "attendanceStatus"
      | "createdFormInstance"
      | "missingFamilyUnit"
      | "missingPatientInfo"
      | "noteHasRiskLanguage"
      | "reminder"
      | "unsignedCriticalEventFormInstance";
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    alertText?: string;
    dueDateText?: string;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetAlertInstancesArgs = {
  _id?: {
    $in?: string[];
  };
  archived?:
    | any
    | {
        $in?: any[];
      };
  associatedUserId?:
    | any
    | {
        $in?: any[];
      };
  resolved?:
    | boolean
    | {
        $in?: boolean[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetAlertInstancesByIdRes = /** status 200 Successful read */ {
  alertId: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver?: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive?: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients?: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text?: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title?: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent?: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  /**
   * Users manage their alertInstance notification center.When they archive an alertInstance,
   * their userId is added to this list to prevent it from showing up in their inbox.
   */
  archivedByUsers: any[];
  /**
   * If the alertInstance is assigned to specific user(s),
   * their userId is added to this list.The alertInstance will show up in their notification
   * center.This is currently only being used if the recipientStaffRole is 'Self'.
   */
  assignedToUsers: any[];
  associatedUserId: LimitedUser;
  /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
  flaggedRiskLanguage: string[];
  /**
   * If recipientUserType is Staff, Specifies the roles of staff members who should view the
   * alertInstance.Copied value of alert.recipientStaffRoles.
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /**
   * Designates the type of user who will view the alertInstance.Copied value of
   * alert.recipientUserTypes.
   */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  resolved: boolean;
  resolvedBy?: {
    /**
     * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
     * instead of Id because many instances will be resolved by automation and will not have a
     * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
     * details on the alertInstances activity use auditLogEvents
     */
    name?: string;
    /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
    userId?: any;
  };
  /** DEPRECATED: Use source.id and source.model instead. */
  scheduleItemId?: any;
  /**
   * If the alertInstance was created relative to a models data,
   * reference to the source.To prevent duplicate alertInstances from being created for the same
   * user and alert.
   */
  source?: {
    model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
    _id?: string;
  };
  /**
   * The event that triggered the alertInstance creation.Reference to the key of
   * alert.triggeringEvents.
   */
  triggeringEvent:
    | "assignTodo"
    | "attendanceStatus"
    | "createdFormInstance"
    | "missingFamilyUnit"
    | "missingPatientInfo"
    | "noteHasRiskLanguage"
    | "reminder"
    | "unsignedCriticalEventFormInstance";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  alertText?: string;
  dueDateText?: string;
};
export type GetAlertInstancesByIdArgs = string;
export type PatchAlertInstancesByIdRes = /** status 200 Successful update */ {
  alertId: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver?: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive?: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients?: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text?: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title?: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent?: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  /**
   * Users manage their alertInstance notification center.When they archive an alertInstance,
   * their userId is added to this list to prevent it from showing up in their inbox.
   */
  archivedByUsers: any[];
  /**
   * If the alertInstance is assigned to specific user(s),
   * their userId is added to this list.The alertInstance will show up in their notification
   * center.This is currently only being used if the recipientStaffRole is 'Self'.
   */
  assignedToUsers: any[];
  associatedUserId: LimitedUser;
  /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
  flaggedRiskLanguage: string[];
  /**
   * If recipientUserType is Staff, Specifies the roles of staff members who should view the
   * alertInstance.Copied value of alert.recipientStaffRoles.
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /**
   * Designates the type of user who will view the alertInstance.Copied value of
   * alert.recipientUserTypes.
   */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  resolved: boolean;
  resolvedBy?: {
    /**
     * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
     * instead of Id because many instances will be resolved by automation and will not have a
     * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
     * details on the alertInstances activity use auditLogEvents
     */
    name?: string;
    /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
    userId?: any;
  };
  /** DEPRECATED: Use source.id and source.model instead. */
  scheduleItemId?: any;
  /**
   * If the alertInstance was created relative to a models data,
   * reference to the source.To prevent duplicate alertInstances from being created for the same
   * user and alert.
   */
  source?: {
    model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
    _id?: string;
  };
  /**
   * The event that triggered the alertInstance creation.Reference to the key of
   * alert.triggeringEvents.
   */
  triggeringEvent:
    | "assignTodo"
    | "attendanceStatus"
    | "createdFormInstance"
    | "missingFamilyUnit"
    | "missingPatientInfo"
    | "noteHasRiskLanguage"
    | "reminder"
    | "unsignedCriticalEventFormInstance";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  alertText?: string;
  dueDateText?: string;
};
export type PatchAlertInstancesByIdArgs = {
  id: string;
  body: {
    alertId?: {
      /**
       * Designates the type of user who the alert is aboutused to filter creation of
       * alertInstance.associatedUserId
       */
      associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /** Identifies the user who created the alert. */
      createdBy?: any;
      driver?: "cron" | "event";
      /**
       * If the alert was loaded from alertsData.ts via loadAlertData,
       * this will be set.To prevent duplicate alerts from being loaded or to update existing
       */
      internalKey?: string;
      isActive?: boolean;
      /**
       * If sendNotificationsToRecipients is true,
       * this message will be sent to all alert recipients when an alertInstance is created.Do NOT
       * Include information that is HIPAA protected
       */
      notificationMessage?: string;
      occurs?: {
        /**
         * Specifies the time frame in which the alertInstance is created relative to the
         * triggering event.
         */
        chronology?: "after" | "before" | "every";
        /**
         * The number of days, weeks, or months before, after,
         * or every the triggering event occurs.If occurs.isImmediately is true, increment must be
         * 0. See validation hook
         */
        increment?: number;
        /**
         * If true, the alert will be triggered immediately before or after the triggering event
         * occurs.If true, increment must be 0 and chronology must be either before or after.
         * See validation hook
         */
        isImmediately?: boolean;
        /**
         * Details the specific type of scheduled item related to when the alertInstance creation
         * occursNot all triggeringEvents require a scheduledItem.See validation hook and
         * TriggeringEventsRequiresScheduleItemForCron.
         */
        scheduleItemType?:
          | "Eligibility Interview"
          | "Clinical Intake"
          | "Guide Clinical Intake"
          | "Therapy Clinical Intake"
          | "Therapy Intake"
          | "Psychiatry Intake"
          | "In Home Onboarding Visit"
          | "Therapy"
          | "Therapy - Patient Session"
          | "Therapy - Caregiver Session"
          | "Psychiatry"
          | "Member Guide"
          | "Patient Guide"
          | "Family Guide"
          | "In Home Guide Visit"
          | "Travel"
          | "Individualized Service Plan"
          | "Meet and Greet"
          | "Other";
        /** The unit of time for the increment field. */
        unit?: "hours" | "days" | "weeks" | "months";
      };
      /**
       * If recipientUserType is Staff Specifies the roles of staff members who should view the
       * alertInstance.'Self' is used to assign an alert to specific users.
       * alertInstance.assignedToUsers will be set to the staff members ids
       */
      recipientStaffRole?: (
        | "ClinicalDirector"
        | "ClinicalLeader"
        | "ClinicalQualityAssurance"
        | "EnrollmentCoordinator"
        | "EnrollmentSupervisor"
        | "FamilyGuide"
        | "FamilyGuideSupervisor"
        | "MedicalDirector"
        | "PatientGuide"
        | "PatientGuideSupervisor"
        | "Psychiatrist"
        | "RiskManager"
        | "SoftwareEngineer"
        | "SuperUser"
        | "Therapist"
        | "TherapistSupervisor"
        | "MemberGuideSupervisor"
        | "Guide"
        | "Self"
      )[];
      /** Designates the type of user who will view the alertInstance. */
      recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
      /**
       * If true, notifications will be sent to all alert recipients when an alertInstance is
       * created.
       */
      sendNotificationsToRecipients?: boolean;
      /** Contains the user facing message of the alertInstance displayed to the recipient. */
      text?: string;
      /** Contains the user facing name of the alert displayed in admin. */
      title?: string;
      /** Specifies the event that triggers alertInstance creation. */
      triggeringEvent?: {
        /**
         * event driven:Specifies if a todo has been assigned to a user and trigger an
         * AlertInstance creation
         */
        assignTodo?: boolean;
        /**
         * event driven:Specifies the status of an individual's attendance that triggers an
         * AlertInstance creation
         */
        attendanceStatus?: string[];
        createdFormInstance?: {
          /**
           * event driven:Specifies the form that was created and triggers an AlertInstance
           * creation
           */
          formInternalKey?: string;
        };
        /**
         * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
         * creation
         */
        missingFamilyUnit?: boolean;
        /**
         * cron driven:Specifies which missing patient information should trigger an AlertInstance
         * creation
         */
        missingPatientInfo?: (
          | "address"
          | "address.address1"
          | "address.city"
          | "address.state"
          | "address.zipcode"
          | "phoneNumber"
          | "email"
          | "billingInfo"
          | "billingInfo.firstName"
          | "billingInfo.lastName"
          | "billingInfo.gender"
          | "billingInfo.insurancePlan"
          | "billingInfo.memberId"
          | "billingInfo.groupId"
          | "billingInfo.healthPlanId"
          | "billingInfo.authorizationCode"
          | "carePod"
          | "careTeam"
          | "careTeam.Therapist"
          | "careTeam.Psychiatrist"
          | "careTeam.PatientGuide"
          | "careTeam.FamilyGuide"
          | "preferredPharmacies"
        )[];
        /**
         * event driven:Specifies if a form, type note,
         * has risk language and triggers an AlertInstance creation
         */
        noteHasRiskLanguage?: boolean;
        /**
         * typically cron driven: Specifies the time for an AlertInstance creation.
         * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance
         * should be created do not create alertInstance
         */
        reminder?: {
          /** Used to process bulk alert creations during cron */
          type?: "user";
          /**
           * Used to find the user's status and status has the correct flag to create an
           * AlertInstance
           */
          userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
          queryConstructor?: {
            /**
             * The field, type Date, within the mode evaluate against alert.occurs for
             * AlertInstance creation.i.e.'growth.enrolledDate',
             * 'created'
             */
            dateField?: "growth.enrolledDate";
            /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
            model?: "User";
          };
          _id?: string;
        };
        /**
         * cron driven:Specifies if a critical event form instance is not status 'Signed' and
         * trigger an AlertInstance creation
         */
        unsignedCriticalEventFormInstance?: boolean;
        _id?: string;
      };
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    /**
     * Users manage their alertInstance notification center.When they archive an alertInstance,
     * their userId is added to this list to prevent it from showing up in their inbox.
     */
    archivedByUsers?: any[];
    /**
     * If the alertInstance is assigned to specific user(s),
     * their userId is added to this list.The alertInstance will show up in their notification
     * center.This is currently only being used if the recipientStaffRole is 'Self'.
     */
    assignedToUsers?: any[];
    associatedUserId?: LimitedUser;
    /** If the alertInstance is flagged for risk language, the risk words are added to this list. */
    flaggedRiskLanguage?: string[];
    /**
     * If recipientUserType is Staff, Specifies the roles of staff members who should view the
     * alertInstance.Copied value of alert.recipientStaffRoles.
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /**
     * Designates the type of user who will view the alertInstance.Copied value of
     * alert.recipientUserTypes.
     */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    resolved?: boolean;
    resolvedBy?: {
      /**
       * The name of the user who resolved the alertInstance or 'System Auto-Resolved'Using String
       * instead of Id because many instances will be resolved by automation and will not have a
       * model/id refAnd to avoid using unnecessary populate calls on complicated systemFor more
       * details on the alertInstances activity use auditLogEvents
       */
      name?: string;
      /** If the alertInstance was resolved by a user, the userId is copied to the instance. */
      userId?: any;
    };
    /** DEPRECATED: Use source.id and source.model instead. */
    scheduleItemId?: any;
    /**
     * If the alertInstance was created relative to a models data,
     * reference to the source.To prevent duplicate alertInstances from being created for the same
     * user and alert.
     */
    source?: {
      model?: "FormInstance" | "ScheduleItem" | "ToDoItem";
      _id?: string;
    };
    /**
     * The event that triggered the alertInstance creation.Reference to the key of
     * alert.triggeringEvents.
     */
    triggeringEvent?:
      | "assignTodo"
      | "attendanceStatus"
      | "createdFormInstance"
      | "missingFamilyUnit"
      | "missingPatientInfo"
      | "noteHasRiskLanguage"
      | "reminder"
      | "unsignedCriticalEventFormInstance";
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    alertText?: string;
    dueDateText?: string;
  };
};
export type GetAlertsRes = /** status 200 Successful list */ {
  data?: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetAlertsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetAlertsByIdRes = /** status 200 Successful read */ {
  /**
   * Designates the type of user who the alert is aboutused to filter creation of
   * alertInstance.associatedUserId
   */
  associatedUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  /** Identifies the user who created the alert. */
  createdBy?: any;
  driver: "cron" | "event";
  /**
   * If the alert was loaded from alertsData.ts via loadAlertData,
   * this will be set.To prevent duplicate alerts from being loaded or to update existing
   */
  internalKey?: string;
  isActive: boolean;
  /**
   * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
   * when an alertInstance is created.Do NOT Include information that is HIPAA protected
   */
  notificationMessage?: string;
  occurs?: {
    /**
     * Specifies the time frame in which the alertInstance is created relative to the triggering
     * event.
     */
    chronology?: "after" | "before" | "every";
    /**
     * The number of days, weeks, or months before, after,
     * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
     * See validation hook
     */
    increment?: number;
    /**
     * If true, the alert will be triggered immediately before or after the triggering event
     * occurs.If true, increment must be 0 and chronology must be either before or after.
     * See validation hook
     */
    isImmediately?: boolean;
    /**
     * Details the specific type of scheduled item related to when the alertInstance creation
     * occursNot all triggeringEvents require a scheduledItem.See validation hook and
     * TriggeringEventsRequiresScheduleItemForCron.
     */
    scheduleItemType?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** The unit of time for the increment field. */
    unit?: "hours" | "days" | "weeks" | "months";
  };
  /**
   * If recipientUserType is Staff Specifies the roles of staff members who should view the
   * alertInstance.'Self' is used to assign an alert to specific users.
   * alertInstance.assignedToUsers will be set to the staff members ids
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /** Designates the type of user who will view the alertInstance. */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  /**
   * If true, notifications will be sent to all alert recipients when an alertInstance is created.
   */
  sendNotificationsToRecipients: boolean;
  /** Contains the user facing message of the alertInstance displayed to the recipient. */
  text: string;
  /** Contains the user facing name of the alert displayed in admin. */
  title: string;
  /** Specifies the event that triggers alertInstance creation. */
  triggeringEvent: {
    /**
     * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
     * creation
     */
    assignTodo?: boolean;
    /**
     * event driven:Specifies the status of an individual's attendance that triggers an
     * AlertInstance creation
     */
    attendanceStatus?: string[];
    createdFormInstance?: {
      /** event driven:Specifies the form that was created and triggers an AlertInstance creation */
      formInternalKey?: string;
    };
    /**
     * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
     * creation
     */
    missingFamilyUnit?: boolean;
    /**
     * cron driven:Specifies which missing patient information should trigger an AlertInstance
     * creation
     */
    missingPatientInfo?: (
      | "address"
      | "address.address1"
      | "address.city"
      | "address.state"
      | "address.zipcode"
      | "phoneNumber"
      | "email"
      | "billingInfo"
      | "billingInfo.firstName"
      | "billingInfo.lastName"
      | "billingInfo.gender"
      | "billingInfo.insurancePlan"
      | "billingInfo.memberId"
      | "billingInfo.groupId"
      | "billingInfo.healthPlanId"
      | "billingInfo.authorizationCode"
      | "carePod"
      | "careTeam"
      | "careTeam.Therapist"
      | "careTeam.Psychiatrist"
      | "careTeam.PatientGuide"
      | "careTeam.FamilyGuide"
      | "preferredPharmacies"
    )[];
    /**
     * event driven:Specifies if a form, type note,
     * has risk language and triggers an AlertInstance creation
     */
    noteHasRiskLanguage?: boolean;
    /**
     * typically cron driven: Specifies the time for an AlertInstance creation.
     * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
     * be created do not create alertInstance
     */
    reminder?: {
      /** Used to process bulk alert creations during cron */
      type?: "user";
      /**
       * Used to find the user's status and status has the correct flag to create an AlertInstance
       */
      userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
      queryConstructor?: {
        /**
         * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
         * creation.i.e.'growth.enrolledDate', 'created'
         */
        dateField?: "growth.enrolledDate";
        /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
        model?: "User";
      };
      _id?: string;
    };
    /**
     * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
     * an AlertInstance creation
     */
    unsignedCriticalEventFormInstance?: boolean;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetAlertsByIdArgs = string;
export type PatchAlertsByIdRes = /** status 200 Successful update */ {
  /**
   * Designates the type of user who the alert is aboutused to filter creation of
   * alertInstance.associatedUserId
   */
  associatedUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  /** Identifies the user who created the alert. */
  createdBy?: any;
  driver: "cron" | "event";
  /**
   * If the alert was loaded from alertsData.ts via loadAlertData,
   * this will be set.To prevent duplicate alerts from being loaded or to update existing
   */
  internalKey?: string;
  isActive: boolean;
  /**
   * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
   * when an alertInstance is created.Do NOT Include information that is HIPAA protected
   */
  notificationMessage?: string;
  occurs?: {
    /**
     * Specifies the time frame in which the alertInstance is created relative to the triggering
     * event.
     */
    chronology?: "after" | "before" | "every";
    /**
     * The number of days, weeks, or months before, after,
     * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
     * See validation hook
     */
    increment?: number;
    /**
     * If true, the alert will be triggered immediately before or after the triggering event
     * occurs.If true, increment must be 0 and chronology must be either before or after.
     * See validation hook
     */
    isImmediately?: boolean;
    /**
     * Details the specific type of scheduled item related to when the alertInstance creation
     * occursNot all triggeringEvents require a scheduledItem.See validation hook and
     * TriggeringEventsRequiresScheduleItemForCron.
     */
    scheduleItemType?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** The unit of time for the increment field. */
    unit?: "hours" | "days" | "weeks" | "months";
  };
  /**
   * If recipientUserType is Staff Specifies the roles of staff members who should view the
   * alertInstance.'Self' is used to assign an alert to specific users.
   * alertInstance.assignedToUsers will be set to the staff members ids
   */
  recipientStaffRole: (
    | "ClinicalDirector"
    | "ClinicalLeader"
    | "ClinicalQualityAssurance"
    | "EnrollmentCoordinator"
    | "EnrollmentSupervisor"
    | "FamilyGuide"
    | "FamilyGuideSupervisor"
    | "MedicalDirector"
    | "PatientGuide"
    | "PatientGuideSupervisor"
    | "Psychiatrist"
    | "RiskManager"
    | "SoftwareEngineer"
    | "SuperUser"
    | "Therapist"
    | "TherapistSupervisor"
    | "MemberGuideSupervisor"
    | "Guide"
    | "Self"
  )[];
  /** Designates the type of user who will view the alertInstance. */
  recipientUserType: "Member" | "Patient" | "FamilyMember" | "Staff";
  /**
   * If true, notifications will be sent to all alert recipients when an alertInstance is created.
   */
  sendNotificationsToRecipients: boolean;
  /** Contains the user facing message of the alertInstance displayed to the recipient. */
  text: string;
  /** Contains the user facing name of the alert displayed in admin. */
  title: string;
  /** Specifies the event that triggers alertInstance creation. */
  triggeringEvent: {
    /**
     * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
     * creation
     */
    assignTodo?: boolean;
    /**
     * event driven:Specifies the status of an individual's attendance that triggers an
     * AlertInstance creation
     */
    attendanceStatus?: string[];
    createdFormInstance?: {
      /** event driven:Specifies the form that was created and triggers an AlertInstance creation */
      formInternalKey?: string;
    };
    /**
     * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
     * creation
     */
    missingFamilyUnit?: boolean;
    /**
     * cron driven:Specifies which missing patient information should trigger an AlertInstance
     * creation
     */
    missingPatientInfo?: (
      | "address"
      | "address.address1"
      | "address.city"
      | "address.state"
      | "address.zipcode"
      | "phoneNumber"
      | "email"
      | "billingInfo"
      | "billingInfo.firstName"
      | "billingInfo.lastName"
      | "billingInfo.gender"
      | "billingInfo.insurancePlan"
      | "billingInfo.memberId"
      | "billingInfo.groupId"
      | "billingInfo.healthPlanId"
      | "billingInfo.authorizationCode"
      | "carePod"
      | "careTeam"
      | "careTeam.Therapist"
      | "careTeam.Psychiatrist"
      | "careTeam.PatientGuide"
      | "careTeam.FamilyGuide"
      | "preferredPharmacies"
    )[];
    /**
     * event driven:Specifies if a form, type note,
     * has risk language and triggers an AlertInstance creation
     */
    noteHasRiskLanguage?: boolean;
    /**
     * typically cron driven: Specifies the time for an AlertInstance creation.
     * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
     * be created do not create alertInstance
     */
    reminder?: {
      /** Used to process bulk alert creations during cron */
      type?: "user";
      /**
       * Used to find the user's status and status has the correct flag to create an AlertInstance
       */
      userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
      queryConstructor?: {
        /**
         * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
         * creation.i.e.'growth.enrolledDate', 'created'
         */
        dateField?: "growth.enrolledDate";
        /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
        model?: "User";
      };
      _id?: string;
    };
    /**
     * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
     * an AlertInstance creation
     */
    unsignedCriticalEventFormInstance?: boolean;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchAlertsByIdArgs = {
  id: string;
  body: {
    /**
     * Designates the type of user who the alert is aboutused to filter creation of
     * alertInstance.associatedUserId
     */
    associatedUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /** Identifies the user who created the alert. */
    createdBy?: any;
    driver?: "cron" | "event";
    /**
     * If the alert was loaded from alertsData.ts via loadAlertData,
     * this will be set.To prevent duplicate alerts from being loaded or to update existing
     */
    internalKey?: string;
    isActive?: boolean;
    /**
     * If sendNotificationsToRecipients is true, this message will be sent to all alert recipients
     * when an alertInstance is created.Do NOT Include information that is HIPAA protected
     */
    notificationMessage?: string;
    occurs?: {
      /**
       * Specifies the time frame in which the alertInstance is created relative to the triggering
       * event.
       */
      chronology?: "after" | "before" | "every";
      /**
       * The number of days, weeks, or months before, after,
       * or every the triggering event occurs.If occurs.isImmediately is true, increment must be 0.
       * See validation hook
       */
      increment?: number;
      /**
       * If true, the alert will be triggered immediately before or after the triggering event
       * occurs.If true, increment must be 0 and chronology must be either before or after.
       * See validation hook
       */
      isImmediately?: boolean;
      /**
       * Details the specific type of scheduled item related to when the alertInstance creation
       * occursNot all triggeringEvents require a scheduledItem.See validation hook and
       * TriggeringEventsRequiresScheduleItemForCron.
       */
      scheduleItemType?:
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other";
      /** The unit of time for the increment field. */
      unit?: "hours" | "days" | "weeks" | "months";
    };
    /**
     * If recipientUserType is Staff Specifies the roles of staff members who should view the
     * alertInstance.'Self' is used to assign an alert to specific users.
     * alertInstance.assignedToUsers will be set to the staff members ids
     */
    recipientStaffRole?: (
      | "ClinicalDirector"
      | "ClinicalLeader"
      | "ClinicalQualityAssurance"
      | "EnrollmentCoordinator"
      | "EnrollmentSupervisor"
      | "FamilyGuide"
      | "FamilyGuideSupervisor"
      | "MedicalDirector"
      | "PatientGuide"
      | "PatientGuideSupervisor"
      | "Psychiatrist"
      | "RiskManager"
      | "SoftwareEngineer"
      | "SuperUser"
      | "Therapist"
      | "TherapistSupervisor"
      | "MemberGuideSupervisor"
      | "Guide"
      | "Self"
    )[];
    /** Designates the type of user who will view the alertInstance. */
    recipientUserType?: "Member" | "Patient" | "FamilyMember" | "Staff";
    /**
     * If true, notifications will be sent to all alert recipients when an alertInstance is
     * created.
     */
    sendNotificationsToRecipients?: boolean;
    /** Contains the user facing message of the alertInstance displayed to the recipient. */
    text?: string;
    /** Contains the user facing name of the alert displayed in admin. */
    title?: string;
    /** Specifies the event that triggers alertInstance creation. */
    triggeringEvent?: {
      /**
       * event driven:Specifies if a todo has been assigned to a user and trigger an AlertInstance
       * creation
       */
      assignTodo?: boolean;
      /**
       * event driven:Specifies the status of an individual's attendance that triggers an
       * AlertInstance creation
       */
      attendanceStatus?: string[];
      createdFormInstance?: {
        /**
         * event driven:Specifies the form that was created and triggers an AlertInstance creation
         */
        formInternalKey?: string;
      };
      /**
       * cron driven:Specifies if a patient is missing a family unit and trigger an AlertInstance
       * creation
       */
      missingFamilyUnit?: boolean;
      /**
       * cron driven:Specifies which missing patient information should trigger an AlertInstance
       * creation
       */
      missingPatientInfo?: (
        | "address"
        | "address.address1"
        | "address.city"
        | "address.state"
        | "address.zipcode"
        | "phoneNumber"
        | "email"
        | "billingInfo"
        | "billingInfo.firstName"
        | "billingInfo.lastName"
        | "billingInfo.gender"
        | "billingInfo.insurancePlan"
        | "billingInfo.memberId"
        | "billingInfo.groupId"
        | "billingInfo.healthPlanId"
        | "billingInfo.authorizationCode"
        | "carePod"
        | "careTeam"
        | "careTeam.Therapist"
        | "careTeam.Psychiatrist"
        | "careTeam.PatientGuide"
        | "careTeam.FamilyGuide"
        | "preferredPharmacies"
      )[];
      /**
       * event driven:Specifies if a form, type note,
       * has risk language and triggers an AlertInstance creation
       */
      noteHasRiskLanguage?: boolean;
      /**
       * typically cron driven: Specifies the time for an AlertInstance creation.
       * It has a 7 day grace period.Meaning, if it is 7 days past the day the alertInstance should
       * be created do not create alertInstance
       */
      reminder?: {
        /** Used to process bulk alert creations during cron */
        type?: "user";
        /**
         * Used to find the user's status and status has the correct flag to create an
         * AlertInstance
         */
        userStatusFlags?: ("isBillable" | "isCare" | "isGrowth")[];
        queryConstructor?: {
          /**
           * The field, type Date, within the mode evaluate against alert.occurs for AlertInstance
           * creation.i.e.'growth.enrolledDate', 'created'
           */
          dateField?: "growth.enrolledDate";
          /** The model/schema that the date field is relative to. i.e. 'User', 'FormInstance' */
          model?: "User";
        };
        _id?: string;
      };
      /**
       * cron driven:Specifies if a critical event form instance is not status 'Signed' and trigger
       * an AlertInstance creation
       */
      unsignedCriticalEventFormInstance?: boolean;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type GetAppointmentSlotsRes = /** status 200 Successful read */ {
  /** Appointment slots by date */
  slots: {
    [key: string]: {
      id: string;
      date: string;
      startTime: string;
      startDatetime: string;
      endTime: string;
      endDatetime: string;
      timezone: string;
      carePodName: string;
      carePodId?: string;
      staffName: string;
      staffId: string;
      warnings?: string[];
      busy?: boolean;
      type:
        | "Psychiatry Intake"
        | "Therapy Intake"
        | "In Home Onboarding Visit"
        | "Guide Clinical Intake";
      otherStaffIds: string[];
      otherStaffNames: string[];
      associatedSlots: {
        id: string;
        date: string;
        startTime: string;
        startDatetime: string;
        endTime: string;
        endDatetime: string;
        timezone: string;
        carePodName: string;
        carePodId?: string;
        staffName: string;
        staffId: string;
        warnings?: string[];
        busy?: boolean;
        type:
          | "Psychiatry Intake"
          | "Therapy Intake"
          | "In Home Onboarding Visit"
          | "Guide Clinical Intake";
        otherStaffIds?: string[];
        otherStaffNames?: string[];
      }[];
    }[];
  };
};
export type GetAppointmentSlotsArgs = {
  staffId?: string;
  carePodId?: string;
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  startDate?: string;
  endDate?: string;
  duration?: number;
  documentationTime?: number;
  travelTime?: number;
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
};
export type PostAuditLogRes = /** status 201 Successful create */ {
  authUserId?: string;
  appliedUserId?: string;
  /** Expected to be a UserType enum value or 'SystemAutomation'. */
  authUserType?: "Member" | "Patient" | "FamilyMember" | "Staff" | "SystemAutomation";
  appVersion?: string;
  eventName?: string;
  additionalProperties?: {
    [key: string]: string;
  };
  collectionModel?: string;
  docId?: string;
  payload?: {
    formType?: string;
    formName?: string;
    prevValue?: string;
    newValue?: string;
  };
  isActivityLogEvent?: boolean;
  isUserUpdateEvent?: boolean;
  connectionDetails?: {
    /** The type of network connection the user is connected with at this point in time. */
    networkType?:
      | "bluetooth"
      | "cellular"
      | "ethernet"
      | "none"
      | "other"
      | "unknown"
      | "vpn"
      | "wifi"
      | "wimax";
    /** The name of the wifi network the user is connected to. */
    wifiNetwork?: string;
    /** The strength of the network connection at this point in time. */
    wifiSignalStrength?: number;
    /** The strength of the cellular network connection at this point in time. */
    cellularSignalStrength?: number;
    /** The name of the cellular carrier the user is connected to. */
    cellularCarrier?: string;
    /** The generation of the cellular network the user is connected to. */
    cellularGeneration?: "2g" | "3g" | "4g" | "5g";
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  description?: string;
};
export type PostAuditLogArgs = {
  authUserId?: string;
  appliedUserId?: string;
  /** Expected to be a UserType enum value or 'SystemAutomation'. */
  authUserType?: "Member" | "Patient" | "FamilyMember" | "Staff" | "SystemAutomation";
  appVersion?: string;
  eventName?: string;
  additionalProperties?: {
    [key: string]: string;
  };
  collectionModel?: string;
  docId?: string;
  payload?: {
    formType?: string;
    formName?: string;
    prevValue?: string;
    newValue?: string;
  };
  isActivityLogEvent?: boolean;
  isUserUpdateEvent?: boolean;
  connectionDetails?: {
    /** The type of network connection the user is connected with at this point in time. */
    networkType?:
      | "bluetooth"
      | "cellular"
      | "ethernet"
      | "none"
      | "other"
      | "unknown"
      | "vpn"
      | "wifi"
      | "wimax";
    /** The name of the wifi network the user is connected to. */
    wifiNetwork?: string;
    /** The strength of the network connection at this point in time. */
    wifiSignalStrength?: number;
    /** The strength of the cellular network connection at this point in time. */
    cellularSignalStrength?: number;
    /** The name of the cellular carrier the user is connected to. */
    cellularCarrier?: string;
    /** The generation of the cellular network the user is connected to. */
    cellularGeneration?: "2g" | "3g" | "4g" | "5g";
    _id?: string;
  };
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  description?: string;
};
export type GetAuditLogRes = /** status 200 Successful list */ {
  data?: {
    authUserId?: string;
    appliedUserId?: string;
    /** Expected to be a UserType enum value or 'SystemAutomation'. */
    authUserType?: "Member" | "Patient" | "FamilyMember" | "Staff" | "SystemAutomation";
    appVersion?: string;
    eventName?: string;
    additionalProperties?: {
      [key: string]: string;
    };
    collectionModel?: string;
    docId?: string;
    payload?: {
      formType?: string;
      formName?: string;
      prevValue?: string;
      newValue?: string;
    };
    isActivityLogEvent?: boolean;
    isUserUpdateEvent?: boolean;
    connectionDetails?: {
      /** The type of network connection the user is connected with at this point in time. */
      networkType?:
        | "bluetooth"
        | "cellular"
        | "ethernet"
        | "none"
        | "other"
        | "unknown"
        | "vpn"
        | "wifi"
        | "wimax";
      /** The name of the wifi network the user is connected to. */
      wifiNetwork?: string;
      /** The strength of the network connection at this point in time. */
      wifiSignalStrength?: number;
      /** The strength of the cellular network connection at this point in time. */
      cellularSignalStrength?: number;
      /** The name of the cellular carrier the user is connected to. */
      cellularCarrier?: string;
      /** The generation of the cellular network the user is connected to. */
      cellularGeneration?: "2g" | "3g" | "4g" | "5g";
      _id?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    description?: string;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetAuditLogArgs = {
  _id?: {
    $in?: string[];
  };
  isActivityLogEvent?:
    | boolean
    | {
        $in?: boolean[];
      };
  appliedUserId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetAuditLogByIdRes = /** status 200 Successful read */ {
  authUserId?: string;
  appliedUserId?: string;
  /** Expected to be a UserType enum value or 'SystemAutomation'. */
  authUserType?: "Member" | "Patient" | "FamilyMember" | "Staff" | "SystemAutomation";
  appVersion?: string;
  eventName?: string;
  additionalProperties?: {
    [key: string]: string;
  };
  collectionModel?: string;
  docId?: string;
  payload?: {
    formType?: string;
    formName?: string;
    prevValue?: string;
    newValue?: string;
  };
  isActivityLogEvent?: boolean;
  isUserUpdateEvent?: boolean;
  connectionDetails?: {
    /** The type of network connection the user is connected with at this point in time. */
    networkType?:
      | "bluetooth"
      | "cellular"
      | "ethernet"
      | "none"
      | "other"
      | "unknown"
      | "vpn"
      | "wifi"
      | "wimax";
    /** The name of the wifi network the user is connected to. */
    wifiNetwork?: string;
    /** The strength of the network connection at this point in time. */
    wifiSignalStrength?: number;
    /** The strength of the cellular network connection at this point in time. */
    cellularSignalStrength?: number;
    /** The name of the cellular carrier the user is connected to. */
    cellularCarrier?: string;
    /** The generation of the cellular network the user is connected to. */
    cellularGeneration?: "2g" | "3g" | "4g" | "5g";
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  description?: string;
};
export type GetAuditLogByIdArgs = string;
export type PostAvatarsRes = /** status 201 Successful create */ {
  ownerId: string;
  imageStorageName?: string;
  imageMediaLink?: string;
  imageFormat?: string;
  width?: string;
  height?: string;
  imageUri?: string;
  bucketId?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostAvatarsArgs = {
  ownerId?: string;
  imageStorageName?: string;
  imageMediaLink?: string;
  imageFormat?: string;
  width?: string;
  height?: string;
  imageUri?: string;
  bucketId?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetAvatarsRes = /** status 200 Successful list */ {
  data?: {
    ownerId: string;
    imageStorageName?: string;
    imageMediaLink?: string;
    imageFormat?: string;
    width?: string;
    height?: string;
    imageUri?: string;
    bucketId?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetAvatarsArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetAvatarsByIdRes = /** status 200 Successful read */ {
  ownerId: string;
  imageStorageName?: string;
  imageMediaLink?: string;
  imageFormat?: string;
  width?: string;
  height?: string;
  imageUri?: string;
  bucketId?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetAvatarsByIdArgs = string;
export type PatchAvatarsByIdRes = /** status 200 Successful update */ {
  ownerId: string;
  imageStorageName?: string;
  imageMediaLink?: string;
  imageFormat?: string;
  width?: string;
  height?: string;
  imageUri?: string;
  bucketId?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchAvatarsByIdArgs = {
  id: string;
  body: {
    ownerId?: string;
    imageStorageName?: string;
    imageMediaLink?: string;
    imageFormat?: string;
    width?: string;
    height?: string;
    imageUri?: string;
    bucketId?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type PostCarePodsRes = /** status 201 Successful create */ {
  name: string;
  /** The calendar to sync events to. */
  googleCalendarId?: string;
  /** The maximum number of members in this pod. */
  capacity?: number;
  careTeam?: {
    PatientGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    FamilyGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Therapist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Psychiatrist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
  };
  /** The Slack channel to send normal alerts to. */
  slackAlertChannel?: string;
  /** The Slack channel to send urgent alerts to. */
  slackUrgentAlertChannel?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  url?: any;
};
export type PostCarePodsArgs = {
  name?: string;
  /** The calendar to sync events to. */
  googleCalendarId?: string;
  /** The maximum number of members in this pod. */
  capacity?: number;
  /** The Slack channel to send normal alerts to. */
  slackAlertChannel?: string;
  /** The Slack channel to send urgent alerts to. */
  slackUrgentAlertChannel?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  url?: any;
};
export type GetCarePodsRes = /** status 200 Successful list */ {
  data?: {
    name: string;
    /** The calendar to sync events to. */
    googleCalendarId?: string;
    /** The maximum number of members in this pod. */
    capacity?: number;
    careTeam?: {
      PatientGuide?: {
        /**
         * The preferred, full name for the user. If their legal/insurance name is different,
         * that should be stored in billing info.
         */
        name?: string;
        /** How the user or staff's name is pronounced. This is visible in the user app. */
        namePronunciation?: string;
        /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
        initials?: string;
        birthday?: any;
        /**
         * The gender the user identifies as. If different than the gender listed on their
         * insurance, that should be stored in billing info.
         */
        gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
        /** If gender is 'Prefer to self-describe', this is used */
        genderSelfDescribe?: string;
        /** The user's current timezone, based on their phone's location. */
        timezone?:
          | "America/New_York"
          | "America/Chicago"
          | "America/Denver"
          | "America/Los_Angeles"
          | "America/Anchorage"
          | "Pacific/Honolulu"
          | "America/Phoenix";
        staffRoles?: {
          /**
           * Gives the ability to access all users, set other staff passwords,
           * and access super user tools.
           */
          SuperUser?: boolean;
          CareAdvocate?: boolean;
          FamilyGuide?: boolean;
          PatientGuide?: boolean;
          Psychiatrist?: boolean;
          Therapist?: boolean;
          EnrollmentCoordinator?: boolean;
          FamilyGuideSupervisor?: boolean;
          PatientGuideSupervisor?: boolean;
          TherapistSupervisor?: boolean;
          ClinicalLeader?: boolean;
          ClinicalDirector?: boolean;
          /** Only one staff user should have this role */
          RiskManager?: boolean;
          /** Only one staff user should have this role */
          MedicalDirector?: boolean;
          EnrollmentSupervisor?: boolean;
          ClinicalQualityAssurance?: boolean;
          SoftwareEngineer?: boolean;
          Guide?: boolean;
          MemberGuideSupervisor?: boolean;
          _id?: string;
        };
        outOfOffice?: boolean;
        /** Staff Degrees, certifications, etc. E.g. LCSW */
        credentials?: string;
        /**
         * The Twilio phone number users can text or call to reach this staff member.
         * Send a text to a test user  to get a phone number assigned.
         */
        staffPhoneNumber?: string;
        careTeam?: {
          Therapist?: any;
          Psychiatrist?: any;
          Guide?: any;
          PatientGuide?: any;
          FamilyGuide?: any;
          _id?: string;
        };
        /** The staff's supervisor. */
        supervisor?: any;
        fitbitId?: string;
        fitbitAccessToken?: string;
        fitbitRefreshToken?: string;
        fitbitConfig?: {
          enabled?: boolean;
          sleepEnabled?: boolean;
          activityEnabled?: boolean;
          allowReminders?: boolean;
          note?: string;
        };
        type?: "Staff" | "Member" | "FamilyMember" | "Patient";
        consentFormAgreements?: {
          consentFormId: number;
          isAgreed: boolean;
          agreedDate?: string;
          consentFormType:
            | "patientAgreement"
            | "familyMemberAgreement"
            | "consent"
            | "transportation"
            | "research"
            | "privacy"
            | "hipaa"
            | "virginiaRights";
          _id?: string;
        }[];
        /** Whether the user has agreed to receive push notifications */
        pushNotifications?: boolean;
        /**
         * Whether SMS messages are allowed or not for the user.
         * Generally used to disable SMS after delivery failures.
         */
        smsEnabled?: boolean;
        smsErrors?: {
          message?: string;
          notification?: any;
          error?: string;
          date?: string;
        }[];
        /**
         * Whether the user has agreed to receive automated notifications, e.g. for appointments.
         */
        smsNotifications?: boolean;
        /** Whether the user has agreed to receive SMS messages from staff */
        smsMessaging?: boolean;
        expoToken?: string;
        expoTokens?: string[];
        /** Web push subscription info */
        webPushTokens?: {
          /**
           * The endpoint takes the form of a custom URL pointing to a push server,
           * which can be used to send a push message to the particular service worker instance
           * that subscribed to the push service.
           */
          endpoint: string;
          /** An authentication secret for the push server */
          auth: string;
          /**
           * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push
           * server.
           */
          p256dh: string;
          _id?: string;
        }[];
        conversations?: {
          conversationId: any;
          role?:
            | "Guide"
            | "PatientGuide"
            | "Therapist"
            | "Psychiatrist"
            | "SuperUser"
            | "FamilyGuide";
        }[];
        /** Hides the user from the UI without deleting them. */
        archived?: boolean;
        address?: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        usageData?: {
          /** The last time the app was launched. */
          lastAppLaunch?: string;
          currentAppVersion?: string;
          phoneModel?: string;
          operatingSystem?: string;
          _id?: string;
        };
        testUser?: boolean;
        pronouns?: string;
        bio?: string;
        /** A string describing the user's care plan. This will be visible in the apps. */
        carePlan?: string;
        /**
         * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
         * and quick current info. This is only shown in the staff app.
         */
        oneLiner?: string;
        /**
         * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
         */
        weeklyUpdates?: string;
        /**
         * Care team members are doing some work on “thrive planning” with patients and this is
         * where they can store it shown to staff AND members
         */
        thrivePlan?: string;
        engagementDetails?: string;
        /** Things that could be triggering for the patient that staff needs to be aware of. */
        safetyConcerns?: string[];
        /**
         * A string describing the user's safety plan. This will be visible in the apps under care
         * plan.
         */
        safetyPlan?: string;
        /**
         * Some early test users will be receiving guide-only services, with no clinical services.
         * At a minimum, we hide references to our clinical services from their app and make this
         * apparent in the workflows.
         */
        guideOnly?: boolean;
        /**
         * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a
         * shorthand for members. Lots of our forms require memberId to be written on every
         * page,and this is a lot easier than `_id`.
         * Use a unique sparse index because staff don't have memberIds,
         * and we don't want to have to check for nulls.
         */
        memberId?: number;
        patientId?: number;
        /**
         * Charm automatically creates a patient id for us to use. If this is not set,
         * the user is not synced to Charm.
         */
        charmPatientId?: string;
        /**
         * For staff that are also set up on Charm,
         * we save their ID so we can show which provider is writing/signing notes.
         */
        charmProviderId?: string;
        taskIdentifiers?: {
          scheduledOfflineToggleForMembersTaskName?: string;
          scheduledOfflineToggleForPatientsTaskName?: string;
          scheduledOfflineToggleForFamilyMembersTaskName?: string;
          _id?: string;
        };
        online?: {
          forMembers?: boolean;
          scheduledOfflineToggleForMembers?: string;
          forPatients?: boolean;
          scheduledOfflineToggleForPatients?: string;
          forFamilyMembers?: boolean;
          scheduledOfflineToggleForFamilyMembers?: string;
          _id?: string;
        };
        billingInfo?: {
          firstName?: string;
          lastName?: string;
          gender?: "male" | "female" | "unknown" | "other";
          insurancePlan?: any;
          renewalDate?: any;
          memberId?: string;
          groupId?: string;
          healthPlanId?: string;
          policyHolder?: "self" | "other";
          authorizationCode?: string;
          medicaidNumber?: string;
          _id?: string;
        };
        charmErrors?: string[];
        growth?: {
          authorizationDate?: any;
          referralSource?: any;
          referralMethod?: any;
          /**
           * The date the patient was referred to Flourish,
           * either self-referral or from a partner
           */
          referralDate?: any;
          /** The date the patient was accepted into our enrollment pipeline. */
          referralAcceptedDate?: any;
          /**
           * The date we can start billing for the patient.
           * This should be the date of their first attended therapy intake.
           * This will be used to calculate billing months for the patient,
           * which will be used for engagement tracking.
           * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
           * 2/15 - 3/14. Or 1/31 - 2/28.
           */
          serviceStartDate?: any;
          /** The date the patient was fully enrolled in the program and finished intakes. */
          enrolledDate?: any;
          /** The actual date the patient was discharged from the Flourish Health program. */
          dischargeDate?: any;
          /**
           * The anticipated date we expect the patient will be discharged from the Flourish Health
           * program. When service start date is set,
           * this is defaulted to 1 year from service start date.
           * Will also be edited to reflect anticipated upcoming early discharge.
           */
          estimatedDischargeDate?: any;
          /** Whether the patient is currently hospitalized. */
          isPatientCurrentlyHospitalized?: boolean;
          /** Whether the patient is currently in outpatient care. */
          outpatient?: boolean;
          /**
           * The date the patient was discharged from a hospital prior to enrollment in Flourish
           * Program.
           */
          hospitalDischargeDate?: any;
          /**
           * The external therapist/other clinician who referred the patient to Flourish and was
           * likely part of the patient's most recent care team. This may be the same as
           * referringPsychiatrist.
           */
          referringClinician?: any;
          /**
           * The external psychiatrist who referred the patient to Flourish and was likely part of
           * the patient's most recent care team.
           */
          referringPsychiatrist?: any;
          /**
           * Link to the Google Drive folder where the user's documents outside the app are stored.
           */
          documentFolder?: string;
          /** Notes about the user's enrollment process, for internal use. */
          notes?: string;
          eligibilityInterview?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          clinicalIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          therapyIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          psychiatryIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          inHomeOnboarding?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          meetAndGreet?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          origin?: string;
          leadSource?: string;
          optInDetails?: string;
          optInDate?: string;
          sourceReferrerUrl?: string;
          sourceContent?: string;
          sourceCampaign?: string;
          conversionReferrerUrl?: string;
          referringProviderPhoneNumber?: string;
          referringProviderOrganization?: string;
          referringProviderName?: string;
          referringProviderEmail?: string;
          referringClinicianName?: string;
          _id?: string;
        };
        tasks?: {
          roiSent?: boolean;
          signedROIReceived?: boolean;
          signedROISentToProviders?: boolean;
          medicalRecordsRequested?: boolean;
          dischargeMedsReceived?: boolean;
          documentationRequestSent?: boolean;
          authorizationInformationConfirmed?: boolean;
          _id?: string;
        };
        contacts?: {
          name: string;
          types: string[];
          isPrimary: boolean;
          phoneNumber?: string;
          email?: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          notes?: string;
          _id?: string;
        }[];
        /** SMS, app, phone, email, times of day, etc */
        preferredPharmacies?: {
          primary: boolean;
          name: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          phoneNumber?: string;
          faxNumber?: string;
          notes?: string;
          _id?: string;
        }[];
        settings?: {
          colorTheme?: string;
          _id?: string;
        };
        statusId?: any;
        /** The reason(s) selected for the user's current UserStatus */
        statusReasons?: string[];
        carePod?: any;
        /**
         * Link to the video chat platform the user prefers.
         * This is shown in the patient/family member app. Usually Doxy or Zoom.
         */
        videoChatLink?: string;
        /** The last time a claim was submitted for this user */
        lastClaimDate?: string;
        /** SMS, app, phone, email, times of day, etc. */
        communicationPreferences?: string;
        /** The primary language the user speaks */
        languages?: string;
        interpreterNeeded?: boolean;
        featureFlags?: {
          enableAlertInstances?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableWebPush?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTwilioCalling?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          allowOpenNotesScheduleItemLinking?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableGoogleCalendar?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableToDoList?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          generateFeedbackSurveys?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableReorganizePanel?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enablePreClinicUpdates?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          appointments?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableUserAlertsExplorer?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventHelpButton?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventCrisisEscalation?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTherapyCadenceIndicator?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableApptTypesTherapyPatientAndTherapyCaregiver?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableDoseSpot?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          _id?: string;
        };
        /** Which Flourish company the user is associated with */
        companyOrganizationId?: any;
        /** The user's diagnosis */
        diagnosis?: {
          diagnoses?: any[];
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** helps staff remember what kind of engagement/outreach a patient needs */
        clinicalStatus?: {
          status?: "High" | "Moderate" | "Mild-Moderate";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** how often the patient has recurring therapy appointments */
        therapyCadence?: {
          status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** The hours a staff user is available to be scheduled. */
        availabilitySchedule?: {
          /** Start hour, 24 hour format */
          startHour: number;
          /** End hour, 24 hour format */
          endHour: number;
          /** Start minute, 24 hour format */
          startMin: number;
          /** End minute, 24 hour format */
          endMin: number;
          /** Day of the week for the schedule */
          day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
          _id?: string;
        }[];
        scheduleCapacity?: {
          /** Psychiatry Intake slots per week */
          PsychiatryIntake?: number;
          /** Therapy Intake slots per week */
          TherapyIntake?: number;
          /** Patient Guide Intake slots per week */
          PatientGuideIntake?: number;
          /** Family Guide Intake slots per week */
          FamilyGuideIntake?: number;
          /** Care Advocate Intake slots per week */
          CareAdvocateIntake?: number;
          _id?: string;
        };
        /**
         * Memory is appended to the start of every GPT conversation to simulate memory and make it
         * less tedious
         */
        gptMemory?: string;
        /**
         * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
         * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
         */
        leadsquaredId?: string;
        /** The ID of the patient in DoseSpot */
        dosespotPatientId?: string;
        /** The ID of the clinician in DoseSpot */
        dosespotClinicianId?: string;
        _id?: string;
        admin?: boolean;
        email?: string;
        /** When a user is set to disable, all requests will return a 401 */
        disabled?: boolean;
        updated?: string;
        created?: string;
        /**
         * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
         * true} to find them.
         */
        deleted?: boolean;
        hash?: string;
        salt?: string;
        attempts?: number;
        last?: string;
      };
      FamilyGuide?: {
        /**
         * The preferred, full name for the user. If their legal/insurance name is different,
         * that should be stored in billing info.
         */
        name?: string;
        /** How the user or staff's name is pronounced. This is visible in the user app. */
        namePronunciation?: string;
        /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
        initials?: string;
        birthday?: any;
        /**
         * The gender the user identifies as. If different than the gender listed on their
         * insurance, that should be stored in billing info.
         */
        gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
        /** If gender is 'Prefer to self-describe', this is used */
        genderSelfDescribe?: string;
        /** The user's current timezone, based on their phone's location. */
        timezone?:
          | "America/New_York"
          | "America/Chicago"
          | "America/Denver"
          | "America/Los_Angeles"
          | "America/Anchorage"
          | "Pacific/Honolulu"
          | "America/Phoenix";
        staffRoles?: {
          /**
           * Gives the ability to access all users, set other staff passwords,
           * and access super user tools.
           */
          SuperUser?: boolean;
          CareAdvocate?: boolean;
          FamilyGuide?: boolean;
          PatientGuide?: boolean;
          Psychiatrist?: boolean;
          Therapist?: boolean;
          EnrollmentCoordinator?: boolean;
          FamilyGuideSupervisor?: boolean;
          PatientGuideSupervisor?: boolean;
          TherapistSupervisor?: boolean;
          ClinicalLeader?: boolean;
          ClinicalDirector?: boolean;
          /** Only one staff user should have this role */
          RiskManager?: boolean;
          /** Only one staff user should have this role */
          MedicalDirector?: boolean;
          EnrollmentSupervisor?: boolean;
          ClinicalQualityAssurance?: boolean;
          SoftwareEngineer?: boolean;
          Guide?: boolean;
          MemberGuideSupervisor?: boolean;
          _id?: string;
        };
        outOfOffice?: boolean;
        /** Staff Degrees, certifications, etc. E.g. LCSW */
        credentials?: string;
        /**
         * The Twilio phone number users can text or call to reach this staff member.
         * Send a text to a test user  to get a phone number assigned.
         */
        staffPhoneNumber?: string;
        careTeam?: {
          Therapist?: any;
          Psychiatrist?: any;
          Guide?: any;
          PatientGuide?: any;
          FamilyGuide?: any;
          _id?: string;
        };
        /** The staff's supervisor. */
        supervisor?: any;
        fitbitId?: string;
        fitbitAccessToken?: string;
        fitbitRefreshToken?: string;
        fitbitConfig?: {
          enabled?: boolean;
          sleepEnabled?: boolean;
          activityEnabled?: boolean;
          allowReminders?: boolean;
          note?: string;
        };
        type?: "Staff" | "Member" | "FamilyMember" | "Patient";
        consentFormAgreements?: {
          consentFormId: number;
          isAgreed: boolean;
          agreedDate?: string;
          consentFormType:
            | "patientAgreement"
            | "familyMemberAgreement"
            | "consent"
            | "transportation"
            | "research"
            | "privacy"
            | "hipaa"
            | "virginiaRights";
          _id?: string;
        }[];
        /** Whether the user has agreed to receive push notifications */
        pushNotifications?: boolean;
        /**
         * Whether SMS messages are allowed or not for the user.
         * Generally used to disable SMS after delivery failures.
         */
        smsEnabled?: boolean;
        smsErrors?: {
          message?: string;
          notification?: any;
          error?: string;
          date?: string;
        }[];
        /**
         * Whether the user has agreed to receive automated notifications, e.g. for appointments.
         */
        smsNotifications?: boolean;
        /** Whether the user has agreed to receive SMS messages from staff */
        smsMessaging?: boolean;
        expoToken?: string;
        expoTokens?: string[];
        /** Web push subscription info */
        webPushTokens?: {
          /**
           * The endpoint takes the form of a custom URL pointing to a push server,
           * which can be used to send a push message to the particular service worker instance
           * that subscribed to the push service.
           */
          endpoint: string;
          /** An authentication secret for the push server */
          auth: string;
          /**
           * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push
           * server.
           */
          p256dh: string;
          _id?: string;
        }[];
        conversations?: {
          conversationId: any;
          role?:
            | "Guide"
            | "PatientGuide"
            | "Therapist"
            | "Psychiatrist"
            | "SuperUser"
            | "FamilyGuide";
        }[];
        /** Hides the user from the UI without deleting them. */
        archived?: boolean;
        address?: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        usageData?: {
          /** The last time the app was launched. */
          lastAppLaunch?: string;
          currentAppVersion?: string;
          phoneModel?: string;
          operatingSystem?: string;
          _id?: string;
        };
        testUser?: boolean;
        pronouns?: string;
        bio?: string;
        /** A string describing the user's care plan. This will be visible in the apps. */
        carePlan?: string;
        /**
         * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
         * and quick current info. This is only shown in the staff app.
         */
        oneLiner?: string;
        /**
         * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
         */
        weeklyUpdates?: string;
        /**
         * Care team members are doing some work on “thrive planning” with patients and this is
         * where they can store it shown to staff AND members
         */
        thrivePlan?: string;
        engagementDetails?: string;
        /** Things that could be triggering for the patient that staff needs to be aware of. */
        safetyConcerns?: string[];
        /**
         * A string describing the user's safety plan. This will be visible in the apps under care
         * plan.
         */
        safetyPlan?: string;
        /**
         * Some early test users will be receiving guide-only services, with no clinical services.
         * At a minimum, we hide references to our clinical services from their app and make this
         * apparent in the workflows.
         */
        guideOnly?: boolean;
        /**
         * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a
         * shorthand for members. Lots of our forms require memberId to be written on every
         * page,and this is a lot easier than `_id`.
         * Use a unique sparse index because staff don't have memberIds,
         * and we don't want to have to check for nulls.
         */
        memberId?: number;
        patientId?: number;
        /**
         * Charm automatically creates a patient id for us to use. If this is not set,
         * the user is not synced to Charm.
         */
        charmPatientId?: string;
        /**
         * For staff that are also set up on Charm,
         * we save their ID so we can show which provider is writing/signing notes.
         */
        charmProviderId?: string;
        taskIdentifiers?: {
          scheduledOfflineToggleForMembersTaskName?: string;
          scheduledOfflineToggleForPatientsTaskName?: string;
          scheduledOfflineToggleForFamilyMembersTaskName?: string;
          _id?: string;
        };
        online?: {
          forMembers?: boolean;
          scheduledOfflineToggleForMembers?: string;
          forPatients?: boolean;
          scheduledOfflineToggleForPatients?: string;
          forFamilyMembers?: boolean;
          scheduledOfflineToggleForFamilyMembers?: string;
          _id?: string;
        };
        billingInfo?: {
          firstName?: string;
          lastName?: string;
          gender?: "male" | "female" | "unknown" | "other";
          insurancePlan?: any;
          renewalDate?: any;
          memberId?: string;
          groupId?: string;
          healthPlanId?: string;
          policyHolder?: "self" | "other";
          authorizationCode?: string;
          medicaidNumber?: string;
          _id?: string;
        };
        charmErrors?: string[];
        growth?: {
          authorizationDate?: any;
          referralSource?: any;
          referralMethod?: any;
          /**
           * The date the patient was referred to Flourish,
           * either self-referral or from a partner
           */
          referralDate?: any;
          /** The date the patient was accepted into our enrollment pipeline. */
          referralAcceptedDate?: any;
          /**
           * The date we can start billing for the patient.
           * This should be the date of their first attended therapy intake.
           * This will be used to calculate billing months for the patient,
           * which will be used for engagement tracking.
           * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
           * 2/15 - 3/14. Or 1/31 - 2/28.
           */
          serviceStartDate?: any;
          /** The date the patient was fully enrolled in the program and finished intakes. */
          enrolledDate?: any;
          /** The actual date the patient was discharged from the Flourish Health program. */
          dischargeDate?: any;
          /**
           * The anticipated date we expect the patient will be discharged from the Flourish Health
           * program. When service start date is set,
           * this is defaulted to 1 year from service start date.
           * Will also be edited to reflect anticipated upcoming early discharge.
           */
          estimatedDischargeDate?: any;
          /** Whether the patient is currently hospitalized. */
          isPatientCurrentlyHospitalized?: boolean;
          /** Whether the patient is currently in outpatient care. */
          outpatient?: boolean;
          /**
           * The date the patient was discharged from a hospital prior to enrollment in Flourish
           * Program.
           */
          hospitalDischargeDate?: any;
          /**
           * The external therapist/other clinician who referred the patient to Flourish and was
           * likely part of the patient's most recent care team. This may be the same as
           * referringPsychiatrist.
           */
          referringClinician?: any;
          /**
           * The external psychiatrist who referred the patient to Flourish and was likely part of
           * the patient's most recent care team.
           */
          referringPsychiatrist?: any;
          /**
           * Link to the Google Drive folder where the user's documents outside the app are stored.
           */
          documentFolder?: string;
          /** Notes about the user's enrollment process, for internal use. */
          notes?: string;
          eligibilityInterview?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          clinicalIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          therapyIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          psychiatryIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          inHomeOnboarding?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          meetAndGreet?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          origin?: string;
          leadSource?: string;
          optInDetails?: string;
          optInDate?: string;
          sourceReferrerUrl?: string;
          sourceContent?: string;
          sourceCampaign?: string;
          conversionReferrerUrl?: string;
          referringProviderPhoneNumber?: string;
          referringProviderOrganization?: string;
          referringProviderName?: string;
          referringProviderEmail?: string;
          referringClinicianName?: string;
          _id?: string;
        };
        tasks?: {
          roiSent?: boolean;
          signedROIReceived?: boolean;
          signedROISentToProviders?: boolean;
          medicalRecordsRequested?: boolean;
          dischargeMedsReceived?: boolean;
          documentationRequestSent?: boolean;
          authorizationInformationConfirmed?: boolean;
          _id?: string;
        };
        contacts?: {
          name: string;
          types: string[];
          isPrimary: boolean;
          phoneNumber?: string;
          email?: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          notes?: string;
          _id?: string;
        }[];
        /** SMS, app, phone, email, times of day, etc */
        preferredPharmacies?: {
          primary: boolean;
          name: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          phoneNumber?: string;
          faxNumber?: string;
          notes?: string;
          _id?: string;
        }[];
        settings?: {
          colorTheme?: string;
          _id?: string;
        };
        statusId?: any;
        /** The reason(s) selected for the user's current UserStatus */
        statusReasons?: string[];
        carePod?: any;
        /**
         * Link to the video chat platform the user prefers.
         * This is shown in the patient/family member app. Usually Doxy or Zoom.
         */
        videoChatLink?: string;
        /** The last time a claim was submitted for this user */
        lastClaimDate?: string;
        /** SMS, app, phone, email, times of day, etc. */
        communicationPreferences?: string;
        /** The primary language the user speaks */
        languages?: string;
        interpreterNeeded?: boolean;
        featureFlags?: {
          enableAlertInstances?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableWebPush?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTwilioCalling?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          allowOpenNotesScheduleItemLinking?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableGoogleCalendar?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableToDoList?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          generateFeedbackSurveys?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableReorganizePanel?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enablePreClinicUpdates?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          appointments?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableUserAlertsExplorer?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventHelpButton?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventCrisisEscalation?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTherapyCadenceIndicator?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableApptTypesTherapyPatientAndTherapyCaregiver?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableDoseSpot?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          _id?: string;
        };
        /** Which Flourish company the user is associated with */
        companyOrganizationId?: any;
        /** The user's diagnosis */
        diagnosis?: {
          diagnoses?: any[];
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** helps staff remember what kind of engagement/outreach a patient needs */
        clinicalStatus?: {
          status?: "High" | "Moderate" | "Mild-Moderate";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** how often the patient has recurring therapy appointments */
        therapyCadence?: {
          status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** The hours a staff user is available to be scheduled. */
        availabilitySchedule?: {
          /** Start hour, 24 hour format */
          startHour: number;
          /** End hour, 24 hour format */
          endHour: number;
          /** Start minute, 24 hour format */
          startMin: number;
          /** End minute, 24 hour format */
          endMin: number;
          /** Day of the week for the schedule */
          day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
          _id?: string;
        }[];
        scheduleCapacity?: {
          /** Psychiatry Intake slots per week */
          PsychiatryIntake?: number;
          /** Therapy Intake slots per week */
          TherapyIntake?: number;
          /** Patient Guide Intake slots per week */
          PatientGuideIntake?: number;
          /** Family Guide Intake slots per week */
          FamilyGuideIntake?: number;
          /** Care Advocate Intake slots per week */
          CareAdvocateIntake?: number;
          _id?: string;
        };
        /**
         * Memory is appended to the start of every GPT conversation to simulate memory and make it
         * less tedious
         */
        gptMemory?: string;
        /**
         * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
         * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
         */
        leadsquaredId?: string;
        /** The ID of the patient in DoseSpot */
        dosespotPatientId?: string;
        /** The ID of the clinician in DoseSpot */
        dosespotClinicianId?: string;
        _id?: string;
        admin?: boolean;
        email?: string;
        /** When a user is set to disable, all requests will return a 401 */
        disabled?: boolean;
        updated?: string;
        created?: string;
        /**
         * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
         * true} to find them.
         */
        deleted?: boolean;
        hash?: string;
        salt?: string;
        attempts?: number;
        last?: string;
      };
      Therapist?: {
        /**
         * The preferred, full name for the user. If their legal/insurance name is different,
         * that should be stored in billing info.
         */
        name?: string;
        /** How the user or staff's name is pronounced. This is visible in the user app. */
        namePronunciation?: string;
        /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
        initials?: string;
        birthday?: any;
        /**
         * The gender the user identifies as. If different than the gender listed on their
         * insurance, that should be stored in billing info.
         */
        gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
        /** If gender is 'Prefer to self-describe', this is used */
        genderSelfDescribe?: string;
        /** The user's current timezone, based on their phone's location. */
        timezone?:
          | "America/New_York"
          | "America/Chicago"
          | "America/Denver"
          | "America/Los_Angeles"
          | "America/Anchorage"
          | "Pacific/Honolulu"
          | "America/Phoenix";
        staffRoles?: {
          /**
           * Gives the ability to access all users, set other staff passwords,
           * and access super user tools.
           */
          SuperUser?: boolean;
          CareAdvocate?: boolean;
          FamilyGuide?: boolean;
          PatientGuide?: boolean;
          Psychiatrist?: boolean;
          Therapist?: boolean;
          EnrollmentCoordinator?: boolean;
          FamilyGuideSupervisor?: boolean;
          PatientGuideSupervisor?: boolean;
          TherapistSupervisor?: boolean;
          ClinicalLeader?: boolean;
          ClinicalDirector?: boolean;
          /** Only one staff user should have this role */
          RiskManager?: boolean;
          /** Only one staff user should have this role */
          MedicalDirector?: boolean;
          EnrollmentSupervisor?: boolean;
          ClinicalQualityAssurance?: boolean;
          SoftwareEngineer?: boolean;
          Guide?: boolean;
          MemberGuideSupervisor?: boolean;
          _id?: string;
        };
        outOfOffice?: boolean;
        /** Staff Degrees, certifications, etc. E.g. LCSW */
        credentials?: string;
        /**
         * The Twilio phone number users can text or call to reach this staff member.
         * Send a text to a test user  to get a phone number assigned.
         */
        staffPhoneNumber?: string;
        careTeam?: {
          Therapist?: any;
          Psychiatrist?: any;
          Guide?: any;
          PatientGuide?: any;
          FamilyGuide?: any;
          _id?: string;
        };
        /** The staff's supervisor. */
        supervisor?: any;
        fitbitId?: string;
        fitbitAccessToken?: string;
        fitbitRefreshToken?: string;
        fitbitConfig?: {
          enabled?: boolean;
          sleepEnabled?: boolean;
          activityEnabled?: boolean;
          allowReminders?: boolean;
          note?: string;
        };
        type?: "Staff" | "Member" | "FamilyMember" | "Patient";
        consentFormAgreements?: {
          consentFormId: number;
          isAgreed: boolean;
          agreedDate?: string;
          consentFormType:
            | "patientAgreement"
            | "familyMemberAgreement"
            | "consent"
            | "transportation"
            | "research"
            | "privacy"
            | "hipaa"
            | "virginiaRights";
          _id?: string;
        }[];
        /** Whether the user has agreed to receive push notifications */
        pushNotifications?: boolean;
        /**
         * Whether SMS messages are allowed or not for the user.
         * Generally used to disable SMS after delivery failures.
         */
        smsEnabled?: boolean;
        smsErrors?: {
          message?: string;
          notification?: any;
          error?: string;
          date?: string;
        }[];
        /**
         * Whether the user has agreed to receive automated notifications, e.g. for appointments.
         */
        smsNotifications?: boolean;
        /** Whether the user has agreed to receive SMS messages from staff */
        smsMessaging?: boolean;
        expoToken?: string;
        expoTokens?: string[];
        /** Web push subscription info */
        webPushTokens?: {
          /**
           * The endpoint takes the form of a custom URL pointing to a push server,
           * which can be used to send a push message to the particular service worker instance
           * that subscribed to the push service.
           */
          endpoint: string;
          /** An authentication secret for the push server */
          auth: string;
          /**
           * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push
           * server.
           */
          p256dh: string;
          _id?: string;
        }[];
        conversations?: {
          conversationId: any;
          role?:
            | "Guide"
            | "PatientGuide"
            | "Therapist"
            | "Psychiatrist"
            | "SuperUser"
            | "FamilyGuide";
        }[];
        /** Hides the user from the UI without deleting them. */
        archived?: boolean;
        address?: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        usageData?: {
          /** The last time the app was launched. */
          lastAppLaunch?: string;
          currentAppVersion?: string;
          phoneModel?: string;
          operatingSystem?: string;
          _id?: string;
        };
        testUser?: boolean;
        pronouns?: string;
        bio?: string;
        /** A string describing the user's care plan. This will be visible in the apps. */
        carePlan?: string;
        /**
         * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
         * and quick current info. This is only shown in the staff app.
         */
        oneLiner?: string;
        /**
         * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
         */
        weeklyUpdates?: string;
        /**
         * Care team members are doing some work on “thrive planning” with patients and this is
         * where they can store it shown to staff AND members
         */
        thrivePlan?: string;
        engagementDetails?: string;
        /** Things that could be triggering for the patient that staff needs to be aware of. */
        safetyConcerns?: string[];
        /**
         * A string describing the user's safety plan. This will be visible in the apps under care
         * plan.
         */
        safetyPlan?: string;
        /**
         * Some early test users will be receiving guide-only services, with no clinical services.
         * At a minimum, we hide references to our clinical services from their app and make this
         * apparent in the workflows.
         */
        guideOnly?: boolean;
        /**
         * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a
         * shorthand for members. Lots of our forms require memberId to be written on every
         * page,and this is a lot easier than `_id`.
         * Use a unique sparse index because staff don't have memberIds,
         * and we don't want to have to check for nulls.
         */
        memberId?: number;
        patientId?: number;
        /**
         * Charm automatically creates a patient id for us to use. If this is not set,
         * the user is not synced to Charm.
         */
        charmPatientId?: string;
        /**
         * For staff that are also set up on Charm,
         * we save their ID so we can show which provider is writing/signing notes.
         */
        charmProviderId?: string;
        taskIdentifiers?: {
          scheduledOfflineToggleForMembersTaskName?: string;
          scheduledOfflineToggleForPatientsTaskName?: string;
          scheduledOfflineToggleForFamilyMembersTaskName?: string;
          _id?: string;
        };
        online?: {
          forMembers?: boolean;
          scheduledOfflineToggleForMembers?: string;
          forPatients?: boolean;
          scheduledOfflineToggleForPatients?: string;
          forFamilyMembers?: boolean;
          scheduledOfflineToggleForFamilyMembers?: string;
          _id?: string;
        };
        billingInfo?: {
          firstName?: string;
          lastName?: string;
          gender?: "male" | "female" | "unknown" | "other";
          insurancePlan?: any;
          renewalDate?: any;
          memberId?: string;
          groupId?: string;
          healthPlanId?: string;
          policyHolder?: "self" | "other";
          authorizationCode?: string;
          medicaidNumber?: string;
          _id?: string;
        };
        charmErrors?: string[];
        growth?: {
          authorizationDate?: any;
          referralSource?: any;
          referralMethod?: any;
          /**
           * The date the patient was referred to Flourish,
           * either self-referral or from a partner
           */
          referralDate?: any;
          /** The date the patient was accepted into our enrollment pipeline. */
          referralAcceptedDate?: any;
          /**
           * The date we can start billing for the patient.
           * This should be the date of their first attended therapy intake.
           * This will be used to calculate billing months for the patient,
           * which will be used for engagement tracking.
           * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
           * 2/15 - 3/14. Or 1/31 - 2/28.
           */
          serviceStartDate?: any;
          /** The date the patient was fully enrolled in the program and finished intakes. */
          enrolledDate?: any;
          /** The actual date the patient was discharged from the Flourish Health program. */
          dischargeDate?: any;
          /**
           * The anticipated date we expect the patient will be discharged from the Flourish Health
           * program. When service start date is set,
           * this is defaulted to 1 year from service start date.
           * Will also be edited to reflect anticipated upcoming early discharge.
           */
          estimatedDischargeDate?: any;
          /** Whether the patient is currently hospitalized. */
          isPatientCurrentlyHospitalized?: boolean;
          /** Whether the patient is currently in outpatient care. */
          outpatient?: boolean;
          /**
           * The date the patient was discharged from a hospital prior to enrollment in Flourish
           * Program.
           */
          hospitalDischargeDate?: any;
          /**
           * The external therapist/other clinician who referred the patient to Flourish and was
           * likely part of the patient's most recent care team. This may be the same as
           * referringPsychiatrist.
           */
          referringClinician?: any;
          /**
           * The external psychiatrist who referred the patient to Flourish and was likely part of
           * the patient's most recent care team.
           */
          referringPsychiatrist?: any;
          /**
           * Link to the Google Drive folder where the user's documents outside the app are stored.
           */
          documentFolder?: string;
          /** Notes about the user's enrollment process, for internal use. */
          notes?: string;
          eligibilityInterview?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          clinicalIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          therapyIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          psychiatryIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          inHomeOnboarding?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          meetAndGreet?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          origin?: string;
          leadSource?: string;
          optInDetails?: string;
          optInDate?: string;
          sourceReferrerUrl?: string;
          sourceContent?: string;
          sourceCampaign?: string;
          conversionReferrerUrl?: string;
          referringProviderPhoneNumber?: string;
          referringProviderOrganization?: string;
          referringProviderName?: string;
          referringProviderEmail?: string;
          referringClinicianName?: string;
          _id?: string;
        };
        tasks?: {
          roiSent?: boolean;
          signedROIReceived?: boolean;
          signedROISentToProviders?: boolean;
          medicalRecordsRequested?: boolean;
          dischargeMedsReceived?: boolean;
          documentationRequestSent?: boolean;
          authorizationInformationConfirmed?: boolean;
          _id?: string;
        };
        contacts?: {
          name: string;
          types: string[];
          isPrimary: boolean;
          phoneNumber?: string;
          email?: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          notes?: string;
          _id?: string;
        }[];
        /** SMS, app, phone, email, times of day, etc */
        preferredPharmacies?: {
          primary: boolean;
          name: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          phoneNumber?: string;
          faxNumber?: string;
          notes?: string;
          _id?: string;
        }[];
        settings?: {
          colorTheme?: string;
          _id?: string;
        };
        statusId?: any;
        /** The reason(s) selected for the user's current UserStatus */
        statusReasons?: string[];
        carePod?: any;
        /**
         * Link to the video chat platform the user prefers.
         * This is shown in the patient/family member app. Usually Doxy or Zoom.
         */
        videoChatLink?: string;
        /** The last time a claim was submitted for this user */
        lastClaimDate?: string;
        /** SMS, app, phone, email, times of day, etc. */
        communicationPreferences?: string;
        /** The primary language the user speaks */
        languages?: string;
        interpreterNeeded?: boolean;
        featureFlags?: {
          enableAlertInstances?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableWebPush?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTwilioCalling?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          allowOpenNotesScheduleItemLinking?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableGoogleCalendar?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableToDoList?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          generateFeedbackSurveys?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableReorganizePanel?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enablePreClinicUpdates?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          appointments?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableUserAlertsExplorer?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventHelpButton?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventCrisisEscalation?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTherapyCadenceIndicator?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableApptTypesTherapyPatientAndTherapyCaregiver?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableDoseSpot?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          _id?: string;
        };
        /** Which Flourish company the user is associated with */
        companyOrganizationId?: any;
        /** The user's diagnosis */
        diagnosis?: {
          diagnoses?: any[];
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** helps staff remember what kind of engagement/outreach a patient needs */
        clinicalStatus?: {
          status?: "High" | "Moderate" | "Mild-Moderate";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** how often the patient has recurring therapy appointments */
        therapyCadence?: {
          status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** The hours a staff user is available to be scheduled. */
        availabilitySchedule?: {
          /** Start hour, 24 hour format */
          startHour: number;
          /** End hour, 24 hour format */
          endHour: number;
          /** Start minute, 24 hour format */
          startMin: number;
          /** End minute, 24 hour format */
          endMin: number;
          /** Day of the week for the schedule */
          day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
          _id?: string;
        }[];
        scheduleCapacity?: {
          /** Psychiatry Intake slots per week */
          PsychiatryIntake?: number;
          /** Therapy Intake slots per week */
          TherapyIntake?: number;
          /** Patient Guide Intake slots per week */
          PatientGuideIntake?: number;
          /** Family Guide Intake slots per week */
          FamilyGuideIntake?: number;
          /** Care Advocate Intake slots per week */
          CareAdvocateIntake?: number;
          _id?: string;
        };
        /**
         * Memory is appended to the start of every GPT conversation to simulate memory and make it
         * less tedious
         */
        gptMemory?: string;
        /**
         * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
         * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
         */
        leadsquaredId?: string;
        /** The ID of the patient in DoseSpot */
        dosespotPatientId?: string;
        /** The ID of the clinician in DoseSpot */
        dosespotClinicianId?: string;
        _id?: string;
        admin?: boolean;
        email?: string;
        /** When a user is set to disable, all requests will return a 401 */
        disabled?: boolean;
        updated?: string;
        created?: string;
        /**
         * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
         * true} to find them.
         */
        deleted?: boolean;
        hash?: string;
        salt?: string;
        attempts?: number;
        last?: string;
      };
      Psychiatrist?: {
        /**
         * The preferred, full name for the user. If their legal/insurance name is different,
         * that should be stored in billing info.
         */
        name?: string;
        /** How the user or staff's name is pronounced. This is visible in the user app. */
        namePronunciation?: string;
        /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
        initials?: string;
        birthday?: any;
        /**
         * The gender the user identifies as. If different than the gender listed on their
         * insurance, that should be stored in billing info.
         */
        gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
        /** If gender is 'Prefer to self-describe', this is used */
        genderSelfDescribe?: string;
        /** The user's current timezone, based on their phone's location. */
        timezone?:
          | "America/New_York"
          | "America/Chicago"
          | "America/Denver"
          | "America/Los_Angeles"
          | "America/Anchorage"
          | "Pacific/Honolulu"
          | "America/Phoenix";
        staffRoles?: {
          /**
           * Gives the ability to access all users, set other staff passwords,
           * and access super user tools.
           */
          SuperUser?: boolean;
          CareAdvocate?: boolean;
          FamilyGuide?: boolean;
          PatientGuide?: boolean;
          Psychiatrist?: boolean;
          Therapist?: boolean;
          EnrollmentCoordinator?: boolean;
          FamilyGuideSupervisor?: boolean;
          PatientGuideSupervisor?: boolean;
          TherapistSupervisor?: boolean;
          ClinicalLeader?: boolean;
          ClinicalDirector?: boolean;
          /** Only one staff user should have this role */
          RiskManager?: boolean;
          /** Only one staff user should have this role */
          MedicalDirector?: boolean;
          EnrollmentSupervisor?: boolean;
          ClinicalQualityAssurance?: boolean;
          SoftwareEngineer?: boolean;
          Guide?: boolean;
          MemberGuideSupervisor?: boolean;
          _id?: string;
        };
        outOfOffice?: boolean;
        /** Staff Degrees, certifications, etc. E.g. LCSW */
        credentials?: string;
        /**
         * The Twilio phone number users can text or call to reach this staff member.
         * Send a text to a test user  to get a phone number assigned.
         */
        staffPhoneNumber?: string;
        careTeam?: {
          Therapist?: any;
          Psychiatrist?: any;
          Guide?: any;
          PatientGuide?: any;
          FamilyGuide?: any;
          _id?: string;
        };
        /** The staff's supervisor. */
        supervisor?: any;
        fitbitId?: string;
        fitbitAccessToken?: string;
        fitbitRefreshToken?: string;
        fitbitConfig?: {
          enabled?: boolean;
          sleepEnabled?: boolean;
          activityEnabled?: boolean;
          allowReminders?: boolean;
          note?: string;
        };
        type?: "Staff" | "Member" | "FamilyMember" | "Patient";
        consentFormAgreements?: {
          consentFormId: number;
          isAgreed: boolean;
          agreedDate?: string;
          consentFormType:
            | "patientAgreement"
            | "familyMemberAgreement"
            | "consent"
            | "transportation"
            | "research"
            | "privacy"
            | "hipaa"
            | "virginiaRights";
          _id?: string;
        }[];
        /** Whether the user has agreed to receive push notifications */
        pushNotifications?: boolean;
        /**
         * Whether SMS messages are allowed or not for the user.
         * Generally used to disable SMS after delivery failures.
         */
        smsEnabled?: boolean;
        smsErrors?: {
          message?: string;
          notification?: any;
          error?: string;
          date?: string;
        }[];
        /**
         * Whether the user has agreed to receive automated notifications, e.g. for appointments.
         */
        smsNotifications?: boolean;
        /** Whether the user has agreed to receive SMS messages from staff */
        smsMessaging?: boolean;
        expoToken?: string;
        expoTokens?: string[];
        /** Web push subscription info */
        webPushTokens?: {
          /**
           * The endpoint takes the form of a custom URL pointing to a push server,
           * which can be used to send a push message to the particular service worker instance
           * that subscribed to the push service.
           */
          endpoint: string;
          /** An authentication secret for the push server */
          auth: string;
          /**
           * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push
           * server.
           */
          p256dh: string;
          _id?: string;
        }[];
        conversations?: {
          conversationId: any;
          role?:
            | "Guide"
            | "PatientGuide"
            | "Therapist"
            | "Psychiatrist"
            | "SuperUser"
            | "FamilyGuide";
        }[];
        /** Hides the user from the UI without deleting them. */
        archived?: boolean;
        address?: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        usageData?: {
          /** The last time the app was launched. */
          lastAppLaunch?: string;
          currentAppVersion?: string;
          phoneModel?: string;
          operatingSystem?: string;
          _id?: string;
        };
        testUser?: boolean;
        pronouns?: string;
        bio?: string;
        /** A string describing the user's care plan. This will be visible in the apps. */
        carePlan?: string;
        /**
         * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
         * and quick current info. This is only shown in the staff app.
         */
        oneLiner?: string;
        /**
         * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
         */
        weeklyUpdates?: string;
        /**
         * Care team members are doing some work on “thrive planning” with patients and this is
         * where they can store it shown to staff AND members
         */
        thrivePlan?: string;
        engagementDetails?: string;
        /** Things that could be triggering for the patient that staff needs to be aware of. */
        safetyConcerns?: string[];
        /**
         * A string describing the user's safety plan. This will be visible in the apps under care
         * plan.
         */
        safetyPlan?: string;
        /**
         * Some early test users will be receiving guide-only services, with no clinical services.
         * At a minimum, we hide references to our clinical services from their app and make this
         * apparent in the workflows.
         */
        guideOnly?: boolean;
        /**
         * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a
         * shorthand for members. Lots of our forms require memberId to be written on every
         * page,and this is a lot easier than `_id`.
         * Use a unique sparse index because staff don't have memberIds,
         * and we don't want to have to check for nulls.
         */
        memberId?: number;
        patientId?: number;
        /**
         * Charm automatically creates a patient id for us to use. If this is not set,
         * the user is not synced to Charm.
         */
        charmPatientId?: string;
        /**
         * For staff that are also set up on Charm,
         * we save their ID so we can show which provider is writing/signing notes.
         */
        charmProviderId?: string;
        taskIdentifiers?: {
          scheduledOfflineToggleForMembersTaskName?: string;
          scheduledOfflineToggleForPatientsTaskName?: string;
          scheduledOfflineToggleForFamilyMembersTaskName?: string;
          _id?: string;
        };
        online?: {
          forMembers?: boolean;
          scheduledOfflineToggleForMembers?: string;
          forPatients?: boolean;
          scheduledOfflineToggleForPatients?: string;
          forFamilyMembers?: boolean;
          scheduledOfflineToggleForFamilyMembers?: string;
          _id?: string;
        };
        billingInfo?: {
          firstName?: string;
          lastName?: string;
          gender?: "male" | "female" | "unknown" | "other";
          insurancePlan?: any;
          renewalDate?: any;
          memberId?: string;
          groupId?: string;
          healthPlanId?: string;
          policyHolder?: "self" | "other";
          authorizationCode?: string;
          medicaidNumber?: string;
          _id?: string;
        };
        charmErrors?: string[];
        growth?: {
          authorizationDate?: any;
          referralSource?: any;
          referralMethod?: any;
          /**
           * The date the patient was referred to Flourish,
           * either self-referral or from a partner
           */
          referralDate?: any;
          /** The date the patient was accepted into our enrollment pipeline. */
          referralAcceptedDate?: any;
          /**
           * The date we can start billing for the patient.
           * This should be the date of their first attended therapy intake.
           * This will be used to calculate billing months for the patient,
           * which will be used for engagement tracking.
           * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
           * 2/15 - 3/14. Or 1/31 - 2/28.
           */
          serviceStartDate?: any;
          /** The date the patient was fully enrolled in the program and finished intakes. */
          enrolledDate?: any;
          /** The actual date the patient was discharged from the Flourish Health program. */
          dischargeDate?: any;
          /**
           * The anticipated date we expect the patient will be discharged from the Flourish Health
           * program. When service start date is set,
           * this is defaulted to 1 year from service start date.
           * Will also be edited to reflect anticipated upcoming early discharge.
           */
          estimatedDischargeDate?: any;
          /** Whether the patient is currently hospitalized. */
          isPatientCurrentlyHospitalized?: boolean;
          /** Whether the patient is currently in outpatient care. */
          outpatient?: boolean;
          /**
           * The date the patient was discharged from a hospital prior to enrollment in Flourish
           * Program.
           */
          hospitalDischargeDate?: any;
          /**
           * The external therapist/other clinician who referred the patient to Flourish and was
           * likely part of the patient's most recent care team. This may be the same as
           * referringPsychiatrist.
           */
          referringClinician?: any;
          /**
           * The external psychiatrist who referred the patient to Flourish and was likely part of
           * the patient's most recent care team.
           */
          referringPsychiatrist?: any;
          /**
           * Link to the Google Drive folder where the user's documents outside the app are stored.
           */
          documentFolder?: string;
          /** Notes about the user's enrollment process, for internal use. */
          notes?: string;
          eligibilityInterview?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          clinicalIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          therapyIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          psychiatryIntake?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          inHomeOnboarding?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          meetAndGreet?: {
            /** The date the intake was scheduled for. */
            date?: string;
            /** Whether the intake was attended yet or not. */
            attended?: boolean;
            /** The linked schedule item for the intake. */
            scheduleItemId?: string;
            _id?: string;
            created?: string;
            updated?: string;
          };
          origin?: string;
          leadSource?: string;
          optInDetails?: string;
          optInDate?: string;
          sourceReferrerUrl?: string;
          sourceContent?: string;
          sourceCampaign?: string;
          conversionReferrerUrl?: string;
          referringProviderPhoneNumber?: string;
          referringProviderOrganization?: string;
          referringProviderName?: string;
          referringProviderEmail?: string;
          referringClinicianName?: string;
          _id?: string;
        };
        tasks?: {
          roiSent?: boolean;
          signedROIReceived?: boolean;
          signedROISentToProviders?: boolean;
          medicalRecordsRequested?: boolean;
          dischargeMedsReceived?: boolean;
          documentationRequestSent?: boolean;
          authorizationInformationConfirmed?: boolean;
          _id?: string;
        };
        contacts?: {
          name: string;
          types: string[];
          isPrimary: boolean;
          phoneNumber?: string;
          email?: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          notes?: string;
          _id?: string;
        }[];
        /** SMS, app, phone, email, times of day, etc */
        preferredPharmacies?: {
          primary: boolean;
          name: string;
          address: {
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            zipcode?: string;
            countyName?: string;
            countyCode?: string;
            _id?: string;
          };
          phoneNumber?: string;
          faxNumber?: string;
          notes?: string;
          _id?: string;
        }[];
        settings?: {
          colorTheme?: string;
          _id?: string;
        };
        statusId?: any;
        /** The reason(s) selected for the user's current UserStatus */
        statusReasons?: string[];
        carePod?: any;
        /**
         * Link to the video chat platform the user prefers.
         * This is shown in the patient/family member app. Usually Doxy or Zoom.
         */
        videoChatLink?: string;
        /** The last time a claim was submitted for this user */
        lastClaimDate?: string;
        /** SMS, app, phone, email, times of day, etc. */
        communicationPreferences?: string;
        /** The primary language the user speaks */
        languages?: string;
        interpreterNeeded?: boolean;
        featureFlags?: {
          enableAlertInstances?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableWebPush?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTwilioCalling?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          allowOpenNotesScheduleItemLinking?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableGoogleCalendar?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableToDoList?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          generateFeedbackSurveys?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableReorganizePanel?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enablePreClinicUpdates?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          appointments?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableUserAlertsExplorer?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventHelpButton?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableCriticalEventCrisisEscalation?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableTherapyCadenceIndicator?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableApptTypesTherapyPatientAndTherapyCaregiver?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          enableDoseSpot?: {
            enabled?: boolean;
            showTutorial?: boolean;
          };
          _id?: string;
        };
        /** Which Flourish company the user is associated with */
        companyOrganizationId?: any;
        /** The user's diagnosis */
        diagnosis?: {
          diagnoses?: any[];
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** helps staff remember what kind of engagement/outreach a patient needs */
        clinicalStatus?: {
          status?: "High" | "Moderate" | "Mild-Moderate";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** how often the patient has recurring therapy appointments */
        therapyCadence?: {
          status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
          _id?: string;
          created?: string;
          updated?: string;
        };
        /** The hours a staff user is available to be scheduled. */
        availabilitySchedule?: {
          /** Start hour, 24 hour format */
          startHour: number;
          /** End hour, 24 hour format */
          endHour: number;
          /** Start minute, 24 hour format */
          startMin: number;
          /** End minute, 24 hour format */
          endMin: number;
          /** Day of the week for the schedule */
          day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
          _id?: string;
        }[];
        scheduleCapacity?: {
          /** Psychiatry Intake slots per week */
          PsychiatryIntake?: number;
          /** Therapy Intake slots per week */
          TherapyIntake?: number;
          /** Patient Guide Intake slots per week */
          PatientGuideIntake?: number;
          /** Family Guide Intake slots per week */
          FamilyGuideIntake?: number;
          /** Care Advocate Intake slots per week */
          CareAdvocateIntake?: number;
          _id?: string;
        };
        /**
         * Memory is appended to the start of every GPT conversation to simulate memory and make it
         * less tedious
         */
        gptMemory?: string;
        /**
         * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
         * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
         */
        leadsquaredId?: string;
        /** The ID of the patient in DoseSpot */
        dosespotPatientId?: string;
        /** The ID of the clinician in DoseSpot */
        dosespotClinicianId?: string;
        _id?: string;
        admin?: boolean;
        email?: string;
        /** When a user is set to disable, all requests will return a 401 */
        disabled?: boolean;
        updated?: string;
        created?: string;
        /**
         * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
         * true} to find them.
         */
        deleted?: boolean;
        hash?: string;
        salt?: string;
        attempts?: number;
        last?: string;
      };
    };
    /** The Slack channel to send normal alerts to. */
    slackAlertChannel?: string;
    /** The Slack channel to send urgent alerts to. */
    slackUrgentAlertChannel?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    url?: any;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetCarePodsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetCarePodsByIdRes = /** status 200 Successful read */ {
  name: string;
  /** The calendar to sync events to. */
  googleCalendarId?: string;
  /** The maximum number of members in this pod. */
  capacity?: number;
  careTeam?: {
    PatientGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    FamilyGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Therapist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Psychiatrist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
  };
  /** The Slack channel to send normal alerts to. */
  slackAlertChannel?: string;
  /** The Slack channel to send urgent alerts to. */
  slackUrgentAlertChannel?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  url?: any;
};
export type GetCarePodsByIdArgs = string;
export type PatchCarePodsByIdRes = /** status 200 Successful update */ {
  name: string;
  /** The calendar to sync events to. */
  googleCalendarId?: string;
  /** The maximum number of members in this pod. */
  capacity?: number;
  careTeam?: {
    PatientGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    FamilyGuide?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Therapist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    Psychiatrist?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
  };
  /** The Slack channel to send normal alerts to. */
  slackAlertChannel?: string;
  /** The Slack channel to send urgent alerts to. */
  slackUrgentAlertChannel?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  url?: any;
};
export type PatchCarePodsByIdArgs = {
  id: string;
  body: {
    name?: string;
    /** The calendar to sync events to. */
    googleCalendarId?: string;
    /** The maximum number of members in this pod. */
    capacity?: number;
    /** The Slack channel to send normal alerts to. */
    slackAlertChannel?: string;
    /** The Slack channel to send urgent alerts to. */
    slackUrgentAlertChannel?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    url?: any;
  };
};
export type DeleteCarePodsByIdRes = /** status 204 Successful delete */ void;
export type DeleteCarePodsByIdArgs = string;
export type GetCharmTokensRes = /** status 200 Successful list */ {
  data?: {
    accessToken: string;
    expires: string;
    refreshToken: string;
    userId: any;
    superUser: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetCharmTokensArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type DeleteCharmTokensByIdRes = /** status 204 Successful delete */ void;
export type DeleteCharmTokensByIdArgs = string;
export type PostCompanyOrganizationsRes = /** status 201 Successful create */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostCompanyOrganizationsArgs = {
  createdBy?: string;
  name?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetCompanyOrganizationsRes = /** status 200 Successful list */ {
  data?: {
    createdBy: string;
    name: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetCompanyOrganizationsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetCompanyOrganizationsByIdRes = /** status 200 Successful read */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetCompanyOrganizationsByIdArgs = string;
export type PatchCompanyOrganizationsByIdRes = /** status 200 Successful update */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchCompanyOrganizationsByIdArgs = {
  id: string;
  body: {
    createdBy?: string;
    name?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteCompanyOrganizationsByIdRes = /** status 204 Successful delete */ void;
export type DeleteCompanyOrganizationsByIdArgs = string;
export type PostConversationsRes = /** status 201 Successful create */ {
  type: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  lastMessageSentDate?: string;
  name?: string;
  archivedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  /**
   * The patients/family members about whom the conversation is regarding.
   * Used for showing patient/family member information in the UI.
   * These users are not participants in the conversation.
   */
  referencedUsers: {
    userId: LimitedUser;
    primary?: boolean;
    _id?: string;
  }[];
  sendAsSms?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  unreadCount?: number;
  tagCount?: number;
  muted?: boolean;
  smsCapable?: boolean;
};
export type PostConversationsArgs = {
  type?: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
  users?: {
    userId: LimitedUser;
    _id?: string;
  }[];
  lastMessageSentDate?: string;
  name?: string;
  archivedUsers?: {
    userId: LimitedUser;
    _id?: string;
  }[];
  /**
   * The patients/family members about whom the conversation is regarding.
   * Used for showing patient/family member information in the UI.
   * These users are not participants in the conversation.
   */
  referencedUsers?: {
    userId: LimitedUser;
    primary?: boolean;
    _id?: string;
  }[];
  sendAsSms?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  unreadCount?: number;
  tagCount?: number;
  muted?: boolean;
  smsCapable?: boolean;
};
export type GetConversationsRes = /** status 200 Successful list */ {
  data?: {
    type: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
    users: {
      userId: LimitedUser;
      _id?: string;
    }[];
    lastMessageSentDate?: string;
    name?: string;
    archivedUsers: {
      userId: LimitedUser;
      _id?: string;
    }[];
    /**
     * The patients/family members about whom the conversation is regarding.
     * Used for showing patient/family member information in the UI.
     * These users are not participants in the conversation.
     */
    referencedUsers: {
      userId: LimitedUser;
      primary?: boolean;
      _id?: string;
    }[];
    sendAsSms?: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    unreadCount?: number;
    tagCount?: number;
    muted?: boolean;
    smsCapable?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetConversationsArgs = {
  _id?: {
    $in?: string[];
  };
  "users.userId"?:
    | any
    | {
        $in?: any[];
      };
  type?:
    | ("Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi")
    | {
        $in?: string[];
      };
  $and?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetConversationsByIdRes = /** status 200 Successful read */ {
  type: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  lastMessageSentDate?: string;
  name?: string;
  archivedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  /**
   * The patients/family members about whom the conversation is regarding.
   * Used for showing patient/family member information in the UI.
   * These users are not participants in the conversation.
   */
  referencedUsers: {
    userId: LimitedUser;
    primary?: boolean;
    _id?: string;
  }[];
  sendAsSms?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  unreadCount?: number;
  tagCount?: number;
  muted?: boolean;
  smsCapable?: boolean;
};
export type GetConversationsByIdArgs = string;
export type PatchConversationsByIdRes = /** status 200 Successful update */ {
  type: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  lastMessageSentDate?: string;
  name?: string;
  archivedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  /**
   * The patients/family members about whom the conversation is regarding.
   * Used for showing patient/family member information in the UI.
   * These users are not participants in the conversation.
   */
  referencedUsers: {
    userId: LimitedUser;
    primary?: boolean;
    _id?: string;
  }[];
  sendAsSms?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  unreadCount?: number;
  tagCount?: number;
  muted?: boolean;
  smsCapable?: boolean;
};
export type PatchConversationsByIdArgs = {
  id: string;
  body: {
    type?: "Member" | "Patient" | "FamilyMember" | "Staff" | "AllStaff" | "Multi";
    users?: {
      userId: LimitedUser;
      _id?: string;
    }[];
    lastMessageSentDate?: string;
    name?: string;
    archivedUsers?: {
      userId: LimitedUser;
      _id?: string;
    }[];
    /**
     * The patients/family members about whom the conversation is regarding.
     * Used for showing patient/family member information in the UI.
     * These users are not participants in the conversation.
     */
    referencedUsers?: {
      userId: LimitedUser;
      primary?: boolean;
      _id?: string;
    }[];
    sendAsSms?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    unreadCount?: number;
    tagCount?: number;
    muted?: boolean;
    smsCapable?: boolean;
  };
};
export type DeleteConversationsByIdRes = /** status 204 Successful delete */ void;
export type DeleteConversationsByIdArgs = string;
export type GetConversationsSummaryRes = /** status 200 Success */ {
  /** Unique identifier for the conversation */
  _id: string;
  /** Type of the conversation */
  type: string;
  /** Users involved in the conversation */
  users: {
    userId?: string;
    _id?: string;
  }[];
  /** Whether messages are sent as SMS */
  sendAsSms: boolean;
  /** Whether the conversation is deleted */
  deleted: boolean;
  /** Users who have archived this conversation */
  archivedUsers: {
    userId?: string;
    _id?: string;
  }[];
  /**
   * The patients/family members about whom the conversation is regarding.
   * Used for showing patient/family member information in the UI.
   * These users are not participants in the conversation.
   */
  referencedUsers?: {
    userId?: string;
    _id?: string;
    primary?: boolean;
  }[];
  /** Creation date of the conversation */
  created: string;
  /** Last update date of the conversation */
  updated: string;
  /** Date of the last message sent in the conversation */
  lastMessageSentDate: string;
  /** Status information for the conversation */
  conversationStatus: {
    _id?: string;
    ownerId?: string;
    conversationIdLastReadMap?: {
      [key: string]: string;
    };
    deleted?: boolean;
    created?: string;
    updated?: string;
  };
  /** Most recent message in the conversation */
  mostRecentMessage?: {
    _id?: string;
    text?: string;
    sentAsSms?: boolean;
    notifications?: string[];
    conversationId?: string;
    roomTag?: boolean;
    flaggedBySystemAsConcerning?: boolean;
    deleted?: boolean;
    taggedUsers?: string[];
    created?: string;
    updated?: string;
  };
  /** Number of unread messages in the conversation */
  unreadCount: number;
}[];
export type GetConversationsSummaryArgs = void;
export type PostConversationStatusesRes = /** status 201 Successful create */ {
  ownerId: string;
  conversationIdLastReadMap?: {
    [key: string]: string;
  };
  conversationIdMutedMap?: {
    [key: string]: boolean;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostConversationStatusesArgs = {
  ownerId?: string;
  conversationIdLastReadMap?: {
    [key: string]: string;
  };
  conversationIdMutedMap?: {
    [key: string]: boolean;
  };
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetConversationStatusesRes = /** status 200 Successful list */ {
  data?: {
    ownerId: string;
    conversationIdLastReadMap?: {
      [key: string]: string;
    };
    conversationIdMutedMap?: {
      [key: string]: boolean;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetConversationStatusesArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetConversationStatusesByIdRes = /** status 200 Successful read */ {
  ownerId: string;
  conversationIdLastReadMap?: {
    [key: string]: string;
  };
  conversationIdMutedMap?: {
    [key: string]: boolean;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetConversationStatusesByIdArgs = string;
export type DeleteConversationStatusesByIdRes = /** status 204 Successful delete */ void;
export type DeleteConversationStatusesByIdArgs = string;
export type PostCriticalEventsRes = /** status 201 Successful create */ {
  /** The user (patient/family member) who the consult pertains to, not the recipient. */
  associatedUserId: any;
  /** The staff user requesting the consult. */
  createdBy: any;
  /** The supervisors consulted for the consult. */
  consultedSupervisors: {
    userId?: string;
    role?: string;
  }[];
  /** Automated forms generated post create */
  formIds: any[];
  /**
   * If a guidingHour document was used during event creation,
   * store the payload here.Used to retrieve/reference data (supervisor, clinicalLeader, type) if
   * needed.
   */
  guidingHoursCopyRef?: {};
  notes?: string;
  /** The notifications associated with the critical event. */
  notificationIds: any[];
  type?: "Consult" | "Crisis Escalation";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostCriticalEventsArgs = {
  /** The user (patient/family member) who the consult pertains to, not the recipient. */
  associatedUserId?: any;
  /** The staff user requesting the consult. */
  createdBy?: any;
  /** The supervisors consulted for the consult. */
  consultedSupervisors?: {
    userId?: string;
    role?: string;
  }[];
  /** Automated forms generated post create */
  formIds?: any[];
  /**
   * If a guidingHour document was used during event creation,
   * store the payload here.Used to retrieve/reference data (supervisor, clinicalLeader, type) if
   * needed.
   */
  guidingHoursCopyRef?: {};
  notes?: string;
  /** The notifications associated with the critical event. */
  notificationIds?: any[];
  type?: "Consult" | "Crisis Escalation";
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetCriticalEventsRes = /** status 200 Successful list */ {
  data?: {
    /** The user (patient/family member) who the consult pertains to, not the recipient. */
    associatedUserId: any;
    /** The staff user requesting the consult. */
    createdBy: any;
    /** The supervisors consulted for the consult. */
    consultedSupervisors: {
      userId?: string;
      role?: string;
    }[];
    /** Automated forms generated post create */
    formIds: any[];
    /**
     * If a guidingHour document was used during event creation,
     * store the payload here.Used to retrieve/reference data (supervisor, clinicalLeader,
     * type) if needed.
     */
    guidingHoursCopyRef?: {};
    notes?: string;
    /** The notifications associated with the critical event. */
    notificationIds: any[];
    type?: "Consult" | "Crisis Escalation";
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetCriticalEventsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetCriticalEventsByIdRes = /** status 200 Successful read */ {
  /** The user (patient/family member) who the consult pertains to, not the recipient. */
  associatedUserId: any;
  /** The staff user requesting the consult. */
  createdBy: any;
  /** The supervisors consulted for the consult. */
  consultedSupervisors: {
    userId?: string;
    role?: string;
  }[];
  /** Automated forms generated post create */
  formIds: any[];
  /**
   * If a guidingHour document was used during event creation,
   * store the payload here.Used to retrieve/reference data (supervisor, clinicalLeader, type) if
   * needed.
   */
  guidingHoursCopyRef?: {};
  notes?: string;
  /** The notifications associated with the critical event. */
  notificationIds: any[];
  type?: "Consult" | "Crisis Escalation";
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetCriticalEventsByIdArgs = string;
export type PostCsvRes = unknown;
export type PostCsvArgs = {};
export type PostMessageCsvRes = unknown;
export type PostMessageCsvArgs = {};
export type GetDiagnosesRes = /** status 200 Successful list */ {
  data?: {
    /**
     * Former system of assigning codes to diagnoses and procedures associated with hospital.
     * Ended in 2015.
     */
    icd9cm?: string;
    /** Current system of assigning codes to diagnoses and procedures. */
    icd10cm: string;
    /** Description of the diagnosis. */
    description: string;
    /**
     * Concat string of description, icd9cm, and icd10cm. Used for search. Created in schema on
     * save.
     */
    label?: string;
    createdBy?: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetDiagnosesArgs = {
  _id?: {
    $in?: string[];
  };
  label?:
    | string
    | {
        $in?: string[];
      };
  $and?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetDiagnosesByIdRes = /** status 200 Successful read */ {
  /**
   * Former system of assigning codes to diagnoses and procedures associated with hospital.
   * Ended in 2015.
   */
  icd9cm?: string;
  /** Current system of assigning codes to diagnoses and procedures. */
  icd10cm: string;
  /** Description of the diagnosis. */
  description: string;
  /**
   * Concat string of description, icd9cm, and icd10cm. Used for search. Created in schema on save.
   */
  label?: string;
  createdBy?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetDiagnosesByIdArgs = string;
export type PostDosespotUiUrlRes = /** status 200 Successfully generated DoseSpot UI URL */ {
  /** The generated DoseSpot UI URL */
  uiUrl?: string;
};
export type PostDosespotUiUrlArgs = {
  /** The ID of the patient */
  patientUserId: string;
};
export type PostDosespotSyncPatientRes = unknown;
export type PostDosespotSyncPatientArgs = {
  /** The ID of the patient */
  patientUserId: string;
};
export type PostDotPhrasesRes = /** status 201 Successful create */ {
  createdBy: string;
  phrase: string;
  replacement: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostDotPhrasesArgs = {
  createdBy?: string;
  phrase?: string;
  replacement?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetDotPhrasesRes = /** status 200 Successful list */ {
  data?: {
    createdBy: string;
    phrase: string;
    replacement: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetDotPhrasesArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetDotPhrasesByIdRes = /** status 200 Successful read */ {
  createdBy: string;
  phrase: string;
  replacement: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetDotPhrasesByIdArgs = string;
export type PatchDotPhrasesByIdRes = /** status 200 Successful update */ {
  createdBy: string;
  phrase: string;
  replacement: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchDotPhrasesByIdArgs = {
  id: string;
  body: {
    createdBy?: string;
    phrase?: string;
    replacement?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteDotPhrasesByIdRes = /** status 204 Successful delete */ void;
export type DeleteDotPhrasesByIdArgs = string;
export type PostExternalCliniciansRes = /** status 201 Successful create */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostExternalCliniciansArgs = {
  createdBy?: string;
  name?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetExternalCliniciansRes = /** status 200 Successful list */ {
  data?: {
    createdBy: string;
    name: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetExternalCliniciansArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetExternalCliniciansByIdRes = /** status 200 Successful read */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetExternalCliniciansByIdArgs = string;
export type PatchExternalCliniciansByIdRes = /** status 200 Successful update */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchExternalCliniciansByIdArgs = {
  id: string;
  body: {
    createdBy?: string;
    name?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteExternalCliniciansByIdRes = /** status 204 Successful delete */ void;
export type DeleteExternalCliniciansByIdArgs = string;
export type PostFamilyUnitsRes = /** status 201 Successful create */ {
  name?: string;
  familyUsers: any[];
  relationships: {
    familyUserId: any;
    relationship: string;
    _id?: string;
  }[];
  primaryCaregiver?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostFamilyUnitsArgs = {
  name?: string;
  familyUsers?: any[];
  relationships?: {
    familyUserId: any;
    relationship: string;
    _id?: string;
  }[];
  primaryCaregiver?: any;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFamilyUnitsRes = /** status 200 Successful list */ {
  data?: {
    name?: string;
    familyUsers: any[];
    relationships: {
      familyUserId: any;
      relationship: string;
      _id?: string;
    }[];
    primaryCaregiver?: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFamilyUnitsArgs = {
  _id?: {
    $in?: string[];
  };
  familyUsers?:
    | any[]
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFamilyUnitsByIdRes = /** status 200 Successful read */ {
  name?: string;
  familyUsers: any[];
  relationships: {
    familyUserId: any;
    relationship: string;
    _id?: string;
  }[];
  primaryCaregiver?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFamilyUnitsByIdArgs = string;
export type PatchFamilyUnitsByIdRes = /** status 200 Successful update */ {
  name?: string;
  familyUsers: any[];
  relationships: {
    familyUserId: any;
    relationship: string;
    _id?: string;
  }[];
  primaryCaregiver?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchFamilyUnitsByIdArgs = {
  id: string;
  body: {
    name?: string;
    familyUsers?: any[];
    relationships?: {
      familyUserId: any;
      relationship: string;
      _id?: string;
    }[];
    primaryCaregiver?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteFamilyUnitsByIdRes = /** status 204 Successful delete */ void;
export type DeleteFamilyUnitsByIdArgs = string;
export type GetFitbitSleepRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    datetime: string;
    offsetFromUTCMillis?: number;
    value: {
      dateOfSleep?: string;
      startTime?: string;
      endTime?: string;
      offsetFromUTCMillis?: number;
      infoCode?: number;
      isMainSleep?: boolean;
      duration?: number;
      efficiency?: number;
      logId?: number;
      logType?: string;
      minutesAfterWakeup?: number;
      minutesAsleep?: number;
      minutesAwake?: number;
      minutesToFallAsleep?: number;
      timeInBed?: number;
      type?: "stages" | "classic";
      levels?: {
        data?: {
          dateTime?: string;
          level?: "wake" | "light" | "deep" | "rem" | "restless" | "asleep" | "awake";
          seconds?: number;
        }[];
        shortData?: {
          dateTime?: string;
          level?: "wake" | "light" | "deep" | "rem" | "restless" | "asleep" | "awake";
          seconds?: number;
        }[];
        summary?: {
          deep?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          light?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          rem?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          wake?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          asleep?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          awake?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
          restless?: {
            count?: number;
            minutes?: number;
            thirtyDayAvgMinutes?: number;
          };
        };
      };
      _id?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFitbitSleepArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  period?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFitbitSleepByIdRes = /** status 200 Successful read */ {
  userId: any;
  datetime: string;
  offsetFromUTCMillis?: number;
  value: {
    dateOfSleep?: string;
    startTime?: string;
    endTime?: string;
    offsetFromUTCMillis?: number;
    infoCode?: number;
    isMainSleep?: boolean;
    duration?: number;
    efficiency?: number;
    logId?: number;
    logType?: string;
    minutesAfterWakeup?: number;
    minutesAsleep?: number;
    minutesAwake?: number;
    minutesToFallAsleep?: number;
    timeInBed?: number;
    type?: "stages" | "classic";
    levels?: {
      data?: {
        dateTime?: string;
        level?: "wake" | "light" | "deep" | "rem" | "restless" | "asleep" | "awake";
        seconds?: number;
      }[];
      shortData?: {
        dateTime?: string;
        level?: "wake" | "light" | "deep" | "rem" | "restless" | "asleep" | "awake";
        seconds?: number;
      }[];
      summary?: {
        deep?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        light?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        rem?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        wake?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        asleep?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        awake?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
        restless?: {
          count?: number;
          minutes?: number;
          thirtyDayAvgMinutes?: number;
        };
      };
    };
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFitbitSleepByIdArgs = string;
export type GetFitbitStepsRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    datetime: string;
    offsetFromUTCMillis?: number;
    value: number;
    dataInterval?: number;
    dataType?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFitbitStepsArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  period?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFitbitStepsByIdRes = /** status 200 Successful read */ {
  userId: any;
  datetime: string;
  offsetFromUTCMillis?: number;
  value: number;
  dataInterval?: number;
  dataType?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFitbitStepsByIdArgs = string;
export type GetFitbitHrvRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    datetime: string;
    value: {
      dailyRmssd?: number;
      deepRmssd?: number;
      _id?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFitbitHrvArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  period?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFitbitHrvByIdRes = /** status 200 Successful read */ {
  userId: any;
  datetime: string;
  value: {
    dailyRmssd?: number;
    deepRmssd?: number;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFitbitHrvByIdArgs = string;
export type GetFitbitHeartRateRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    datetime: string;
    value?: {
      restingHeartRate?: number;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFitbitHeartRateArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  period?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFitbitHeartRateByIdRes = /** status 200 Successful read */ {
  userId: any;
  datetime: string;
  value?: {
    restingHeartRate?: number;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFitbitHeartRateByIdArgs = string;
export type GetFitbitStatusesRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    subscriptions?: {
      collectionType?: string;
      ownerId?: string;
      ownerType?: string;
      subscriberId?: string;
      subscriptionId?: string;
    }[];
    initialSync?: string;
    lastSync?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFitbitStatusesArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFitbitStatusesByIdRes = /** status 200 Successful read */ {
  userId: any;
  subscriptions?: {
    collectionType?: string;
    ownerId?: string;
    ownerType?: string;
    subscriberId?: string;
    subscriptionId?: string;
  }[];
  initialSync?: string;
  lastSync?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFitbitStatusesByIdArgs = string;
export type PostFormInstancesRes = /** status 201 Successful create */ {
  userId: any;
  createdBy?: any;
  formId: any;
  type: "Assessment" | "Note" | "Survey";
  form: {};
  /**
   * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
   * data and eventually timely documentation completion alerting for staff. For type Survey,
   * scheduleItem id of the session this form is in reference to,
   * used only to connect forms and schedule items in DB when a user is completing a survey about a
   * scheduled session
   */
  scheduleItemId?: any;
  serviceDate?: string;
  /**
   * Allowed for all note types, but required for notes linked to a schedule item.
   * Schedule item attendance status is automatically updated with this value upon note being
   * signed and locked.
   */
  attendanceStatus?: string;
  /**
   * This is an array of user._ids for all users who attended the encounter/scheduled item linked
   * to the form instance. The schedule item is automatically updated with this value upon note
   * being signed and locked
   */
  attended: {
    userId: any;
    _id?: string;
  }[];
  answers: {
    prompt: string;
    questionId: any;
    answers: string[];
    score?: number;
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    followUpResponse?: string;
    _id?: string;
  }[];
  scores: {
    grouping?: string;
    score?: number;
    _id?: string;
  }[];
  /** Totaled up score, according to the assessment type. */
  totalScore?: number;
  status:
    | "Completed"
    | "Dismissed"
    | "In Progress"
    | "Sent To User"
    | "Signed"
    | "Not Started"
    | "Requires Supervisor Action";
  completedDate?: string;
  charmEncounterId?: string;
  signature?: {
    signedById?: string;
    signedDate?: string;
    hash?: string;
  };
  /**
   * This is an array of signatures for users who have signed the form instance.
   * This is used for forms that the users (patient or family member) filled out themselves.
   */
  userSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  /** signatures used for staff with supervisor roles */
  supervisorSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  scoreText?: any;
};
export type PostFormInstancesArgs = {
  userId?: any;
  createdBy?: any;
  formId?: any;
  type?: "Assessment" | "Note" | "Survey";
  form?: {};
  /**
   * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
   * data and eventually timely documentation completion alerting for staff. For type Survey,
   * scheduleItem id of the session this form is in reference to,
   * used only to connect forms and schedule items in DB when a user is completing a survey about a
   * scheduled session
   */
  scheduleItemId?: any;
  serviceDate?: string;
  /**
   * Allowed for all note types, but required for notes linked to a schedule item.
   * Schedule item attendance status is automatically updated with this value upon note being
   * signed and locked.
   */
  attendanceStatus?: string;
  /**
   * This is an array of user._ids for all users who attended the encounter/scheduled item linked
   * to the form instance. The schedule item is automatically updated with this value upon note
   * being signed and locked
   */
  attended?: {
    userId: any;
    _id?: string;
  }[];
  answers?: {
    prompt: string;
    questionId: any;
    answers: string[];
    score?: number;
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    followUpResponse?: string;
    _id?: string;
  }[];
  scores?: {
    grouping?: string;
    score?: number;
    _id?: string;
  }[];
  /** Totaled up score, according to the assessment type. */
  totalScore?: number;
  status?:
    | "Completed"
    | "Dismissed"
    | "In Progress"
    | "Sent To User"
    | "Signed"
    | "Not Started"
    | "Requires Supervisor Action";
  completedDate?: string;
  charmEncounterId?: string;
  signature?: {
    signedById?: string;
    signedDate?: string;
    hash?: string;
  };
  /**
   * This is an array of signatures for users who have signed the form instance.
   * This is used for forms that the users (patient or family member) filled out themselves.
   */
  userSignatures?: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  /** signatures used for staff with supervisor roles */
  supervisorSignatures?: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  scoreText?: any;
};
export type GetFormInstancesRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    createdBy?: any;
    formId: any;
    type: "Assessment" | "Note" | "Survey";
    form: {};
    /**
     * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
     * data and eventually timely documentation completion alerting for staff. For type Survey,
     * scheduleItem id of the session this form is in reference to,
     * used only to connect forms and schedule items in DB when a user is completing a survey about
     * a scheduled session
     */
    scheduleItemId?: any;
    serviceDate?: string;
    /**
     * Allowed for all note types, but required for notes linked to a schedule item.
     * Schedule item attendance status is automatically updated with this value upon note being
     * signed and locked.
     */
    attendanceStatus?: string;
    /**
     * This is an array of user._ids for all users who attended the encounter/scheduled item linked
     * to the form instance. The schedule item is automatically updated with this value upon note
     * being signed and locked
     */
    attended: {
      userId: any;
      _id?: string;
    }[];
    answers: {
      prompt: string;
      questionId: any;
      answers: string[];
      score?: number;
      grouping?: string;
      populateFields?: {
        schema: string;
        key: string;
        _id?: string;
      }[];
      followUpResponse?: string;
      _id?: string;
    }[];
    scores: {
      grouping?: string;
      score?: number;
      _id?: string;
    }[];
    /** Totaled up score, according to the assessment type. */
    totalScore?: number;
    status:
      | "Completed"
      | "Dismissed"
      | "In Progress"
      | "Sent To User"
      | "Signed"
      | "Not Started"
      | "Requires Supervisor Action";
    completedDate?: string;
    charmEncounterId?: string;
    signature?: {
      signedById?: string;
      signedDate?: string;
      hash?: string;
    };
    /**
     * This is an array of signatures for users who have signed the form instance.
     * This is used for forms that the users (patient or family member) filled out themselves.
     */
    userSignatures: {
      signedByUserId?: any;
      signedDate?: string;
      typedName?: string;
      image?: string;
      hash?: string;
      _id?: string;
    }[];
    /** signatures used for staff with supervisor roles */
    supervisorSignatures: {
      signedByUserId?: any;
      signedDate?: string;
      typedName?: string;
      image?: string;
      hash?: string;
      _id?: string;
    }[];
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    scoreText?: any;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFormInstancesArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  type?:
    | ("Assessment" | "Note" | "Survey")
    | {
        $in?: string[];
      };
  formId?:
    | any
    | {
        $in?: any[];
      };
  "answers.1.answers.0"?:
    | any
    | {
        $in?: any[];
      };
  authorType?:
    | any
    | {
        $in?: any[];
      };
  "form.assessmentType"?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFormInstancesByIdRes = /** status 200 Successful read */ {
  userId: any;
  createdBy?: any;
  formId: any;
  type: "Assessment" | "Note" | "Survey";
  form: {};
  /**
   * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
   * data and eventually timely documentation completion alerting for staff. For type Survey,
   * scheduleItem id of the session this form is in reference to,
   * used only to connect forms and schedule items in DB when a user is completing a survey about a
   * scheduled session
   */
  scheduleItemId?: any;
  serviceDate?: string;
  /**
   * Allowed for all note types, but required for notes linked to a schedule item.
   * Schedule item attendance status is automatically updated with this value upon note being
   * signed and locked.
   */
  attendanceStatus?: string;
  /**
   * This is an array of user._ids for all users who attended the encounter/scheduled item linked
   * to the form instance. The schedule item is automatically updated with this value upon note
   * being signed and locked
   */
  attended: {
    userId: any;
    _id?: string;
  }[];
  answers: {
    prompt: string;
    questionId: any;
    answers: string[];
    score?: number;
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    followUpResponse?: string;
    _id?: string;
  }[];
  scores: {
    grouping?: string;
    score?: number;
    _id?: string;
  }[];
  /** Totaled up score, according to the assessment type. */
  totalScore?: number;
  status:
    | "Completed"
    | "Dismissed"
    | "In Progress"
    | "Sent To User"
    | "Signed"
    | "Not Started"
    | "Requires Supervisor Action";
  completedDate?: string;
  charmEncounterId?: string;
  signature?: {
    signedById?: string;
    signedDate?: string;
    hash?: string;
  };
  /**
   * This is an array of signatures for users who have signed the form instance.
   * This is used for forms that the users (patient or family member) filled out themselves.
   */
  userSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  /** signatures used for staff with supervisor roles */
  supervisorSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  scoreText?: any;
};
export type GetFormInstancesByIdArgs = string;
export type PatchFormInstancesByIdRes = /** status 200 Successful update */ {
  userId: any;
  createdBy?: any;
  formId: any;
  type: "Assessment" | "Note" | "Survey";
  form: {};
  /**
   * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
   * data and eventually timely documentation completion alerting for staff. For type Survey,
   * scheduleItem id of the session this form is in reference to,
   * used only to connect forms and schedule items in DB when a user is completing a survey about a
   * scheduled session
   */
  scheduleItemId?: any;
  serviceDate?: string;
  /**
   * Allowed for all note types, but required for notes linked to a schedule item.
   * Schedule item attendance status is automatically updated with this value upon note being
   * signed and locked.
   */
  attendanceStatus?: string;
  /**
   * This is an array of user._ids for all users who attended the encounter/scheduled item linked
   * to the form instance. The schedule item is automatically updated with this value upon note
   * being signed and locked
   */
  attended: {
    userId: any;
    _id?: string;
  }[];
  answers: {
    prompt: string;
    questionId: any;
    answers: string[];
    score?: number;
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    followUpResponse?: string;
    _id?: string;
  }[];
  scores: {
    grouping?: string;
    score?: number;
    _id?: string;
  }[];
  /** Totaled up score, according to the assessment type. */
  totalScore?: number;
  status:
    | "Completed"
    | "Dismissed"
    | "In Progress"
    | "Sent To User"
    | "Signed"
    | "Not Started"
    | "Requires Supervisor Action";
  completedDate?: string;
  charmEncounterId?: string;
  signature?: {
    signedById?: string;
    signedDate?: string;
    hash?: string;
  };
  /**
   * This is an array of signatures for users who have signed the form instance.
   * This is used for forms that the users (patient or family member) filled out themselves.
   */
  userSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  /** signatures used for staff with supervisor roles */
  supervisorSignatures: {
    signedByUserId?: any;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  scoreText?: any;
};
export type PatchFormInstancesByIdArgs = {
  id: string;
  body: {
    userId?: any;
    createdBy?: any;
    formId?: any;
    type?: "Assessment" | "Note" | "Survey";
    form?: {};
    /**
     * For type Assessment for Note, scheduleItem id used for linked schedule items for engagement
     * data and eventually timely documentation completion alerting for staff. For type Survey,
     * scheduleItem id of the session this form is in reference to,
     * used only to connect forms and schedule items in DB when a user is completing a survey about
     * a scheduled session
     */
    scheduleItemId?: any;
    serviceDate?: string;
    /**
     * Allowed for all note types, but required for notes linked to a schedule item.
     * Schedule item attendance status is automatically updated with this value upon note being
     * signed and locked.
     */
    attendanceStatus?: string;
    /**
     * This is an array of user._ids for all users who attended the encounter/scheduled item linked
     * to the form instance. The schedule item is automatically updated with this value upon note
     * being signed and locked
     */
    attended?: {
      userId: any;
      _id?: string;
    }[];
    answers?: {
      prompt: string;
      questionId: any;
      answers: string[];
      score?: number;
      grouping?: string;
      populateFields?: {
        schema: string;
        key: string;
        _id?: string;
      }[];
      followUpResponse?: string;
      _id?: string;
    }[];
    scores?: {
      grouping?: string;
      score?: number;
      _id?: string;
    }[];
    /** Totaled up score, according to the assessment type. */
    totalScore?: number;
    status?:
      | "Completed"
      | "Dismissed"
      | "In Progress"
      | "Sent To User"
      | "Signed"
      | "Not Started"
      | "Requires Supervisor Action";
    completedDate?: string;
    charmEncounterId?: string;
    signature?: {
      signedById?: string;
      signedDate?: string;
      hash?: string;
    };
    /**
     * This is an array of signatures for users who have signed the form instance.
     * This is used for forms that the users (patient or family member) filled out themselves.
     */
    userSignatures?: {
      signedByUserId?: any;
      signedDate?: string;
      typedName?: string;
      image?: string;
      hash?: string;
      _id?: string;
    }[];
    /** signatures used for staff with supervisor roles */
    supervisorSignatures?: {
      signedByUserId?: any;
      signedDate?: string;
      typedName?: string;
      image?: string;
      hash?: string;
      _id?: string;
    }[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    scoreText?: any;
  };
};
export type DeleteFormInstancesByIdRes = /** status 204 Successful delete */ void;
export type DeleteFormInstancesByIdArgs = string;
export type PostFormInstancesPdfByFormInstanceIdRes = unknown;
export type PostFormInstancesPdfByFormInstanceIdArgs = {
  formInstanceId: string;
  body: {};
};
export type PostFormsRes = /** status 201 Successful create */ {
  name: string;
  description?: string;
  createdBy: any;
  type: "Assessment" | "Note" | "Survey";
  assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
  allowScheduleItemLinking: boolean;
  hideAttendanceStatus: boolean;
  questions: {
    prompt: string;
    description?: string;
    type:
      | "CarePlan"
      | "ClinicalStatus"
      | "Date"
      | "Email"
      | "Freeform"
      | "Heading"
      | "Multiselect"
      | "Number"
      | "PhoneNumber"
      | "SafetyPlan"
      | "Select"
      | "Shortform"
      | "SingleCheckbox";
    options: {
      label: string;
      score?: number;
      _id?: string;
    }[];
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    charmKey?:
      | "chiefComplaints"
      | "symptoms"
      | "physicalExamination"
      | "treatmentNotes"
      | "psychotherapyNotes"
      | "assessmentNotes"
      | "presentIllnessHistory"
      | "familySocialHistory"
      | "reviewOfSystems"
      | "pastMedicalHistory";
    template?: string;
    followUpResponseSettings?: {
      allowFollowUpResponse?: boolean;
      prompt?: string;
      qualifyingOptions?: string[];
    };
    /** Only staff users with supervisor roles can answer this question */
    isSupervisorOnly: boolean;
    /** Question must be answered to complete or sign the form */
    isRequired: boolean;
    /** Exclude this question from flagging risk language alerts */
    excludeQuestionRiskLanguageAlerts: boolean;
    /** A key used to identify specific questions in order to populate them with data */
    internalKey?: string;
    _id?: string;
  }[];
  patientVisibility: boolean;
  memberVisibility?: boolean;
  /** Requires the user the form is for (patient or family member) to sign */
  requireUserSignature: boolean;
  /**
   * Requires a staff user have supervisor listed their staffRoles to also signi.e.
   * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
   * or FamilyGuideSupervisor
   */
  requireSupervisorSignature: boolean;
  /** Allows user taking the form (the user the form is about) to complete form instance */
  allowUserCompletion: boolean;
  /** staff roles that will receive updates about the form instance */
  userUpdateRoles?: {
    Therapist?: boolean;
    Psychiatrist?: boolean;
    Guide?: boolean;
    PatientGuide?: boolean;
    FamilyGuide?: boolean;
    TherapistSupervisor?: boolean;
    MemberGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    FamilyGuideSupervisor?: boolean;
    EnrollmentCoordinator?: boolean;
    _id?: string;
  };
  /** A key used to identify this form in the system for backend use */
  internalKey?: string;
  /** Exclude this Form from flagging risk language alerts */
  excludeFormRiskLanguageAlerts: boolean;
  isDraft: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostFormsArgs = {
  name?: string;
  description?: string;
  createdBy?: any;
  type?: "Assessment" | "Note" | "Survey";
  assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
  allowScheduleItemLinking?: boolean;
  hideAttendanceStatus?: boolean;
  questions?: {
    prompt: string;
    description?: string;
    type:
      | "CarePlan"
      | "ClinicalStatus"
      | "Date"
      | "Email"
      | "Freeform"
      | "Heading"
      | "Multiselect"
      | "Number"
      | "PhoneNumber"
      | "SafetyPlan"
      | "Select"
      | "Shortform"
      | "SingleCheckbox";
    options: {
      label: string;
      score?: number;
      _id?: string;
    }[];
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    charmKey?:
      | "chiefComplaints"
      | "symptoms"
      | "physicalExamination"
      | "treatmentNotes"
      | "psychotherapyNotes"
      | "assessmentNotes"
      | "presentIllnessHistory"
      | "familySocialHistory"
      | "reviewOfSystems"
      | "pastMedicalHistory";
    template?: string;
    followUpResponseSettings?: {
      allowFollowUpResponse?: boolean;
      prompt?: string;
      qualifyingOptions?: string[];
    };
    /** Only staff users with supervisor roles can answer this question */
    isSupervisorOnly: boolean;
    /** Question must be answered to complete or sign the form */
    isRequired: boolean;
    /** Exclude this question from flagging risk language alerts */
    excludeQuestionRiskLanguageAlerts: boolean;
    /** A key used to identify specific questions in order to populate them with data */
    internalKey?: string;
    _id?: string;
  }[];
  patientVisibility?: boolean;
  memberVisibility?: boolean;
  /** Requires the user the form is for (patient or family member) to sign */
  requireUserSignature?: boolean;
  /**
   * Requires a staff user have supervisor listed their staffRoles to also signi.e.
   * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
   * or FamilyGuideSupervisor
   */
  requireSupervisorSignature?: boolean;
  /** Allows user taking the form (the user the form is about) to complete form instance */
  allowUserCompletion?: boolean;
  /** staff roles that will receive updates about the form instance */
  userUpdateRoles?: {
    Therapist?: boolean;
    Psychiatrist?: boolean;
    Guide?: boolean;
    PatientGuide?: boolean;
    FamilyGuide?: boolean;
    TherapistSupervisor?: boolean;
    MemberGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    FamilyGuideSupervisor?: boolean;
    EnrollmentCoordinator?: boolean;
    _id?: string;
  };
  /** A key used to identify this form in the system for backend use */
  internalKey?: string;
  /** Exclude this Form from flagging risk language alerts */
  excludeFormRiskLanguageAlerts?: boolean;
  isDraft?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFormsRes = /** status 200 Successful list */ {
  data?: {
    name: string;
    description?: string;
    createdBy: any;
    type: "Assessment" | "Note" | "Survey";
    assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
    allowScheduleItemLinking: boolean;
    hideAttendanceStatus: boolean;
    questions: {
      prompt: string;
      description?: string;
      type:
        | "CarePlan"
        | "ClinicalStatus"
        | "Date"
        | "Email"
        | "Freeform"
        | "Heading"
        | "Multiselect"
        | "Number"
        | "PhoneNumber"
        | "SafetyPlan"
        | "Select"
        | "Shortform"
        | "SingleCheckbox";
      options: {
        label: string;
        score?: number;
        _id?: string;
      }[];
      grouping?: string;
      populateFields?: {
        schema: string;
        key: string;
        _id?: string;
      }[];
      charmKey?:
        | "chiefComplaints"
        | "symptoms"
        | "physicalExamination"
        | "treatmentNotes"
        | "psychotherapyNotes"
        | "assessmentNotes"
        | "presentIllnessHistory"
        | "familySocialHistory"
        | "reviewOfSystems"
        | "pastMedicalHistory";
      template?: string;
      followUpResponseSettings?: {
        allowFollowUpResponse?: boolean;
        prompt?: string;
        qualifyingOptions?: string[];
      };
      /** Only staff users with supervisor roles can answer this question */
      isSupervisorOnly: boolean;
      /** Question must be answered to complete or sign the form */
      isRequired: boolean;
      /** Exclude this question from flagging risk language alerts */
      excludeQuestionRiskLanguageAlerts: boolean;
      /** A key used to identify specific questions in order to populate them with data */
      internalKey?: string;
      _id?: string;
    }[];
    patientVisibility: boolean;
    memberVisibility?: boolean;
    /** Requires the user the form is for (patient or family member) to sign */
    requireUserSignature: boolean;
    /**
     * Requires a staff user have supervisor listed their staffRoles to also signi.e.
     * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
     * or FamilyGuideSupervisor
     */
    requireSupervisorSignature: boolean;
    /** Allows user taking the form (the user the form is about) to complete form instance */
    allowUserCompletion: boolean;
    /** staff roles that will receive updates about the form instance */
    userUpdateRoles?: {
      Therapist?: boolean;
      Psychiatrist?: boolean;
      Guide?: boolean;
      PatientGuide?: boolean;
      FamilyGuide?: boolean;
      TherapistSupervisor?: boolean;
      MemberGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      FamilyGuideSupervisor?: boolean;
      EnrollmentCoordinator?: boolean;
      _id?: string;
    };
    /** A key used to identify this form in the system for backend use */
    internalKey?: string;
    /** Exclude this Form from flagging risk language alerts */
    excludeFormRiskLanguageAlerts: boolean;
    isDraft: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetFormsArgs = {
  _id?: {
    $in?: string[];
  };
  type?:
    | ("Assessment" | "Note" | "Survey")
    | {
        $in?: string[];
      };
  name?:
    | string
    | {
        $in?: string[];
      };
  deleted?:
    | boolean
    | {
        $in?: boolean[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetFormsByIdRes = /** status 200 Successful read */ {
  name: string;
  description?: string;
  createdBy: any;
  type: "Assessment" | "Note" | "Survey";
  assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
  allowScheduleItemLinking: boolean;
  hideAttendanceStatus: boolean;
  questions: {
    prompt: string;
    description?: string;
    type:
      | "CarePlan"
      | "ClinicalStatus"
      | "Date"
      | "Email"
      | "Freeform"
      | "Heading"
      | "Multiselect"
      | "Number"
      | "PhoneNumber"
      | "SafetyPlan"
      | "Select"
      | "Shortform"
      | "SingleCheckbox";
    options: {
      label: string;
      score?: number;
      _id?: string;
    }[];
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    charmKey?:
      | "chiefComplaints"
      | "symptoms"
      | "physicalExamination"
      | "treatmentNotes"
      | "psychotherapyNotes"
      | "assessmentNotes"
      | "presentIllnessHistory"
      | "familySocialHistory"
      | "reviewOfSystems"
      | "pastMedicalHistory";
    template?: string;
    followUpResponseSettings?: {
      allowFollowUpResponse?: boolean;
      prompt?: string;
      qualifyingOptions?: string[];
    };
    /** Only staff users with supervisor roles can answer this question */
    isSupervisorOnly: boolean;
    /** Question must be answered to complete or sign the form */
    isRequired: boolean;
    /** Exclude this question from flagging risk language alerts */
    excludeQuestionRiskLanguageAlerts: boolean;
    /** A key used to identify specific questions in order to populate them with data */
    internalKey?: string;
    _id?: string;
  }[];
  patientVisibility: boolean;
  memberVisibility?: boolean;
  /** Requires the user the form is for (patient or family member) to sign */
  requireUserSignature: boolean;
  /**
   * Requires a staff user have supervisor listed their staffRoles to also signi.e.
   * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
   * or FamilyGuideSupervisor
   */
  requireSupervisorSignature: boolean;
  /** Allows user taking the form (the user the form is about) to complete form instance */
  allowUserCompletion: boolean;
  /** staff roles that will receive updates about the form instance */
  userUpdateRoles?: {
    Therapist?: boolean;
    Psychiatrist?: boolean;
    Guide?: boolean;
    PatientGuide?: boolean;
    FamilyGuide?: boolean;
    TherapistSupervisor?: boolean;
    MemberGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    FamilyGuideSupervisor?: boolean;
    EnrollmentCoordinator?: boolean;
    _id?: string;
  };
  /** A key used to identify this form in the system for backend use */
  internalKey?: string;
  /** Exclude this Form from flagging risk language alerts */
  excludeFormRiskLanguageAlerts: boolean;
  isDraft: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetFormsByIdArgs = string;
export type PatchFormsByIdRes = /** status 200 Successful update */ {
  name: string;
  description?: string;
  createdBy: any;
  type: "Assessment" | "Note" | "Survey";
  assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
  allowScheduleItemLinking: boolean;
  hideAttendanceStatus: boolean;
  questions: {
    prompt: string;
    description?: string;
    type:
      | "CarePlan"
      | "ClinicalStatus"
      | "Date"
      | "Email"
      | "Freeform"
      | "Heading"
      | "Multiselect"
      | "Number"
      | "PhoneNumber"
      | "SafetyPlan"
      | "Select"
      | "Shortform"
      | "SingleCheckbox";
    options: {
      label: string;
      score?: number;
      _id?: string;
    }[];
    grouping?: string;
    populateFields?: {
      schema: string;
      key: string;
      _id?: string;
    }[];
    charmKey?:
      | "chiefComplaints"
      | "symptoms"
      | "physicalExamination"
      | "treatmentNotes"
      | "psychotherapyNotes"
      | "assessmentNotes"
      | "presentIllnessHistory"
      | "familySocialHistory"
      | "reviewOfSystems"
      | "pastMedicalHistory";
    template?: string;
    followUpResponseSettings?: {
      allowFollowUpResponse?: boolean;
      prompt?: string;
      qualifyingOptions?: string[];
    };
    /** Only staff users with supervisor roles can answer this question */
    isSupervisorOnly: boolean;
    /** Question must be answered to complete or sign the form */
    isRequired: boolean;
    /** Exclude this question from flagging risk language alerts */
    excludeQuestionRiskLanguageAlerts: boolean;
    /** A key used to identify specific questions in order to populate them with data */
    internalKey?: string;
    _id?: string;
  }[];
  patientVisibility: boolean;
  memberVisibility?: boolean;
  /** Requires the user the form is for (patient or family member) to sign */
  requireUserSignature: boolean;
  /**
   * Requires a staff user have supervisor listed their staffRoles to also signi.e.
   * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
   * or FamilyGuideSupervisor
   */
  requireSupervisorSignature: boolean;
  /** Allows user taking the form (the user the form is about) to complete form instance */
  allowUserCompletion: boolean;
  /** staff roles that will receive updates about the form instance */
  userUpdateRoles?: {
    Therapist?: boolean;
    Psychiatrist?: boolean;
    Guide?: boolean;
    PatientGuide?: boolean;
    FamilyGuide?: boolean;
    TherapistSupervisor?: boolean;
    MemberGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    FamilyGuideSupervisor?: boolean;
    EnrollmentCoordinator?: boolean;
    _id?: string;
  };
  /** A key used to identify this form in the system for backend use */
  internalKey?: string;
  /** Exclude this Form from flagging risk language alerts */
  excludeFormRiskLanguageAlerts: boolean;
  isDraft: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchFormsByIdArgs = {
  id: string;
  body: {
    name?: string;
    description?: string;
    createdBy?: any;
    type?: "Assessment" | "Note" | "Survey";
    assessmentType?: "GAD-7" | "PHQ-9" | "Flourishing" | "CSSRS Screen" | "PEARLS";
    allowScheduleItemLinking?: boolean;
    hideAttendanceStatus?: boolean;
    questions?: {
      prompt: string;
      description?: string;
      type:
        | "CarePlan"
        | "ClinicalStatus"
        | "Date"
        | "Email"
        | "Freeform"
        | "Heading"
        | "Multiselect"
        | "Number"
        | "PhoneNumber"
        | "SafetyPlan"
        | "Select"
        | "Shortform"
        | "SingleCheckbox";
      options: {
        label: string;
        score?: number;
        _id?: string;
      }[];
      grouping?: string;
      populateFields?: {
        schema: string;
        key: string;
        _id?: string;
      }[];
      charmKey?:
        | "chiefComplaints"
        | "symptoms"
        | "physicalExamination"
        | "treatmentNotes"
        | "psychotherapyNotes"
        | "assessmentNotes"
        | "presentIllnessHistory"
        | "familySocialHistory"
        | "reviewOfSystems"
        | "pastMedicalHistory";
      template?: string;
      followUpResponseSettings?: {
        allowFollowUpResponse?: boolean;
        prompt?: string;
        qualifyingOptions?: string[];
      };
      /** Only staff users with supervisor roles can answer this question */
      isSupervisorOnly: boolean;
      /** Question must be answered to complete or sign the form */
      isRequired: boolean;
      /** Exclude this question from flagging risk language alerts */
      excludeQuestionRiskLanguageAlerts: boolean;
      /** A key used to identify specific questions in order to populate them with data */
      internalKey?: string;
      _id?: string;
    }[];
    patientVisibility?: boolean;
    memberVisibility?: boolean;
    /** Requires the user the form is for (patient or family member) to sign */
    requireUserSignature?: boolean;
    /**
     * Requires a staff user have supervisor listed their staffRoles to also signi.e.
     * TherapistSupervisor, MemberGuideSupervisor, PatientGuideSupervisor,
     * or FamilyGuideSupervisor
     */
    requireSupervisorSignature?: boolean;
    /** Allows user taking the form (the user the form is about) to complete form instance */
    allowUserCompletion?: boolean;
    /** staff roles that will receive updates about the form instance */
    userUpdateRoles?: {
      Therapist?: boolean;
      Psychiatrist?: boolean;
      Guide?: boolean;
      PatientGuide?: boolean;
      FamilyGuide?: boolean;
      TherapistSupervisor?: boolean;
      MemberGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      FamilyGuideSupervisor?: boolean;
      EnrollmentCoordinator?: boolean;
      _id?: string;
    };
    /** A key used to identify this form in the system for backend use */
    internalKey?: string;
    /** Exclude this Form from flagging risk language alerts */
    excludeFormRiskLanguageAlerts?: boolean;
    isDraft?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteFormsByIdRes = /** status 204 Successful delete */ void;
export type DeleteFormsByIdArgs = string;
export type PostGptHistoriesRes = /** status 201 Successful create */ {
  title?: string;
  userId: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  prompts: {
    text: string;
    type: "user" | "assistant" | "system";
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostGptHistoriesArgs = {
  title?: string;
  prompts?: {
    text: string;
    type: "user" | "assistant" | "system";
    _id?: string;
  }[];
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetGptHistoriesRes = /** status 200 Successful list */ {
  data?: {
    title?: string;
    userId: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    prompts: {
      text: string;
      type: "user" | "assistant" | "system";
      _id?: string;
    }[];
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetGptHistoriesArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetGptHistoriesByIdRes = /** status 200 Successful read */ {
  title?: string;
  userId: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  prompts: {
    text: string;
    type: "user" | "assistant" | "system";
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetGptHistoriesByIdArgs = string;
export type PatchGptHistoriesByIdRes = /** status 200 Successful update */ {
  title?: string;
  userId: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  prompts: {
    text: string;
    type: "user" | "assistant" | "system";
    _id?: string;
  }[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchGptHistoriesByIdArgs = {
  id: string;
  body: {
    title?: string;
    prompts?: {
      text: string;
      type: "user" | "assistant" | "system";
      _id?: string;
    }[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteGptHistoriesByIdRes = /** status 204 Successful delete */ void;
export type DeleteGptHistoriesByIdArgs = string;
export type PostGuidingHoursRes = /** status 201 Successful create */ {
  /** Weekly guiding hour time range, in Eastern Time. */
  time: {
    /** Start hour, 24 hour format */
    startHour?: number;
    /** End hour, 24 hour format */
    endHour?: number;
    /** Start minute, 24 hour format */
    startMin?: number;
    /** End minute, 24 hour format */
    endMin?: number;
    /** Day of the week for the schedule */
    day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  };
  /** Whether the guiding hour is for patients or families */
  type: "PatientGuide" | "FamilyGuide";
  supervisor: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  clinicalLeader: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostGuidingHoursArgs = {
  /** Weekly guiding hour time range, in Eastern Time. */
  time?: {
    /** Start hour, 24 hour format */
    startHour?: number;
    /** End hour, 24 hour format */
    endHour?: number;
    /** Start minute, 24 hour format */
    startMin?: number;
    /** End minute, 24 hour format */
    endMin?: number;
    /** Day of the week for the schedule */
    day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  };
  /** Whether the guiding hour is for patients or families */
  type?: "PatientGuide" | "FamilyGuide";
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetGuidingHoursRes = /** status 200 Successful list */ {
  data?: {
    /** Weekly guiding hour time range, in Eastern Time. */
    time: {
      /** Start hour, 24 hour format */
      startHour?: number;
      /** End hour, 24 hour format */
      endHour?: number;
      /** Start minute, 24 hour format */
      startMin?: number;
      /** End minute, 24 hour format */
      endMin?: number;
      /** Day of the week for the schedule */
      day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    };
    /** Whether the guiding hour is for patients or families */
    type: "PatientGuide" | "FamilyGuide";
    supervisor: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    clinicalLeader: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetGuidingHoursArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetGuidingHoursByIdRes = /** status 200 Successful read */ {
  /** Weekly guiding hour time range, in Eastern Time. */
  time: {
    /** Start hour, 24 hour format */
    startHour?: number;
    /** End hour, 24 hour format */
    endHour?: number;
    /** Start minute, 24 hour format */
    startMin?: number;
    /** End minute, 24 hour format */
    endMin?: number;
    /** Day of the week for the schedule */
    day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  };
  /** Whether the guiding hour is for patients or families */
  type: "PatientGuide" | "FamilyGuide";
  supervisor: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  clinicalLeader: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetGuidingHoursByIdArgs = string;
export type PatchGuidingHoursByIdRes = /** status 200 Successful update */ {
  /** Weekly guiding hour time range, in Eastern Time. */
  time: {
    /** Start hour, 24 hour format */
    startHour?: number;
    /** End hour, 24 hour format */
    endHour?: number;
    /** Start minute, 24 hour format */
    startMin?: number;
    /** End minute, 24 hour format */
    endMin?: number;
    /** Day of the week for the schedule */
    day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  };
  /** Whether the guiding hour is for patients or families */
  type: "PatientGuide" | "FamilyGuide";
  supervisor: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  clinicalLeader: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchGuidingHoursByIdArgs = {
  id: string;
  body: {
    /** Weekly guiding hour time range, in Eastern Time. */
    time?: {
      /** Start hour, 24 hour format */
      startHour?: number;
      /** End hour, 24 hour format */
      endHour?: number;
      /** Start minute, 24 hour format */
      startMin?: number;
      /** End minute, 24 hour format */
      endMin?: number;
      /** Day of the week for the schedule */
      day?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    };
    /** Whether the guiding hour is for patients or families */
    type?: "PatientGuide" | "FamilyGuide";
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteGuidingHoursByIdRes = /** status 204 Successful delete */ void;
export type DeleteGuidingHoursByIdArgs = string;
export type PostHealthEventInstancesRes = /** status 201 Successful create */ {
  event: {
    name?: string;
    description?: string;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  severity: string;
  details?: string;
  userId: any;
  startDate: string;
  endDate?: string;
  isOngoing?: boolean;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostHealthEventInstancesArgs = {
  event?: {
    name?: string;
    description?: string;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  severity?: string;
  details?: string;
  userId?: any;
  startDate?: string;
  endDate?: string;
  isOngoing?: boolean;
  createdBy?: any;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetHealthEventInstancesRes = /** status 200 Successful list */ {
  data?: {
    event: {
      name?: string;
      description?: string;
      createdBy?: any;
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    severity: string;
    details?: string;
    userId: any;
    startDate: string;
    endDate?: string;
    isOngoing?: boolean;
    createdBy: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetHealthEventInstancesArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetHealthEventInstancesByIdRes = /** status 200 Successful read */ {
  event: {
    name?: string;
    description?: string;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  severity: string;
  details?: string;
  userId: any;
  startDate: string;
  endDate?: string;
  isOngoing?: boolean;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetHealthEventInstancesByIdArgs = string;
export type PatchHealthEventInstancesByIdRes = /** status 200 Successful update */ {
  event: {
    name?: string;
    description?: string;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
  severity: string;
  details?: string;
  userId: any;
  startDate: string;
  endDate?: string;
  isOngoing?: boolean;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchHealthEventInstancesByIdArgs = {
  id: string;
  body: {
    event?: {
      name?: string;
      description?: string;
      createdBy?: any;
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    };
    severity?: string;
    details?: string;
    userId?: any;
    startDate?: string;
    endDate?: string;
    isOngoing?: boolean;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteHealthEventInstancesByIdRes = /** status 204 Successful delete */ void;
export type DeleteHealthEventInstancesByIdArgs = string;
export type PostHealthEventsRes = /** status 201 Successful create */ {
  name: string;
  description?: string;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostHealthEventsArgs = {
  name?: string;
  description?: string;
  createdBy?: any;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetHealthEventsRes = /** status 200 Successful list */ {
  data?: {
    name: string;
    description?: string;
    createdBy: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetHealthEventsArgs = {
  _id?: {
    $in?: string[];
  };
  type?:
    | any
    | {
        $in?: any[];
      };
  name?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetHealthEventsByIdRes = /** status 200 Successful read */ {
  name: string;
  description?: string;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetHealthEventsByIdArgs = string;
export type PatchHealthEventsByIdRes = /** status 200 Successful update */ {
  name: string;
  description?: string;
  createdBy: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchHealthEventsByIdArgs = {
  id: string;
  body: {
    name?: string;
    description?: string;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteHealthEventsByIdRes = /** status 204 Successful delete */ void;
export type DeleteHealthEventsByIdArgs = string;
export type PostInsurancePlansRes = /** status 201 Successful create */ {
  createdBy: string;
  name: string;
  billable?: boolean;
  acceptingReferrals?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostInsurancePlansArgs = {
  createdBy?: string;
  name?: string;
  billable?: boolean;
  acceptingReferrals?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetInsurancePlansRes = /** status 200 Successful list */ {
  data?: {
    createdBy: string;
    name: string;
    billable?: boolean;
    acceptingReferrals?: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetInsurancePlansArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetInsurancePlansByIdRes = /** status 200 Successful read */ {
  createdBy: string;
  name: string;
  billable?: boolean;
  acceptingReferrals?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetInsurancePlansByIdArgs = string;
export type PatchInsurancePlansByIdRes = /** status 200 Successful update */ {
  createdBy: string;
  name: string;
  billable?: boolean;
  acceptingReferrals?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchInsurancePlansByIdArgs = {
  id: string;
  body: {
    createdBy?: string;
    name?: string;
    billable?: boolean;
    acceptingReferrals?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteInsurancePlansByIdRes = /** status 204 Successful delete */ void;
export type DeleteInsurancePlansByIdArgs = string;
export type PostMessagesRes = /** status 201 Successful create */ {
  text?: string;
  sentAsSms?: boolean;
  notifications?: {
    message?: string;
    title?: string;
    to?: any;
    from?: any;
    sendAsPush?: boolean;
    sendAsSms?: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses?: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses?: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus?: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  from?: LimitedUser;
  conversationId: any;
  taggedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  roomTag: boolean;
  flaggedBySystemAsConcerning?: boolean;
  messageUUID?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostMessagesArgs = {
  text?: string;
  sentAsSms?: boolean;
  notifications?: {
    message?: string;
    title?: string;
    to?: any;
    from?: any;
    sendAsPush?: boolean;
    sendAsSms?: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses?: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses?: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus?: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  from?: LimitedUser;
  conversationId?: any;
  taggedUsers?: {
    userId: LimitedUser;
    _id?: string;
  }[];
  roomTag?: boolean;
  flaggedBySystemAsConcerning?: boolean;
  messageUUID?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetMessagesRes = /** status 200 Successful list */ {
  data?: {
    text?: string;
    sentAsSms?: boolean;
    notifications?: {
      message?: string;
      title?: string;
      to?: any;
      from?: any;
      sendAsPush?: boolean;
      sendAsSms?: boolean;
      scheduledDeliveryTime?: string;
      sourceModel?:
        | "AlertInstance"
        | "Conversation"
        | "CriticalEvent"
        | "Message"
        | "ScheduleItem"
        | "FitbitSyncNotification"
        | "FitbitBatteryLowNotification"
        | "Voicemail"
        | "RecurringScheduleItem"
        | "UserUpdate";
      sourceId?: any;
      pushStatuses?: {
        userId?: any;
        ticketStatus?: "ok" | "error";
        ticketId?: string;
        ticketErrorMessage?: string;
        ticketErrorType?:
          | "DeviceNotRegistered"
          | "InvalidCredentials"
          | "MessageTooBig"
          | "MessageRateExceeded";
        receiptStatus?: "ok" | "error";
        receiptErrorMessage?: string;
        receiptErrorDetails?: string;
        _id?: string;
      }[];
      webPushStatuses?: {
        userId?: any;
        status?: "ok" | "error";
        _id?: string;
      }[];
      smsStatus?: {
        fromPhoneNumber?: string;
        toPhoneNumber?: string;
        twilioMessageSid?: string;
        twilioStatuses?: {
          accountSid?: string;
          apiVersion?: string;
          body?: string;
          mediaContentType0?: string;
          mediaUrl0?: string;
          dateCreated?: string;
          dateSent?: string;
          dateUpdated?: string;
          direction?: string;
          errorCode?: number;
          errorMessage?: string;
          from?: string;
          messagingServiceSid?: string;
          sid?: string;
          status?: string;
          to?: string;
          uri?: string;
          _id?: string;
        }[];
        _id?: string;
      };
      taskId?: string;
      direction?: "Inbound" | "Outbound";
      status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
      deliveryErrors?: string[];
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    }[];
    from?: LimitedUser;
    conversationId: any;
    taggedUsers: {
      userId: LimitedUser;
      _id?: string;
    }[];
    roomTag: boolean;
    flaggedBySystemAsConcerning?: boolean;
    messageUUID?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetMessagesArgs = {
  _id?: {
    $in?: string[];
  };
  conversationId?:
    | any
    | {
        $in?: any[];
      };
  from?:
    | any
    | {
        $in?: any[];
      };
  created?: {
    $gte?: string;
    $gt?: string;
    $lte?: string;
    $lt?: string;
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetMessagesByIdRes = /** status 200 Successful read */ {
  text?: string;
  sentAsSms?: boolean;
  notifications?: {
    message?: string;
    title?: string;
    to?: any;
    from?: any;
    sendAsPush?: boolean;
    sendAsSms?: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses?: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses?: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus?: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  from?: LimitedUser;
  conversationId: any;
  taggedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  roomTag: boolean;
  flaggedBySystemAsConcerning?: boolean;
  messageUUID?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetMessagesByIdArgs = string;
export type PatchMessagesByIdRes = /** status 200 Successful update */ {
  text?: string;
  sentAsSms?: boolean;
  notifications?: {
    message?: string;
    title?: string;
    to?: any;
    from?: any;
    sendAsPush?: boolean;
    sendAsSms?: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses?: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses?: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus?: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  from?: LimitedUser;
  conversationId: any;
  taggedUsers: {
    userId: LimitedUser;
    _id?: string;
  }[];
  roomTag: boolean;
  flaggedBySystemAsConcerning?: boolean;
  messageUUID?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchMessagesByIdArgs = {
  id: string;
  body: {
    text?: string;
    sentAsSms?: boolean;
    notifications?: {
      message?: string;
      title?: string;
      to?: any;
      from?: any;
      sendAsPush?: boolean;
      sendAsSms?: boolean;
      scheduledDeliveryTime?: string;
      sourceModel?:
        | "AlertInstance"
        | "Conversation"
        | "CriticalEvent"
        | "Message"
        | "ScheduleItem"
        | "FitbitSyncNotification"
        | "FitbitBatteryLowNotification"
        | "Voicemail"
        | "RecurringScheduleItem"
        | "UserUpdate";
      sourceId?: any;
      pushStatuses?: {
        userId?: any;
        ticketStatus?: "ok" | "error";
        ticketId?: string;
        ticketErrorMessage?: string;
        ticketErrorType?:
          | "DeviceNotRegistered"
          | "InvalidCredentials"
          | "MessageTooBig"
          | "MessageRateExceeded";
        receiptStatus?: "ok" | "error";
        receiptErrorMessage?: string;
        receiptErrorDetails?: string;
        _id?: string;
      }[];
      webPushStatuses?: {
        userId?: any;
        status?: "ok" | "error";
        _id?: string;
      }[];
      smsStatus?: {
        fromPhoneNumber?: string;
        toPhoneNumber?: string;
        twilioMessageSid?: string;
        twilioStatuses?: {
          accountSid?: string;
          apiVersion?: string;
          body?: string;
          mediaContentType0?: string;
          mediaUrl0?: string;
          dateCreated?: string;
          dateSent?: string;
          dateUpdated?: string;
          direction?: string;
          errorCode?: number;
          errorMessage?: string;
          from?: string;
          messagingServiceSid?: string;
          sid?: string;
          status?: string;
          to?: string;
          uri?: string;
          _id?: string;
        }[];
        _id?: string;
      };
      taskId?: string;
      direction?: "Inbound" | "Outbound";
      status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
      deliveryErrors?: string[];
      _id?: string;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
    }[];
    from?: LimitedUser;
    conversationId?: any;
    taggedUsers?: {
      userId: LimitedUser;
      _id?: string;
    }[];
    roomTag?: boolean;
    flaggedBySystemAsConcerning?: boolean;
    messageUUID?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteMessagesByIdRes = /** status 204 Successful delete */ void;
export type DeleteMessagesByIdArgs = string;
export type PostNotificationsRes = /** status 201 Successful create */ {
  message: string;
  title?: string;
  to: any;
  from?: any;
  sendAsPush: boolean;
  sendAsSms: boolean;
  scheduledDeliveryTime?: string;
  sourceModel?:
    | "AlertInstance"
    | "Conversation"
    | "CriticalEvent"
    | "Message"
    | "ScheduleItem"
    | "FitbitSyncNotification"
    | "FitbitBatteryLowNotification"
    | "Voicemail"
    | "RecurringScheduleItem"
    | "UserUpdate";
  sourceId?: any;
  pushStatuses: {
    userId?: any;
    ticketStatus?: "ok" | "error";
    ticketId?: string;
    ticketErrorMessage?: string;
    ticketErrorType?:
      | "DeviceNotRegistered"
      | "InvalidCredentials"
      | "MessageTooBig"
      | "MessageRateExceeded";
    receiptStatus?: "ok" | "error";
    receiptErrorMessage?: string;
    receiptErrorDetails?: string;
    _id?: string;
  }[];
  webPushStatuses: {
    userId?: any;
    status?: "ok" | "error";
    _id?: string;
  }[];
  smsStatus: {
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
    twilioMessageSid?: string;
    twilioStatuses?: {
      accountSid?: string;
      apiVersion?: string;
      body?: string;
      mediaContentType0?: string;
      mediaUrl0?: string;
      dateCreated?: string;
      dateSent?: string;
      dateUpdated?: string;
      direction?: string;
      errorCode?: number;
      errorMessage?: string;
      from?: string;
      messagingServiceSid?: string;
      sid?: string;
      status?: string;
      to?: string;
      uri?: string;
      _id?: string;
    }[];
    _id?: string;
  };
  taskId?: string;
  direction?: "Inbound" | "Outbound";
  status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostNotificationsArgs = {
  message?: string;
  title?: string;
  to?: any;
  from?: any;
  sendAsPush?: boolean;
  sendAsSms?: boolean;
  scheduledDeliveryTime?: string;
  sourceModel?:
    | "AlertInstance"
    | "Conversation"
    | "CriticalEvent"
    | "Message"
    | "ScheduleItem"
    | "FitbitSyncNotification"
    | "FitbitBatteryLowNotification"
    | "Voicemail"
    | "RecurringScheduleItem"
    | "UserUpdate";
  sourceId?: any;
  pushStatuses?: {
    userId?: any;
    ticketStatus?: "ok" | "error";
    ticketId?: string;
    ticketErrorMessage?: string;
    ticketErrorType?:
      | "DeviceNotRegistered"
      | "InvalidCredentials"
      | "MessageTooBig"
      | "MessageRateExceeded";
    receiptStatus?: "ok" | "error";
    receiptErrorMessage?: string;
    receiptErrorDetails?: string;
    _id?: string;
  }[];
  webPushStatuses?: {
    userId?: any;
    status?: "ok" | "error";
    _id?: string;
  }[];
  smsStatus?: {
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
    twilioMessageSid?: string;
    twilioStatuses?: {
      accountSid?: string;
      apiVersion?: string;
      body?: string;
      mediaContentType0?: string;
      mediaUrl0?: string;
      dateCreated?: string;
      dateSent?: string;
      dateUpdated?: string;
      direction?: string;
      errorCode?: number;
      errorMessage?: string;
      from?: string;
      messagingServiceSid?: string;
      sid?: string;
      status?: string;
      to?: string;
      uri?: string;
      _id?: string;
    }[];
    _id?: string;
  };
  taskId?: string;
  direction?: "Inbound" | "Outbound";
  status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetNotificationsRes = /** status 200 Successful list */ {
  data?: {
    message: string;
    title?: string;
    to: any;
    from?: any;
    sendAsPush: boolean;
    sendAsSms: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetNotificationsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetNotificationsByIdRes = /** status 200 Successful read */ {
  message: string;
  title?: string;
  to: any;
  from?: any;
  sendAsPush: boolean;
  sendAsSms: boolean;
  scheduledDeliveryTime?: string;
  sourceModel?:
    | "AlertInstance"
    | "Conversation"
    | "CriticalEvent"
    | "Message"
    | "ScheduleItem"
    | "FitbitSyncNotification"
    | "FitbitBatteryLowNotification"
    | "Voicemail"
    | "RecurringScheduleItem"
    | "UserUpdate";
  sourceId?: any;
  pushStatuses: {
    userId?: any;
    ticketStatus?: "ok" | "error";
    ticketId?: string;
    ticketErrorMessage?: string;
    ticketErrorType?:
      | "DeviceNotRegistered"
      | "InvalidCredentials"
      | "MessageTooBig"
      | "MessageRateExceeded";
    receiptStatus?: "ok" | "error";
    receiptErrorMessage?: string;
    receiptErrorDetails?: string;
    _id?: string;
  }[];
  webPushStatuses: {
    userId?: any;
    status?: "ok" | "error";
    _id?: string;
  }[];
  smsStatus: {
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
    twilioMessageSid?: string;
    twilioStatuses?: {
      accountSid?: string;
      apiVersion?: string;
      body?: string;
      mediaContentType0?: string;
      mediaUrl0?: string;
      dateCreated?: string;
      dateSent?: string;
      dateUpdated?: string;
      direction?: string;
      errorCode?: number;
      errorMessage?: string;
      from?: string;
      messagingServiceSid?: string;
      sid?: string;
      status?: string;
      to?: string;
      uri?: string;
      _id?: string;
    }[];
    _id?: string;
  };
  taskId?: string;
  direction?: "Inbound" | "Outbound";
  status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetNotificationsByIdArgs = string;
export type PatchNotificationsByIdRes = /** status 200 Successful update */ {
  message: string;
  title?: string;
  to: any;
  from?: any;
  sendAsPush: boolean;
  sendAsSms: boolean;
  scheduledDeliveryTime?: string;
  sourceModel?:
    | "AlertInstance"
    | "Conversation"
    | "CriticalEvent"
    | "Message"
    | "ScheduleItem"
    | "FitbitSyncNotification"
    | "FitbitBatteryLowNotification"
    | "Voicemail"
    | "RecurringScheduleItem"
    | "UserUpdate";
  sourceId?: any;
  pushStatuses: {
    userId?: any;
    ticketStatus?: "ok" | "error";
    ticketId?: string;
    ticketErrorMessage?: string;
    ticketErrorType?:
      | "DeviceNotRegistered"
      | "InvalidCredentials"
      | "MessageTooBig"
      | "MessageRateExceeded";
    receiptStatus?: "ok" | "error";
    receiptErrorMessage?: string;
    receiptErrorDetails?: string;
    _id?: string;
  }[];
  webPushStatuses: {
    userId?: any;
    status?: "ok" | "error";
    _id?: string;
  }[];
  smsStatus: {
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
    twilioMessageSid?: string;
    twilioStatuses?: {
      accountSid?: string;
      apiVersion?: string;
      body?: string;
      mediaContentType0?: string;
      mediaUrl0?: string;
      dateCreated?: string;
      dateSent?: string;
      dateUpdated?: string;
      direction?: string;
      errorCode?: number;
      errorMessage?: string;
      from?: string;
      messagingServiceSid?: string;
      sid?: string;
      status?: string;
      to?: string;
      uri?: string;
      _id?: string;
    }[];
    _id?: string;
  };
  taskId?: string;
  direction?: "Inbound" | "Outbound";
  status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchNotificationsByIdArgs = {
  id: string;
  body: {
    message?: string;
    title?: string;
    to?: any;
    from?: any;
    sendAsPush?: boolean;
    sendAsSms?: boolean;
    scheduledDeliveryTime?: string;
    sourceModel?:
      | "AlertInstance"
      | "Conversation"
      | "CriticalEvent"
      | "Message"
      | "ScheduleItem"
      | "FitbitSyncNotification"
      | "FitbitBatteryLowNotification"
      | "Voicemail"
      | "RecurringScheduleItem"
      | "UserUpdate";
    sourceId?: any;
    pushStatuses?: {
      userId?: any;
      ticketStatus?: "ok" | "error";
      ticketId?: string;
      ticketErrorMessage?: string;
      ticketErrorType?:
        | "DeviceNotRegistered"
        | "InvalidCredentials"
        | "MessageTooBig"
        | "MessageRateExceeded";
      receiptStatus?: "ok" | "error";
      receiptErrorMessage?: string;
      receiptErrorDetails?: string;
      _id?: string;
    }[];
    webPushStatuses?: {
      userId?: any;
      status?: "ok" | "error";
      _id?: string;
    }[];
    smsStatus?: {
      fromPhoneNumber?: string;
      toPhoneNumber?: string;
      twilioMessageSid?: string;
      twilioStatuses?: {
        accountSid?: string;
        apiVersion?: string;
        body?: string;
        mediaContentType0?: string;
        mediaUrl0?: string;
        dateCreated?: string;
        dateSent?: string;
        dateUpdated?: string;
        direction?: string;
        errorCode?: number;
        errorMessage?: string;
        from?: string;
        messagingServiceSid?: string;
        sid?: string;
        status?: string;
        to?: string;
        uri?: string;
        _id?: string;
      }[];
      _id?: string;
    };
    taskId?: string;
    direction?: "Inbound" | "Outbound";
    status?: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
    deliveryErrors?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteNotificationsByIdRes = /** status 204 Successful delete */ void;
export type DeleteNotificationsByIdArgs = string;
export type PostPatientWeeklyUpdatesRes = /** status 201 Successful create */ {
  weekOfDate?: string;
  patientId: any;
  updateText: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostPatientWeeklyUpdatesArgs = {
  weekOfDate?: string;
  patientId?: any;
  updateText?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetPatientWeeklyUpdatesRes = /** status 200 Successful list */ {
  data?: {
    weekOfDate?: string;
    patientId: any;
    updateText: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetPatientWeeklyUpdatesArgs = {
  _id?: {
    $in?: string[];
  };
  patientId?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetPatientWeeklyUpdatesByIdRes = /** status 200 Successful read */ {
  weekOfDate?: string;
  patientId: any;
  updateText: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetPatientWeeklyUpdatesByIdArgs = string;
export type PatchPatientWeeklyUpdatesByIdRes = /** status 200 Successful update */ {
  weekOfDate?: string;
  patientId: any;
  updateText: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchPatientWeeklyUpdatesByIdArgs = {
  id: string;
  body: {
    weekOfDate?: string;
    patientId?: any;
    updateText?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type PostPreClinicUpdatesRes = /** status 201 Successful create */ {
  clinicDate: string;
  ownerId: any;
  text: string;
  readByOwner?: boolean;
  updatedBy?: any;
  createdBy?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostPreClinicUpdatesArgs = {
  clinicDate?: string;
  ownerId?: any;
  text?: string;
  readByOwner?: boolean;
  updatedBy?: any;
  createdBy?: any;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetPreClinicUpdatesRes = /** status 200 Successful list */ {
  data?: {
    clinicDate: string;
    ownerId: any;
    text: string;
    readByOwner?: boolean;
    updatedBy?: any;
    createdBy?: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetPreClinicUpdatesArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetPreClinicUpdatesByIdRes = /** status 200 Successful read */ {
  clinicDate: string;
  ownerId: any;
  text: string;
  readByOwner?: boolean;
  updatedBy?: any;
  createdBy?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetPreClinicUpdatesByIdArgs = string;
export type PatchPreClinicUpdatesByIdRes = /** status 200 Successful update */ {
  clinicDate: string;
  ownerId: any;
  text: string;
  readByOwner?: boolean;
  updatedBy?: any;
  createdBy?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchPreClinicUpdatesByIdArgs = {
  id: string;
  body: {
    clinicDate?: string;
    ownerId?: any;
    text?: string;
    readByOwner?: boolean;
    updatedBy?: any;
    createdBy?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeletePreClinicUpdatesByIdRes = /** status 204 Successful delete */ void;
export type DeletePreClinicUpdatesByIdArgs = string;
export type GetPrescriptionsRes = /** status 200 Successful list */ {
  data?: {
    prescriptionId: number;
    medicationName: string;
    daysSupply: number;
    status: string;
    strength: string;
    quantity: number;
    refills: number;
    writtenDate: string;
    lastFilledDate?: string;
    inactiveDate?: string;
    directions?: string;
    prescriberId: string;
    patientId: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    expirationDate?: any;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetPrescriptionsArgs = {
  _id?: {
    $in?: string[];
  };
  patientId?:
    | string
    | {
        $in?: string[];
      };
  prescriberId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetPrescriptionsByIdRes = /** status 200 Successful read */ {
  prescriptionId: number;
  medicationName: string;
  daysSupply: number;
  status: string;
  strength: string;
  quantity: number;
  refills: number;
  writtenDate: string;
  lastFilledDate?: string;
  inactiveDate?: string;
  directions?: string;
  prescriberId: string;
  patientId: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  expirationDate?: any;
};
export type GetPrescriptionsByIdArgs = string;
export type PostRefreshPrescriptionsRes =
  /** status 200 Successfully fetched patient prescriptions */ object[];
export type PostRefreshPrescriptionsArgs = {
  /** The user ID of the patient */
  patientUserId: string;
};
export type PostReferralMethodsRes = /** status 201 Successful create */ {
  name: string;
  index?: number;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostReferralMethodsArgs = {
  name?: string;
  index?: number;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetReferralMethodsRes = /** status 200 Successful list */ {
  data?: {
    name: string;
    index?: number;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetReferralMethodsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetReferralMethodsByIdRes = /** status 200 Successful read */ {
  name: string;
  index?: number;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetReferralMethodsByIdArgs = string;
export type PatchReferralMethodsByIdRes = /** status 200 Successful update */ {
  name: string;
  index?: number;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchReferralMethodsByIdArgs = {
  id: string;
  body: {
    name?: string;
    index?: number;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteReferralMethodsByIdRes = /** status 204 Successful delete */ void;
export type DeleteReferralMethodsByIdArgs = string;
export type PostReferralSourcesRes = /** status 201 Successful create */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostReferralSourcesArgs = {
  createdBy?: string;
  name?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetReferralSourcesRes = /** status 200 Successful list */ {
  data?: {
    createdBy: string;
    name: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetReferralSourcesArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetReferralSourcesByIdRes = /** status 200 Successful read */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetReferralSourcesByIdArgs = string;
export type PatchReferralSourcesByIdRes = /** status 200 Successful update */ {
  createdBy: string;
  name: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchReferralSourcesByIdArgs = {
  id: string;
  body: {
    createdBy?: string;
    name?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteReferralSourcesByIdRes = /** status 204 Successful delete */ void;
export type DeleteReferralSourcesByIdArgs = string;
export type PostScheduleItemsRes = /** status 201 Successful create */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: LimitedUser;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * Schedule items create Google Calendar events.
   * Store the ID so we can update/delete the  event when the schedule item item is
   * updated/deleted.
   */
  googleCalendarEventId?: string;
  timezone:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  /**
   * This is the source of truth for engagement tracking data and staff alerting for missing
   * documentation. Schedule item attendance status is automatically updated with this value when a
   * linked form instance (clinical note) is signed and locked.
   */
  attendanceStatus: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
  /** This is an array of user._ids for all users who attended the scheduled meeting. */
  attended: {
    userId: any;
    _id?: string;
  }[];
  recurringId?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostScheduleItemsArgs = {
  title?: string;
  startDatetime?: string;
  endDatetime?: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff?: {
    userId: LimitedUser;
    role?: string;
    _id?: string;
  }[];
  users?: {
    userId: LimitedUser;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications?: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * Schedule items create Google Calendar events.
   * Store the ID so we can update/delete the  event when the schedule item item is
   * updated/deleted.
   */
  googleCalendarEventId?: string;
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType?: "Appointment" | "Vacation" | "CompanyHoliday";
  /**
   * This is the source of truth for engagement tracking data and staff alerting for missing
   * documentation. Schedule item attendance status is automatically updated with this value when a
   * linked form instance (clinical note) is signed and locked.
   */
  attendanceStatus?: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
  /** This is an array of user._ids for all users who attended the scheduled meeting. */
  attended?: {
    userId: any;
    _id?: string;
  }[];
  recurringId?: any;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetScheduleItemsRes = /** status 200 Successful list */ {
  data?: {
    title?: string;
    startDatetime: string;
    endDatetime: string;
    /** All day event, should ignore startDatetime and endDatetime. */
    allDay?: boolean;
    /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
    type?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
    location?: string;
    staff: {
      userId: LimitedUser;
      role?: string;
      _id?: string;
    }[];
    users: {
      userId: LimitedUser;
      _id?: string;
    }[];
    staffNotes?: string;
    userNotes?: string;
    notifications: {
      sendAsSms?: boolean;
      sendAsPush?: boolean;
      minutesBefore: number;
      notifications?: any[];
      _id?: string;
    }[];
    createdBy?: string;
    /**
     * Schedule items create Google Calendar events.
     * Store the ID so we can update/delete the  event when the schedule item item is
     * updated/deleted.
     */
    googleCalendarEventId?: string;
    timezone:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
    itemType: "Appointment" | "Vacation" | "CompanyHoliday";
    /**
     * This is the source of truth for engagement tracking data and staff alerting for missing
     * documentation. Schedule item attendance status is automatically updated with this value when
     * a linked form instance (clinical note) is signed and locked.
     */
    attendanceStatus: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
    /** This is an array of user._ids for all users who attended the scheduled meeting. */
    attended: {
      userId: any;
      _id?: string;
    }[];
    recurringId?: any;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetScheduleItemsArgs = {
  _id?: {
    $in?: string[];
  };
  "users.userId"?:
    | any
    | {
        $in?: any[];
      };
  "staff.userId"?:
    | any
    | {
        $in?: any[];
      };
  startDatetime?: {
    $gte?: string;
    $gt?: string;
    $lte?: string;
    $lt?: string;
  };
  endDatetime?: {
    $gte?: string;
    $gt?: string;
    $lte?: string;
    $lt?: string;
  };
  type?:
    | (
        | "Eligibility Interview"
        | "Clinical Intake"
        | "Guide Clinical Intake"
        | "Therapy Clinical Intake"
        | "Therapy Intake"
        | "Psychiatry Intake"
        | "In Home Onboarding Visit"
        | "Therapy"
        | "Therapy - Patient Session"
        | "Therapy - Caregiver Session"
        | "Psychiatry"
        | "Member Guide"
        | "Patient Guide"
        | "Family Guide"
        | "In Home Guide Visit"
        | "Travel"
        | "Individualized Service Plan"
        | "Meet and Greet"
        | "Other"
      )
    | {
        $in?: string[];
      };
  recurringId?:
    | any
    | {
        $in?: any[];
      };
  attendanceStatus?:
    | ("Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown")
    | {
        $in?: string[];
      };
  itemType?:
    | ("Appointment" | "Vacation" | "CompanyHoliday")
    | {
        $in?: string[];
      };
  carePodIds?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetScheduleItemsByIdRes = /** status 200 Successful read */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: LimitedUser;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * Schedule items create Google Calendar events.
   * Store the ID so we can update/delete the  event when the schedule item item is
   * updated/deleted.
   */
  googleCalendarEventId?: string;
  timezone:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  /**
   * This is the source of truth for engagement tracking data and staff alerting for missing
   * documentation. Schedule item attendance status is automatically updated with this value when a
   * linked form instance (clinical note) is signed and locked.
   */
  attendanceStatus: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
  /** This is an array of user._ids for all users who attended the scheduled meeting. */
  attended: {
    userId: any;
    _id?: string;
  }[];
  recurringId?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetScheduleItemsByIdArgs = string;
export type PatchScheduleItemsByIdRes = /** status 200 Successful update */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: LimitedUser;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: LimitedUser;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * Schedule items create Google Calendar events.
   * Store the ID so we can update/delete the  event when the schedule item item is
   * updated/deleted.
   */
  googleCalendarEventId?: string;
  timezone:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  /**
   * This is the source of truth for engagement tracking data and staff alerting for missing
   * documentation. Schedule item attendance status is automatically updated with this value when a
   * linked form instance (clinical note) is signed and locked.
   */
  attendanceStatus: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
  /** This is an array of user._ids for all users who attended the scheduled meeting. */
  attended: {
    userId: any;
    _id?: string;
  }[];
  recurringId?: any;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchScheduleItemsByIdArgs = {
  id: string;
  body: {
    title?: string;
    startDatetime?: string;
    endDatetime?: string;
    /** All day event, should ignore startDatetime and endDatetime. */
    allDay?: boolean;
    /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
    type?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
    location?: string;
    staff?: {
      userId: LimitedUser;
      role?: string;
      _id?: string;
    }[];
    users?: {
      userId: LimitedUser;
      _id?: string;
    }[];
    staffNotes?: string;
    userNotes?: string;
    notifications?: {
      sendAsSms?: boolean;
      sendAsPush?: boolean;
      minutesBefore: number;
      notifications?: any[];
      _id?: string;
    }[];
    createdBy?: string;
    /**
     * Schedule items create Google Calendar events.
     * Store the ID so we can update/delete the  event when the schedule item item is
     * updated/deleted.
     */
    googleCalendarEventId?: string;
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
    itemType?: "Appointment" | "Vacation" | "CompanyHoliday";
    /**
     * This is the source of truth for engagement tracking data and staff alerting for missing
     * documentation. Schedule item attendance status is automatically updated with this value when
     * a linked form instance (clinical note) is signed and locked.
     */
    attendanceStatus?: "Attended" | "Cancellation" | "No-Show" | "Reschedule" | "Unknown";
    /** This is an array of user._ids for all users who attended the scheduled meeting. */
    attended?: {
      userId: any;
      _id?: string;
    }[];
    recurringId?: any;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteScheduleItemsByIdRes = /** status 204 Successful delete */ void;
export type DeleteScheduleItemsByIdArgs = string;
export type PostRecurringScheduleItemsRes = /** status 201 Successful create */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: any;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: any;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
   * series, and modify individual events as needed
   */
  googleCalendarEventId?: string;
  timezone?: string;
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  frequency: "Daily" | "Weekly" | "Monthly" | "Yearly";
  interval: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes: number;
  skippedDates?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostRecurringScheduleItemsArgs = {
  title?: string;
  startDatetime?: string;
  endDatetime?: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff?: {
    userId: any;
    role?: string;
    _id?: string;
  }[];
  users?: {
    userId: any;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications?: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
   * series, and modify individual events as needed
   */
  googleCalendarEventId?: string;
  timezone?: string;
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType?: "Appointment" | "Vacation" | "CompanyHoliday";
  frequency?: "Daily" | "Weekly" | "Monthly" | "Yearly";
  interval?: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes?: number;
  skippedDates?: string[];
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetRecurringScheduleItemsRes = /** status 200 Successful list */ {
  data?: {
    title?: string;
    startDatetime: string;
    endDatetime: string;
    /** All day event, should ignore startDatetime and endDatetime. */
    allDay?: boolean;
    /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
    type?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
    location?: string;
    staff: {
      userId: any;
      role?: string;
      _id?: string;
    }[];
    users: {
      userId: any;
      _id?: string;
    }[];
    staffNotes?: string;
    userNotes?: string;
    notifications: {
      sendAsSms?: boolean;
      sendAsPush?: boolean;
      minutesBefore: number;
      notifications?: any[];
      _id?: string;
    }[];
    createdBy?: string;
    /**
     * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
     * series, and modify individual events as needed
     */
    googleCalendarEventId?: string;
    timezone?: string;
    /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
    itemType: "Appointment" | "Vacation" | "CompanyHoliday";
    frequency: "Daily" | "Weekly" | "Monthly" | "Yearly";
    interval: number;
    daysOfWeek?: string[];
    dayOfMonth?: number;
    durationMinutes: number;
    skippedDates?: string[];
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetRecurringScheduleItemsArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetRecurringScheduleItemsByIdRes = /** status 200 Successful read */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: any;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: any;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
   * series, and modify individual events as needed
   */
  googleCalendarEventId?: string;
  timezone?: string;
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  frequency: "Daily" | "Weekly" | "Monthly" | "Yearly";
  interval: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes: number;
  skippedDates?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetRecurringScheduleItemsByIdArgs = string;
export type PatchRecurringScheduleItemsByIdRes = /** status 200 Successful update */ {
  title?: string;
  startDatetime: string;
  endDatetime: string;
  /** All day event, should ignore startDatetime and endDatetime. */
  allDay?: boolean;
  /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
  type?:
    | "Eligibility Interview"
    | "Clinical Intake"
    | "Guide Clinical Intake"
    | "Therapy Clinical Intake"
    | "Therapy Intake"
    | "Psychiatry Intake"
    | "In Home Onboarding Visit"
    | "Therapy"
    | "Therapy - Patient Session"
    | "Therapy - Caregiver Session"
    | "Psychiatry"
    | "Member Guide"
    | "Patient Guide"
    | "Family Guide"
    | "In Home Guide Visit"
    | "Travel"
    | "Individualized Service Plan"
    | "Meet and Greet"
    | "Other";
  /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
  location?: string;
  staff: {
    userId: any;
    role?: string;
    _id?: string;
  }[];
  users: {
    userId: any;
    _id?: string;
  }[];
  staffNotes?: string;
  userNotes?: string;
  notifications: {
    sendAsSms?: boolean;
    sendAsPush?: boolean;
    minutesBefore: number;
    notifications?: any[];
    _id?: string;
  }[];
  createdBy?: string;
  /**
   * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
   * series, and modify individual events as needed
   */
  googleCalendarEventId?: string;
  timezone?: string;
  /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  frequency: "Daily" | "Weekly" | "Monthly" | "Yearly";
  interval: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes: number;
  skippedDates?: string[];
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchRecurringScheduleItemsByIdArgs = {
  id: string;
  body: {
    title?: string;
    startDatetime?: string;
    endDatetime?: string;
    /** All day event, should ignore startDatetime and endDatetime. */
    allDay?: boolean;
    /** The type of appointment, e.g. Therapy Intake, Psychiatry, etc. */
    type?:
      | "Eligibility Interview"
      | "Clinical Intake"
      | "Guide Clinical Intake"
      | "Therapy Clinical Intake"
      | "Therapy Intake"
      | "Psychiatry Intake"
      | "In Home Onboarding Visit"
      | "Therapy"
      | "Therapy - Patient Session"
      | "Therapy - Caregiver Session"
      | "Psychiatry"
      | "Member Guide"
      | "Patient Guide"
      | "Family Guide"
      | "In Home Guide Visit"
      | "Travel"
      | "Individualized Service Plan"
      | "Meet and Greet"
      | "Other";
    /** For video meetings, the URL of the meeting. For in person, the address of the meeting. */
    location?: string;
    staff?: {
      userId: any;
      role?: string;
      _id?: string;
    }[];
    users?: {
      userId: any;
      _id?: string;
    }[];
    staffNotes?: string;
    userNotes?: string;
    notifications?: {
      sendAsSms?: boolean;
      sendAsPush?: boolean;
      minutesBefore: number;
      notifications?: any[];
      _id?: string;
    }[];
    createdBy?: string;
    /**
     * The recurring event ID in Google Calendar. This let's us send one invite for a recurring
     * series, and modify individual events as needed
     */
    googleCalendarEventId?: string;
    timezone?: string;
    /** The category of schedule item, either ScheduleItem, Vacation, or CompanyHoliday. */
    itemType?: "Appointment" | "Vacation" | "CompanyHoliday";
    frequency?: "Daily" | "Weekly" | "Monthly" | "Yearly";
    interval?: number;
    daysOfWeek?: string[];
    dayOfMonth?: number;
    durationMinutes?: number;
    skippedDates?: string[];
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteRecurringScheduleItemsByIdRes = /** status 204 Successful delete */ void;
export type DeleteRecurringScheduleItemsByIdArgs = string;
export type PostSyncScheduleGoogleCalendarRes = /** status 201 Successful create */ {};
export type PostSyncScheduleGoogleCalendarArgs = {
  id: string;
  isRecurring: boolean;
};
export type PostScriptsLoadTestDataRes = /** status 201 Successful ran migration */ {};
export type PostScriptsLoadTestDataArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsFtpRes = /** status 201 Successful ran migration */ {};
export type PostScriptsFtpArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCharmSyncStaffRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCharmSyncStaffArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCharmSyncUsersRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCharmSyncUsersArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCharmSyncFormsRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCharmSyncFormsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsInternalChatSetupRes = /** status 201 Successful ran migration */ {};
export type PostScriptsInternalChatSetupArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCreateConvosRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCreateConvosArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsDeleteOrphanWorkflowMappingsRes =
  /** status 201 Successful ran migration */ {};
export type PostScriptsDeleteOrphanWorkflowMappingsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsBackfillUserSettingsRes = /** status 201 Successful ran migration */ {};
export type PostScriptsBackfillUserSettingsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsSyncAllScheduleItemsGoogleCalendarRes =
  /** status 201 Successful ran migration */ {};
export type PostScriptsSyncAllScheduleItemsGoogleCalendarArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCreateGoogleCalendarRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCreateGoogleCalendarArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsSyncAssessmentsRes = /** status 201 Successful ran migration */ {};
export type PostScriptsSyncAssessmentsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsLoadMedicalDiagnosisDataRes = /** status 201 Successful ran migration */ {};
export type PostScriptsLoadMedicalDiagnosisDataArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsLoadAlertsDataRes = /** status 201 Successful ran migration */ {};
export type PostScriptsLoadAlertsDataArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsCleanupNotificationTasksRes = /** status 201 Successful ran migration */ {};
export type PostScriptsCleanupNotificationTasksArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsBackfillPatientIdsRes = /** status 201 Successful ran migration */ {};
export type PostScriptsBackfillPatientIdsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsSyncIntakeDatesRes = /** status 201 Successful ran migration */ {};
export type PostScriptsSyncIntakeDatesArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsDeleteNotificationsForDeletedScheduledItemsRes =
  /** status 201 Successful ran migration */ {};
export type PostScriptsDeleteNotificationsForDeletedScheduledItemsArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsUpdateFormsIsRequiredRes = /** status 201 Successful ran migration */ {};
export type PostScriptsUpdateFormsIsRequiredArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsLoadCompanyHolidaysRes = /** status 201 Successful ran migration */ {};
export type PostScriptsLoadCompanyHolidaysArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostScriptsSaveAllUsersRes = /** status 201 Successful ran migration */ {};
export type PostScriptsSaveAllUsersArgs = {
  /**
   * A boolean query parameter to specify whether to run the migration and save (true) or just
   * return what it would have done (false or not provided)
   */
  wetRun?: boolean;
  body: {};
};
export type PostTodoRes = /** status 201 Successful create */ {
  title: string;
  dueDate?: string;
  isCompleted: boolean;
  completionDate?: string;
  assignedUser: string;
  referencedUser: string;
  createdBy: string;
  createUserAlert: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostTodoArgs = {
  title?: string;
  dueDate?: string;
  isCompleted?: boolean;
  completionDate?: string;
  assignedUser?: string;
  referencedUser?: string;
  createdBy?: string;
  createUserAlert?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetTodoRes = /** status 200 Successful list */ {
  data?: {
    title: string;
    dueDate?: string;
    isCompleted: boolean;
    completionDate?: string;
    assignedUser: string;
    referencedUser: string;
    createdBy: string;
    createUserAlert: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetTodoArgs = {
  _id?: {
    $in?: string[];
  };
  assignedUser?:
    | string
    | {
        $in?: string[];
      };
  referencedUser?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetTodoByIdRes = /** status 200 Successful read */ {
  title: string;
  dueDate?: string;
  isCompleted: boolean;
  completionDate?: string;
  assignedUser: string;
  referencedUser: string;
  createdBy: string;
  createUserAlert: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetTodoByIdArgs = string;
export type PatchTodoByIdRes = /** status 200 Successful update */ {
  title: string;
  dueDate?: string;
  isCompleted: boolean;
  completionDate?: string;
  assignedUser: string;
  referencedUser: string;
  createdBy: string;
  createUserAlert: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchTodoByIdArgs = {
  id: string;
  body: {
    title?: string;
    dueDate?: string;
    isCompleted?: boolean;
    completionDate?: string;
    assignedUser?: string;
    referencedUser?: string;
    createdBy?: string;
    createUserAlert?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteTodoByIdRes = /** status 204 Successful delete */ void;
export type DeleteTodoByIdArgs = string;
export type PostUserBioRes = /** status 201 Successful create */ {
  userId: string;
  aboutMe?: string;
  location?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostUserBioArgs = {
  userId?: string;
  aboutMe?: string;
  location?: string;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserBioRes = /** status 200 Successful list */ {
  data?: {
    userId: string;
    aboutMe?: string;
    location?: string;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetUserBioArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetUserBioByIdRes = /** status 200 Successful read */ {
  userId: string;
  aboutMe?: string;
  location?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserBioByIdArgs = string;
export type PatchUserBioByIdRes = /** status 200 Successful update */ {
  userId: string;
  aboutMe?: string;
  location?: string;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchUserBioByIdArgs = {
  id: string;
  body: {
    userId?: string;
    aboutMe?: string;
    location?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteUserBioByIdRes = /** status 204 Successful delete */ void;
export type DeleteUserBioByIdArgs = string;
export type PostGetStaffPhoneNumberRes =
  /** status 200 Successfully fetched Staff Twilio Number */ {
    /** The phone number for the staff member */
    staffPhoneNumber?: string;
  };
export type PostGetStaffPhoneNumberArgs = {
  /** The user ID for the staff member */
  userId: string;
};
export type PostUsersRes = /** status 201 Successful create */ {
  /**
   * The preferred, full name for the user. If their legal/insurance name is different,
   * that should be stored in billing info.
   */
  name: string;
  /** How the user or staff's name is pronounced. This is visible in the user app. */
  namePronunciation?: string;
  /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
  initials?: string;
  birthday?: any;
  /**
   * The gender the user identifies as. If different than the gender listed on their insurance,
   * that should be stored in billing info.
   */
  gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
  /** If gender is 'Prefer to self-describe', this is used */
  genderSelfDescribe?: string;
  /** The user's current timezone, based on their phone's location. */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  staffRoles: {
    /**
     * Gives the ability to access all users, set other staff passwords, and access super user
     * tools.
     */
    SuperUser?: boolean;
    CareAdvocate?: boolean;
    FamilyGuide?: boolean;
    PatientGuide?: boolean;
    Psychiatrist?: boolean;
    Therapist?: boolean;
    EnrollmentCoordinator?: boolean;
    FamilyGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    TherapistSupervisor?: boolean;
    ClinicalLeader?: boolean;
    ClinicalDirector?: boolean;
    /** Only one staff user should have this role */
    RiskManager?: boolean;
    /** Only one staff user should have this role */
    MedicalDirector?: boolean;
    EnrollmentSupervisor?: boolean;
    ClinicalQualityAssurance?: boolean;
    SoftwareEngineer?: boolean;
    Guide?: boolean;
    MemberGuideSupervisor?: boolean;
    _id?: string;
  };
  outOfOffice?: boolean;
  /** Staff Degrees, certifications, etc. E.g. LCSW */
  credentials?: string;
  /**
   * The Twilio phone number users can text or call to reach this staff member.
   * Send a text to a test user  to get a phone number assigned.
   */
  staffPhoneNumber?: string;
  careTeam: {
    Therapist?: any;
    Psychiatrist?: any;
    Guide?: any;
    PatientGuide?: any;
    FamilyGuide?: any;
    _id?: string;
  };
  /** The staff's supervisor. */
  supervisor?: any;
  fitbitId?: string;
  fitbitAccessToken?: string;
  fitbitRefreshToken?: string;
  fitbitConfig?: {
    enabled?: boolean;
    sleepEnabled?: boolean;
    activityEnabled?: boolean;
    allowReminders?: boolean;
    note?: string;
  };
  type: "Staff" | "Member" | "FamilyMember" | "Patient";
  consentFormAgreements: {
    consentFormId: number;
    isAgreed: boolean;
    agreedDate?: string;
    consentFormType:
      | "patientAgreement"
      | "familyMemberAgreement"
      | "consent"
      | "transportation"
      | "research"
      | "privacy"
      | "hipaa"
      | "virginiaRights";
    _id?: string;
  }[];
  /** Whether the user has agreed to receive push notifications */
  pushNotifications?: boolean;
  /**
   * Whether SMS messages are allowed or not for the user.
   * Generally used to disable SMS after delivery failures.
   */
  smsEnabled?: boolean;
  smsErrors?: {
    message?: string;
    notification?: any;
    error?: string;
    date?: string;
  }[];
  /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
  smsNotifications?: boolean;
  /** Whether the user has agreed to receive SMS messages from staff */
  smsMessaging?: boolean;
  expoToken?: string;
  expoTokens: string[];
  /** Web push subscription info */
  webPushTokens: {
    /**
     * The endpoint takes the form of a custom URL pointing to a push server,
     * which can be used to send a push message to the particular service worker instance that
     * subscribed to the push service.
     */
    endpoint: string;
    /** An authentication secret for the push server */
    auth: string;
    /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
    p256dh: string;
    _id?: string;
  }[];
  conversations?: {
    conversationId: any;
    role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
  }[];
  /** Hides the user from the UI without deleting them. */
  archived?: boolean;
  address: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    countyName?: string;
    countyCode?: string;
    _id?: string;
  };
  phoneNumber?: string;
  usageData: {
    /** The last time the app was launched. */
    lastAppLaunch?: string;
    currentAppVersion?: string;
    phoneModel?: string;
    operatingSystem?: string;
    _id?: string;
  };
  testUser?: boolean;
  pronouns?: string;
  bio?: string;
  /** A string describing the user's care plan. This will be visible in the apps. */
  carePlan?: string;
  /**
   * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
   * and quick current info. This is only shown in the staff app.
   */
  oneLiner?: string;
  /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
  weeklyUpdates?: string;
  /**
   * Care team members are doing some work on “thrive planning” with patients and this is where
   * they can store it shown to staff AND members
   */
  thrivePlan?: string;
  engagementDetails?: string;
  /** Things that could be triggering for the patient that staff needs to be aware of. */
  safetyConcerns?: string[];
  /**
   * A string describing the user's safety plan. This will be visible in the apps under care plan.
   */
  safetyPlan?: string;
  /**
   * Some early test users will be receiving guide-only services, with no clinical services. At a
   * minimum, we hide references to our clinical services from their app and make this apparent in
   * the workflows.
   */
  guideOnly?: boolean;
  /**
   * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand for
   * members. Lots of our forms require memberId to be written on every page,and this is a lot
   * easier than `_id`. Use a unique sparse index because staff don't have memberIds,
   * and we don't want to have to check for nulls.
   */
  memberId?: number;
  patientId?: number;
  /**
   * Charm automatically creates a patient id for us to use. If this is not set,
   * the user is not synced to Charm.
   */
  charmPatientId?: string;
  /**
   * For staff that are also set up on Charm, we save their ID so we can show which provider is
   * writing/signing notes.
   */
  charmProviderId?: string;
  taskIdentifiers: {
    scheduledOfflineToggleForMembersTaskName?: string;
    scheduledOfflineToggleForPatientsTaskName?: string;
    scheduledOfflineToggleForFamilyMembersTaskName?: string;
    _id?: string;
  };
  online: {
    forMembers?: boolean;
    scheduledOfflineToggleForMembers?: string;
    forPatients?: boolean;
    scheduledOfflineToggleForPatients?: string;
    forFamilyMembers?: boolean;
    scheduledOfflineToggleForFamilyMembers?: string;
    _id?: string;
  };
  billingInfo: {
    firstName?: string;
    lastName?: string;
    gender?: "male" | "female" | "unknown" | "other";
    insurancePlan?: any;
    renewalDate?: any;
    memberId?: string;
    groupId?: string;
    healthPlanId?: string;
    policyHolder?: "self" | "other";
    authorizationCode?: string;
    medicaidNumber?: string;
    _id?: string;
  };
  charmErrors?: string[];
  growth: {
    authorizationDate?: any;
    referralSource?: any;
    referralMethod?: any;
    /** The date the patient was referred to Flourish, either self-referral or from a partner */
    referralDate?: any;
    /** The date the patient was accepted into our enrollment pipeline. */
    referralAcceptedDate?: any;
    /**
     * The date we can start billing for the patient.
     * This should be the date of their first attended therapy intake.
     * This will be used to calculate billing months for the patient,
     * which will be used for engagement tracking.
     * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
     * 2/15 - 3/14. Or 1/31 - 2/28.
     */
    serviceStartDate?: any;
    /** The date the patient was fully enrolled in the program and finished intakes. */
    enrolledDate?: any;
    /** The actual date the patient was discharged from the Flourish Health program. */
    dischargeDate?: any;
    /**
     * The anticipated date we expect the patient will be discharged from the Flourish Health
     * program. When service start date is set, this is defaulted to 1 year from service start
     * date. Will also be edited to reflect anticipated upcoming early discharge.
     */
    estimatedDischargeDate?: any;
    /** Whether the patient is currently hospitalized. */
    isPatientCurrentlyHospitalized?: boolean;
    /** Whether the patient is currently in outpatient care. */
    outpatient?: boolean;
    /**
     * The date the patient was discharged from a hospital prior to enrollment in Flourish Program.
     */
    hospitalDischargeDate?: any;
    /**
     * The external therapist/other clinician who referred the patient to Flourish and was likely
     * part of the patient's most recent care team. This may be the same as referringPsychiatrist.
     */
    referringClinician?: any;
    /**
     * The external psychiatrist who referred the patient to Flourish and was likely part of the
     * patient's most recent care team.
     */
    referringPsychiatrist?: any;
    /** Link to the Google Drive folder where the user's documents outside the app are stored. */
    documentFolder?: string;
    /** Notes about the user's enrollment process, for internal use. */
    notes?: string;
    eligibilityInterview?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    clinicalIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    therapyIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    psychiatryIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    inHomeOnboarding?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    meetAndGreet?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    origin?: string;
    leadSource?: string;
    optInDetails?: string;
    optInDate?: string;
    sourceReferrerUrl?: string;
    sourceContent?: string;
    sourceCampaign?: string;
    conversionReferrerUrl?: string;
    referringProviderPhoneNumber?: string;
    referringProviderOrganization?: string;
    referringProviderName?: string;
    referringProviderEmail?: string;
    referringClinicianName?: string;
    _id?: string;
  };
  tasks: {
    roiSent?: boolean;
    signedROIReceived?: boolean;
    signedROISentToProviders?: boolean;
    medicalRecordsRequested?: boolean;
    dischargeMedsReceived?: boolean;
    documentationRequestSent?: boolean;
    authorizationInformationConfirmed?: boolean;
    _id?: string;
  };
  contacts: {
    name: string;
    types: string[];
    isPrimary: boolean;
    phoneNumber?: string;
    email?: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    notes?: string;
    _id?: string;
  }[];
  /** SMS, app, phone, email, times of day, etc */
  preferredPharmacies: {
    primary: boolean;
    name: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    faxNumber?: string;
    notes?: string;
    _id?: string;
  }[];
  settings: {
    colorTheme?: string;
    _id?: string;
  };
  statusId?: any;
  /** The reason(s) selected for the user's current UserStatus */
  statusReasons?: string[];
  carePod?: any;
  /**
   * Link to the video chat platform the user prefers. This is shown in the patient/family member
   * app. Usually Doxy or Zoom.
   */
  videoChatLink?: string;
  /** The last time a claim was submitted for this user */
  lastClaimDate?: string;
  /** SMS, app, phone, email, times of day, etc. */
  communicationPreferences?: string;
  /** The primary language the user speaks */
  languages?: string;
  interpreterNeeded?: boolean;
  featureFlags: {
    enableAlertInstances?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableWebPush?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTwilioCalling?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    allowOpenNotesScheduleItemLinking?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableGoogleCalendar?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableToDoList?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    generateFeedbackSurveys?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableReorganizePanel?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enablePreClinicUpdates?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    appointments?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableUserAlertsExplorer?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventHelpButton?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventCrisisEscalation?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTherapyCadenceIndicator?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableApptTypesTherapyPatientAndTherapyCaregiver?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableDoseSpot?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    _id?: string;
  };
  /** Which Flourish company the user is associated with */
  companyOrganizationId?: any;
  /** The user's diagnosis */
  diagnosis: {
    diagnoses?: any[];
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** helps staff remember what kind of engagement/outreach a patient needs */
  clinicalStatus: {
    status?: "High" | "Moderate" | "Mild-Moderate";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** how often the patient has recurring therapy appointments */
  therapyCadence: {
    status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** The hours a staff user is available to be scheduled. */
  availabilitySchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  scheduleCapacity: {
    /** Psychiatry Intake slots per week */
    PsychiatryIntake?: number;
    /** Therapy Intake slots per week */
    TherapyIntake?: number;
    /** Patient Guide Intake slots per week */
    PatientGuideIntake?: number;
    /** Family Guide Intake slots per week */
    FamilyGuideIntake?: number;
    /** Care Advocate Intake slots per week */
    CareAdvocateIntake?: number;
    _id?: string;
  };
  /**
   * Memory is appended to the start of every GPT conversation to simulate memory and make it less
   * tedious
   */
  gptMemory?: string;
  /**
   * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
   * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
   */
  leadsquaredId?: string;
  /** The ID of the patient in DoseSpot */
  dosespotPatientId?: string;
  /** The ID of the clinician in DoseSpot */
  dosespotClinicianId?: string;
  _id: string;
  admin?: boolean;
  email?: string;
  /** When a user is set to disable, all requests will return a 401 */
  disabled?: boolean;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  hash?: string;
  salt?: string;
  attempts?: number;
  last?: string;
  userStatusAlerts?: any;
};
export type PostUsersArgs = {
  /**
   * The preferred, full name for the user. If their legal/insurance name is different,
   * that should be stored in billing info.
   */
  name?: string;
  /** How the user or staff's name is pronounced. This is visible in the user app. */
  namePronunciation?: string;
  /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
  initials?: string;
  birthday?: any;
  /**
   * The gender the user identifies as. If different than the gender listed on their insurance,
   * that should be stored in billing info.
   */
  gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
  /** If gender is 'Prefer to self-describe', this is used */
  genderSelfDescribe?: string;
  /** The user's current timezone, based on their phone's location. */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  staffRoles?: {
    /**
     * Gives the ability to access all users, set other staff passwords, and access super user
     * tools.
     */
    SuperUser?: boolean;
    CareAdvocate?: boolean;
    FamilyGuide?: boolean;
    PatientGuide?: boolean;
    Psychiatrist?: boolean;
    Therapist?: boolean;
    EnrollmentCoordinator?: boolean;
    FamilyGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    TherapistSupervisor?: boolean;
    ClinicalLeader?: boolean;
    ClinicalDirector?: boolean;
    /** Only one staff user should have this role */
    RiskManager?: boolean;
    /** Only one staff user should have this role */
    MedicalDirector?: boolean;
    EnrollmentSupervisor?: boolean;
    ClinicalQualityAssurance?: boolean;
    SoftwareEngineer?: boolean;
    Guide?: boolean;
    MemberGuideSupervisor?: boolean;
    _id?: string;
  };
  outOfOffice?: boolean;
  /** Staff Degrees, certifications, etc. E.g. LCSW */
  credentials?: string;
  /**
   * The Twilio phone number users can text or call to reach this staff member.
   * Send a text to a test user  to get a phone number assigned.
   */
  staffPhoneNumber?: string;
  careTeam?: {
    Therapist?: any;
    Psychiatrist?: any;
    Guide?: any;
    PatientGuide?: any;
    FamilyGuide?: any;
    _id?: string;
  };
  /** The staff's supervisor. */
  supervisor?: any;
  fitbitId?: string;
  fitbitAccessToken?: string;
  fitbitRefreshToken?: string;
  fitbitConfig?: {
    enabled?: boolean;
    sleepEnabled?: boolean;
    activityEnabled?: boolean;
    allowReminders?: boolean;
    note?: string;
  };
  type?: "Staff" | "Member" | "FamilyMember" | "Patient";
  consentFormAgreements?: {
    consentFormId: number;
    isAgreed: boolean;
    agreedDate?: string;
    consentFormType:
      | "patientAgreement"
      | "familyMemberAgreement"
      | "consent"
      | "transportation"
      | "research"
      | "privacy"
      | "hipaa"
      | "virginiaRights";
    _id?: string;
  }[];
  /** Whether the user has agreed to receive push notifications */
  pushNotifications?: boolean;
  /**
   * Whether SMS messages are allowed or not for the user.
   * Generally used to disable SMS after delivery failures.
   */
  smsEnabled?: boolean;
  smsErrors?: {
    message?: string;
    notification?: any;
    error?: string;
    date?: string;
  }[];
  /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
  smsNotifications?: boolean;
  /** Whether the user has agreed to receive SMS messages from staff */
  smsMessaging?: boolean;
  expoToken?: string;
  expoTokens?: string[];
  /** Web push subscription info */
  webPushTokens?: {
    /**
     * The endpoint takes the form of a custom URL pointing to a push server,
     * which can be used to send a push message to the particular service worker instance that
     * subscribed to the push service.
     */
    endpoint: string;
    /** An authentication secret for the push server */
    auth: string;
    /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
    p256dh: string;
    _id?: string;
  }[];
  conversations?: {
    conversationId: any;
    role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
  }[];
  /** Hides the user from the UI without deleting them. */
  archived?: boolean;
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    countyName?: string;
    countyCode?: string;
    _id?: string;
  };
  phoneNumber?: string;
  usageData?: {
    /** The last time the app was launched. */
    lastAppLaunch?: string;
    currentAppVersion?: string;
    phoneModel?: string;
    operatingSystem?: string;
    _id?: string;
  };
  testUser?: boolean;
  pronouns?: string;
  bio?: string;
  /** A string describing the user's care plan. This will be visible in the apps. */
  carePlan?: string;
  /**
   * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
   * and quick current info. This is only shown in the staff app.
   */
  oneLiner?: string;
  /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
  weeklyUpdates?: string;
  /**
   * Care team members are doing some work on “thrive planning” with patients and this is where
   * they can store it shown to staff AND members
   */
  thrivePlan?: string;
  engagementDetails?: string;
  /** Things that could be triggering for the patient that staff needs to be aware of. */
  safetyConcerns?: string[];
  /**
   * A string describing the user's safety plan. This will be visible in the apps under care plan.
   */
  safetyPlan?: string;
  /**
   * Some early test users will be receiving guide-only services, with no clinical services. At a
   * minimum, we hide references to our clinical services from their app and make this apparent in
   * the workflows.
   */
  guideOnly?: boolean;
  /**
   * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand for
   * members. Lots of our forms require memberId to be written on every page,and this is a lot
   * easier than `_id`. Use a unique sparse index because staff don't have memberIds,
   * and we don't want to have to check for nulls.
   */
  memberId?: number;
  patientId?: number;
  /**
   * Charm automatically creates a patient id for us to use. If this is not set,
   * the user is not synced to Charm.
   */
  charmPatientId?: string;
  /**
   * For staff that are also set up on Charm, we save their ID so we can show which provider is
   * writing/signing notes.
   */
  charmProviderId?: string;
  taskIdentifiers?: {
    scheduledOfflineToggleForMembersTaskName?: string;
    scheduledOfflineToggleForPatientsTaskName?: string;
    scheduledOfflineToggleForFamilyMembersTaskName?: string;
    _id?: string;
  };
  online?: {
    forMembers?: boolean;
    scheduledOfflineToggleForMembers?: string;
    forPatients?: boolean;
    scheduledOfflineToggleForPatients?: string;
    forFamilyMembers?: boolean;
    scheduledOfflineToggleForFamilyMembers?: string;
    _id?: string;
  };
  billingInfo?: {
    firstName?: string;
    lastName?: string;
    gender?: "male" | "female" | "unknown" | "other";
    insurancePlan?: any;
    renewalDate?: any;
    memberId?: string;
    groupId?: string;
    healthPlanId?: string;
    policyHolder?: "self" | "other";
    authorizationCode?: string;
    medicaidNumber?: string;
    _id?: string;
  };
  charmErrors?: string[];
  growth?: {
    authorizationDate?: any;
    referralSource?: any;
    referralMethod?: any;
    /** The date the patient was referred to Flourish, either self-referral or from a partner */
    referralDate?: any;
    /** The date the patient was accepted into our enrollment pipeline. */
    referralAcceptedDate?: any;
    /**
     * The date we can start billing for the patient.
     * This should be the date of their first attended therapy intake.
     * This will be used to calculate billing months for the patient,
     * which will be used for engagement tracking.
     * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
     * 2/15 - 3/14. Or 1/31 - 2/28.
     */
    serviceStartDate?: any;
    /** The date the patient was fully enrolled in the program and finished intakes. */
    enrolledDate?: any;
    /** The actual date the patient was discharged from the Flourish Health program. */
    dischargeDate?: any;
    /**
     * The anticipated date we expect the patient will be discharged from the Flourish Health
     * program. When service start date is set, this is defaulted to 1 year from service start
     * date. Will also be edited to reflect anticipated upcoming early discharge.
     */
    estimatedDischargeDate?: any;
    /** Whether the patient is currently hospitalized. */
    isPatientCurrentlyHospitalized?: boolean;
    /** Whether the patient is currently in outpatient care. */
    outpatient?: boolean;
    /**
     * The date the patient was discharged from a hospital prior to enrollment in Flourish Program.
     */
    hospitalDischargeDate?: any;
    /**
     * The external therapist/other clinician who referred the patient to Flourish and was likely
     * part of the patient's most recent care team. This may be the same as referringPsychiatrist.
     */
    referringClinician?: any;
    /**
     * The external psychiatrist who referred the patient to Flourish and was likely part of the
     * patient's most recent care team.
     */
    referringPsychiatrist?: any;
    /** Link to the Google Drive folder where the user's documents outside the app are stored. */
    documentFolder?: string;
    /** Notes about the user's enrollment process, for internal use. */
    notes?: string;
    eligibilityInterview?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    clinicalIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    therapyIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    psychiatryIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    inHomeOnboarding?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    meetAndGreet?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    origin?: string;
    leadSource?: string;
    optInDetails?: string;
    optInDate?: string;
    sourceReferrerUrl?: string;
    sourceContent?: string;
    sourceCampaign?: string;
    conversionReferrerUrl?: string;
    referringProviderPhoneNumber?: string;
    referringProviderOrganization?: string;
    referringProviderName?: string;
    referringProviderEmail?: string;
    referringClinicianName?: string;
    _id?: string;
  };
  tasks?: {
    roiSent?: boolean;
    signedROIReceived?: boolean;
    signedROISentToProviders?: boolean;
    medicalRecordsRequested?: boolean;
    dischargeMedsReceived?: boolean;
    documentationRequestSent?: boolean;
    authorizationInformationConfirmed?: boolean;
    _id?: string;
  };
  contacts?: {
    name: string;
    types: string[];
    isPrimary: boolean;
    phoneNumber?: string;
    email?: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    notes?: string;
    _id?: string;
  }[];
  /** SMS, app, phone, email, times of day, etc */
  preferredPharmacies?: {
    primary: boolean;
    name: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    faxNumber?: string;
    notes?: string;
    _id?: string;
  }[];
  settings?: {
    colorTheme?: string;
    _id?: string;
  };
  statusId?: any;
  /** The reason(s) selected for the user's current UserStatus */
  statusReasons?: string[];
  carePod?: any;
  /**
   * Link to the video chat platform the user prefers. This is shown in the patient/family member
   * app. Usually Doxy or Zoom.
   */
  videoChatLink?: string;
  /** The last time a claim was submitted for this user */
  lastClaimDate?: string;
  /** SMS, app, phone, email, times of day, etc. */
  communicationPreferences?: string;
  /** The primary language the user speaks */
  languages?: string;
  interpreterNeeded?: boolean;
  featureFlags?: {
    enableAlertInstances?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableWebPush?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTwilioCalling?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    allowOpenNotesScheduleItemLinking?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableGoogleCalendar?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableToDoList?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    generateFeedbackSurveys?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableReorganizePanel?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enablePreClinicUpdates?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    appointments?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableUserAlertsExplorer?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventHelpButton?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventCrisisEscalation?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTherapyCadenceIndicator?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableApptTypesTherapyPatientAndTherapyCaregiver?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableDoseSpot?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    _id?: string;
  };
  /** Which Flourish company the user is associated with */
  companyOrganizationId?: any;
  /** The user's diagnosis */
  diagnosis?: {
    diagnoses?: any[];
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** helps staff remember what kind of engagement/outreach a patient needs */
  clinicalStatus?: {
    status?: "High" | "Moderate" | "Mild-Moderate";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** how often the patient has recurring therapy appointments */
  therapyCadence?: {
    status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** The hours a staff user is available to be scheduled. */
  availabilitySchedule?: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  scheduleCapacity?: {
    /** Psychiatry Intake slots per week */
    PsychiatryIntake?: number;
    /** Therapy Intake slots per week */
    TherapyIntake?: number;
    /** Patient Guide Intake slots per week */
    PatientGuideIntake?: number;
    /** Family Guide Intake slots per week */
    FamilyGuideIntake?: number;
    /** Care Advocate Intake slots per week */
    CareAdvocateIntake?: number;
    _id?: string;
  };
  /**
   * Memory is appended to the start of every GPT conversation to simulate memory and make it less
   * tedious
   */
  gptMemory?: string;
  /**
   * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
   * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
   */
  leadsquaredId?: string;
  /** The ID of the patient in DoseSpot */
  dosespotPatientId?: string;
  /** The ID of the clinician in DoseSpot */
  dosespotClinicianId?: string;
  _id?: string;
  admin?: boolean;
  email?: string;
  /** When a user is set to disable, all requests will return a 401 */
  disabled?: boolean;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  hash?: string;
  salt?: string;
  attempts?: number;
  last?: string;
  userStatusAlerts?: any;
};
export type GetUsersRes = /** status 200 Successful list */ {
  data?: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens: string[];
    /** Web push subscription info */
    webPushTokens: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
    userStatusAlerts?: any;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetUsersArgs = {
  _id?: {
    $in?: string[];
  };
  type?:
    | ("Staff" | "Member" | "FamilyMember" | "Patient")
    | {
        $in?: string[];
      };
  name?:
    | string
    | {
        $in?: string[];
      };
  initials?:
    | string
    | {
        $in?: string[];
      };
  testUser?:
    | boolean
    | {
        $in?: boolean[];
      };
  disabled?:
    | boolean
    | {
        $in?: boolean[];
      };
  patientId?: {
    $gte?: number;
    $gt?: number;
    $lte?: number;
    $lt?: number;
  };
  "careTeam.Therapist"?:
    | any
    | {
        $in?: any[];
      };
  "careTeam.Psychiatrist"?:
    | any
    | {
        $in?: any[];
      };
  "careTeam.Guide"?:
    | any
    | {
        $in?: any[];
      };
  "careTeam.PatientGuide"?:
    | any
    | {
        $in?: any[];
      };
  "careTeam.FamilyGuide"?:
    | any
    | {
        $in?: any[];
      };
  "staffRoles.Psychiatrist"?:
    | any
    | {
        $in?: any[];
      };
  "staffRoles.PatientGuideSupervisor"?:
    | any
    | {
        $in?: any[];
      };
  "staffRoles.FamilyGuideSupervisor"?:
    | any
    | {
        $in?: any[];
      };
  "staffRoles.TherapistSupervisor"?:
    | any
    | {
        $in?: any[];
      };
  carePod?:
    | any
    | {
        $in?: any[];
      };
  supervisor?:
    | any
    | {
        $in?: any[];
      };
  $or?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetUsersByIdRes = /** status 200 Successful read */ {
  /**
   * The preferred, full name for the user. If their legal/insurance name is different,
   * that should be stored in billing info.
   */
  name: string;
  /** How the user or staff's name is pronounced. This is visible in the user app. */
  namePronunciation?: string;
  /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
  initials?: string;
  birthday?: any;
  /**
   * The gender the user identifies as. If different than the gender listed on their insurance,
   * that should be stored in billing info.
   */
  gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
  /** If gender is 'Prefer to self-describe', this is used */
  genderSelfDescribe?: string;
  /** The user's current timezone, based on their phone's location. */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  staffRoles: {
    /**
     * Gives the ability to access all users, set other staff passwords, and access super user
     * tools.
     */
    SuperUser?: boolean;
    CareAdvocate?: boolean;
    FamilyGuide?: boolean;
    PatientGuide?: boolean;
    Psychiatrist?: boolean;
    Therapist?: boolean;
    EnrollmentCoordinator?: boolean;
    FamilyGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    TherapistSupervisor?: boolean;
    ClinicalLeader?: boolean;
    ClinicalDirector?: boolean;
    /** Only one staff user should have this role */
    RiskManager?: boolean;
    /** Only one staff user should have this role */
    MedicalDirector?: boolean;
    EnrollmentSupervisor?: boolean;
    ClinicalQualityAssurance?: boolean;
    SoftwareEngineer?: boolean;
    Guide?: boolean;
    MemberGuideSupervisor?: boolean;
    _id?: string;
  };
  outOfOffice?: boolean;
  /** Staff Degrees, certifications, etc. E.g. LCSW */
  credentials?: string;
  /**
   * The Twilio phone number users can text or call to reach this staff member.
   * Send a text to a test user  to get a phone number assigned.
   */
  staffPhoneNumber?: string;
  careTeam: {
    Therapist?: any;
    Psychiatrist?: any;
    Guide?: any;
    PatientGuide?: any;
    FamilyGuide?: any;
    _id?: string;
  };
  /** The staff's supervisor. */
  supervisor?: any;
  fitbitId?: string;
  fitbitAccessToken?: string;
  fitbitRefreshToken?: string;
  fitbitConfig?: {
    enabled?: boolean;
    sleepEnabled?: boolean;
    activityEnabled?: boolean;
    allowReminders?: boolean;
    note?: string;
  };
  type: "Staff" | "Member" | "FamilyMember" | "Patient";
  consentFormAgreements: {
    consentFormId: number;
    isAgreed: boolean;
    agreedDate?: string;
    consentFormType:
      | "patientAgreement"
      | "familyMemberAgreement"
      | "consent"
      | "transportation"
      | "research"
      | "privacy"
      | "hipaa"
      | "virginiaRights";
    _id?: string;
  }[];
  /** Whether the user has agreed to receive push notifications */
  pushNotifications?: boolean;
  /**
   * Whether SMS messages are allowed or not for the user.
   * Generally used to disable SMS after delivery failures.
   */
  smsEnabled?: boolean;
  smsErrors?: {
    message?: string;
    notification?: any;
    error?: string;
    date?: string;
  }[];
  /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
  smsNotifications?: boolean;
  /** Whether the user has agreed to receive SMS messages from staff */
  smsMessaging?: boolean;
  expoToken?: string;
  expoTokens: string[];
  /** Web push subscription info */
  webPushTokens: {
    /**
     * The endpoint takes the form of a custom URL pointing to a push server,
     * which can be used to send a push message to the particular service worker instance that
     * subscribed to the push service.
     */
    endpoint: string;
    /** An authentication secret for the push server */
    auth: string;
    /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
    p256dh: string;
    _id?: string;
  }[];
  conversations?: {
    conversationId: any;
    role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
  }[];
  /** Hides the user from the UI without deleting them. */
  archived?: boolean;
  address: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    countyName?: string;
    countyCode?: string;
    _id?: string;
  };
  phoneNumber?: string;
  usageData: {
    /** The last time the app was launched. */
    lastAppLaunch?: string;
    currentAppVersion?: string;
    phoneModel?: string;
    operatingSystem?: string;
    _id?: string;
  };
  testUser?: boolean;
  pronouns?: string;
  bio?: string;
  /** A string describing the user's care plan. This will be visible in the apps. */
  carePlan?: string;
  /**
   * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
   * and quick current info. This is only shown in the staff app.
   */
  oneLiner?: string;
  /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
  weeklyUpdates?: string;
  /**
   * Care team members are doing some work on “thrive planning” with patients and this is where
   * they can store it shown to staff AND members
   */
  thrivePlan?: string;
  engagementDetails?: string;
  /** Things that could be triggering for the patient that staff needs to be aware of. */
  safetyConcerns?: string[];
  /**
   * A string describing the user's safety plan. This will be visible in the apps under care plan.
   */
  safetyPlan?: string;
  /**
   * Some early test users will be receiving guide-only services, with no clinical services. At a
   * minimum, we hide references to our clinical services from their app and make this apparent in
   * the workflows.
   */
  guideOnly?: boolean;
  /**
   * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand for
   * members. Lots of our forms require memberId to be written on every page,and this is a lot
   * easier than `_id`. Use a unique sparse index because staff don't have memberIds,
   * and we don't want to have to check for nulls.
   */
  memberId?: number;
  patientId?: number;
  /**
   * Charm automatically creates a patient id for us to use. If this is not set,
   * the user is not synced to Charm.
   */
  charmPatientId?: string;
  /**
   * For staff that are also set up on Charm, we save their ID so we can show which provider is
   * writing/signing notes.
   */
  charmProviderId?: string;
  taskIdentifiers: {
    scheduledOfflineToggleForMembersTaskName?: string;
    scheduledOfflineToggleForPatientsTaskName?: string;
    scheduledOfflineToggleForFamilyMembersTaskName?: string;
    _id?: string;
  };
  online: {
    forMembers?: boolean;
    scheduledOfflineToggleForMembers?: string;
    forPatients?: boolean;
    scheduledOfflineToggleForPatients?: string;
    forFamilyMembers?: boolean;
    scheduledOfflineToggleForFamilyMembers?: string;
    _id?: string;
  };
  billingInfo: {
    firstName?: string;
    lastName?: string;
    gender?: "male" | "female" | "unknown" | "other";
    insurancePlan?: any;
    renewalDate?: any;
    memberId?: string;
    groupId?: string;
    healthPlanId?: string;
    policyHolder?: "self" | "other";
    authorizationCode?: string;
    medicaidNumber?: string;
    _id?: string;
  };
  charmErrors?: string[];
  growth: {
    authorizationDate?: any;
    referralSource?: any;
    referralMethod?: any;
    /** The date the patient was referred to Flourish, either self-referral or from a partner */
    referralDate?: any;
    /** The date the patient was accepted into our enrollment pipeline. */
    referralAcceptedDate?: any;
    /**
     * The date we can start billing for the patient.
     * This should be the date of their first attended therapy intake.
     * This will be used to calculate billing months for the patient,
     * which will be used for engagement tracking.
     * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
     * 2/15 - 3/14. Or 1/31 - 2/28.
     */
    serviceStartDate?: any;
    /** The date the patient was fully enrolled in the program and finished intakes. */
    enrolledDate?: any;
    /** The actual date the patient was discharged from the Flourish Health program. */
    dischargeDate?: any;
    /**
     * The anticipated date we expect the patient will be discharged from the Flourish Health
     * program. When service start date is set, this is defaulted to 1 year from service start
     * date. Will also be edited to reflect anticipated upcoming early discharge.
     */
    estimatedDischargeDate?: any;
    /** Whether the patient is currently hospitalized. */
    isPatientCurrentlyHospitalized?: boolean;
    /** Whether the patient is currently in outpatient care. */
    outpatient?: boolean;
    /**
     * The date the patient was discharged from a hospital prior to enrollment in Flourish Program.
     */
    hospitalDischargeDate?: any;
    /**
     * The external therapist/other clinician who referred the patient to Flourish and was likely
     * part of the patient's most recent care team. This may be the same as referringPsychiatrist.
     */
    referringClinician?: any;
    /**
     * The external psychiatrist who referred the patient to Flourish and was likely part of the
     * patient's most recent care team.
     */
    referringPsychiatrist?: any;
    /** Link to the Google Drive folder where the user's documents outside the app are stored. */
    documentFolder?: string;
    /** Notes about the user's enrollment process, for internal use. */
    notes?: string;
    eligibilityInterview?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    clinicalIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    therapyIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    psychiatryIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    inHomeOnboarding?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    meetAndGreet?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    origin?: string;
    leadSource?: string;
    optInDetails?: string;
    optInDate?: string;
    sourceReferrerUrl?: string;
    sourceContent?: string;
    sourceCampaign?: string;
    conversionReferrerUrl?: string;
    referringProviderPhoneNumber?: string;
    referringProviderOrganization?: string;
    referringProviderName?: string;
    referringProviderEmail?: string;
    referringClinicianName?: string;
    _id?: string;
  };
  tasks: {
    roiSent?: boolean;
    signedROIReceived?: boolean;
    signedROISentToProviders?: boolean;
    medicalRecordsRequested?: boolean;
    dischargeMedsReceived?: boolean;
    documentationRequestSent?: boolean;
    authorizationInformationConfirmed?: boolean;
    _id?: string;
  };
  contacts: {
    name: string;
    types: string[];
    isPrimary: boolean;
    phoneNumber?: string;
    email?: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    notes?: string;
    _id?: string;
  }[];
  /** SMS, app, phone, email, times of day, etc */
  preferredPharmacies: {
    primary: boolean;
    name: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    faxNumber?: string;
    notes?: string;
    _id?: string;
  }[];
  settings: {
    colorTheme?: string;
    _id?: string;
  };
  statusId?: any;
  /** The reason(s) selected for the user's current UserStatus */
  statusReasons?: string[];
  carePod?: any;
  /**
   * Link to the video chat platform the user prefers. This is shown in the patient/family member
   * app. Usually Doxy or Zoom.
   */
  videoChatLink?: string;
  /** The last time a claim was submitted for this user */
  lastClaimDate?: string;
  /** SMS, app, phone, email, times of day, etc. */
  communicationPreferences?: string;
  /** The primary language the user speaks */
  languages?: string;
  interpreterNeeded?: boolean;
  featureFlags: {
    enableAlertInstances?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableWebPush?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTwilioCalling?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    allowOpenNotesScheduleItemLinking?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableGoogleCalendar?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableToDoList?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    generateFeedbackSurveys?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableReorganizePanel?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enablePreClinicUpdates?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    appointments?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableUserAlertsExplorer?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventHelpButton?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventCrisisEscalation?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTherapyCadenceIndicator?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableApptTypesTherapyPatientAndTherapyCaregiver?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableDoseSpot?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    _id?: string;
  };
  /** Which Flourish company the user is associated with */
  companyOrganizationId?: any;
  /** The user's diagnosis */
  diagnosis: {
    diagnoses?: any[];
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** helps staff remember what kind of engagement/outreach a patient needs */
  clinicalStatus: {
    status?: "High" | "Moderate" | "Mild-Moderate";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** how often the patient has recurring therapy appointments */
  therapyCadence: {
    status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** The hours a staff user is available to be scheduled. */
  availabilitySchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  scheduleCapacity: {
    /** Psychiatry Intake slots per week */
    PsychiatryIntake?: number;
    /** Therapy Intake slots per week */
    TherapyIntake?: number;
    /** Patient Guide Intake slots per week */
    PatientGuideIntake?: number;
    /** Family Guide Intake slots per week */
    FamilyGuideIntake?: number;
    /** Care Advocate Intake slots per week */
    CareAdvocateIntake?: number;
    _id?: string;
  };
  /**
   * Memory is appended to the start of every GPT conversation to simulate memory and make it less
   * tedious
   */
  gptMemory?: string;
  /**
   * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
   * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
   */
  leadsquaredId?: string;
  /** The ID of the patient in DoseSpot */
  dosespotPatientId?: string;
  /** The ID of the clinician in DoseSpot */
  dosespotClinicianId?: string;
  _id: string;
  admin?: boolean;
  email?: string;
  /** When a user is set to disable, all requests will return a 401 */
  disabled?: boolean;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  hash?: string;
  salt?: string;
  attempts?: number;
  last?: string;
  userStatusAlerts?: any;
};
export type GetUsersByIdArgs = string;
export type PatchUsersByIdRes = /** status 200 Successful update */ {
  /**
   * The preferred, full name for the user. If their legal/insurance name is different,
   * that should be stored in billing info.
   */
  name: string;
  /** How the user or staff's name is pronounced. This is visible in the user app. */
  namePronunciation?: string;
  /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
  initials?: string;
  birthday?: any;
  /**
   * The gender the user identifies as. If different than the gender listed on their insurance,
   * that should be stored in billing info.
   */
  gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
  /** If gender is 'Prefer to self-describe', this is used */
  genderSelfDescribe?: string;
  /** The user's current timezone, based on their phone's location. */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  staffRoles: {
    /**
     * Gives the ability to access all users, set other staff passwords, and access super user
     * tools.
     */
    SuperUser?: boolean;
    CareAdvocate?: boolean;
    FamilyGuide?: boolean;
    PatientGuide?: boolean;
    Psychiatrist?: boolean;
    Therapist?: boolean;
    EnrollmentCoordinator?: boolean;
    FamilyGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    TherapistSupervisor?: boolean;
    ClinicalLeader?: boolean;
    ClinicalDirector?: boolean;
    /** Only one staff user should have this role */
    RiskManager?: boolean;
    /** Only one staff user should have this role */
    MedicalDirector?: boolean;
    EnrollmentSupervisor?: boolean;
    ClinicalQualityAssurance?: boolean;
    SoftwareEngineer?: boolean;
    Guide?: boolean;
    MemberGuideSupervisor?: boolean;
    _id?: string;
  };
  outOfOffice?: boolean;
  /** Staff Degrees, certifications, etc. E.g. LCSW */
  credentials?: string;
  /**
   * The Twilio phone number users can text or call to reach this staff member.
   * Send a text to a test user  to get a phone number assigned.
   */
  staffPhoneNumber?: string;
  careTeam: {
    Therapist?: any;
    Psychiatrist?: any;
    Guide?: any;
    PatientGuide?: any;
    FamilyGuide?: any;
    _id?: string;
  };
  /** The staff's supervisor. */
  supervisor?: any;
  fitbitId?: string;
  fitbitAccessToken?: string;
  fitbitRefreshToken?: string;
  fitbitConfig?: {
    enabled?: boolean;
    sleepEnabled?: boolean;
    activityEnabled?: boolean;
    allowReminders?: boolean;
    note?: string;
  };
  type: "Staff" | "Member" | "FamilyMember" | "Patient";
  consentFormAgreements: {
    consentFormId: number;
    isAgreed: boolean;
    agreedDate?: string;
    consentFormType:
      | "patientAgreement"
      | "familyMemberAgreement"
      | "consent"
      | "transportation"
      | "research"
      | "privacy"
      | "hipaa"
      | "virginiaRights";
    _id?: string;
  }[];
  /** Whether the user has agreed to receive push notifications */
  pushNotifications?: boolean;
  /**
   * Whether SMS messages are allowed or not for the user.
   * Generally used to disable SMS after delivery failures.
   */
  smsEnabled?: boolean;
  smsErrors?: {
    message?: string;
    notification?: any;
    error?: string;
    date?: string;
  }[];
  /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
  smsNotifications?: boolean;
  /** Whether the user has agreed to receive SMS messages from staff */
  smsMessaging?: boolean;
  expoToken?: string;
  expoTokens: string[];
  /** Web push subscription info */
  webPushTokens: {
    /**
     * The endpoint takes the form of a custom URL pointing to a push server,
     * which can be used to send a push message to the particular service worker instance that
     * subscribed to the push service.
     */
    endpoint: string;
    /** An authentication secret for the push server */
    auth: string;
    /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
    p256dh: string;
    _id?: string;
  }[];
  conversations?: {
    conversationId: any;
    role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
  }[];
  /** Hides the user from the UI without deleting them. */
  archived?: boolean;
  address: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    countyName?: string;
    countyCode?: string;
    _id?: string;
  };
  phoneNumber?: string;
  usageData: {
    /** The last time the app was launched. */
    lastAppLaunch?: string;
    currentAppVersion?: string;
    phoneModel?: string;
    operatingSystem?: string;
    _id?: string;
  };
  testUser?: boolean;
  pronouns?: string;
  bio?: string;
  /** A string describing the user's care plan. This will be visible in the apps. */
  carePlan?: string;
  /**
   * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
   * and quick current info. This is only shown in the staff app.
   */
  oneLiner?: string;
  /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
  weeklyUpdates?: string;
  /**
   * Care team members are doing some work on “thrive planning” with patients and this is where
   * they can store it shown to staff AND members
   */
  thrivePlan?: string;
  engagementDetails?: string;
  /** Things that could be triggering for the patient that staff needs to be aware of. */
  safetyConcerns?: string[];
  /**
   * A string describing the user's safety plan. This will be visible in the apps under care plan.
   */
  safetyPlan?: string;
  /**
   * Some early test users will be receiving guide-only services, with no clinical services. At a
   * minimum, we hide references to our clinical services from their app and make this apparent in
   * the workflows.
   */
  guideOnly?: boolean;
  /**
   * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand for
   * members. Lots of our forms require memberId to be written on every page,and this is a lot
   * easier than `_id`. Use a unique sparse index because staff don't have memberIds,
   * and we don't want to have to check for nulls.
   */
  memberId?: number;
  patientId?: number;
  /**
   * Charm automatically creates a patient id for us to use. If this is not set,
   * the user is not synced to Charm.
   */
  charmPatientId?: string;
  /**
   * For staff that are also set up on Charm, we save their ID so we can show which provider is
   * writing/signing notes.
   */
  charmProviderId?: string;
  taskIdentifiers: {
    scheduledOfflineToggleForMembersTaskName?: string;
    scheduledOfflineToggleForPatientsTaskName?: string;
    scheduledOfflineToggleForFamilyMembersTaskName?: string;
    _id?: string;
  };
  online: {
    forMembers?: boolean;
    scheduledOfflineToggleForMembers?: string;
    forPatients?: boolean;
    scheduledOfflineToggleForPatients?: string;
    forFamilyMembers?: boolean;
    scheduledOfflineToggleForFamilyMembers?: string;
    _id?: string;
  };
  billingInfo: {
    firstName?: string;
    lastName?: string;
    gender?: "male" | "female" | "unknown" | "other";
    insurancePlan?: any;
    renewalDate?: any;
    memberId?: string;
    groupId?: string;
    healthPlanId?: string;
    policyHolder?: "self" | "other";
    authorizationCode?: string;
    medicaidNumber?: string;
    _id?: string;
  };
  charmErrors?: string[];
  growth: {
    authorizationDate?: any;
    referralSource?: any;
    referralMethod?: any;
    /** The date the patient was referred to Flourish, either self-referral or from a partner */
    referralDate?: any;
    /** The date the patient was accepted into our enrollment pipeline. */
    referralAcceptedDate?: any;
    /**
     * The date we can start billing for the patient.
     * This should be the date of their first attended therapy intake.
     * This will be used to calculate billing months for the patient,
     * which will be used for engagement tracking.
     * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
     * 2/15 - 3/14. Or 1/31 - 2/28.
     */
    serviceStartDate?: any;
    /** The date the patient was fully enrolled in the program and finished intakes. */
    enrolledDate?: any;
    /** The actual date the patient was discharged from the Flourish Health program. */
    dischargeDate?: any;
    /**
     * The anticipated date we expect the patient will be discharged from the Flourish Health
     * program. When service start date is set, this is defaulted to 1 year from service start
     * date. Will also be edited to reflect anticipated upcoming early discharge.
     */
    estimatedDischargeDate?: any;
    /** Whether the patient is currently hospitalized. */
    isPatientCurrentlyHospitalized?: boolean;
    /** Whether the patient is currently in outpatient care. */
    outpatient?: boolean;
    /**
     * The date the patient was discharged from a hospital prior to enrollment in Flourish Program.
     */
    hospitalDischargeDate?: any;
    /**
     * The external therapist/other clinician who referred the patient to Flourish and was likely
     * part of the patient's most recent care team. This may be the same as referringPsychiatrist.
     */
    referringClinician?: any;
    /**
     * The external psychiatrist who referred the patient to Flourish and was likely part of the
     * patient's most recent care team.
     */
    referringPsychiatrist?: any;
    /** Link to the Google Drive folder where the user's documents outside the app are stored. */
    documentFolder?: string;
    /** Notes about the user's enrollment process, for internal use. */
    notes?: string;
    eligibilityInterview?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    clinicalIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    therapyIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    psychiatryIntake?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    inHomeOnboarding?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    meetAndGreet?: {
      /** The date the intake was scheduled for. */
      date?: string;
      /** Whether the intake was attended yet or not. */
      attended?: boolean;
      /** The linked schedule item for the intake. */
      scheduleItemId?: string;
      _id?: string;
      created?: string;
      updated?: string;
    };
    origin?: string;
    leadSource?: string;
    optInDetails?: string;
    optInDate?: string;
    sourceReferrerUrl?: string;
    sourceContent?: string;
    sourceCampaign?: string;
    conversionReferrerUrl?: string;
    referringProviderPhoneNumber?: string;
    referringProviderOrganization?: string;
    referringProviderName?: string;
    referringProviderEmail?: string;
    referringClinicianName?: string;
    _id?: string;
  };
  tasks: {
    roiSent?: boolean;
    signedROIReceived?: boolean;
    signedROISentToProviders?: boolean;
    medicalRecordsRequested?: boolean;
    dischargeMedsReceived?: boolean;
    documentationRequestSent?: boolean;
    authorizationInformationConfirmed?: boolean;
    _id?: string;
  };
  contacts: {
    name: string;
    types: string[];
    isPrimary: boolean;
    phoneNumber?: string;
    email?: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    notes?: string;
    _id?: string;
  }[];
  /** SMS, app, phone, email, times of day, etc */
  preferredPharmacies: {
    primary: boolean;
    name: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    faxNumber?: string;
    notes?: string;
    _id?: string;
  }[];
  settings: {
    colorTheme?: string;
    _id?: string;
  };
  statusId?: any;
  /** The reason(s) selected for the user's current UserStatus */
  statusReasons?: string[];
  carePod?: any;
  /**
   * Link to the video chat platform the user prefers. This is shown in the patient/family member
   * app. Usually Doxy or Zoom.
   */
  videoChatLink?: string;
  /** The last time a claim was submitted for this user */
  lastClaimDate?: string;
  /** SMS, app, phone, email, times of day, etc. */
  communicationPreferences?: string;
  /** The primary language the user speaks */
  languages?: string;
  interpreterNeeded?: boolean;
  featureFlags: {
    enableAlertInstances?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableWebPush?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTwilioCalling?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    allowOpenNotesScheduleItemLinking?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableGoogleCalendar?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableToDoList?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    generateFeedbackSurveys?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableReorganizePanel?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enablePreClinicUpdates?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    appointments?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableUserAlertsExplorer?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventHelpButton?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableCriticalEventCrisisEscalation?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableTherapyCadenceIndicator?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableApptTypesTherapyPatientAndTherapyCaregiver?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    enableDoseSpot?: {
      enabled?: boolean;
      showTutorial?: boolean;
    };
    _id?: string;
  };
  /** Which Flourish company the user is associated with */
  companyOrganizationId?: any;
  /** The user's diagnosis */
  diagnosis: {
    diagnoses?: any[];
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** helps staff remember what kind of engagement/outreach a patient needs */
  clinicalStatus: {
    status?: "High" | "Moderate" | "Mild-Moderate";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** how often the patient has recurring therapy appointments */
  therapyCadence: {
    status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
    _id?: string;
    created?: string;
    updated?: string;
  };
  /** The hours a staff user is available to be scheduled. */
  availabilitySchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  scheduleCapacity: {
    /** Psychiatry Intake slots per week */
    PsychiatryIntake?: number;
    /** Therapy Intake slots per week */
    TherapyIntake?: number;
    /** Patient Guide Intake slots per week */
    PatientGuideIntake?: number;
    /** Family Guide Intake slots per week */
    FamilyGuideIntake?: number;
    /** Care Advocate Intake slots per week */
    CareAdvocateIntake?: number;
    _id?: string;
  };
  /**
   * Memory is appended to the start of every GPT conversation to simulate memory and make it less
   * tedious
   */
  gptMemory?: string;
  /**
   * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
   * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
   */
  leadsquaredId?: string;
  /** The ID of the patient in DoseSpot */
  dosespotPatientId?: string;
  /** The ID of the clinician in DoseSpot */
  dosespotClinicianId?: string;
  _id: string;
  admin?: boolean;
  email?: string;
  /** When a user is set to disable, all requests will return a 401 */
  disabled?: boolean;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  hash?: string;
  salt?: string;
  attempts?: number;
  last?: string;
  userStatusAlerts?: any;
};
export type PatchUsersByIdArgs = {
  id: string;
  body: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
    userStatusAlerts?: any;
  };
};
export type DeleteUsersByIdRes = /** status 204 Successful delete */ void;
export type DeleteUsersByIdArgs = string;
export type PostUserSessionsRes = /** status 201 Successful create */ {
  ownerId: string;
  lastOnlineWeb?: string;
  lastOnlineMobile?: string;
  /** If set, the user is in do not disturb mode until this date, in UTC */
  doNotDisturbUntil?: string;
  notificationSchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  /**
   * The timezone for the schedule. Separate from user.timezone because we don't want these to
   * automatically change if the user travels
   */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  notificationSettings: {
    notifyInternalChatMessages?: boolean;
    notifyInternalChatMentions?: boolean;
    notifyUserUpdates?: boolean;
    notifyUserAlerts?: boolean;
    notificationSound?: string;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  doNotDisturb?: any;
};
export type PostUserSessionsArgs = {
  ownerId?: string;
  lastOnlineWeb?: string;
  lastOnlineMobile?: string;
  /** If set, the user is in do not disturb mode until this date, in UTC */
  doNotDisturbUntil?: string;
  notificationSchedule?: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  /**
   * The timezone for the schedule. Separate from user.timezone because we don't want these to
   * automatically change if the user travels
   */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  notificationSettings?: {
    notifyInternalChatMessages?: boolean;
    notifyInternalChatMentions?: boolean;
    notifyUserUpdates?: boolean;
    notifyUserAlerts?: boolean;
    notificationSound?: string;
    _id?: string;
  };
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  doNotDisturb?: any;
};
export type GetUserSessionsRes = /** status 200 Successful list */ {
  data?: {
    ownerId: string;
    lastOnlineWeb?: string;
    lastOnlineMobile?: string;
    /** If set, the user is in do not disturb mode until this date, in UTC */
    doNotDisturbUntil?: string;
    notificationSchedule: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    /**
     * The timezone for the schedule. Separate from user.timezone because we don't want these to
     * automatically change if the user travels
     */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    notificationSettings: {
      notifyInternalChatMessages?: boolean;
      notifyInternalChatMentions?: boolean;
      notifyUserUpdates?: boolean;
      notifyUserAlerts?: boolean;
      notificationSound?: string;
      _id?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    doNotDisturb?: any;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetUserSessionsArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetUserSessionsByIdRes = /** status 200 Successful read */ {
  ownerId: string;
  lastOnlineWeb?: string;
  lastOnlineMobile?: string;
  /** If set, the user is in do not disturb mode until this date, in UTC */
  doNotDisturbUntil?: string;
  notificationSchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  /**
   * The timezone for the schedule. Separate from user.timezone because we don't want these to
   * automatically change if the user travels
   */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  notificationSettings: {
    notifyInternalChatMessages?: boolean;
    notifyInternalChatMentions?: boolean;
    notifyUserUpdates?: boolean;
    notifyUserAlerts?: boolean;
    notificationSound?: string;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  doNotDisturb?: any;
};
export type GetUserSessionsByIdArgs = string;
export type PatchUserSessionsByIdRes = /** status 200 Successful update */ {
  ownerId: string;
  lastOnlineWeb?: string;
  lastOnlineMobile?: string;
  /** If set, the user is in do not disturb mode until this date, in UTC */
  doNotDisturbUntil?: string;
  notificationSchedule: {
    /** Start hour, 24 hour format */
    startHour: number;
    /** End hour, 24 hour format */
    endHour: number;
    /** Start minute, 24 hour format */
    startMin: number;
    /** End minute, 24 hour format */
    endMin: number;
    /** Day of the week for the schedule */
    day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
    _id?: string;
  }[];
  /**
   * The timezone for the schedule. Separate from user.timezone because we don't want these to
   * automatically change if the user travels
   */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  notificationSettings: {
    notifyInternalChatMessages?: boolean;
    notifyInternalChatMentions?: boolean;
    notifyUserUpdates?: boolean;
    notifyUserAlerts?: boolean;
    notificationSound?: string;
    _id?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  doNotDisturb?: any;
};
export type PatchUserSessionsByIdArgs = {
  id: string;
  body: {
    ownerId?: string;
    lastOnlineWeb?: string;
    lastOnlineMobile?: string;
    /** If set, the user is in do not disturb mode until this date, in UTC */
    doNotDisturbUntil?: string;
    notificationSchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    /**
     * The timezone for the schedule. Separate from user.timezone because we don't want these to
     * automatically change if the user travels
     */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    notificationSettings?: {
      notifyInternalChatMessages?: boolean;
      notifyInternalChatMentions?: boolean;
      notifyUserUpdates?: boolean;
      notifyUserAlerts?: boolean;
      notificationSound?: string;
      _id?: string;
    };
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    doNotDisturb?: any;
  };
};
export type DeleteUserSessionsByIdRes = /** status 204 Successful delete */ void;
export type DeleteUserSessionsByIdArgs = string;
export type PostUserSettingsRes = /** status 201 Successful create */ {
  _id: any;
  panelConfig?: {
    isAlphabetized?: boolean;
    selectedCarePod?: string;
    reorganizedWorkflowMappings?: {
      workflowMappingId?: any;
      pinned?: boolean;
      snoozedUntil?: string;
      completed?: boolean;
    }[];
    _id?: string;
    modifiedWorkflowMappingIds?: any;
  };
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostUserSettingsArgs = {
  _id?: any;
  panelConfig?: {
    isAlphabetized?: boolean;
    selectedCarePod?: string;
    reorganizedWorkflowMappings?: {
      workflowMappingId?: any;
      pinned?: boolean;
      snoozedUntil?: string;
      completed?: boolean;
    }[];
    _id?: string;
    modifiedWorkflowMappingIds?: any;
  };
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserSettingsByIdRes = /** status 200 Successful read */ {
  _id: any;
  panelConfig?: {
    isAlphabetized?: boolean;
    selectedCarePod?: string;
    reorganizedWorkflowMappings?: {
      workflowMappingId?: any;
      pinned?: boolean;
      snoozedUntil?: string;
      completed?: boolean;
    }[];
    _id?: string;
    modifiedWorkflowMappingIds?: any;
  };
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserSettingsByIdArgs = string;
export type PatchUserSettingsByIdRes = /** status 200 Successful update */ {
  _id: any;
  panelConfig?: {
    isAlphabetized?: boolean;
    selectedCarePod?: string;
    reorganizedWorkflowMappings?: {
      workflowMappingId?: any;
      pinned?: boolean;
      snoozedUntil?: string;
      completed?: boolean;
    }[];
    _id?: string;
    modifiedWorkflowMappingIds?: any;
  };
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchUserSettingsByIdArgs = {
  id: string;
  body: {
    _id?: any;
    panelConfig?: {
      isAlphabetized?: boolean;
      selectedCarePod?: string;
      reorganizedWorkflowMappings?: {
        workflowMappingId?: any;
        pinned?: boolean;
        snoozedUntil?: string;
        completed?: boolean;
      }[];
      _id?: string;
      modifiedWorkflowMappingIds?: any;
    };
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type PostUserStatusesRes = /** status 201 Successful create */ {
  name: string;
  /** Whether or not to prompt to select a reason when assigning this status to a user. */
  captureReasons?: boolean;
  /** Possible reasons for selecting this status for a given user. */
  reasons?: string[];
  index?: number;
  color:
    | "primary"
    | "secondary"
    | "tertiary"
    | "accent"
    | "blue"
    | "darkGray"
    | "eggplant"
    | "gray"
    | "green"
    | "springGreen"
    | "lightGray"
    | "maroon"
    | "midnight"
    | "navy"
    | "olive"
    | "orange"
    | "orchid"
    | "pine"
    | "purple"
    | "red"
    | "watermelon";
  fontColor: "light" | "dark";
  /** If the user is part of the growth team's workflow */
  isGrowth?: boolean;
  /** If the user is now considered to be in care. */
  isCare?: boolean;
  /** If the user should be billable in this status. */
  isBillable?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostUserStatusesArgs = {
  name?: string;
  /** Whether or not to prompt to select a reason when assigning this status to a user. */
  captureReasons?: boolean;
  /** Possible reasons for selecting this status for a given user. */
  reasons?: string[];
  index?: number;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "accent"
    | "blue"
    | "darkGray"
    | "eggplant"
    | "gray"
    | "green"
    | "springGreen"
    | "lightGray"
    | "maroon"
    | "midnight"
    | "navy"
    | "olive"
    | "orange"
    | "orchid"
    | "pine"
    | "purple"
    | "red"
    | "watermelon";
  fontColor?: "light" | "dark";
  /** If the user is part of the growth team's workflow */
  isGrowth?: boolean;
  /** If the user is now considered to be in care. */
  isCare?: boolean;
  /** If the user should be billable in this status. */
  isBillable?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserStatusesRes = /** status 200 Successful list */ {
  data?: {
    name: string;
    /** Whether or not to prompt to select a reason when assigning this status to a user. */
    captureReasons?: boolean;
    /** Possible reasons for selecting this status for a given user. */
    reasons?: string[];
    index?: number;
    color:
      | "primary"
      | "secondary"
      | "tertiary"
      | "accent"
      | "blue"
      | "darkGray"
      | "eggplant"
      | "gray"
      | "green"
      | "springGreen"
      | "lightGray"
      | "maroon"
      | "midnight"
      | "navy"
      | "olive"
      | "orange"
      | "orchid"
      | "pine"
      | "purple"
      | "red"
      | "watermelon";
    fontColor: "light" | "dark";
    /** If the user is part of the growth team's workflow */
    isGrowth?: boolean;
    /** If the user is now considered to be in care. */
    isCare?: boolean;
    /** If the user should be billable in this status. */
    isBillable?: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetUserStatusesArgs = {
  _id?: {
    $in?: string[];
  };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetUserStatusesByIdRes = /** status 200 Successful read */ {
  name: string;
  /** Whether or not to prompt to select a reason when assigning this status to a user. */
  captureReasons?: boolean;
  /** Possible reasons for selecting this status for a given user. */
  reasons?: string[];
  index?: number;
  color:
    | "primary"
    | "secondary"
    | "tertiary"
    | "accent"
    | "blue"
    | "darkGray"
    | "eggplant"
    | "gray"
    | "green"
    | "springGreen"
    | "lightGray"
    | "maroon"
    | "midnight"
    | "navy"
    | "olive"
    | "orange"
    | "orchid"
    | "pine"
    | "purple"
    | "red"
    | "watermelon";
  fontColor: "light" | "dark";
  /** If the user is part of the growth team's workflow */
  isGrowth?: boolean;
  /** If the user is now considered to be in care. */
  isCare?: boolean;
  /** If the user should be billable in this status. */
  isBillable?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetUserStatusesByIdArgs = string;
export type PatchUserStatusesByIdRes = /** status 200 Successful update */ {
  name: string;
  /** Whether or not to prompt to select a reason when assigning this status to a user. */
  captureReasons?: boolean;
  /** Possible reasons for selecting this status for a given user. */
  reasons?: string[];
  index?: number;
  color:
    | "primary"
    | "secondary"
    | "tertiary"
    | "accent"
    | "blue"
    | "darkGray"
    | "eggplant"
    | "gray"
    | "green"
    | "springGreen"
    | "lightGray"
    | "maroon"
    | "midnight"
    | "navy"
    | "olive"
    | "orange"
    | "orchid"
    | "pine"
    | "purple"
    | "red"
    | "watermelon";
  fontColor: "light" | "dark";
  /** If the user is part of the growth team's workflow */
  isGrowth?: boolean;
  /** If the user is now considered to be in care. */
  isCare?: boolean;
  /** If the user should be billable in this status. */
  isBillable?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchUserStatusesByIdArgs = {
  id: string;
  body: {
    name?: string;
    /** Whether or not to prompt to select a reason when assigning this status to a user. */
    captureReasons?: boolean;
    /** Possible reasons for selecting this status for a given user. */
    reasons?: string[];
    index?: number;
    color?:
      | "primary"
      | "secondary"
      | "tertiary"
      | "accent"
      | "blue"
      | "darkGray"
      | "eggplant"
      | "gray"
      | "green"
      | "springGreen"
      | "lightGray"
      | "maroon"
      | "midnight"
      | "navy"
      | "olive"
      | "orange"
      | "orchid"
      | "pine"
      | "purple"
      | "red"
      | "watermelon";
    fontColor?: "light" | "dark";
    /** If the user is part of the growth team's workflow */
    isGrowth?: boolean;
    /** If the user is now considered to be in care. */
    isCare?: boolean;
    /** If the user should be billable in this status. */
    isBillable?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteUserStatusesByIdRes = /** status 204 Successful delete */ void;
export type DeleteUserStatusesByIdArgs = string;
export type GetUserUpdatesRes = /** status 200 Successful list */ {
  data?: {
    eventName: string;
    authUserId: string;
    appliedUserId?: string;
    ownerId?: string;
    prevValue?: string;
    newValue?: string;
    formType?: string;
    formName?: string;
    markedRead?: boolean;
    archived?: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    description?: string;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetUserUpdatesArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | string
    | {
        $in?: string[];
      };
  appliedUserId?:
    | string
    | {
        $in?: string[];
      };
  eventName?:
    | string
    | {
        $in?: string[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetUserUpdatesByIdRes = /** status 200 Successful read */ {
  eventName: string;
  authUserId: string;
  appliedUserId?: string;
  ownerId?: string;
  prevValue?: string;
  newValue?: string;
  formType?: string;
  formName?: string;
  markedRead?: boolean;
  archived?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  description?: string;
};
export type GetUserUpdatesByIdArgs = string;
export type PatchUserUpdatesByIdRes = /** status 200 Successful update */ {
  eventName: string;
  authUserId: string;
  appliedUserId?: string;
  ownerId?: string;
  prevValue?: string;
  newValue?: string;
  formType?: string;
  formName?: string;
  markedRead?: boolean;
  archived?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
  description?: string;
};
export type PatchUserUpdatesByIdArgs = {
  id: string;
  body: {
    eventName?: string;
    authUserId?: string;
    appliedUserId?: string;
    ownerId?: string;
    prevValue?: string;
    newValue?: string;
    formType?: string;
    formName?: string;
    markedRead?: boolean;
    archived?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    description?: string;
  };
};
export type GetVoicemailsRes = /** status 200 Successful list */ {
  data?: {
    /**
     * Recording source refers to the API that initiated the recording, which in this case is
     * theStartCallRecordingAPI.
     */
    RecordingSource?: string;
    /**
     * Recording track refers to the audio track that was recorded. In this case,
     * it is both the caller and the call recipient.
     */
    RecordingTrack?: string;
    /** RecordingSid is a unique identifier for the recording. */
    RecordingSid: string;
    /** RecordingUrl is the URL of the recording. */
    RecordingUrl: string;
    /**
     * RecordingStatus is the status of the recording, which can be 'in-progress', 'completed', or
     * 'absent'.
     */
    RecordingStatus: "in-progress" | "completed" | "absent";
    /**
     * RecordingChannels is the number of channels that were recorded.
     * Possible values are '1' or '2'
     */
    RecordingChannels?: "1" | "2";
    /** ErrorCode is the error code of the recording, if any. */
    ErrorCode?: string;
    /**
     * CallSid is a unique identifier for the call. This is the call that was recorded.
     * In the current implementation, if the call is forwarded,
     * the CallSid will be the forwarded call's Sid.
     * If we immediatedly started recording due to Do Not Disturb or Out of Office,
     * the CallSid will be the original call's Sid.
     */
    CallSid: string;
    /** RecordingStartTime is the time the recording started */
    RecordingStartTime: string;
    /** AccountSid is a unique identifier for the Twilio account. */
    AccountSid: string;
    /** RecordingDuration is the duration of the recording. */
    RecordingDuration?: string;
    /** The user for whom this voicemail was intended. */
    ownerId: any;
    /** Boolean indicating whether the voicemail has been listened to. */
    read?: boolean;
    /** Boolean indicating whether the voicemail has been archived. */
    archived?: boolean;
    /** Recipient's phone number */
    toPhoneNumber: string;
    toUser: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    /** Sender's phone number */
    fromPhoneNumber: string;
    fromUser?: {
      /**
       * The preferred, full name for the user. If their legal/insurance name is different,
       * that should be stored in billing info.
       */
      name?: string;
      /** How the user or staff's name is pronounced. This is visible in the user app. */
      namePronunciation?: string;
      /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
      initials?: string;
      birthday?: any;
      /**
       * The gender the user identifies as. If different than the gender listed on their insurance,
       * that should be stored in billing info.
       */
      gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
      /** If gender is 'Prefer to self-describe', this is used */
      genderSelfDescribe?: string;
      /** The user's current timezone, based on their phone's location. */
      timezone?:
        | "America/New_York"
        | "America/Chicago"
        | "America/Denver"
        | "America/Los_Angeles"
        | "America/Anchorage"
        | "Pacific/Honolulu"
        | "America/Phoenix";
      staffRoles?: {
        /**
         * Gives the ability to access all users, set other staff passwords, and access super user
         * tools.
         */
        SuperUser?: boolean;
        CareAdvocate?: boolean;
        FamilyGuide?: boolean;
        PatientGuide?: boolean;
        Psychiatrist?: boolean;
        Therapist?: boolean;
        EnrollmentCoordinator?: boolean;
        FamilyGuideSupervisor?: boolean;
        PatientGuideSupervisor?: boolean;
        TherapistSupervisor?: boolean;
        ClinicalLeader?: boolean;
        ClinicalDirector?: boolean;
        /** Only one staff user should have this role */
        RiskManager?: boolean;
        /** Only one staff user should have this role */
        MedicalDirector?: boolean;
        EnrollmentSupervisor?: boolean;
        ClinicalQualityAssurance?: boolean;
        SoftwareEngineer?: boolean;
        Guide?: boolean;
        MemberGuideSupervisor?: boolean;
        _id?: string;
      };
      outOfOffice?: boolean;
      /** Staff Degrees, certifications, etc. E.g. LCSW */
      credentials?: string;
      /**
       * The Twilio phone number users can text or call to reach this staff member.
       * Send a text to a test user  to get a phone number assigned.
       */
      staffPhoneNumber?: string;
      careTeam?: {
        Therapist?: any;
        Psychiatrist?: any;
        Guide?: any;
        PatientGuide?: any;
        FamilyGuide?: any;
        _id?: string;
      };
      /** The staff's supervisor. */
      supervisor?: any;
      fitbitId?: string;
      fitbitAccessToken?: string;
      fitbitRefreshToken?: string;
      fitbitConfig?: {
        enabled?: boolean;
        sleepEnabled?: boolean;
        activityEnabled?: boolean;
        allowReminders?: boolean;
        note?: string;
      };
      type?: "Staff" | "Member" | "FamilyMember" | "Patient";
      consentFormAgreements?: {
        consentFormId: number;
        isAgreed: boolean;
        agreedDate?: string;
        consentFormType:
          | "patientAgreement"
          | "familyMemberAgreement"
          | "consent"
          | "transportation"
          | "research"
          | "privacy"
          | "hipaa"
          | "virginiaRights";
        _id?: string;
      }[];
      /** Whether the user has agreed to receive push notifications */
      pushNotifications?: boolean;
      /**
       * Whether SMS messages are allowed or not for the user.
       * Generally used to disable SMS after delivery failures.
       */
      smsEnabled?: boolean;
      smsErrors?: {
        message?: string;
        notification?: any;
        error?: string;
        date?: string;
      }[];
      /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
      smsNotifications?: boolean;
      /** Whether the user has agreed to receive SMS messages from staff */
      smsMessaging?: boolean;
      expoToken?: string;
      expoTokens?: string[];
      /** Web push subscription info */
      webPushTokens?: {
        /**
         * The endpoint takes the form of a custom URL pointing to a push server,
         * which can be used to send a push message to the particular service worker instance that
         * subscribed to the push service.
         */
        endpoint: string;
        /** An authentication secret for the push server */
        auth: string;
        /**
         * An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server.
         */
        p256dh: string;
        _id?: string;
      }[];
      conversations?: {
        conversationId: any;
        role?:
          | "Guide"
          | "PatientGuide"
          | "Therapist"
          | "Psychiatrist"
          | "SuperUser"
          | "FamilyGuide";
      }[];
      /** Hides the user from the UI without deleting them. */
      archived?: boolean;
      address?: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      usageData?: {
        /** The last time the app was launched. */
        lastAppLaunch?: string;
        currentAppVersion?: string;
        phoneModel?: string;
        operatingSystem?: string;
        _id?: string;
      };
      testUser?: boolean;
      pronouns?: string;
      bio?: string;
      /** A string describing the user's care plan. This will be visible in the apps. */
      carePlan?: string;
      /**
       * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
       * and quick current info. This is only shown in the staff app.
       */
      oneLiner?: string;
      /**
       * A place to store weekly updates ahead of care team huddles. Only shown in the staff app.
       */
      weeklyUpdates?: string;
      /**
       * Care team members are doing some work on “thrive planning” with patients and this is where
       * they can store it shown to staff AND members
       */
      thrivePlan?: string;
      engagementDetails?: string;
      /** Things that could be triggering for the patient that staff needs to be aware of. */
      safetyConcerns?: string[];
      /**
       * A string describing the user's safety plan. This will be visible in the apps under care
       * plan.
       */
      safetyPlan?: string;
      /**
       * Some early test users will be receiving guide-only services, with no clinical services.
       * At a minimum, we hide references to our clinical services from their app and make this
       * apparent in the workflows.
       */
      guideOnly?: boolean;
      /**
       * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
       * for members. Lots of our forms require memberId to be written on every page,and this is a
       * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
       * and we don't want to have to check for nulls.
       */
      memberId?: number;
      patientId?: number;
      /**
       * Charm automatically creates a patient id for us to use. If this is not set,
       * the user is not synced to Charm.
       */
      charmPatientId?: string;
      /**
       * For staff that are also set up on Charm,
       * we save their ID so we can show which provider is writing/signing notes.
       */
      charmProviderId?: string;
      taskIdentifiers?: {
        scheduledOfflineToggleForMembersTaskName?: string;
        scheduledOfflineToggleForPatientsTaskName?: string;
        scheduledOfflineToggleForFamilyMembersTaskName?: string;
        _id?: string;
      };
      online?: {
        forMembers?: boolean;
        scheduledOfflineToggleForMembers?: string;
        forPatients?: boolean;
        scheduledOfflineToggleForPatients?: string;
        forFamilyMembers?: boolean;
        scheduledOfflineToggleForFamilyMembers?: string;
        _id?: string;
      };
      billingInfo?: {
        firstName?: string;
        lastName?: string;
        gender?: "male" | "female" | "unknown" | "other";
        insurancePlan?: any;
        renewalDate?: any;
        memberId?: string;
        groupId?: string;
        healthPlanId?: string;
        policyHolder?: "self" | "other";
        authorizationCode?: string;
        medicaidNumber?: string;
        _id?: string;
      };
      charmErrors?: string[];
      growth?: {
        authorizationDate?: any;
        referralSource?: any;
        referralMethod?: any;
        /** The date the patient was referred to Flourish, either self-referral or from a partner */
        referralDate?: any;
        /** The date the patient was accepted into our enrollment pipeline. */
        referralAcceptedDate?: any;
        /**
         * The date we can start billing for the patient.
         * This should be the date of their first attended therapy intake.
         * This will be used to calculate billing months for the patient,
         * which will be used for engagement tracking.
         * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
         * 2/15 - 3/14. Or 1/31 - 2/28.
         */
        serviceStartDate?: any;
        /** The date the patient was fully enrolled in the program and finished intakes. */
        enrolledDate?: any;
        /** The actual date the patient was discharged from the Flourish Health program. */
        dischargeDate?: any;
        /**
         * The anticipated date we expect the patient will be discharged from the Flourish Health
         * program. When service start date is set, this is defaulted to 1 year from service start
         * date. Will also be edited to reflect anticipated upcoming early discharge.
         */
        estimatedDischargeDate?: any;
        /** Whether the patient is currently hospitalized. */
        isPatientCurrentlyHospitalized?: boolean;
        /** Whether the patient is currently in outpatient care. */
        outpatient?: boolean;
        /**
         * The date the patient was discharged from a hospital prior to enrollment in Flourish
         * Program.
         */
        hospitalDischargeDate?: any;
        /**
         * The external therapist/other clinician who referred the patient to Flourish and was
         * likely part of the patient's most recent care team. This may be the same as
         * referringPsychiatrist.
         */
        referringClinician?: any;
        /**
         * The external psychiatrist who referred the patient to Flourish and was likely part of
         * the patient's most recent care team.
         */
        referringPsychiatrist?: any;
        /**
         * Link to the Google Drive folder where the user's documents outside the app are stored.
         */
        documentFolder?: string;
        /** Notes about the user's enrollment process, for internal use. */
        notes?: string;
        eligibilityInterview?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        clinicalIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        therapyIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        psychiatryIntake?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        inHomeOnboarding?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        meetAndGreet?: {
          /** The date the intake was scheduled for. */
          date?: string;
          /** Whether the intake was attended yet or not. */
          attended?: boolean;
          /** The linked schedule item for the intake. */
          scheduleItemId?: string;
          _id?: string;
          created?: string;
          updated?: string;
        };
        origin?: string;
        leadSource?: string;
        optInDetails?: string;
        optInDate?: string;
        sourceReferrerUrl?: string;
        sourceContent?: string;
        sourceCampaign?: string;
        conversionReferrerUrl?: string;
        referringProviderPhoneNumber?: string;
        referringProviderOrganization?: string;
        referringProviderName?: string;
        referringProviderEmail?: string;
        referringClinicianName?: string;
        _id?: string;
      };
      tasks?: {
        roiSent?: boolean;
        signedROIReceived?: boolean;
        signedROISentToProviders?: boolean;
        medicalRecordsRequested?: boolean;
        dischargeMedsReceived?: boolean;
        documentationRequestSent?: boolean;
        authorizationInformationConfirmed?: boolean;
        _id?: string;
      };
      contacts?: {
        name: string;
        types: string[];
        isPrimary: boolean;
        phoneNumber?: string;
        email?: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        notes?: string;
        _id?: string;
      }[];
      /** SMS, app, phone, email, times of day, etc */
      preferredPharmacies?: {
        primary: boolean;
        name: string;
        address: {
          address1?: string;
          address2?: string;
          city?: string;
          state?: string;
          zipcode?: string;
          countyName?: string;
          countyCode?: string;
          _id?: string;
        };
        phoneNumber?: string;
        faxNumber?: string;
        notes?: string;
        _id?: string;
      }[];
      settings?: {
        colorTheme?: string;
        _id?: string;
      };
      statusId?: any;
      /** The reason(s) selected for the user's current UserStatus */
      statusReasons?: string[];
      carePod?: any;
      /**
       * Link to the video chat platform the user prefers.
       * This is shown in the patient/family member app. Usually Doxy or Zoom.
       */
      videoChatLink?: string;
      /** The last time a claim was submitted for this user */
      lastClaimDate?: string;
      /** SMS, app, phone, email, times of day, etc. */
      communicationPreferences?: string;
      /** The primary language the user speaks */
      languages?: string;
      interpreterNeeded?: boolean;
      featureFlags?: {
        enableAlertInstances?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableWebPush?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTwilioCalling?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        allowOpenNotesScheduleItemLinking?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableGoogleCalendar?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableToDoList?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        generateFeedbackSurveys?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableReorganizePanel?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enablePreClinicUpdates?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        appointments?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableUserAlertsExplorer?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventHelpButton?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableCriticalEventCrisisEscalation?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableTherapyCadenceIndicator?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableApptTypesTherapyPatientAndTherapyCaregiver?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        enableDoseSpot?: {
          enabled?: boolean;
          showTutorial?: boolean;
        };
        _id?: string;
      };
      /** Which Flourish company the user is associated with */
      companyOrganizationId?: any;
      /** The user's diagnosis */
      diagnosis?: {
        diagnoses?: any[];
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** helps staff remember what kind of engagement/outreach a patient needs */
      clinicalStatus?: {
        status?: "High" | "Moderate" | "Mild-Moderate";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** how often the patient has recurring therapy appointments */
      therapyCadence?: {
        status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
        _id?: string;
        created?: string;
        updated?: string;
      };
      /** The hours a staff user is available to be scheduled. */
      availabilitySchedule?: {
        /** Start hour, 24 hour format */
        startHour: number;
        /** End hour, 24 hour format */
        endHour: number;
        /** Start minute, 24 hour format */
        startMin: number;
        /** End minute, 24 hour format */
        endMin: number;
        /** Day of the week for the schedule */
        day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
        _id?: string;
      }[];
      scheduleCapacity?: {
        /** Psychiatry Intake slots per week */
        PsychiatryIntake?: number;
        /** Therapy Intake slots per week */
        TherapyIntake?: number;
        /** Patient Guide Intake slots per week */
        PatientGuideIntake?: number;
        /** Family Guide Intake slots per week */
        FamilyGuideIntake?: number;
        /** Care Advocate Intake slots per week */
        CareAdvocateIntake?: number;
        _id?: string;
      };
      /**
       * Memory is appended to the start of every GPT conversation to simulate memory and make it
       * less tedious
       */
      gptMemory?: string;
      /**
       * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
       * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
       */
      leadsquaredId?: string;
      /** The ID of the patient in DoseSpot */
      dosespotPatientId?: string;
      /** The ID of the clinician in DoseSpot */
      dosespotClinicianId?: string;
      _id?: string;
      admin?: boolean;
      email?: string;
      /** When a user is set to disable, all requests will return a 401 */
      disabled?: boolean;
      updated?: string;
      created?: string;
      /**
       * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
       * true} to find them.
       */
      deleted?: boolean;
      hash?: string;
      salt?: string;
      attempts?: number;
      last?: string;
    };
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetVoicemailsArgs = {
  _id?: {
    $in?: string[];
  };
  ownerId?:
    | any
    | {
        $in?: any[];
      };
  read?:
    | boolean
    | {
        $in?: boolean[];
      };
  archived?:
    | boolean
    | {
        $in?: boolean[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetVoicemailsByIdRes = /** status 200 Successful read */ {
  /**
   * Recording source refers to the API that initiated the recording, which in this case is
   * theStartCallRecordingAPI.
   */
  RecordingSource?: string;
  /**
   * Recording track refers to the audio track that was recorded. In this case,
   * it is both the caller and the call recipient.
   */
  RecordingTrack?: string;
  /** RecordingSid is a unique identifier for the recording. */
  RecordingSid: string;
  /** RecordingUrl is the URL of the recording. */
  RecordingUrl: string;
  /**
   * RecordingStatus is the status of the recording, which can be 'in-progress', 'completed', or
   * 'absent'.
   */
  RecordingStatus: "in-progress" | "completed" | "absent";
  /**
   * RecordingChannels is the number of channels that were recorded.
   * Possible values are '1' or '2'
   */
  RecordingChannels?: "1" | "2";
  /** ErrorCode is the error code of the recording, if any. */
  ErrorCode?: string;
  /**
   * CallSid is a unique identifier for the call. This is the call that was recorded.
   * In the current implementation, if the call is forwarded,
   * the CallSid will be the forwarded call's Sid.
   * If we immediatedly started recording due to Do Not Disturb or Out of Office,
   * the CallSid will be the original call's Sid.
   */
  CallSid: string;
  /** RecordingStartTime is the time the recording started */
  RecordingStartTime: string;
  /** AccountSid is a unique identifier for the Twilio account. */
  AccountSid: string;
  /** RecordingDuration is the duration of the recording. */
  RecordingDuration?: string;
  /** The user for whom this voicemail was intended. */
  ownerId: any;
  /** Boolean indicating whether the voicemail has been listened to. */
  read?: boolean;
  /** Boolean indicating whether the voicemail has been archived. */
  archived?: boolean;
  /** Recipient's phone number */
  toPhoneNumber: string;
  toUser: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  /** Sender's phone number */
  fromPhoneNumber: string;
  fromUser?: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetVoicemailsByIdArgs = string;
export type PatchVoicemailsByIdRes = /** status 200 Successful update */ {
  /**
   * Recording source refers to the API that initiated the recording, which in this case is
   * theStartCallRecordingAPI.
   */
  RecordingSource?: string;
  /**
   * Recording track refers to the audio track that was recorded. In this case,
   * it is both the caller and the call recipient.
   */
  RecordingTrack?: string;
  /** RecordingSid is a unique identifier for the recording. */
  RecordingSid: string;
  /** RecordingUrl is the URL of the recording. */
  RecordingUrl: string;
  /**
   * RecordingStatus is the status of the recording, which can be 'in-progress', 'completed', or
   * 'absent'.
   */
  RecordingStatus: "in-progress" | "completed" | "absent";
  /**
   * RecordingChannels is the number of channels that were recorded.
   * Possible values are '1' or '2'
   */
  RecordingChannels?: "1" | "2";
  /** ErrorCode is the error code of the recording, if any. */
  ErrorCode?: string;
  /**
   * CallSid is a unique identifier for the call. This is the call that was recorded.
   * In the current implementation, if the call is forwarded,
   * the CallSid will be the forwarded call's Sid.
   * If we immediatedly started recording due to Do Not Disturb or Out of Office,
   * the CallSid will be the original call's Sid.
   */
  CallSid: string;
  /** RecordingStartTime is the time the recording started */
  RecordingStartTime: string;
  /** AccountSid is a unique identifier for the Twilio account. */
  AccountSid: string;
  /** RecordingDuration is the duration of the recording. */
  RecordingDuration?: string;
  /** The user for whom this voicemail was intended. */
  ownerId: any;
  /** Boolean indicating whether the voicemail has been listened to. */
  read?: boolean;
  /** Boolean indicating whether the voicemail has been archived. */
  archived?: boolean;
  /** Recipient's phone number */
  toPhoneNumber: string;
  toUser: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  /** Sender's phone number */
  fromPhoneNumber: string;
  fromUser?: {
    /**
     * The preferred, full name for the user. If their legal/insurance name is different,
     * that should be stored in billing info.
     */
    name?: string;
    /** How the user or staff's name is pronounced. This is visible in the user app. */
    namePronunciation?: string;
    /** Shortened name for the user, so we can reference them in a HIPAA-compliant manner. */
    initials?: string;
    birthday?: any;
    /**
     * The gender the user identifies as. If different than the gender listed on their insurance,
     * that should be stored in billing info.
     */
    gender?: "Male" | "Female" | "Prefer not to say" | "Prefer to self-describe" | "Non-binary";
    /** If gender is 'Prefer to self-describe', this is used */
    genderSelfDescribe?: string;
    /** The user's current timezone, based on their phone's location. */
    timezone?:
      | "America/New_York"
      | "America/Chicago"
      | "America/Denver"
      | "America/Los_Angeles"
      | "America/Anchorage"
      | "Pacific/Honolulu"
      | "America/Phoenix";
    staffRoles?: {
      /**
       * Gives the ability to access all users, set other staff passwords, and access super user
       * tools.
       */
      SuperUser?: boolean;
      CareAdvocate?: boolean;
      FamilyGuide?: boolean;
      PatientGuide?: boolean;
      Psychiatrist?: boolean;
      Therapist?: boolean;
      EnrollmentCoordinator?: boolean;
      FamilyGuideSupervisor?: boolean;
      PatientGuideSupervisor?: boolean;
      TherapistSupervisor?: boolean;
      ClinicalLeader?: boolean;
      ClinicalDirector?: boolean;
      /** Only one staff user should have this role */
      RiskManager?: boolean;
      /** Only one staff user should have this role */
      MedicalDirector?: boolean;
      EnrollmentSupervisor?: boolean;
      ClinicalQualityAssurance?: boolean;
      SoftwareEngineer?: boolean;
      Guide?: boolean;
      MemberGuideSupervisor?: boolean;
      _id?: string;
    };
    outOfOffice?: boolean;
    /** Staff Degrees, certifications, etc. E.g. LCSW */
    credentials?: string;
    /**
     * The Twilio phone number users can text or call to reach this staff member.
     * Send a text to a test user  to get a phone number assigned.
     */
    staffPhoneNumber?: string;
    careTeam?: {
      Therapist?: any;
      Psychiatrist?: any;
      Guide?: any;
      PatientGuide?: any;
      FamilyGuide?: any;
      _id?: string;
    };
    /** The staff's supervisor. */
    supervisor?: any;
    fitbitId?: string;
    fitbitAccessToken?: string;
    fitbitRefreshToken?: string;
    fitbitConfig?: {
      enabled?: boolean;
      sleepEnabled?: boolean;
      activityEnabled?: boolean;
      allowReminders?: boolean;
      note?: string;
    };
    type?: "Staff" | "Member" | "FamilyMember" | "Patient";
    consentFormAgreements?: {
      consentFormId: number;
      isAgreed: boolean;
      agreedDate?: string;
      consentFormType:
        | "patientAgreement"
        | "familyMemberAgreement"
        | "consent"
        | "transportation"
        | "research"
        | "privacy"
        | "hipaa"
        | "virginiaRights";
      _id?: string;
    }[];
    /** Whether the user has agreed to receive push notifications */
    pushNotifications?: boolean;
    /**
     * Whether SMS messages are allowed or not for the user.
     * Generally used to disable SMS after delivery failures.
     */
    smsEnabled?: boolean;
    smsErrors?: {
      message?: string;
      notification?: any;
      error?: string;
      date?: string;
    }[];
    /** Whether the user has agreed to receive automated notifications, e.g. for appointments. */
    smsNotifications?: boolean;
    /** Whether the user has agreed to receive SMS messages from staff */
    smsMessaging?: boolean;
    expoToken?: string;
    expoTokens?: string[];
    /** Web push subscription info */
    webPushTokens?: {
      /**
       * The endpoint takes the form of a custom URL pointing to a push server,
       * which can be used to send a push message to the particular service worker instance that
       * subscribed to the push service.
       */
      endpoint: string;
      /** An authentication secret for the push server */
      auth: string;
      /** An Elliptic curve Diffie–Hellman public key on the P-256 curve for the web push server. */
      p256dh: string;
      _id?: string;
    }[];
    conversations?: {
      conversationId: any;
      role?: "Guide" | "PatientGuide" | "Therapist" | "Psychiatrist" | "SuperUser" | "FamilyGuide";
    }[];
    /** Hides the user from the UI without deleting them. */
    archived?: boolean;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      countyName?: string;
      countyCode?: string;
      _id?: string;
    };
    phoneNumber?: string;
    usageData?: {
      /** The last time the app was launched. */
      lastAppLaunch?: string;
      currentAppVersion?: string;
      phoneModel?: string;
      operatingSystem?: string;
      _id?: string;
    };
    testUser?: boolean;
    pronouns?: string;
    bio?: string;
    /** A string describing the user's care plan. This will be visible in the apps. */
    carePlan?: string;
    /**
     * A clinical "one liner" describing the patient, their symptoms, meds, treatment,
     * and quick current info. This is only shown in the staff app.
     */
    oneLiner?: string;
    /** A place to store weekly updates ahead of care team huddles. Only shown in the staff app. */
    weeklyUpdates?: string;
    /**
     * Care team members are doing some work on “thrive planning” with patients and this is where
     * they can store it shown to staff AND members
     */
    thrivePlan?: string;
    engagementDetails?: string;
    /** Things that could be triggering for the patient that staff needs to be aware of. */
    safetyConcerns?: string[];
    /**
     * A string describing the user's safety plan. This will be visible in the apps under care
     * plan.
     */
    safetyPlan?: string;
    /**
     * Some early test users will be receiving guide-only services, with no clinical services.
     * At a minimum, we hide references to our clinical services from their app and make this
     * apparent in the workflows.
     */
    guideOnly?: boolean;
    /**
     * *** DEPRECATED - only used for backwards compatibility ***A 6 digit number as a shorthand
     * for members. Lots of our forms require memberId to be written on every page,and this is a
     * lot easier than `_id`. Use a unique sparse index because staff don't have memberIds,
     * and we don't want to have to check for nulls.
     */
    memberId?: number;
    patientId?: number;
    /**
     * Charm automatically creates a patient id for us to use. If this is not set,
     * the user is not synced to Charm.
     */
    charmPatientId?: string;
    /**
     * For staff that are also set up on Charm, we save their ID so we can show which provider is
     * writing/signing notes.
     */
    charmProviderId?: string;
    taskIdentifiers?: {
      scheduledOfflineToggleForMembersTaskName?: string;
      scheduledOfflineToggleForPatientsTaskName?: string;
      scheduledOfflineToggleForFamilyMembersTaskName?: string;
      _id?: string;
    };
    online?: {
      forMembers?: boolean;
      scheduledOfflineToggleForMembers?: string;
      forPatients?: boolean;
      scheduledOfflineToggleForPatients?: string;
      forFamilyMembers?: boolean;
      scheduledOfflineToggleForFamilyMembers?: string;
      _id?: string;
    };
    billingInfo?: {
      firstName?: string;
      lastName?: string;
      gender?: "male" | "female" | "unknown" | "other";
      insurancePlan?: any;
      renewalDate?: any;
      memberId?: string;
      groupId?: string;
      healthPlanId?: string;
      policyHolder?: "self" | "other";
      authorizationCode?: string;
      medicaidNumber?: string;
      _id?: string;
    };
    charmErrors?: string[];
    growth?: {
      authorizationDate?: any;
      referralSource?: any;
      referralMethod?: any;
      /** The date the patient was referred to Flourish, either self-referral or from a partner */
      referralDate?: any;
      /** The date the patient was accepted into our enrollment pipeline. */
      referralAcceptedDate?: any;
      /**
       * The date we can start billing for the patient.
       * This should be the date of their first attended therapy intake.
       * This will be used to calculate billing months for the patient,
       * which will be used for engagement tracking.
       * Billing month will be from this day to this day + 1 month - 1 day, rounded down. E.g.
       * 2/15 - 3/14. Or 1/31 - 2/28.
       */
      serviceStartDate?: any;
      /** The date the patient was fully enrolled in the program and finished intakes. */
      enrolledDate?: any;
      /** The actual date the patient was discharged from the Flourish Health program. */
      dischargeDate?: any;
      /**
       * The anticipated date we expect the patient will be discharged from the Flourish Health
       * program. When service start date is set, this is defaulted to 1 year from service start
       * date. Will also be edited to reflect anticipated upcoming early discharge.
       */
      estimatedDischargeDate?: any;
      /** Whether the patient is currently hospitalized. */
      isPatientCurrentlyHospitalized?: boolean;
      /** Whether the patient is currently in outpatient care. */
      outpatient?: boolean;
      /**
       * The date the patient was discharged from a hospital prior to enrollment in Flourish
       * Program.
       */
      hospitalDischargeDate?: any;
      /**
       * The external therapist/other clinician who referred the patient to Flourish and was likely
       * part of the patient's most recent care team. This may be the same as
       * referringPsychiatrist.
       */
      referringClinician?: any;
      /**
       * The external psychiatrist who referred the patient to Flourish and was likely part of the
       * patient's most recent care team.
       */
      referringPsychiatrist?: any;
      /** Link to the Google Drive folder where the user's documents outside the app are stored. */
      documentFolder?: string;
      /** Notes about the user's enrollment process, for internal use. */
      notes?: string;
      eligibilityInterview?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      clinicalIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      therapyIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      psychiatryIntake?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      inHomeOnboarding?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      meetAndGreet?: {
        /** The date the intake was scheduled for. */
        date?: string;
        /** Whether the intake was attended yet or not. */
        attended?: boolean;
        /** The linked schedule item for the intake. */
        scheduleItemId?: string;
        _id?: string;
        created?: string;
        updated?: string;
      };
      origin?: string;
      leadSource?: string;
      optInDetails?: string;
      optInDate?: string;
      sourceReferrerUrl?: string;
      sourceContent?: string;
      sourceCampaign?: string;
      conversionReferrerUrl?: string;
      referringProviderPhoneNumber?: string;
      referringProviderOrganization?: string;
      referringProviderName?: string;
      referringProviderEmail?: string;
      referringClinicianName?: string;
      _id?: string;
    };
    tasks?: {
      roiSent?: boolean;
      signedROIReceived?: boolean;
      signedROISentToProviders?: boolean;
      medicalRecordsRequested?: boolean;
      dischargeMedsReceived?: boolean;
      documentationRequestSent?: boolean;
      authorizationInformationConfirmed?: boolean;
      _id?: string;
    };
    contacts?: {
      name: string;
      types: string[];
      isPrimary: boolean;
      phoneNumber?: string;
      email?: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      notes?: string;
      _id?: string;
    }[];
    /** SMS, app, phone, email, times of day, etc */
    preferredPharmacies?: {
      primary: boolean;
      name: string;
      address: {
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        countyName?: string;
        countyCode?: string;
        _id?: string;
      };
      phoneNumber?: string;
      faxNumber?: string;
      notes?: string;
      _id?: string;
    }[];
    settings?: {
      colorTheme?: string;
      _id?: string;
    };
    statusId?: any;
    /** The reason(s) selected for the user's current UserStatus */
    statusReasons?: string[];
    carePod?: any;
    /**
     * Link to the video chat platform the user prefers.
     * This is shown in the patient/family member app. Usually Doxy or Zoom.
     */
    videoChatLink?: string;
    /** The last time a claim was submitted for this user */
    lastClaimDate?: string;
    /** SMS, app, phone, email, times of day, etc. */
    communicationPreferences?: string;
    /** The primary language the user speaks */
    languages?: string;
    interpreterNeeded?: boolean;
    featureFlags?: {
      enableAlertInstances?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableWebPush?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTwilioCalling?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      allowOpenNotesScheduleItemLinking?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableGoogleCalendar?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableToDoList?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      generateFeedbackSurveys?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableReorganizePanel?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enablePreClinicUpdates?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      appointments?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableUserAlertsExplorer?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventHelpButton?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableCriticalEventCrisisEscalation?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableTherapyCadenceIndicator?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableApptTypesTherapyPatientAndTherapyCaregiver?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      enableDoseSpot?: {
        enabled?: boolean;
        showTutorial?: boolean;
      };
      _id?: string;
    };
    /** Which Flourish company the user is associated with */
    companyOrganizationId?: any;
    /** The user's diagnosis */
    diagnosis?: {
      diagnoses?: any[];
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** helps staff remember what kind of engagement/outreach a patient needs */
    clinicalStatus?: {
      status?: "High" | "Moderate" | "Mild-Moderate";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** how often the patient has recurring therapy appointments */
    therapyCadence?: {
      status?: "Weekly" | "EveryOtherWeek" | "TwiceWeekly";
      _id?: string;
      created?: string;
      updated?: string;
    };
    /** The hours a staff user is available to be scheduled. */
    availabilitySchedule?: {
      /** Start hour, 24 hour format */
      startHour: number;
      /** End hour, 24 hour format */
      endHour: number;
      /** Start minute, 24 hour format */
      startMin: number;
      /** End minute, 24 hour format */
      endMin: number;
      /** Day of the week for the schedule */
      day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
      _id?: string;
    }[];
    scheduleCapacity?: {
      /** Psychiatry Intake slots per week */
      PsychiatryIntake?: number;
      /** Therapy Intake slots per week */
      TherapyIntake?: number;
      /** Patient Guide Intake slots per week */
      PatientGuideIntake?: number;
      /** Family Guide Intake slots per week */
      FamilyGuideIntake?: number;
      /** Care Advocate Intake slots per week */
      CareAdvocateIntake?: number;
      _id?: string;
    };
    /**
     * Memory is appended to the start of every GPT conversation to simulate memory and make it
     * less tedious
     */
    gptMemory?: string;
    /**
     * The ID of the user in Leadsquared. Could be a lead or opportunity ID.
     * Use user type to determine (Lead is FamilyMember and Opportunity is Patient
     */
    leadsquaredId?: string;
    /** The ID of the patient in DoseSpot */
    dosespotPatientId?: string;
    /** The ID of the clinician in DoseSpot */
    dosespotClinicianId?: string;
    _id?: string;
    admin?: boolean;
    email?: string;
    /** When a user is set to disable, all requests will return a 401 */
    disabled?: boolean;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
    hash?: string;
    salt?: string;
    attempts?: number;
    last?: string;
  };
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchVoicemailsByIdArgs = {
  id: string;
  body: {
    /**
     * Recording source refers to the API that initiated the recording, which in this case is
     * theStartCallRecordingAPI.
     */
    RecordingSource?: string;
    /**
     * Recording track refers to the audio track that was recorded. In this case,
     * it is both the caller and the call recipient.
     */
    RecordingTrack?: string;
    /** RecordingSid is a unique identifier for the recording. */
    RecordingSid?: string;
    /** RecordingUrl is the URL of the recording. */
    RecordingUrl?: string;
    /**
     * RecordingStatus is the status of the recording, which can be 'in-progress', 'completed', or
     * 'absent'.
     */
    RecordingStatus?: "in-progress" | "completed" | "absent";
    /**
     * RecordingChannels is the number of channels that were recorded.
     * Possible values are '1' or '2'
     */
    RecordingChannels?: "1" | "2";
    /** ErrorCode is the error code of the recording, if any. */
    ErrorCode?: string;
    /**
     * CallSid is a unique identifier for the call. This is the call that was recorded.
     * In the current implementation, if the call is forwarded,
     * the CallSid will be the forwarded call's Sid.
     * If we immediatedly started recording due to Do Not Disturb or Out of Office,
     * the CallSid will be the original call's Sid.
     */
    CallSid?: string;
    /** RecordingStartTime is the time the recording started */
    RecordingStartTime?: string;
    /** AccountSid is a unique identifier for the Twilio account. */
    AccountSid?: string;
    /** RecordingDuration is the duration of the recording. */
    RecordingDuration?: string;
    /** The user for whom this voicemail was intended. */
    ownerId?: any;
    /** Boolean indicating whether the voicemail has been listened to. */
    read?: boolean;
    /** Boolean indicating whether the voicemail has been archived. */
    archived?: boolean;
    /** Recipient's phone number */
    toPhoneNumber?: string;
    /** Sender's phone number */
    fromPhoneNumber?: string;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type PostWorkflowMappingsRes = /** status 201 Successful create */ {
  userId: any;
  staffId: any;
  archived?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PostWorkflowMappingsArgs = {
  userId?: any;
  staffId?: any;
  archived?: boolean;
  _id?: string;
  updated?: string;
  created?: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetWorkflowMappingsRes = /** status 200 Successful list */ {
  data?: {
    userId: any;
    staffId: any;
    archived?: boolean;
    _id: string;
    updated: string;
    created: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  }[];
  more?: boolean;
  page?: number;
  limit?: number;
  total?: number;
};
export type GetWorkflowMappingsArgs = {
  _id?: {
    $in?: string[];
  };
  userId?:
    | any
    | {
        $in?: any[];
      };
  staffId?:
    | any
    | {
        $in?: any[];
      };
  page?: number;
  sort?: string;
  limit?: number;
};
export type GetWorkflowMappingsByIdRes = /** status 200 Successful read */ {
  userId: any;
  staffId: any;
  archived?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type GetWorkflowMappingsByIdArgs = string;
export type PatchWorkflowMappingsByIdRes = /** status 200 Successful update */ {
  userId: any;
  staffId: any;
  archived?: boolean;
  _id: string;
  updated: string;
  created: string;
  /**
   * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
   * true} to find them.
   */
  deleted?: boolean;
};
export type PatchWorkflowMappingsByIdArgs = {
  id: string;
  body: {
    userId?: any;
    staffId?: any;
    archived?: boolean;
    _id?: string;
    updated?: string;
    created?: string;
    /**
     * Deleted objects are not returned in any find() or findOne() by default. Add {deleted:
     * true} to find them.
     */
    deleted?: boolean;
  };
};
export type DeleteWorkflowMappingsByIdRes = /** status 204 Successful delete */ void;
export type DeleteWorkflowMappingsByIdArgs = string;
export type LimitedUser = {
  /**
   * The preferred, full name for the user. If their legal/insurance name is different,
   * that should be stored in billing info.
   */
  name: string;
  /** The user's current timezone, based on their phone's location. */
  timezone?:
    | "America/New_York"
    | "America/Chicago"
    | "America/Denver"
    | "America/Los_Angeles"
    | "America/Anchorage"
    | "Pacific/Honolulu"
    | "America/Phoenix";
  staffRoles: {
    /**
     * Gives the ability to access all users, set other staff passwords, and access super user
     * tools.
     */
    SuperUser?: boolean;
    CareAdvocate?: boolean;
    FamilyGuide?: boolean;
    PatientGuide?: boolean;
    Psychiatrist?: boolean;
    Therapist?: boolean;
    EnrollmentCoordinator?: boolean;
    FamilyGuideSupervisor?: boolean;
    PatientGuideSupervisor?: boolean;
    TherapistSupervisor?: boolean;
    ClinicalLeader?: boolean;
    ClinicalDirector?: boolean;
    /** Only one staff user should have this role */
    RiskManager?: boolean;
    /** Only one staff user should have this role */
    MedicalDirector?: boolean;
    EnrollmentSupervisor?: boolean;
    ClinicalQualityAssurance?: boolean;
    SoftwareEngineer?: boolean;
    Guide?: boolean;
    MemberGuideSupervisor?: boolean;
    _id?: string;
  };
  careTeam: {
    Therapist?: any;
    Psychiatrist?: any;
    Guide?: any;
    PatientGuide?: any;
    FamilyGuide?: any;
    _id?: string;
  };
  type: "Staff" | "Member" | "FamilyMember" | "Patient";
  testUser?: boolean;
  pronouns?: string;
  carePod?: any;
  _id: string;
};
export type ApiError = {
  /** The error message */
  title?: string;
  /** The HTTP status code applicable to this problem, expressed as a string value. */
  status?: number;
  /** A unique identifier for this particular occurrence of the problem. */
  id?: string;
  links?: {
    /**
     * A link that leads to further details about this particular occurrence of the problem. When
     * derefenced, this URI SHOULD return a human-readable description of the error.
     */
    about?: string;
    /**
     * A link that identifies the type of error that this particular error is an instance of.
     * This URI SHOULD be dereferencable to a human-readable explanation of the general error.
     */
    type?: string;
  };
  /** An application-specific error code, expressed as a string value. */
  code?: string;
  /**
   * A human-readable explanation specific to this occurrence of the problem. Like title,
   * this field’s value can be localized.
   */
  detail?: string;
  source?: {
    /**
     * A JSON Pointer [RFC6901] to the associated entity in the request document [e.g.
     * "/data" for a primary data object, or "/data/attributes/title" for a specific attribute].
     */
    pointer?: string;
    /** A string indicating which URI query parameter caused the error. */
    parameter?: string;
    /** A string indicating the name of a single request header which caused the error. */
    header?: string;
  };
  /** A meta object containing non-standard meta-information about the error. */
  meta?: object;
};
export const {
  useGetAlertInstanceQuery,
  useGetAlertInstanceByIdQuery,
  usePatchAlertInstanceByIdMutation,
  useGetAlertInstancesQuery,
  useGetAlertInstancesByIdQuery,
  usePatchAlertInstancesByIdMutation,
  useGetAlertsQuery,
  useGetAlertsByIdQuery,
  usePatchAlertsByIdMutation,
  useGetAppointmentSlotsQuery,
  usePostAuditLogMutation,
  useGetAuditLogQuery,
  useGetAuditLogByIdQuery,
  usePostAvatarsMutation,
  useGetAvatarsQuery,
  useGetAvatarsByIdQuery,
  usePatchAvatarsByIdMutation,
  usePostCarePodsMutation,
  useGetCarePodsQuery,
  useGetCarePodsByIdQuery,
  usePatchCarePodsByIdMutation,
  useDeleteCarePodsByIdMutation,
  useGetCharmTokensQuery,
  useDeleteCharmTokensByIdMutation,
  usePostCompanyOrganizationsMutation,
  useGetCompanyOrganizationsQuery,
  useGetCompanyOrganizationsByIdQuery,
  usePatchCompanyOrganizationsByIdMutation,
  useDeleteCompanyOrganizationsByIdMutation,
  usePostConversationsMutation,
  useGetConversationsQuery,
  useGetConversationsByIdQuery,
  usePatchConversationsByIdMutation,
  useDeleteConversationsByIdMutation,
  useGetConversationsSummaryQuery,
  usePostConversationStatusesMutation,
  useGetConversationStatusesQuery,
  useGetConversationStatusesByIdQuery,
  useDeleteConversationStatusesByIdMutation,
  usePostCriticalEventsMutation,
  useGetCriticalEventsQuery,
  useGetCriticalEventsByIdQuery,
  usePostCsvMutation,
  usePostMessageCsvMutation,
  useGetDiagnosesQuery,
  useGetDiagnosesByIdQuery,
  usePostDosespotUiUrlMutation,
  usePostDosespotSyncPatientMutation,
  usePostDotPhrasesMutation,
  useGetDotPhrasesQuery,
  useGetDotPhrasesByIdQuery,
  usePatchDotPhrasesByIdMutation,
  useDeleteDotPhrasesByIdMutation,
  usePostExternalCliniciansMutation,
  useGetExternalCliniciansQuery,
  useGetExternalCliniciansByIdQuery,
  usePatchExternalCliniciansByIdMutation,
  useDeleteExternalCliniciansByIdMutation,
  usePostFamilyUnitsMutation,
  useGetFamilyUnitsQuery,
  useGetFamilyUnitsByIdQuery,
  usePatchFamilyUnitsByIdMutation,
  useDeleteFamilyUnitsByIdMutation,
  useGetFitbitSleepQuery,
  useGetFitbitSleepByIdQuery,
  useGetFitbitStepsQuery,
  useGetFitbitStepsByIdQuery,
  useGetFitbitHrvQuery,
  useGetFitbitHrvByIdQuery,
  useGetFitbitHeartRateQuery,
  useGetFitbitHeartRateByIdQuery,
  useGetFitbitStatusesQuery,
  useGetFitbitStatusesByIdQuery,
  usePostFormInstancesMutation,
  useGetFormInstancesQuery,
  useGetFormInstancesByIdQuery,
  usePatchFormInstancesByIdMutation,
  useDeleteFormInstancesByIdMutation,
  usePostFormInstancesPdfByFormInstanceIdMutation,
  usePostFormsMutation,
  useGetFormsQuery,
  useGetFormsByIdQuery,
  usePatchFormsByIdMutation,
  useDeleteFormsByIdMutation,
  usePostGptHistoriesMutation,
  useGetGptHistoriesQuery,
  useGetGptHistoriesByIdQuery,
  usePatchGptHistoriesByIdMutation,
  useDeleteGptHistoriesByIdMutation,
  usePostGuidingHoursMutation,
  useGetGuidingHoursQuery,
  useGetGuidingHoursByIdQuery,
  usePatchGuidingHoursByIdMutation,
  useDeleteGuidingHoursByIdMutation,
  usePostHealthEventInstancesMutation,
  useGetHealthEventInstancesQuery,
  useGetHealthEventInstancesByIdQuery,
  usePatchHealthEventInstancesByIdMutation,
  useDeleteHealthEventInstancesByIdMutation,
  usePostHealthEventsMutation,
  useGetHealthEventsQuery,
  useGetHealthEventsByIdQuery,
  usePatchHealthEventsByIdMutation,
  useDeleteHealthEventsByIdMutation,
  usePostInsurancePlansMutation,
  useGetInsurancePlansQuery,
  useGetInsurancePlansByIdQuery,
  usePatchInsurancePlansByIdMutation,
  useDeleteInsurancePlansByIdMutation,
  usePostMessagesMutation,
  useGetMessagesQuery,
  useGetMessagesByIdQuery,
  usePatchMessagesByIdMutation,
  useDeleteMessagesByIdMutation,
  usePostNotificationsMutation,
  useGetNotificationsQuery,
  useGetNotificationsByIdQuery,
  usePatchNotificationsByIdMutation,
  useDeleteNotificationsByIdMutation,
  usePostPatientWeeklyUpdatesMutation,
  useGetPatientWeeklyUpdatesQuery,
  useGetPatientWeeklyUpdatesByIdQuery,
  usePatchPatientWeeklyUpdatesByIdMutation,
  usePostPreClinicUpdatesMutation,
  useGetPreClinicUpdatesQuery,
  useGetPreClinicUpdatesByIdQuery,
  usePatchPreClinicUpdatesByIdMutation,
  useDeletePreClinicUpdatesByIdMutation,
  useGetPrescriptionsQuery,
  useGetPrescriptionsByIdQuery,
  usePostRefreshPrescriptionsMutation,
  usePostReferralMethodsMutation,
  useGetReferralMethodsQuery,
  useGetReferralMethodsByIdQuery,
  usePatchReferralMethodsByIdMutation,
  useDeleteReferralMethodsByIdMutation,
  usePostReferralSourcesMutation,
  useGetReferralSourcesQuery,
  useGetReferralSourcesByIdQuery,
  usePatchReferralSourcesByIdMutation,
  useDeleteReferralSourcesByIdMutation,
  usePostScheduleItemsMutation,
  useGetScheduleItemsQuery,
  useGetScheduleItemsByIdQuery,
  usePatchScheduleItemsByIdMutation,
  useDeleteScheduleItemsByIdMutation,
  usePostRecurringScheduleItemsMutation,
  useGetRecurringScheduleItemsQuery,
  useGetRecurringScheduleItemsByIdQuery,
  usePatchRecurringScheduleItemsByIdMutation,
  useDeleteRecurringScheduleItemsByIdMutation,
  usePostSyncScheduleGoogleCalendarMutation,
  usePostScriptsLoadTestDataMutation,
  usePostScriptsFtpMutation,
  usePostScriptsCharmSyncStaffMutation,
  usePostScriptsCharmSyncUsersMutation,
  usePostScriptsCharmSyncFormsMutation,
  usePostScriptsInternalChatSetupMutation,
  usePostScriptsCreateConvosMutation,
  usePostScriptsDeleteOrphanWorkflowMappingsMutation,
  usePostScriptsBackfillUserSettingsMutation,
  usePostScriptsSyncAllScheduleItemsGoogleCalendarMutation,
  usePostScriptsCreateGoogleCalendarMutation,
  usePostScriptsSyncAssessmentsMutation,
  usePostScriptsLoadMedicalDiagnosisDataMutation,
  usePostScriptsLoadAlertsDataMutation,
  usePostScriptsCleanupNotificationTasksMutation,
  usePostScriptsBackfillPatientIdsMutation,
  usePostScriptsSyncIntakeDatesMutation,
  usePostScriptsDeleteNotificationsForDeletedScheduledItemsMutation,
  usePostScriptsUpdateFormsIsRequiredMutation,
  usePostScriptsLoadCompanyHolidaysMutation,
  usePostScriptsSaveAllUsersMutation,
  usePostTodoMutation,
  useGetTodoQuery,
  useGetTodoByIdQuery,
  usePatchTodoByIdMutation,
  useDeleteTodoByIdMutation,
  usePostUserBioMutation,
  useGetUserBioQuery,
  useGetUserBioByIdQuery,
  usePatchUserBioByIdMutation,
  useDeleteUserBioByIdMutation,
  usePostGetStaffPhoneNumberMutation,
  usePostUsersMutation,
  useGetUsersQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  useDeleteUsersByIdMutation,
  usePostUserSessionsMutation,
  useGetUserSessionsQuery,
  useGetUserSessionsByIdQuery,
  usePatchUserSessionsByIdMutation,
  useDeleteUserSessionsByIdMutation,
  usePostUserSettingsMutation,
  useGetUserSettingsByIdQuery,
  usePatchUserSettingsByIdMutation,
  usePostUserStatusesMutation,
  useGetUserStatusesQuery,
  useGetUserStatusesByIdQuery,
  usePatchUserStatusesByIdMutation,
  useDeleteUserStatusesByIdMutation,
  useGetUserUpdatesQuery,
  useGetUserUpdatesByIdQuery,
  usePatchUserUpdatesByIdMutation,
  useGetVoicemailsQuery,
  useGetVoicemailsByIdQuery,
  usePatchVoicemailsByIdMutation,
  usePostWorkflowMappingsMutation,
  useGetWorkflowMappingsQuery,
  useGetWorkflowMappingsByIdQuery,
  usePatchWorkflowMappingsByIdMutation,
  useDeleteWorkflowMappingsByIdMutation,
} = injectedRtkApi;
