import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {isPatientOrFamilyMember} from "@utils";
import {Box, Button, Heading} from "ferns-ui";
import React from "react";

import {StaffStackParamList} from "../types";

export const ProfileSettings: React.FC<{}> = () => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const user = useReadProfile();

  if (!user) {
    return null;
  }

  return (
    <Box gap={4} width="100%">
      <Heading size="lg">Settings</Heading>

      <Button
        iconName="unlock"
        text="Reset Password"
        variant="primary"
        onClick={(): void => {
          if (user) {
            navigation.navigate("ResetPassword", {user});
          }
        }}
      />

      {Boolean(isPatientOrFamilyMember(user.type)) && (
        <Button
          iconName="bell"
          text="Notification Settings"
          variant="muted"
          onClick={(): void => {
            if (user) {
              navigation.navigate("NotificationsSettings");
            }
          }}
        />
      )}

      <Button
        iconName="palette"
        text="Color Theme"
        variant="secondary"
        onClick={(): void => {
          if (user) {
            navigation.navigate("ColorThemes");
          }
        }}
      />
    </Box>
  );
};
