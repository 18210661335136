import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetScheduleItemsByIdQuery, User} from "@store";
import {StaffStackParamList} from "@types";
import {IntakeScheduleType} from "@utils";
import {BorderTheme, Box, IconButton, printDateAndTime, Text} from "ferns-ui";
import React, {useCallback} from "react";

interface TapToEditScheduleProps {
  scheduleItemId?: string;
  border?: boolean;
  type: IntakeScheduleType;
  title?: string;
  currentUser: User;
}

export const TapToEditSchedule = ({
  scheduleItemId,
  border,
  title,
  type,
  currentUser,
}: TapToEditScheduleProps): React.ReactElement => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(scheduleItemId ?? skipToken);

  const onEdit = useCallback(() => {
    navigation.navigate("CreateScheduleItem", {scheduleItemId: scheduleItem?._id});
  }, [navigation, scheduleItem?._id]);

  const onAdd = useCallback(() => {
    let selectedStaffId;
    if (["Meet and Greet", "Clinical Intake", "In Home Onboarding Visit"].includes(type)) {
      selectedStaffId = currentUser.careTeam?.PatientGuide;
    } else if (type === "Therapy Intake") {
      selectedStaffId = currentUser.careTeam?.Therapist;
    } else if (type === "Psychiatry Intake") {
      selectedStaffId = currentUser.careTeam?.Psychiatrist;
    }

    navigation.navigate("CreateScheduleItem", {
      type,
      selectedUserId: currentUser?._id,
      selectedStaffId,
    });
  }, [
    currentUser?._id,
    currentUser.careTeam?.PatientGuide,
    currentUser.careTeam?.Psychiatrist,
    currentUser.careTeam?.Therapist,
    navigation,
    type,
  ]);

  return (
    <Box
      borderBottom={border ? ("default" as keyof BorderTheme) : undefined}
      direction="row"
      justifyContent="between"
      paddingY={2}
      width="100%"
    >
      <Box flex="grow">
        <Text bold>{title ?? type}</Text>
      </Box>
      <Box alignItems="center" direction="row" marginLeft={2}>
        <Box justifyContent="start">
          {Boolean(scheduleItem) ? (
            <Text align="right">
              {scheduleItem?.startDatetime
                ? printDateAndTime(scheduleItem.startDatetime, {
                    timezone: currentUser.timezone,
                    showTimezone: true,
                  })
                : ""}
            </Text>
          ) : (
            <Box marginRight={2}>
              <IconButton accessibilityLabel="add schedule" iconName="plus" onClick={onAdd} />
            </Box>
          )}
        </Box>
        {Boolean(scheduleItem) && (
          <IconButton accessibilityLabel="edit schedule" iconName="pencil" onClick={onEdit} />
        )}
      </Box>
    </Box>
  );
};
