import {useReadProfile} from "@hooks";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Page} from "ferns-ui";
import React, {useEffect, useState} from "react";
import {useWindowDimensions} from "react-native";

import {usePostDosespotUiUrlMutation} from "../store";

interface DoseSpotScreenProps extends StaffStackScreenProps<"DoseSpotUI"> {}

export const DoseSpotScreen = ({
  navigation,
  route,
}: DoseSpotScreenProps): React.ReactElement | null => {
  const {width, height} = useWindowDimensions();
  const user = useReadProfile();
  const [uiUrl, setUiUrl] = useState<string | null>(null);
  const [postDosespotUiUrl] = usePostDosespotUiUrlMutation();
  const {patientUserId} = route.params;

  // Fetch the url for the UI from the backend since it requires some crypto functions and access
  // keys
  useEffect(() => {
    async function fetchUiUrl(): Promise<void> {
      const result = await postDosespotUiUrl({
        patientUserId,
      }).unwrap();
      if (result.uiUrl) {
        setUiUrl(result.uiUrl);
      }
    }
    void fetchUiUrl();
  }, [postDosespotUiUrl, patientUserId]);

  if (!user || !patientUserId || !uiUrl) {
    return null;
  }

  return (
    <Page maxWidth="100%" navigation={navigation} onError={pageOnError}>
      <Box height="100%" width="100%">
        <FrameWrapper height={height - 100} url={uiUrl} width={width} />
      </Box>
    </Page>
  );
};

const FrameWrapper = ({
  height,
  url,
  width,
}: {
  height: number;
  url: string;
  width: number;
}): React.ReactNode => {
  const ref = React.useRef<HTMLIFrameElement>(null);
  return (
    <iframe
      ref={ref}
      height={height}
      id="myFrame"
      src={url}
      style={{
        width: "100%",
        overflow: "auto",
      }}
      width={width}
    />
  );
};
