import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  GetPrescriptionsRes,
  GetUsersByIdRes,
  useGetPrescriptionsQuery,
  useGetUsersByIdQuery,
  usePostDosespotSyncPatientMutation,
  usePostRefreshPrescriptionsMutation,
} from "@store";
import {StaffStackParamList} from "@types";
import {IsWeb, UserTypes} from "@utils";
import {
  Box,
  Button,
  printDate,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableText,
  Text,
  useToast,
} from "ferns-ui";
import startCase from "lodash/startCase";
import React, {ReactElement} from "react";

import {useReadProfile} from "../hooks";

export type PrescriptionDataType = NonNullable<GetPrescriptionsRes["data"]>;

const PrescriptionTable = ({
  prescriptions,
}: {
  prescriptions: PrescriptionDataType;
}): ReactElement => {
  return (
    <Table columns={[200, 200, 200, 200]}>
      <TableHeader>
        <TableHeaderCell index={0} title="Medication" />
        <TableHeaderCell index={1} title="Date Prescribed" />
        <TableHeaderCell index={3} title="Expiration Date" />
        <TableHeaderCell index={4} title="Status" />
      </TableHeader>
      {prescriptions.map((p) => (
        <TableRow key={p._id}>
          <TableText value={p.medicationName} />
          <TableText value={printDate(p.writtenDate)} />
          <TableText value={printDate(p.expirationDate)} />
          <TableText value={p.status} />
        </TableRow>
      ))}
    </Table>
  );
};

const DoseSpotRequiredFields = [
  "birthday",
  "billingInfo.gender",
  "billingInfo.firstName",
  "billingInfo.lastName",
  "address.address1",
  "address.city",
  "address.state",
  "address.zipcode",
  "phoneNumber",
] as const;

export const hasDoseSpotRequiredFields = (
  patient: GetUsersByIdRes
): {hasRequired: boolean; missingFields: string[]} => {
  const missingFields = DoseSpotRequiredFields.filter((field) => {
    const fieldParts = field.split(".");
    let value: any = patient;
    for (const part of fieldParts) {
      if (value && typeof value === "object" && part in value) {
        value = value[part];
      } else {
        return true; // Field is missing
      }
    }
    return value === undefined || value === null || value === "";
  });

  return {hasRequired: missingFields.length === 0, missingFields};
};

export const Prescriptions = ({userId}: {userId: string}): ReactElement | undefined => {
  const staffUser = useReadProfile();
  const {data: patient} = useGetUsersByIdQuery(userId);
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const {data: prescriptions} = useGetPrescriptionsQuery({patientId: userId});
  const [refreshPrescriptions] = usePostRefreshPrescriptionsMutation();
  const [syncPatient] = usePostDosespotSyncPatientMutation();
  const toast = useToast();

  if (!patient) {
    return undefined;
  }

  if (!patient?.dosespotPatientId) {
    const {hasRequired, missingFields} = hasDoseSpotRequiredFields(patient);
    if (!hasRequired) {
      const fields = missingFields.map((field) => {
        if (field === "billingInfo.gender") {
          return "Billing Gender";
        } else if (field === "billingInfo.firstName") {
          return "Billing First Name";
        } else if (field === "billingInfo.lastName") {
          return "Billing Last Name";
        }
        return startCase(field.split(".").pop() ?? "");
      });
      return (
        <Box alignItems="center" height="100%" justifyContent="center">
          <Text>The following fields are required to enable prescriptions:</Text>
          <Text>{fields.join(", ")}</Text>
        </Box>
      );
    } else {
      return (
        <Box alignItems="center" height="100%" justifyContent="center">
          <Button
            text="Enable Prescriptions"
            variant="primary"
            onClick={async (): Promise<void> => {
              try {
                await syncPatient({patientUserId: userId}).unwrap();
                toast.success("User synced");
              } catch (error: any) {
                toast.catch(error, "Error syncing user");
              }
            }}
          />
        </Box>
      );
    }
  }

  return (
    <Box>
      <Box width="100%">
        {prescriptions?.data && prescriptions.data.length > 0 && (
          <PrescriptionTable prescriptions={prescriptions.data} />
        )}
      </Box>
      <Box direction="row" paddingY={3}>
        {IsWeb && Boolean(staffUser?.dosespotClinicianId) && (
          <Box marginRight={2}>
            <Button
              text="Manage"
              variant="primary"
              onClick={
                (): void =>
                  navigation.navigate("DoseSpotUI", {
                    patientUserId: userId,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </Box>
        )}
        {/* The sync buttons will eventually be hidden for non-engineers/support staff, but until we get access to webhooks they will show for all users */}
        <Box marginRight={2}>
          <Button
            text="Sync Prescriptions"
            variant="muted"
            onClick={async (): Promise<void> => {
              await refreshPrescriptions({patientUserId: userId});
            }}
          />
        </Box>
        <Box>
          <Button
            text="Sync User"
            variant="muted"
            onClick={async (): Promise<void> => {
              try {
                await syncPatient({patientUserId: userId}).unwrap();
                toast.success("User synced");
              } catch (error: any) {
                toast.catch(error, "Error syncing user");
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
